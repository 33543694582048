import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { CompanyHeader } from "components/CompanyHeader";
import { useState } from "react";
import { studentInfoTypes } from "types/page/studentInfoTypes";
import NonAcceptedOfferedStudentProfileCard from "components/NonAcceptedOfferedStudentProfileCard";
import { Typography } from "@mui/material";
import { styled } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const mdTheme = createTheme();

const StyledHeadline = styled(Typography)`
  font-size: 22px !important;
  font-weight: bold !important;
  margin-top: 24px !important;
  color: dimgray;
`;
const StyledSubHeadline = styled(Typography)`
  font-size: 20px !important;
  margin-left: 16px !important;
  margin-top: 8px !important;
`;
const StyledButton = styled.button`
  width: 200px;
  height: 40px;
  font-size: 16px;
  border-radius: 5px;
  background-color: black;
  color: white;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  border: none;
  &:hover {
    background-color: #4d4d4d;
  }
`;

export default function EditRecruitDetail() {
  const navigate = useNavigate();

  const [open, setOpen] = useState(true);
  const [publicRecruit, setPublic] = useState(true);
  const [deleteModal, showDeleteModal] = useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const mainStyles = {
    width: open ? "calc(100vw - 240px)" : "calc(100vw - 72px)",
    marginLeft: open ? "240px" : "72px",
    transition: ".3s",
    overflow: "auto",
  };
  return (
    <ThemeProvider theme={mdTheme}>
      <CssBaseline />
      <CompanyHeader
        open={open}
        toggleDrawer={toggleDrawer}
        showrecruitModalProps={true}
      />
      <Box
        component="main"
        sx={{
          ...mainStyles,
        }}
      >
        <Box sx={{ mx: 5, my: 5, mt: 15 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mt: 5,
              mb: 3,
            }}
          >
            <Box
              sx={{
                borderRadius: 5,
                width: 200,
                height: 60,
                alignItems: "center",
                display: "flex",
              }}
            >
              <Box
                sx={{
                  width: "3px",
                  height: "50px",
                  backgroundColor: "red",
                  mr: 2,
                }}
              />
              <Box
                sx={{
                  width: "100%",
                  textAlign: "left",
                  color: "black",
                }}
              >
                <Typography sx={{ fontSize: 24, fontWeight: "bold" }}>
                  2024年卒向け
                </Typography>
                <Typography sx={{ fontWeight: "bold" }}>
                  ＜新卒採用＞
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Box sx={{ textAlign: "right", mr: 5 }}>
                <StyledButton>求人内容を編集する</StyledButton>
              </Box>
              <Box sx={{ textAlign: "right" }}>
                <StyledButton onClick={() => showDeleteModal(true)}>
                  この求人を削除する
                </StyledButton>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              width: { xs: 340, sm: 322 },
              height: 40,
              borderRadius: 20,
              marginTop: 5,
              marginBottom: 5,
              backgroundColor: "#D9D9D9",
            }}
          >
            <Button
              onClick={() => setPublic(true)}
              variant="contained"
              disableElevation
              sx={{
                width: 160,
                borderRadius: 20,
                height: 40,
                fontSize: { xs: "0.95rem", sm: "1.2rem" },
                fontWeight: "bold",
                color: publicRecruit ? "white" : "#4b4b4b",
                backgroundColor: publicRecruit ? "#4b4b4b" : "#D9D9D9",
                ":hover": {
                  backgroundColor: publicRecruit ? "#4b4b4b" : "#D9D9D9",
                },
              }}
            >
              掲載中
            </Button>
            <Button
              onClick={() => setPublic(false)}
              variant="contained"
              disableElevation
              sx={{
                width: 160,
                borderRadius: 20,
                fontWeight: "bold",
                color: publicRecruit ? "#4b4b4b" : "white",
                fontSize: { xs: "0.95rem", sm: "1.2rem" },
                height: 40,
                backgroundColor: publicRecruit ? "#D9D9D9" : "#4b4b4b",
                ":hover": {
                  backgroundColor: publicRecruit ? "#D9D9D9" : "#4b4b4b",
                },
              }}
            >
              未掲載
            </Button>
          </Box>
          <StyledHeadline>職種名</StyledHeadline>
          <StyledSubHeadline>早稲田大学 専任職員</StyledSubHeadline>

          <StyledHeadline>具体的な仕事内容</StyledHeadline>
          <StyledSubHeadline>大学運営に関わる庶務、事務作業</StyledSubHeadline>
          <StyledSubHeadline>学生、教員のサポート業務</StyledSubHeadline>
          <StyledSubHeadline>大学内イベントの企画、運営補助</StyledSubHeadline>
          <StyledHeadline>求人カテゴリー</StyledHeadline>
          <StyledSubHeadline>教育・事務職</StyledSubHeadline>
          <StyledHeadline>企業アピールポイント</StyledHeadline>
          <StyledSubHeadline>
            「学生と共に成長する、早稲田大学の一員を募集します！」
          </StyledSubHeadline>
          <StyledHeadline>採用卒業年</StyledHeadline>
          <StyledSubHeadline>2024年</StyledSubHeadline>
          <StyledHeadline>雇用形態</StyledHeadline>
          <StyledSubHeadline>正職員</StyledSubHeadline>
          <StyledHeadline>給与</StyledHeadline>
          <StyledSubHeadline>
            【基本給】月給 250,000 円〜350,000 円（経験・スキルにより決定）
          </StyledSubHeadline>
          <StyledSubHeadline>
            【年収例】年収 4,000,000円~5,500,000円（月給、賞与、残業手当含む）
          </StyledSubHeadline>
          <StyledHeadline>試用期間</StyledHeadline>
          <StyledSubHeadline>3ヶ月</StyledSubHeadline>
          <StyledHeadline>採用予定人数</StyledHeadline>
          <StyledSubHeadline>複数名</StyledSubHeadline>
          <StyledHeadline>採用までの希望日数</StyledHeadline>
          <StyledSubHeadline>応相談</StyledSubHeadline>
          <StyledHeadline>仕事内容</StyledHeadline>
          <StyledSubHeadline>
            早稲田大学の専任職員として、学生や教職員のサポートを中心に、日々の事務作業やイベントの企画・運営を行います。教育機関における事務経験が活かせます。
          </StyledSubHeadline>
          <StyledHeadline>アピールポイント</StyledHeadline>
          <StyledSubHeadline>教育機関での働きがい</StyledSubHeadline>
          <StyledSubHeadline>
            学生や教職員と密接に関わる機会多数
          </StyledSubHeadline>
          <StyledSubHeadline>
            ワークライフバランスを重視した勤務体系
          </StyledSubHeadline>
          <StyledHeadline>求める人材</StyledHeadline>
          <StyledSubHeadline>
            【必須条件】大学での事務経験、または同等の経験
          </StyledSubHeadline>
          <StyledSubHeadline>
            【歓迎するスキル】コミュニケーション能力、イベント企画経験
          </StyledSubHeadline>
          <StyledSubHeadline>
            【その他】チームワークを大切にできる方
          </StyledSubHeadline>
          <StyledHeadline>勤務時間・曜日</StyledHeadline>
          <StyledSubHeadline>
            9:00〜17:00（休憩1時間、実働7時間）
          </StyledSubHeadline>
          <StyledSubHeadline>週5日勤務（月曜日〜金曜日）</StyledSubHeadline>
          <StyledSubHeadline>残業あり（月平均10〜20時間）</StyledSubHeadline>
          <StyledHeadline>勤務地</StyledHeadline>
          <StyledSubHeadline>東京都新宿区</StyledSubHeadline>
          <StyledHeadline>アクセス</StyledHeadline>
          <StyledSubHeadline>早稲田大学最寄り駅より徒歩</StyledSubHeadline>
          <StyledHeadline>待遇・福利厚生</StyledHeadline>
          <StyledSubHeadline>正職員雇用、無期雇用契約</StyledSubHeadline>
          <StyledSubHeadline>年2回賞与（業績に応じる）</StyledSubHeadline>
          <StyledSubHeadline>社会保険完備、交通費全額支給</StyledSubHeadline>
          <StyledHeadline>その他</StyledHeadline>
          <StyledSubHeadline>
            教育機関での勤務経験を活かせる職場です。学生の成長を支えるやりがいのある仕事。
          </StyledSubHeadline>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
