import { Box, Button, Typography, styled } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CompanyHeader } from "components/CompanyHeader";
import DefaultMultilineTextField from "components/DefaultMultilineTextField";
import PreviewSnackbar from "components/PreviewSnackbar";
import { createOffer } from "hooks/firebase/context/offer";
import { useUser } from "hooks/firebase/context/auth";
import PreviewSnackbarEditableMsg from "components/PreviewSnackbarEditableMsg";

const mdTheme = createTheme();

const Title = styled(Typography)`
  font-size: 22px !important;
  font-weight: bold !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  text-align: center;
`;
const Subtitle = styled(Typography)`
  font-size: 18px !important;
  text-align: center;
`;

export default function CompleteReverseOfferProfile() {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useUser();

  const [open, setOpen] = useState(true);
  const [selectedTemplateId, setSelectedTemplateId] = useState("");
  const [studentSenderId, setStudentSenderId] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const mainStyles = {
    width: open ? "calc(100vw - 240px)" : "calc(100vw - 72px)",
    marginLeft: open ? "240px" : "72px",
    transition: ".3s",
    overflow: "auto",
  };

  useEffect(() => {
    setStudentSenderId(location.state.studentSenderId);
    setSelectedTemplateId(location.state.selectedTemplateId);
    window.scrollTo(0, 0);
  }, [location]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    function formRef(key: string): string {
      if (data.get(key) === null) return "";
      else return data.get(key) as string;
    }
    console.log(formRef("offerDetail"));
    if (user && studentSenderId && selectedTemplateId) {
      const offerDetail = formRef("offerDetail") ? formRef("offerDetail") : "";
      const createMsg: string = await createOffer(
        user!,
        studentSenderId,
        selectedTemplateId!,
        offerDetail
      );
      if (createMsg === "") {
        navigate("/company-offer-complete");
      } else {
        setErrMsg(createMsg);
      }
    }
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <CompanyHeader open={open} toggleDrawer={toggleDrawer} />
        <Box component="main" sx={{ ...mainStyles }}>
          {errMsg !== "" && (
            <PreviewSnackbarEditableMsg open={true} message={errMsg} />
          )}
          <Box sx={{ display: "flex", mt: 15, px: 20 }}>
            <Box
              component="form"
              onSubmit={handleSubmit}
              sx={{ pb: 5, width: "100%" }}
            >
              <Title>個別メッセージを入力してください</Title>
              <Subtitle>
                このフォームは任意ですが個別メッセージを送信することでオファー承認率が高くなります
              </Subtitle>
              <DefaultMultilineTextField
                name={"offerDetail"}
                placeholder="例：&#13;&#10;
                  〇〇様&#13;&#10;
                  初めまして、ライトローズ人事部の斉藤と申します。突然のメールとなりますが、貴重なお時間をいただけると幸いです。&#13;&#10;
                  〇〇様がこれまでに積み上げてきた学業成果や各種活動、特にプログラミングに関する情報を拝見させていただき、非常に強い印象を受けました。
                  私たちライトローズでは、常に新しい才能と共に成長を追求しております。そんな中、〇〇様のような優れたポテンシャルを持つ学生と共に未来を築いていくことに大変な興味を持っております。&#13;&#10;
                  是非ともライトローズにて、私たちと共にあなたのキャリアを形成していただけることを心より願っております。具体的なポジションや働き方に関しては、後ほど詳しくご説明させていただきたいと考えております。&#13;&#10;
                  もし興味を持っていただけましたら、ご都合の良い日時を教えていただき、弊社を訪問いただき、直接お話をさせていただけると幸いです。&#13;&#10;
                  長文となりましたが、最後までお読みいただきありがとうございます。〇〇様のご返信を心よりお待ちしております。&#13;&#10;
                  何卒よろしくお願い申し上げます&#13;&#10;"
                rows={15}
                required={true}
              />
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  type="submit"
                  variant="contained"
                  disableElevation
                  style={{
                    width: 100,
                    borderRadius: 5,
                    marginTop: 40,
                    marginBottom: 8,
                    height: 40,
                    backgroundColor: "black",
                  }}
                >
                  次へ
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
