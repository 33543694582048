import { Box, Button, Typography, styled } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CompanyHeader } from "components/CompanyHeader";
import PreviewSnackbar from "components/PreviewSnackbar";

const mdTheme = createTheme();

const Title = styled(Typography)`
  font-size: 22px !important;
  font-weight: bold !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  text-align: center;
`;
const Subtitle = styled(Typography)`
  font-size: 18px !important;
  text-align: center;
`;

export default function CompanyOfferComplete() {
  const navigate = useNavigate();

  const [open, setOpen] = useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const mainStyles = {
    width: open ? "calc(100vw - 240px)" : "calc(100vw - 72px)",
    marginLeft: open ? "240px" : "72px",
    transition: ".3s",
    overflow: "auto",
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <CompanyHeader open={open} toggleDrawer={toggleDrawer} />
        <PreviewSnackbar open={open} />
        <Box component="main" sx={{ ...mainStyles }}>
          <Box sx={{ display: "flex", mt: 15, px: 20 }}>
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Title>オファーを送信しました</Title>
              <Subtitle>
                「オファーを送信した学生」から送信したオファーを確認できます
              </Subtitle>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  onClick={() => navigate("/sample/accepted-student-offer")}
                  disableElevation
                  style={{
                    width: 250,
                    fontWeight: "bold",
                    borderRadius: 5,
                    marginTop: 30,
                    marginBottom: 8,
                    height: 40,
                    backgroundColor: "#434343",
                  }}
                >
                  オファーを送信した学生へ ＞
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
