import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { CompanyHeader } from "components/CompanyHeader";
import styled from "styled-components";
import { CardMedia, Chip, Grid, Modal, Typography } from "@mui/material";
import { useEffect, useLayoutEffect, useState } from "react";
import { studentInfoTypes } from "types/page/studentInfoTypes";
import { useUser } from "hooks/firebase/context/auth";
import {
  createOffer,
  getOfferTemplatesInfos,
} from "hooks/firebase/context/offer";
import { offerType } from "types/page/offerType";
import {
  graduatedMonthData,
  graduatedYearData,
  offerTypeData,
  prefectureData,
  studentDepartmentData,
  studentFacultyData,
  universityData,
} from "data/StudentProfileData";
import OfferTemplateCard from "components/OfferTemplateCard";
import { oemUniversityData } from "context/OEMinfo";
const mdTheme = createTheme();

const StyledSaveButton = styled.button`
  width: 150px;
  height: 40px;
  margin: 0 10px;
  font-size: 16px;
  border-radius: 20px;
  background-color: whitesmoke;
  color: #4f4f4f;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  border: 2px solid #4f4f4f;
  &:hover {
    background-color: white;
  }
`;
const StyledOfferButton = styled.button`
  width: 150px;
  height: 40px;
  margin: 0 10px;
  font-size: 16px;
  border-radius: 20px;
  background-color: #35b500;
  color: white;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  border: none;
  &:hover {
    background-color: #29d630;
  }
`;
const TableLeftText = styled(Typography)`
  font-size: 18px !important;
  color: dimgray !important;
  text-align: right !important;
`;
const TableRightText = styled(Typography)`
  font-size: 20px !important;
  font-weight: bold !important;
`;

const ModalBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  height: 90%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 20px;
  box-shadow: 24px;
  padding: 60px;
  text-align: center;
`;
const StyledRecruitButton = styled(Button)`
  display: flex;
  textalign: left;
  width: 90%;
  box-shadow: 0px 5px 10px lightgray;
  padding: 15px !important;
  background-color: white !important;
`;

export default function CompanyStudentProfileDetail() {
  // const [favorite, isFavorite] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const [studentInfos, setStudentInfos] = useState<studentInfoTypes>();
  const [offerTemplateState, setOfferTemplateState] = useState<offerType[]>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState("");
  useEffect(() => {
    setStudentInfos(location.state);
    window.scrollTo(0, 0);
  }, [location]);
  const mainStyles = {
    width: open ? "calc(100vw - 240px)" : "calc(100vw - 72px)",
    marginLeft: open ? "240px" : "72px",
    transition: ".3s",
    overflow: "auto",
  };
  const user = useUser();
  const handleClose = () => setIsModalOpen(false);

  const completeOffer = async () => {
    if (selectedTemplateId !== "") {
      const createMsg: string = await createOffer(
        user!,
        "QaOmCKhWjNT00dlVW1vo7mGKgGG3",
        selectedTemplateId!,
        "オファーしました！"
      );
      console.log("createMsg", createMsg);
    } else {
      alert("オファーする求人内容を選択してください");
    }

    // if (user != null && studentInfos && selectedTemplateId) {
    //   console.log("studentInfos", studentInfos);
    //   console.log("selectedTemplateId", selectedTemplateId);

    //   const createMsg: string = await createOffer(
    //     user!,
    //     studentInfos!.senderId,
    //     selectedTemplateId!
    //   );
    //   if (createMsg == "") {
    //     navigate("/companycompleteoffer", {
    //       state: {
    //         senderId: studentInfos.senderId,
    //       },
    //     });
    //   }
    // }
  };
  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <CompanyHeader open={open} toggleDrawer={toggleDrawer} />
        <Box
          component="main"
          sx={{
            ...mainStyles,
          }}
        >
          <Box
            sx={{
              mt: 8,
              px: 5,
              backgroundColor: "#C6D6E2",
            }}
          >
            <Box
              sx={{
                display: "flex",
              }}
            >
              <CardMedia
                component="img"
                sx={{ width: 200, height: 200, borderRadius: 100, mt: 5 }}
                image={studentInfos?.iconPhtUrl}
              />
              <Box sx={{ ml: 10, mt: 5 }}>
                {studentInfos?.lastName ? (
                  <Box sx={{ my: 1 }}>
                    <Typography sx={{ fontSize: 38, fontWeight: "bold" }}>
                      {studentInfos?.lastName + " " + studentInfos?.firstName}
                    </Typography>
                  </Box>
                ) : (
                  <Box />
                )}

                <Box sx={{ my: 1 }}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {studentInfos?.university ? (
                      <Typography
                        sx={{ ml: 3, fontSize: 26, fontWeight: "bold" }}
                      >
                        {oemUniversityData[studentInfos?.university - 1].value}
                      </Typography>
                    ) : (
                      <Box />
                    )}

                    <Typography
                      sx={{ fontSize: 20, ml: 5, fontWeight: "bold" }}
                    >
                      {/* {
                        graduatedYearData[studentInfos?.graduatedYear]
                          .value
                      } */}
                      {studentInfos?.graduatedYear &&
                        studentInfos?.graduatedMonth &&
                        graduatedYearData[studentInfos?.graduatedYear - 1]
                          .value +
                          "年" +
                          graduatedMonthData[studentInfos?.graduatedMonth - 1]
                            .value +
                          "月"}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {studentInfos?.humanitiesOrScience &&
                    studentInfos.humanitiesOrScience === 1 ? (
                      <Chip label={"文系"} color="error" />
                    ) : (
                      <Chip label={"理系"} color="primary" />
                    )}
                    <Typography
                      sx={{ ml: 2, fontSize: 16, fontWeight: "bold" }}
                    >
                      {studentInfos?.faculty &&
                        studentFacultyData[studentInfos?.faculty - 1].value +
                          " "}
                      {studentInfos?.department
                        ? studentDepartmentData[studentInfos?.department - 1]
                            .value
                        : ""}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box sx={{ textAlign: "right", pr: 5 }}>
              {/* <StyledSaveButton>保存する</StyledSaveButton> */}
              <StyledOfferButton
                onClick={() => {
                  navigate("/company-offer-select-detail", {
                    state: {
                      studentSenderId: studentInfos?.senderId,
                    },
                  });
                }}
              >
                オファーする
              </StyledOfferButton>
            </Box>

            <Box sx={{ display: "flex" }}>
              <Button
                sx={{
                  fontSize: 22,
                  mx: 1,
                  fontWeight: "bold",
                  color: "black",
                }}
              >
                プロフィール
              </Button>
              <Button
                sx={{
                  fontSize: 22,
                  mx: 1,
                  fontWeight: "bold",
                  color: "black",
                }}
                onClick={() => {
                  navigate("/studentprofiledesiredoccupation", {
                    state: {
                      iconPhtUrl: studentInfos?.iconPhtUrl,
                      senderId: studentInfos?.senderId,
                      grade: studentInfos?.grade,
                      university: studentInfos?.university,
                      faculty: studentInfos?.faculty,
                      department: studentInfos?.department,
                      firstName: studentInfos?.firstName,
                      lastName: studentInfos?.lastName,
                      firstNameKana: studentInfos?.firstNameKana,
                      lastNameKana: studentInfos?.lastNameKana,
                      sex: studentInfos?.sex,
                      livingArea: studentInfos?.livingArea,
                      birthPlace: studentInfos?.birthPlace,
                      graduatedHighSchool: studentInfos?.graduatedHighSchool,
                      certification: studentInfos?.certification,
                      programmingExperience:
                        studentInfos?.programmingExperience,
                      programmingExperienceDetail:
                        studentInfos?.programmingExperienceDetail,
                      circle: studentInfos?.circle,
                      researchGroup: studentInfos?.researchGroup,
                      desiredFirstBusiness: studentInfos?.desiredFirstBusiness,
                      desiredSecondBusiness:
                        studentInfos?.desiredSecondBusiness,
                      desiredThirdBusiness: studentInfos?.desiredThirdBusiness,
                      desiredFirstJob: studentInfos?.desiredFirstJob,
                      desiredSecondJob: studentInfos?.desiredSecondJob,
                      desiredThirdJob: studentInfos?.desiredThirdJob,
                      choiceFirstStandard: studentInfos?.choiceFirstStandard,
                      choiceSecondStandard: studentInfos?.choiceSecondStandard,
                      choiceThirdStandard: studentInfos?.choiceThirdStandard,
                      graduatedYear: studentInfos?.graduatedYear,
                      graduatedMonth: studentInfos?.graduatedMonth,
                      humanitiesOrScience: studentInfos?.humanitiesOrScience,
                      internCompName: studentInfos?.internCompName,
                      internJobType: studentInfos?.internJobType,
                      internPeriod: studentInfos?.internPeriod,
                      selfPRMain: studentInfos?.selfPRMain,
                      updatedAt: studentInfos?.updatedAt,
                    },
                  });
                }}
              >
                就活における価値観
              </Button>
            </Box>

            <Box sx={{ display: "flex" }}>
              <Box
                sx={{ width: "160px", height: "5px", backgroundColor: "black" }}
              />
            </Box>
          </Box>

          <Modal open={isModalOpen} onClose={handleClose}>
            <ModalBox sx={{ width: { xs: "80%", sm: "65%" } }}>
              <Typography fontSize={26} fontWeight={"bold"}>
                オファーする求人内容を選択してください
              </Typography>
              {offerTemplateState?.map(
                (offerTemplate: offerType, i: number) => {
                  return (
                    <OfferTemplateCard
                      key={i}
                      offerTemplate={offerTemplate}
                      selectedTemplateId={selectedTemplateId}
                      setSelectedTemplateId={setSelectedTemplateId}
                    />
                  );
                }
              )}
              <StyledOfferButton
                onClick={completeOffer}
                style={{ marginTop: "50px" }}
              >
                決定
              </StyledOfferButton>
            </ModalBox>
          </Modal>
          <Box sx={{ mx: 5, my: 5 }}>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <TableLeftText>性別</TableLeftText>
              </Grid>
              <Grid item xs={9}>
                <TableRightText>
                  {studentInfos?.sex === 0 ? "男性" : "女性"}
                </TableRightText>
              </Grid>
              <Grid item xs={3}>
                <TableLeftText>住所</TableLeftText>
              </Grid>
              <Grid item xs={9}>
                <TableRightText>
                  {studentInfos?.livingArea &&
                    prefectureData[studentInfos.livingArea - 1].value}
                </TableRightText>
              </Grid>
              {studentInfos?.graduatedHighSchool && (
                <>
                  <Grid item xs={3}>
                    <TableLeftText>卒業高校</TableLeftText>
                  </Grid>
                  <Grid item xs={9}>
                    <TableRightText>
                      {studentInfos.graduatedHighSchool}
                    </TableRightText>
                  </Grid>
                </>
              )}
              {studentInfos?.certification && (
                <>
                  <Grid item xs={3}>
                    <TableLeftText>資格</TableLeftText>
                  </Grid>
                  <Grid item xs={9}>
                    <TableRightText>
                      {studentInfos.certification}
                    </TableRightText>
                  </Grid>
                </>
              )}

              <Grid item xs={3}>
                <TableLeftText>プログラミング経験</TableLeftText>
              </Grid>
              <Grid item xs={9}>
                <TableRightText>
                  {studentInfos?.programmingExperience === 1 ? "有り" : "無し"}
                </TableRightText>
              </Grid>
              {studentInfos?.programmingExperienceDetail && (
                <>
                  <Grid item xs={3}>
                    <TableLeftText>プログラミング経験詳細</TableLeftText>
                  </Grid>
                  <Grid item xs={9}>
                    <TableRightText>
                      {studentInfos?.programmingExperienceDetail}
                    </TableRightText>
                  </Grid>
                </>
              )}
              {studentInfos?.circle && (
                <>
                  <Grid item xs={3}>
                    <TableLeftText>サークル</TableLeftText>
                  </Grid>
                  <Grid item xs={9}>
                    <TableRightText>{studentInfos?.circle}</TableRightText>
                  </Grid>
                </>
              )}
              {studentInfos?.researchGroup && (
                <>
                  <Grid item xs={3}>
                    <TableLeftText>ゼミ・研究室</TableLeftText>
                  </Grid>
                  <Grid item xs={9}>
                    <TableRightText>
                      {studentInfos?.researchGroup}
                    </TableRightText>
                  </Grid>
                </>
              )}
              {studentInfos?.selfPRMain && (
                <>
                  <Grid item xs={3}>
                    <TableLeftText>自己PR</TableLeftText>
                  </Grid>
                  <Grid item xs={9}>
                    <TableRightText>
                      {studentInfos?.selfPRMain
                        .split("\n")
                        .map((line, index) => (
                          <React.Fragment key={index}>
                            {line}
                            <br />
                          </React.Fragment>
                        ))}
                    </TableRightText>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
