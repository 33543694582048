import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import {
  Alert,
  Avatar,
  Backdrop,
  Button,
  CircularProgress,
  Snackbar,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import serviceLogo from "assets/serviceLogo.png";
import styled from "styled-components";
import { useUser } from "hooks/firebase/context/auth";
import {
  getCompanyInfo,
  submitCompanyInfo,
} from "hooks/firebase/context/companyInfo";
import {
  listingDivisionData,
  picRoleData,
  prefectureData,
} from "data/CompanyUserData";
import { user_company_private } from "types/firebase/user";
import { getDownLoadUrlFromFullPath } from "hooks/firebase/context/storage";
import MultipleSelectFromList from "components/MultipleSelectFromList";
import { jobCategoryData } from "data/StudentProfileData";
import SelectFromDefaultValueList from "components/SelectFromDefaultValueList";

const LogoImg = styled.img`
  width: 250px;
`;
const StyledButton = styled(Button)`
  color: white !important;
  background-color: #4d4d4d !important;
  font-size: 18px !important;
  font-weight: bold !important;
  padding: 10px 20px !important;
  &:hover {
    background-color: gray !important;
  }
`;
const TextareaStyle = styled(TextareaAutosize)`
  width: 100%;
  font-size: 16px;
  margin: 0 !important;
  padding: 10px;
  border-radius: 5px;
  margin-left: 10px;
  outline: none;
  border: 1px solid #c4c4c4;
  resize: none;
`;
const ImageWrapper = styled.img`
  object-fit: cover;
  max-width: 100%;
  max-height: 500px;
`;
const StyledHeadline = styled(Typography)`
  font-size: 1.8rem !important;
  font-weight: bold !important;
  @media (max-width: 600px) {
    font-size: 1.4rem !important;
  }
`;
const StyledSubHeadline = styled(Typography)`
  font-size: 1.1rem !important;
  @media (max-width: 600px) {
    font-size: 0.9rem !important;
  }
`;
export default function CompanyEditDetail() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [prefectureState, setPrefectureState] = useState("");
  const [listingDivisionState, setListingDivisionState] = useState("");
  const [picRoleState, setPicRoleState] = useState("");
  const [picNameState, setPicNameState] = useState("");
  const [phoneNumberState, setPhoneNumberState] = useState("");
  const [businessContentState, setBusinessContentState] = useState("");
  const [recruitCatchcopyState, setRecruitCatchcopyState] = useState("");
  const [addressDetailState, setAddressDetailState] = useState("");
  const [companyURLState, setCompanyURLState] = useState("");
  const [companyNameState, setCompanyNameState] = useState("");
  const [userInfo, setUserInfo] = useState<user_company_private>();
  const [defaultIconImage, setDefaultIconImage] = useState("");
  const [defaultProfileImage, setDefaultProfileImage] = useState("");
  const [showAlert, setShowAlert] = React.useState(false); // アラートの表示状態

  const user = useUser();

  const inputProfileRef = useRef<HTMLInputElement>(null!);
  const inputIconRef = useRef<HTMLInputElement>(null!);
  const [iconImage, setIconImage] = useState("");
  const [profileImage, setProfileImage] = useState<string | null>(null);
  const [jobCategory, setJobCategory] = useState<string[]>([""]);
  const [defaultPr1Image, setDefaultPr1Image] = useState("");
  const [defaultPr2Image, setDefaultPr2Image] = useState("");
  const [companyCultureState, setCompanyCultureState] = useState("");

  const onIconInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const fileObject = e.target.files[0];
    setIconImage(window.URL.createObjectURL(fileObject));
  };
  const onIconButtonClick = () => {
    inputIconRef.current.click();
  };
  const onProfileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    setProfileImage(window.URL.createObjectURL(e.target.files[0]));
  };
  const onProfileButtonClick = () => {
    inputProfileRef.current.click();
  };
  const inputPrImage1Ref = useRef<HTMLInputElement>(null!);
  const [prImage1, setPrImage1] = useState("");
  const onPrImage1InputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    setPrImage1(window.URL.createObjectURL(e.target.files[0]));
  };
  const onPrImage1ButtonClick = () => {
    inputPrImage1Ref.current.click();
  };
  const inputPrImage2Ref = useRef<HTMLInputElement>(null!);
  const [prImage2, setPrImage2] = useState("");
  const onPrImage2InputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    setPrImage2(window.URL.createObjectURL(e.target.files[0]));
  };
  const onPrImage2ButtonClick = () => {
    inputPrImage2Ref.current.click();
  };

  const imageUrlToBlob = async (imageUrl: string): Promise<Blob> => {
    const response = await fetch(imageUrl);
    if (!response.ok) {
      throw new Error("Network response was not ok.");
    }
    return response.blob();
  };

  React.useEffect(() => {
    if (user) {
      getCompanyInfo(user).then((data) => {
        setUserInfo(data);
        setPicNameState(data.pic);
        setPhoneNumberState(data.phone);
        setBusinessContentState(data.businessContent);
        setRecruitCatchcopyState(data.recruitCatchcopy);
        setCompanyURLState(data.companyURL);
        setPicRoleState(String(data.picRole));
        setCompanyNameState(data.name);
        setPrefectureState(String(data.prefecture));
        setAddressDetailState(data.addressDetail);
        setListingDivisionState(String(data.listingDivision));
        setJobCategory(data.jobCategory.map((category) => category.toString()));
        setCompanyCultureState(data.companyCulture);
        Promise.all([
          getDownLoadUrlFromFullPath(data.iconPhtUrl),
          getDownLoadUrlFromFullPath(data.coverPhtUrl!),
          getDownLoadUrlFromFullPath(data.PRPhtUrl1!),
          getDownLoadUrlFromFullPath(data.PRPhtUrl2!),
        ]).then(async (values) => {
          const icon = values[0];
          const profile = values[1];
          const pr1 = values[2];
          const pr2 = values[3];
          if (icon) setDefaultIconImage(icon);
          if (profile) setDefaultProfileImage(profile);
          if (pr1) setDefaultPr1Image(pr1);
          if (pr2) setDefaultPr2Image(pr2);
        });
      });
    }
  }, [user]);
  const onChangeJobCategory = (values: string[]) => {
    setJobCategory(values);
  };
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    function formRef(key: string): string {
      if (data.get(key) === null) return "";
      else return data.get(key) as string;
    }
    if (
      formRef("companyName") === "" ||
      formRef("pic") === "" ||
      formRef("phone") === "" ||
      formRef("businessContent") === "" ||
      formRef("recruitCatchcopy") === "" ||
      formRef("address") === "" ||
      formRef("companyCulture") === ""
    ) {
      setShowAlert(true);
      return;
    }
    setIsLoading(true);
    let coverBlob = new Blob([formRef("coverImg") as BlobPart], {
      type: "image/jpeg",
    });
    let iconBlob = new Blob([formRef("iconImg") as BlobPart], {
      type: "image/jpeg",
    });
    let prImg1 = new Blob([formRef("prImg1") as BlobPart], {
      type: "image/jpeg",
    });
    let prImg2 = new Blob([formRef("prImg2") as BlobPart], {
      type: "image/jpeg",
    });
    if (user) {
      Promise.all([
        imageUrlToBlob(defaultIconImage),
        imageUrlToBlob(defaultProfileImage),
        imageUrlToBlob(defaultPr1Image),
        imageUrlToBlob(defaultPr2Image),
      ])
        .then(async (values) => {
          let submitErrMsg: string = "";
          submitErrMsg = await submitCompanyInfo(
            formRef("companyName"),
            formRef("businessContent"),
            Number(listingDivisionState),
            formRef("pic"),
            formRef("phone"),
            user,
            iconBlob.size ? iconBlob : values[0],
            coverBlob.size ? coverBlob : values[1],
            Number(prefectureState),
            formRef("address"),
            formRef("recruitCatchcopy"),
            jobCategory.map((str: string) => parseFloat(str)),
            Number(picRoleState),
            formRef("companyCulture"),
            formRef("companyURL"),
            prImg1.size !== 0 ? prImg1 : values[2],
            prImg2.size !== 0 ? prImg2 : values[3]
          );
          setErrMsg(submitErrMsg);
          const sleep = (second: number) =>
            new Promise((resolve) => setTimeout(resolve, second * 1000));
          if (submitErrMsg === "") {
            await sleep(3);
            navigate("/completeregisterdetail");
            setIsLoading(false);
            return;
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error converting image URL to Blob:", error);
          setIsLoading(false);
        });
    }
  };
  const getDefaultValues = (jobCategory: string[]): string[] => {
    return jobCategory.reduce((values, categoryKey) => {
      const foundCategory = jobCategoryData.find(
        (category) => category.key === categoryKey
      );
      if (foundCategory) {
        values.push(foundCategory.value); // foundCategoryのkeyをkeys配列に追加
      }
      return values;
    }, [] as string[]);
  };
  const handleClose = (event: any) => {
    setShowAlert(false);
  };

  useEffect(() => {
    setIsLoading(true);
    if (!user) {
      const timer = setTimeout(() => {
        if (!user) {
          navigate("/companylogin");
        }
      }, 3800);
      return () => clearTimeout(timer);
    } else {
      setIsLoading(false);
    }
  }, [user, navigate]);
  return (
    <Box
      sx={{
        pt: 7,
        backgroundColor: "whitesmoke",
      }}
    >
      <Snackbar
        open={showAlert}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{
          width: "80%",
        }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          未入力の項目があります
        </Alert>
      </Snackbar>
      <Container component="main">
        <CssBaseline />
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Box sx={{ textAlign: "center" }}>
          <LogoImg src={serviceLogo} />
          <Typography
            mt={1}
            mb={2}
            sx={{ fontSize: "26px", fontWeight: "bold" }}
          >
            プロフィール情報編集
          </Typography>
        </Box>
        <Box mt={1} textAlign={"center"}>
          <Typography color="red">{errMsg}</Typography>
        </Box>
        <Box component="form" onSubmit={handleSubmit} sx={{ pb: 5 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "white",
              mt: 4,
              px: 8,
              py: 5,
              border: "1px solid black",
            }}
          >
            <Box sx={{ textAlign: "left", width: "100%" }}>
              <Typography mb={3} sx={{ fontSize: "16px", fontWeight: "bold" }}>
                <span style={{ color: "red" }}>＊</span>は必須項目です
              </Typography>
              <Box sx={{ mb: 3 }}>
                <StyledHeadline>
                  会社名<span style={{ color: "red" }}>*</span>
                </StyledHeadline>
                <Typography sx={{ fontSize: "18px" }}>
                  正式名称で入力をお願いします
                </Typography>
                <TextField
                  inputProps={{ style: { padding: "10px" } }}
                  margin="normal"
                  required
                  fullWidth
                  name="companyName"
                  value={companyNameState}
                  onChange={(e) => setCompanyNameState(e.target.value)}
                />
              </Box>
              <Box sx={{ mb: 3 }}>
                <StyledHeadline>
                  申込者名（フルネーム）<span style={{ color: "red" }}>*</span>
                </StyledHeadline>
                <TextField
                  inputProps={{
                    style: { padding: "10px" },
                  }}
                  margin="normal"
                  required
                  fullWidth
                  name="pic"
                  value={picNameState}
                  onChange={(e) => setPicNameState(e.target.value)}
                />
              </Box>
              <Box sx={{ mb: 3 }}>
                <StyledHeadline>
                  申込者の電話番号<span style={{ color: "red" }}>*</span>
                </StyledHeadline>
                <Typography sx={{ fontSize: "18px" }}>
                  半角、ハイフンあり(例:070-5313-2216)
                </Typography>
                <TextField
                  inputProps={{ style: { padding: "10px" } }}
                  margin="normal"
                  required
                  fullWidth
                  name="phone"
                  value={phoneNumberState}
                  onChange={(e) => setPhoneNumberState(e.target.value)}
                />
              </Box>
              <Box sx={{ mb: 3 }}>
                <StyledHeadline>
                  採用における申込者の役割
                  <span style={{ color: "red" }}>*</span>
                </StyledHeadline>
                <SelectFromDefaultValueList
                  label={"役割"}
                  data={picRoleData}
                  onChange={setPicRoleState}
                  defaultValue={picRoleData[Number(picRoleState)].value}
                />
              </Box>
              <Box
                mt={5}
                mb={2}
                sx={{
                  width: "100%",
                }}
              >
                <StyledHeadline>PR画像(1)</StyledHeadline>
                <StyledSubHeadline>
                  オフィスの風景など、企業の雰囲気が伝わるイメージをアップロードしてください
                </StyledSubHeadline>
                <Button
                  variant="outlined"
                  component="span"
                  onClick={onPrImage1ButtonClick}
                  sx={{
                    width: 110,
                    borderRadius: 1,
                    height: 35,
                    backgroundColor: "white",
                    border: "1px solid black",
                    color: "black",
                    mt: 2,
                  }}
                >
                  画像を選択
                </Button>
              </Box>
              <Box sx={{ width: "100%", marginBottom: 5, textAlign: "center" }}>
                {prImage1 ? (
                  <ImageWrapper alt="prImg1" src={prImage1} />
                ) : (
                  defaultPr1Image && (
                    <ImageWrapper alt="prImg1" src={defaultPr1Image} />
                  )
                )}
                <input
                  style={{ display: "none" }}
                  ref={inputPrImage1Ref}
                  name="prImg1"
                  type="file"
                  accept="image/*"
                  onChange={onPrImage1InputChange}
                />
              </Box>
              <Box
                mt={5}
                mb={2}
                sx={{
                  width: "100%",
                }}
              >
                <StyledHeadline>PR画像(2)</StyledHeadline>
                <StyledSubHeadline>
                  オフィスの風景など、企業の雰囲気が伝わるイメージをアップロードしてください
                </StyledSubHeadline>
                <Button
                  variant="outlined"
                  component="span"
                  onClick={onPrImage2ButtonClick}
                  sx={{
                    width: 110,
                    borderRadius: 1,
                    height: 35,
                    backgroundColor: "white",
                    border: "1px solid black",
                    color: "black",
                    mt: 2,
                  }}
                >
                  画像を選択
                </Button>
              </Box>
              <Box sx={{ width: "100%", marginBottom: 5, textAlign: "center" }}>
                {prImage2 ? (
                  <ImageWrapper alt="prImg2" src={prImage2} />
                ) : (
                  defaultPr2Image && (
                    <ImageWrapper alt="prImg2" src={defaultPr2Image} />
                  )
                )}
                <input
                  style={{ display: "none" }}
                  ref={inputPrImage2Ref}
                  name="prImg2"
                  type="file"
                  accept="image/*"
                  onChange={onPrImage2InputChange}
                />
              </Box>
              <Box sx={{ mb: 3 }}>
                <StyledHeadline>
                  企業アピールポイント<span style={{ color: "red" }}>*</span>
                </StyledHeadline>
                <StyledSubHeadline>
                  事業概要を簡潔に伝えるための短い文章を入力してください
                </StyledSubHeadline>
                <TextareaStyle
                  minRows={5}
                  name="recruitCatchcopy"
                  placeholder="例：&#13;&#10;コンサル業界に興味ある方におすすめ！&#13;&#10;製造業や印刷業回のコンサル経験ができます！"
                  required
                  value={recruitCatchcopyState}
                  onChange={(e) => setRecruitCatchcopyState(e.target.value)}
                />
              </Box>
              <Box sx={{ mb: 3 }}>
                <StyledHeadline>
                  事業内容<span style={{ color: "red" }}>*</span>
                </StyledHeadline>
                <StyledSubHeadline>
                  事業の概要がわかるような説明を記載してください。
                </StyledSubHeadline>
                <TextareaStyle
                  minRows={5}
                  name="businessContent"
                  placeholder="例：&#13;&#10;当社は、製造業や印刷業界に特化したコンサルティングサービスを提供しています。当社の事業内容は、以下の通りです：&#13;&#10;プロセス最適化: 製造業のクライアントに対して、生産プロセスの効率化と品質向上のためのコンサルティングを行います。現行プロセスの分析と改善策の提案を通じて、生産ライ"
                  required
                  value={businessContentState}
                  onChange={(e) => setBusinessContentState(e.target.value)}
                />
              </Box>
              <Box sx={{ mb: 3 }}>
                <StyledHeadline>
                  所在地<span style={{ color: "red" }}>*</span>
                </StyledHeadline>
                <StyledSubHeadline>
                  都道府県を選択してください
                  <span style={{ color: "red" }}>*</span>
                </StyledSubHeadline>
                <SelectFromDefaultValueList
                  label={"勤務地"}
                  data={prefectureData}
                  onChange={setPrefectureState}
                  defaultValue={prefectureData[Number(prefectureState)].value}
                />
                <StyledSubHeadline>
                  建物名まで記載してください
                  <span style={{ color: "red" }}>*</span>
                </StyledSubHeadline>
                <TextField
                  inputProps={{
                    style: { padding: "10px" },
                  }}
                  placeholder="例： 東京都 新宿区 ○ー○ー○ 学生生活ビル5階"
                  required
                  fullWidth
                  name="address"
                  value={addressDetailState}
                  onChange={(e) => setAddressDetailState(e.target.value)}
                />
              </Box>
              <Box sx={{ mb: 3 }}>
                <StyledHeadline>
                  上場区分<span style={{ color: "red" }}>*</span>
                </StyledHeadline>
                <SelectFromDefaultValueList
                  label={"上場区分"}
                  data={listingDivisionData}
                  onChange={setListingDivisionState}
                  defaultValue={
                    listingDivisionData[Number(listingDivisionState)].value
                  }
                />
              </Box>
              <Box sx={{ mb: 3 }}>
                <StyledHeadline>
                  職種カテゴリー<span style={{ color: "red" }}>*</span>
                </StyledHeadline>
                <StyledSubHeadline>現在選択中の職種：</StyledSubHeadline>
                <Typography
                  sx={{ fontSize: { xs: "14px", sm: "18px" }, mt: 2, mb: 3 }}
                >
                  {getDefaultValues(jobCategory).join(" / ")}
                </Typography>
                <MultipleSelectFromList
                  label={"職種カテゴリー"}
                  data={jobCategoryData}
                  onChange={onChangeJobCategory}
                />
              </Box>
              <Box sx={{ mb: 3 }}>
                <StyledHeadline>企業ページURL</StyledHeadline>
                <TextField
                  inputProps={{
                    style: { padding: "10px" },
                  }}
                  margin="normal"
                  fullWidth
                  name="companyURL"
                  value={companyURLState}
                  onChange={(e) => setCompanyURLState(e.target.value)}
                />
              </Box>

              <Box sx={{ mb: 3 }}>
                <StyledHeadline>
                  社風・風土<span style={{ color: "red" }}>*</span>
                </StyledHeadline>
                <StyledSubHeadline>
                  会社の雰囲気が伝わるような説明を記載してください。
                </StyledSubHeadline>
                <TextareaStyle
                  minRows={5}
                  name="companyCulture"
                  placeholder="例：&#13;&#10;1. 革新と技術への情熱: 「私たちの会社は、製造と印刷業界の最前線で革新を追求しています。最新技術の採用や独自のソリューション開発に熱心で、業界の進化をリードすることに情熱を注いでいます。あなたがもし、最新技術への好奇心と、常に学び、成長し続ける意欲を持っているなら、当社はその才能を存分に発揮できる場所です。」&#13;&#10;
                  2. 協力とチームワーク: 「当社では、社員一人ひとりが重要な役割を担い、チームワークを大切にしています。互いに支え合い、知識を共有する文化が根付いており、新卒のあなたもすぐにこの協力的な環境の一員となれます。チームで成果を出す喜びを、私たちと共に感じてください。」"
                  required
                  value={companyCultureState}
                  onChange={(e) => setCompanyCultureState(e.target.value)}
                />
              </Box>
              <Box
                mt={1}
                mb={2}
                sx={{
                  width: "100%",
                }}
              >
                <StyledHeadline>
                  アイコン画像<span style={{ color: "red" }}>*</span>
                </StyledHeadline>
                <StyledSubHeadline>
                  会社ロゴなど、会社を象徴するイメージをアップロードしてください
                </StyledSubHeadline>
                <Button
                  variant="outlined"
                  component="span"
                  onClick={onIconButtonClick}
                  sx={{
                    width: 110,
                    borderRadius: 1,
                    height: 35,
                    backgroundColor: "white",
                    border: "1px solid black",
                    color: "black",
                    mt: 2,
                  }}
                >
                  画像を選択
                </Button>
              </Box>
              <Box textAlign={"center"}>
                {iconImage ? (
                  <Avatar
                    alt="iconImg"
                    src={iconImage}
                    sx={{ width: 240, height: 240, margin: "0 auto" }}
                  />
                ) : (
                  defaultIconImage && (
                    <Avatar
                      alt="iconImg"
                      src={defaultIconImage}
                      sx={{ width: 240, height: 240, margin: "0 auto" }}
                    />
                  )
                )}
                <input
                  style={{ display: "none" }}
                  ref={inputIconRef}
                  name="iconImg"
                  type="file"
                  accept="image/*"
                  onChange={onIconInputChange}
                />
              </Box>
              <Box
                mt={5}
                mb={2}
                sx={{
                  width: "100%",
                }}
              >
                <StyledHeadline>
                  カバー画像<span style={{ color: "red" }}>*</span>
                </StyledHeadline>
                <StyledSubHeadline>
                  オフィスの風景など、企業の雰囲気が伝わるイメージをアップロードしてください
                </StyledSubHeadline>
                <Button
                  variant="outlined"
                  component="span"
                  onClick={onProfileButtonClick}
                  sx={{
                    width: 110,
                    borderRadius: 1,
                    height: 35,
                    backgroundColor: "white",
                    border: "1px solid black",
                    color: "black",
                    mt: 2,
                  }}
                >
                  画像を選択
                </Button>
              </Box>
              <Box sx={{ width: "100%", marginBottom: 5, textAlign: "center" }}>
                {profileImage ? (
                  <ImageWrapper alt="coverImg" src={profileImage} />
                ) : (
                  defaultProfileImage && (
                    <ImageWrapper alt="coverImg" src={defaultProfileImage} />
                  )
                )}
                <input
                  style={{ display: "none" }}
                  ref={inputProfileRef}
                  name="coverImg"
                  type="file"
                  accept="image/*"
                  onChange={onProfileInputChange}
                />
              </Box>
            </Box>
          </Box>
          <Box sx={{ textAlign: "right", mt: 3 }}>
            <StyledButton type="submit">保存</StyledButton>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
