import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";

export default function CompleteVerified() {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        background: "linear-gradient(45deg, #d37c89, #fbecb6)",
        height: "100vh",
        pt: 7,
      }}
    >
      <Container component="main">
        <CssBaseline />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "rgba(255, 255, 255, 0.2)",
            py: 5,
          }}
        >
          <Typography component="h1" variant="h4" fontWeight={"bold"}>
            CAMPUS REACH
          </Typography>
          <Typography fontWeight={"bold"}>新規登録</Typography>
          <Box sx={{ mt: 8, textAlign: "center" }}>
            <Typography sx={{ fontSize: "28px", fontWeight: "bold" }}>
              認証が完了しました
            </Typography>
            <Typography my={5} sx={{ fontSize: "18px" }}>
              以下のボタンをクリックし、ログインをしてください
            </Typography>
            <Button
              disableElevation
              variant="contained"
              onClick={() => {
                navigate("/login");
              }}
              sx={{
                width: "300px",
                mt: 3,
                mb: 2,
                background: "linear-gradient(45deg, #66e29c, #66b2e2)",
              }}
            >
              ログインへ
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
