import { Box, Button, IconButton, Typography } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CompanyHeader } from "components/CompanyHeader";
import { styled } from "styled-components";
import PreviewSnackbar from "components/PreviewSnackbar";

const mdTheme = createTheme();

const Title = styled(Typography)`
  font-size: 22px !important;
  font-weight: bold !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  text-align: center;
`;
const Subtitle = styled(Typography)`
  font-size: 16px !important;
  font-weight: bold !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
`;

export default function CompanyOfferConfirm() {
  const navigate = useNavigate();

  const [open, setOpen] = useState(true);

  const toggleDrawer = () => {
    setOpen(!open);
  };
  const mainStyles = {
    width: open ? "calc(100vw - 240px)" : "calc(100vw - 72px)",
    marginLeft: open ? "240px" : "72px",
    transition: ".3s",
    overflow: "visible",
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <CompanyHeader open={open} toggleDrawer={toggleDrawer} />
        <Box component="main" sx={{ ...mainStyles }}>
          <PreviewSnackbar open={open} />
          <Box sx={{ display: "flex", mt: 10, px: 5 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "100%",
                px: 15,
              }}
            >
              <Title>オファー内容確認</Title>
              <Subtitle>学生情報</Subtitle>
              <Typography>名前：白石七海</Typography>
              <Typography>大学：早稲田大学</Typography>
              <Typography>卒業年度：2025年3月</Typography>
              <Subtitle>募集要項</Subtitle>
              <Typography>2025卒向け</Typography>
              <Typography>＜新卒採用＞</Typography>
              <Subtitle>企業紹介動画</Subtitle>
              <video src="images/topmov.mp4" width={"50%"} controls={true} />
              <Subtitle>選考フロー</Subtitle>

              <Box sx={{ display: "flex" }}>
                <Typography
                  sx={{
                    border: "1px solid black",
                    display: "inline-block",
                    borderRadius: 1,
                    padding: "10px",
                  }}
                >
                  オファー承認
                </Typography>
                <img
                  src="images/recruitArrowIcon.png"
                  alt=""
                  width="40px"
                  style={{
                    marginLeft: 5,
                    marginRight: 5,
                  }}
                />
                <Typography
                  sx={{
                    border: "1px solid black",
                    display: "inline-block",
                    borderRadius: 1,
                    padding: "10px",
                  }}
                >
                  チャット面談
                </Typography>
              </Box>
              <Subtitle>個別メッセージ</Subtitle>
              <Typography>〇〇様</Typography>
              <Typography>
                初めまして、ライトローズ人事部の斉藤と申します。突然のメールとなりますが、貴重なお時間をいただけると幸いです。
              </Typography>
              <Typography>
                〇〇様がこれまでに積み上げてきた学業成果や各種活動、特にプログラミングに関する情報を拝見させていただき、非常に強い印象を受けました。
                私たちライトローズでは、常に新しい才能と共に成長を追求しております。そんな中、〇〇様のような優れたポテンシャルを持つ学生と共に未来を築いていくことに大変な興味を持っております。
              </Typography>
              <Typography>
                是非ともライトローズにて、私たちと共にあなたのキャリアを形成していただけることを心より願っております。具体的なポジションや働き方に関しては、後ほど詳しくご説明させていただきたいと考えております。
              </Typography>
              <Typography>
                もし興味を持っていただけましたら、ご都合の良い日時を教えていただき、弊社を訪問いただき、直接お話をさせていただけると幸いです。
              </Typography>
              <Typography>
                長文となりましたが、最後までお読みいただきありがとうございます。〇〇様のご返信を心よりお待ちしております。
              </Typography>
              <Typography>何卒よろしくお願い申し上げます。</Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              px: 15,
            }}
          >
            <Button
              variant="contained"
              onClick={() => navigate("/sample/company-offer-complete")}
              disableElevation
              style={{
                width: 100,
                borderRadius: 5,
                marginTop: 40,
                marginBottom: 40,
                height: 40,
                backgroundColor: "black",
              }}
            >
              送信する
            </Button>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
