import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import { Button, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import serviceLogo from "assets/serviceLogo.png";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";

const LogoImg = styled.img`
  width: 250px;
`;

const StyledButton = styled(Button)`
  &:hover {
    background-color: gray !important;
  }
`;

const ButtonStyle = styled.button`
  width: 200px;
  border-radius: 20px;
  margin-top: 50px;
  margin-bottom: 40px;
  height: 40px;
  background-color: black;
  color: white;
  outline: none;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
`;

export default function CompleteCompanyMainRegister() {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        height: "100vh",
        pt: 7,
        backgroundColor: "whitesmoke",
      }}
    >
      <Container component="main">
        <CssBaseline />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "white",
            pt: 5,
            height: 400,
            px: 4,
          }}
        >
          <Link to="/">
            <LogoImg src={serviceLogo} />
          </Link>
          <Box sx={{ mt: 8, textAlign: "center" }}>
            <Typography sx={{ fontSize: "28px", fontWeight: "bold" }}>
              企業のアカウント本登録が完了いたしました。
            </Typography>
            <ButtonStyle
              onClick={() => {
                navigate("/companyprofiledetail");
              }}
            >
              企業プロフィール画面へ
            </ButtonStyle>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
