import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import Container from "@mui/material/Container";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { CircularProgress } from "@mui/material";
import {
  updateEmailAdress,
  useUser,
  reauthenticate,
} from "hooks/firebase/context/auth";
import { sendEmailVerification } from "firebase/auth";

const BottomLink = styled(Button)`
  text-decoration: none;
  font-size: 0.95rem;
  color: #2a4cce;
  padding: 0;
`;

export default function ResetEmail() {
  const user = useUser();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    function formRef(key: string): string {
      if (data.get(key) === null) return "";
      else return data.get(key) as string;
    }
    setIsLoading(true);
    if (formRef("email") === "") {
      setErrMsg("メールアドレスを入力してください");
      setIsLoading(false);
      return;
    } else setErrMsg("");
    if (formRef("newemail") === "") {
      setErrMsg("新しいメールアドレスを入力してください");
      setIsLoading(false);
      return;
    } else setErrMsg("");
    if (formRef("newemailconfirm") === "") {
      setErrMsg("確認用メールアドレスを入力してください");
      setIsLoading(false);
      return;
    } else setErrMsg("");
    if (formRef("newemail") !== formRef("newemailconfirm")) {
      setErrMsg("新しいメールアドレスと確認用メールアドレスが一致しません");
      setIsLoading(false);
      return;
    } else setErrMsg("");
    reauthenticate(user!.auth, formRef("email"), formRef("password"))
      .then((credential) => {
        updateEmailAdress(credential.user, formRef("newemail"))
          .then(() => {
            sendEmailVerification(credential.user)
              .then(() => {
                // console.log("新しいメールアドレスが登録されました");
                //新しいメールアドレスに認証メールがいくのでそれを確認するように伝える
                // & 古いメールアドレスに変更されたメールがいくので取り消したい場合はそれを確認するように伝える
                navigate("/confirmresetemail");
                setIsLoading(false);
              })
              .catch(() => {
                setErrMsg(
                  "認証メールの送信に失敗しました。問題が解決しない場合は運営へお問い合わせください"
                );
                setIsLoading(false);
              });
          })
          .catch((err) => {
            if (err.code === "auth/invalid-email") {
              setErrMsg(
                "無効なメールアドレスです。正しいメールアドレスを入力してください。"
              );
            } else if (err.code === "auth/email-already-in-use") {
              setErrMsg("このメールアドレスは既に使用されています。");
            } else {
              setErrMsg("メールアドレスの変更に失敗しました。");
            }
            setIsLoading(false);
          });
      })
      .catch((err) => {
        if (err.code === "auth/wrong-password") {
          setErrMsg("パスワードが間違っています");
        } else if (err.code === "auth/invalid-email") {
          setErrMsg("メールアドレスが間違っています");
        } else if (err.code === "auth/user-not-found") {
          setErrMsg("ユーザーが見つかりません");
        } else {
          setErrMsg("ユーザー認証に失敗しました");
        }
        setIsLoading(false);
      });
  };
  return (
    <Box
      sx={{
        background: "linear-gradient(45deg, #d37c89, #fbecb6)",
        height: "100vh",
        pt: 7,
      }}
    >
      <Container component="main">
        <CssBaseline />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "rgba(255, 255, 255, 0.2)",
            pt: 3,
          }}
        >
          <Typography component="h1" variant="h4" fontWeight={"bold"}>
            早稲田生活
          </Typography>
          <Typography fontWeight={"bold"}>メールアドレス再設定</Typography>
          <Box sx={{ position: "absolute", top: { xs: 100, sm: 150 } }}>
            {isLoading ? <CircularProgress /> : <Box />}
          </Box>
          {errMsg !== "" ? (
            <Box mx={3} height={3}>
              <Typography color="red">{errMsg}</Typography>
            </Box>
          ) : (
            <Box mx={3} height={3} />
          )}
          <Box sx={{ position: "absolute", top: { xs: 100, sm: 150 } }}>
            {isLoading ? <CircularProgress /> : <Box />}
          </Box>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 3, width: { xs: "300px", sm: "400px" } }}
          >
            <Typography>メールアドレス</Typography>
            <TextField
              margin="normal"
              required
              fullWidth
              name="email"
              type="email"
              id="clubEmail"
            />
            <Typography>現在のパスワード</Typography>
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              type="password"
              id="password"
            />
            <Typography>新しいメールアドレス</Typography>
            <TextField
              margin="normal"
              required
              fullWidth
              name="newemail"
              type="email"
              id="newemail"
            />
            <Typography>新しいメールアドレス（確認）</Typography>
            <TextField
              margin="normal"
              required
              fullWidth
              name="newemailconfirm"
              type="email"
              id="newemailconfirm"
            />
            <Button
              type="submit"
              fullWidth
              disableElevation
              variant="contained"
              sx={{
                mt: 2,
                mb: 1,
                background: "linear-gradient(45deg, #66e29c, #66b2e2)",
              }}
            >
              決定
            </Button>
            <Grid container>
              <Grid item xs>
                <BottomLink
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  戻る
                </BottomLink>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
