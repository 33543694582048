import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import { Typography, Grid, CardMedia, Chip, Modal } from "@mui/material";
import Button from "@mui/material/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { CompanyHeader } from "components/CompanyHeader";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { studentInfoTypes } from "types/page/studentInfoTypes";
import { offerType } from "types/page/offerType";
import OfferTemplateCard from "components/OfferTemplateCard";
import { graduatedYearData } from "data/StudentProfileData";
import PreviewSnackbar from "components/PreviewSnackbar";

const mdTheme = createTheme();

const StyledSaveButton = styled.button`
  width: 150px;
  height: 40px;
  margin: 0 10px;
  font-size: 16px;
  border-radius: 20px;
  background-color: whitesmoke;
  color: #4f4f4f;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  border: 2px solid #4f4f4f;
  &:hover {
    background-color: white;
  }
`;
const StyledOfferButton = styled.button`
  width: 150px;
  height: 40px;
  margin: 0 10px;
  font-size: 16px;
  border-radius: 20px;
  background-color: #35b500;
  color: white;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  border: none;
  &:hover {
    background-color: #29d630;
  }
`;
const TableLeftText = styled(Typography)`
  font-size: 20px !important;
  color: dimgray !important;
  margin: 10px 0 !important;
  font-weight: bold !important;
  text-align: center !important;
`;
const TableText = styled(Typography)`
  font-size: 20px !important;
  font-weight: bold !important;
  margin: 10px 0 !important;
`;
const ModalBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  height: 90%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 20px;
  box-shadow: 24px;
  padding: 60px;
  text-align: center;
`;
export default function SampleStudentProfileDesiredOccupation() {
  const [studentInfos, setStudentInfos] = useState<studentInfoTypes>();

  const [offerTemplateState, setOfferTemplateState] = useState<offerType[]>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState("");

  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    setStudentInfos(location.state);
    window.scrollTo(0, 0);
  }, [location]);
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const sendOffer = () => {
    navigate("/sample/company-offer-select-detail");
  };

  const handleClose = () => setIsModalOpen(false);

  const completeOffer = async () => {
    navigate("/sample/companycompleteoffer", {
      state: {
        senderId: "",
      },
    });
  };
  const mainStyles = {
    width: open ? "calc(100vw - 240px)" : "calc(100vw - 72px)",
    marginLeft: open ? "240px" : "72px",
    transition: ".3s",
    overflow: "auto",
  };
  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <CompanyHeader
          open={open}
          toggleDrawer={toggleDrawer}
          showofferModalProps={true}
        />
        <PreviewSnackbar open={open} />
        <Box
          component="main"
          sx={{
            ...mainStyles,
          }}
        >
          <Box
            sx={{
              mt: 8,
              px: 5,
              backgroundColor: "#C6D6E2",
            }}
          >
            <Box
              sx={{
                display: "flex",
              }}
            >
              <CardMedia
                component="img"
                sx={{ width: 200, height: 200, borderRadius: 100, mt: 5 }}
                image={studentInfos?.iconPhtUrl}
                alt="Live from space album cover"
              />
              <Box sx={{ ml: 10, mt: 5 }}>
                {studentInfos?.lastName ? (
                  <Box sx={{ my: 1 }}>
                    <Typography sx={{ fontSize: 38, fontWeight: "bold" }}>
                      {studentInfos?.lastName + " " + studentInfos?.firstName}
                    </Typography>
                  </Box>
                ) : (
                  <Box />
                )}

                <Box sx={{ my: 1 }}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography sx={{ fontSize: 26, fontWeight: "bold" }}>
                      早稲田大学
                    </Typography>

                    <Typography
                      sx={{ fontSize: 20, ml: 3, fontWeight: "bold" }}
                    >
                      {studentInfos?.graduatedYear
                        ? graduatedYearData[studentInfos?.graduatedYear - 1]
                            .value
                        : ""}
                      年 3月 (卒業見込み)
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                    <Chip
                      label={
                        studentInfos?.humanitiesOrScience === 0
                          ? "理系"
                          : "文系"
                      }
                      color={
                        studentInfos?.humanitiesOrScience === 0
                          ? "primary"
                          : "error"
                      }
                      size="medium"
                    />
                    <Typography
                      sx={{ ml: 2, fontSize: 16, fontWeight: "bold" }}
                    >
                      商学部 商学科
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box sx={{ textAlign: "right", pr: 5 }}>
              <StyledSaveButton>保存する</StyledSaveButton>

              <StyledOfferButton onClick={sendOffer}>
                オファーする
              </StyledOfferButton>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Button
                onClick={() => {
                  navigate("/sample/companystudentprofiledetail", {
                    state: studentInfos,
                  });
                }}
              >
                <Typography
                  sx={{
                    fontSize: 22,
                    mx: 1,
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  プロフィール
                </Typography>
              </Button>
              <Button>
                <Typography
                  sx={{
                    fontSize: 22,
                    mx: 1,
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  就活における価値観
                </Typography>
              </Button>
            </Box>

            <Box sx={{ display: "flex" }}>
              <Box
                sx={{
                  width: "240px",
                  height: "5px",
                  backgroundColor: "black",
                  ml: "160px",
                }}
              />
            </Box>
          </Box>

          <Modal open={isModalOpen} onClose={handleClose}>
            <ModalBox sx={{ width: { xs: "80%", sm: "65%" } }}>
              <Typography fontSize={26} fontWeight={"bold"}>
                オファーする求人内容を選択してください
              </Typography>
              {offerTemplateState?.map(
                (offerTemplate: offerType, i: number) => {
                  return (
                    <OfferTemplateCard
                      key={i}
                      offerTemplate={offerTemplate}
                      selectedTemplateId={selectedTemplateId}
                      setSelectedTemplateId={setSelectedTemplateId}
                    />
                  );
                }
              )}
              <StyledOfferButton
                onClick={completeOffer}
                style={{ marginTop: "50px" }}
              >
                決定
              </StyledOfferButton>
            </ModalBox>
          </Modal>
          <Box sx={{ mx: 5, my: 5 }}>
            <Grid container spacing={3}>
              <Grid item xs={2}>
                <TableLeftText>志望業界</TableLeftText>
              </Grid>
              <Grid item xs={2}>
                <TableText>第一志望</TableText>
                <TableText>第二志望</TableText>
                <TableText>第三志望</TableText>
              </Grid>
              <Grid item xs={8}>
                <TableText>商社</TableText>
                <TableText>証券</TableText>
                <TableText>ソフトウェア・情報処理</TableText>
              </Grid>
              <Grid item xs={2}>
                <TableLeftText>志望職種</TableLeftText>
              </Grid>
              <Grid item xs={2}>
                <TableText>第一志望</TableText>
                <TableText>第二志望</TableText>
                <TableText>第三志望</TableText>
              </Grid>
              <Grid item xs={8}>
                <TableText>マーケティング</TableText>
                <TableText>営業</TableText>
                <TableText>証券アナリスト</TableText>
              </Grid>
              <Grid item xs={2}>
                <TableLeftText>働き方の軸</TableLeftText>
              </Grid>
              <Grid item xs={2}>
                <TableText>第一志望</TableText>
                <TableText>第二志望</TableText>
                <TableText>第三志望</TableText>
              </Grid>
              <Grid item xs={8}>
                <TableText>会社の安定性</TableText>
                <TableText>市場の成長性</TableText>
                <TableText>裁量権</TableText>
              </Grid>
              <Grid item xs={2}>
                <TableLeftText>企業規模</TableLeftText>
              </Grid>
              <Grid item xs={10}>
                <TableText>大企業, ベンチャー</TableText>
              </Grid>
              <Grid item xs={2}>
                <TableLeftText>求めるキャリア</TableLeftText>
              </Grid>
              <Grid item xs={10}>
                <TableText>
                  裁量権があり、若いうちから成長できる環境にあること
                </TableText>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
