import * as React from "react";
import { sendEmailVerificationWrap } from "hooks/firebase/context/auth";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ConfirmEmail from "pages/General/ConfirmEmail";

export default function CompanyConfirmEmail() {
  const navigate = useNavigate();
  const [errMsg, setErrMsg] = useState("");
  const resendEmail = async () => {
    const errResendMsg: string = await sendEmailVerificationWrap(); //errMsgはデフォルトが"", エラーが発生するとそのエラーメッセージを含みます。
    setErrMsg(errResendMsg);
  };
  return <ConfirmEmail navigatePage="/companylogin" />;
}
