import * as React from "react";
import {
  sendEmailVerificationWrap,
  useUser,
} from "hooks/firebase/context/auth";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  CssBaseline,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import { CompanyHeader } from "components/CompanyHeader";
import { styled } from "styled-components";
import { Textsms } from "@mui/icons-material";

const LogoImg = styled.img`
  width: 250px;
`;
type studentSenderIdTypes = {
  senderId: string;
};
export default function SampleCompanyCompleteOffer() {
  const navigate = useNavigate();
  const location = useLocation();
  const [errMsg, setErrMsg] = useState("");
  const [studentSenderId, setStudentSenderId] =
    useState<studentSenderIdTypes>();
  const resendEmail = async () => {
    const errResendMsg: string = await sendEmailVerificationWrap(); //errMsgはデフォルトが"", エラーが発生するとそのエラーメッセージを含みます。
    setErrMsg(errResendMsg);
  };

  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const mainStyles = {
    width: open ? "calc(100vw - 240px)" : "calc(100vw - 72px)",
    marginLeft: open ? "240px" : "72px",
    transition: ".3s",
    overflow: "auto",
  };

  const mdTheme = createTheme();
  const user = useUser();
  useEffect(() => {
    setStudentSenderId(location.state);
    window.scrollTo(0, 0);
  }, [location]);

  const startChat = () => {
    navigate("/sample/companychat");

    // if (user && studentSenderId) {
    //   console.log(studentSenderId?.senderId);
    //   sendMessage(1, user!.auth.uid, "おはよう", studentSenderId!.senderId);
    //   // navigate("/companychat");
    // }
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <CompanyHeader open={open} toggleDrawer={toggleDrawer} />
        <Box
          component="main"
          sx={{
            ...mainStyles,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "white",
              pt: 10,
              height: 400,
              px: 4,
            }}
          >
            <Box sx={{ mt: 10, textAlign: "center" }}>
              <Typography sx={{ fontSize: "34px", fontWeight: "bold" }}>
                オファーを送信しました
              </Typography>
              <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
                学生にメッセージを送信することでオファー承諾率が上がります
              </Typography>
              <Button
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  color: "black",
                  mx: "auto",
                  mt: 3,
                }}
                onClick={startChat}
              >
                <Textsms sx={{ fontSize: "30px" }} />
                <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
                  メッセージを開く
                </Typography>
              </Button>
              <Button
                onClick={startChat}
                sx={{
                  backgroundColor: "black",
                  color: "white",
                  fontWeight: "bold",
                  fontSize: "large",
                  py: 1,
                  px: 2,
                  mt: 3,
                  "&:hover": {
                    backgroundColor: "dimgray",
                  },
                }}
                startIcon={<Textsms />}
              >
                テンプレートを使ってメッセージを送る ＞
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
