import * as React from "react";
import { Typography } from "@mui/material";
// import MailOutlineIcon from "@mui/icons-material/MailOutline";
import {
  Avatar,
  Box,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
// import styled from "styled-components";
import { getDownLoadUrlFromFullPath } from "hooks/firebase/context/storage";
import { Timestamp } from "firebase/firestore";
import { useEffect } from "react";

type ChatRoomType = {
  senderId: string;
  iconPhtRef: string;
  lastMessage: string;
  name: string;
  roomID: string;
  unRead: number;
  updatedAt: Timestamp;
};

type Props = {
  item: ChatRoomType;
  chatTimelineList: ChatRoomType[];
};

const ChatCell: React.FC<Props> = (props) => {
  const [iconValue, setIconValue] = React.useState("");

  const { item, chatTimelineList } = props;
  useEffect(() => {
    Promise.all([getDownLoadUrlFromFullPath(item.iconPhtRef)]).then(
      async (values) => {
        const icon = values[0];
        if (icon) {
          setIconValue(icon);
        }
      }
    );
  }, [item.iconPhtRef]);

  return (
    <ListItem
      alignItems="center"
      sx={{
        width: "100%",
        // "&:hover": {
        //   backgroundColor: "whitesmoke",
        //   cursor: "pointer",
        //   transition: ".1s",
        // },
      }}
    >
      <ListItemAvatar>
        <Avatar
          src={iconValue}
          sx={{
            width: 64,
            height: 64,
            marginRight: 2,
            marginTop: 1,
            marginBottom: 1,
          }}
        />
      </ListItemAvatar>
      {/* <ListItemText
        primary={
          <React.Fragment>
            <Box
              sx={{
                textOverflow: "ellipsis",
              }}
            >
              <Typography noWrap fontSize={16} mb={1} fontWeight="bold">
                {item.name}
              </Typography>
            </Box>
          </React.Fragment>
        }
        secondary={
          <React.Fragment>
            <Box
              sx={{
                textOverflow: "ellipsis",
              }}
            >
              <Typography
                noWrap
                component={"span"}
                variant="body2"
                color="gray"
              >
                {item.lastMessage}
              </Typography>
            </Box>
          </React.Fragment>
        }
      /> */}
      <ListItemText
        primary={
          <React.Fragment>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mb: 1,
              }}
            >
              <Box
                sx={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  mr: 1,
                }}
              >
                <Typography
                  noWrap
                  fontSize={16}
                  fontWeight="bold"
                  color="black"
                >
                  {item.name}
                </Typography>
              </Box>
              <Box
                sx={{
                  border: "1px solid #00B3DB",
                  minWidth: 75,
                  height: 20,
                  borderRadius: 20,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography sx={{ color: "#00B3DB", fontSize: 12 }}>
                  スカウト
                </Typography>
              </Box>
              {/* <Box
                sx={{
                  border: "1px solid #E12A2A",
                  minWidth: 75,
                  height: 20,
                  borderRadius: 20,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography sx={{ color: "#E12A2A", fontSize: 12 }}>
                  応募
                </Typography>
              </Box> */}
            </Box>
          </React.Fragment>
        }
        secondary={
          <React.Fragment>
            <Box
              sx={{
                textOverflow: "ellipsis",
              }}
            >
              <Typography noWrap variant="body2" color="gray">
                {item.lastMessage}
              </Typography>
            </Box>
          </React.Fragment>
        }
        secondaryTypographyProps={{ component: "span" }}
      />
      {item.unRead !== 0 ? (
        <Box
          sx={{
            backgroundColor: "tomato",
            position: "absolute",
            right: 10,
            width: 25,
            height: 25,
            borderRadius: 15,
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Typography color={"white"} fontSize={14}>
            {item.unRead}
          </Typography>
        </Box>
      ) : (
        <Box></Box>
      )}
    </ListItem>
  );
};

export default ChatCell;
