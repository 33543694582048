import { useEffect, useRef, useState } from "react";
import ComboBox, { ComboBoxItem } from "./ComboBox";
import ClubGenreList from "data/ClubGenre";
type Props = {
  largeId: string;
  smallId: string;
  updateState: (detail: string) => any;
};
export default function UseCombo({ largeId, smallId, updateState }: Props) {
  const [selectedEnqueteId, setSelectedEnqueteId] = useState<string>(largeId);
  const [selectedQuestionId, setSelectedQuestionId] = useState(smallId);
  useEffect(() => {
    updateState(selectedEnqueteId + "-" + selectedQuestionId);
  }, [selectedEnqueteId, selectedQuestionId, updateState]);
  const [enqueteOptions] = useState<ComboBoxItem[]>(
    ClubGenreList.map((d) => {
      return {
        id: d.largeGenreId,
        value: d.genreTitle,
      };
    })
  );
  const questionOptionsRef = useRef(
    ClubGenreList.filter(
      (d) => d.largeGenreId === selectedEnqueteId
    )[0].smallGenres.map((d) => {
      return {
        id: d.smallGenreId,
        value: d.smallGenreText,
      };
    })
  );
  const onEnqueteComboBoxChangeHandler = (enqueteId: string) => {
    setSelectedEnqueteId(enqueteId);

    const selectedEnqueteQuestions = ClubGenreList.filter(
      (d) => d.largeGenreId === enqueteId
    )[0].smallGenres;

    setSelectedQuestionId(selectedEnqueteQuestions[0].smallGenreId);

    questionOptionsRef.current = selectedEnqueteQuestions.map((d) => {
      return {
        id: d.smallGenreId,
        value: d.smallGenreText,
      };
    });
  };

  return (
    <>
      <ComboBox
        inputLabel="大分類"
        items={enqueteOptions}
        value={selectedEnqueteId}
        defaultValue={enqueteOptions[0].id}
        onChange={(selected) => onEnqueteComboBoxChangeHandler(selected)}
      />
      <ComboBox
        inputLabel="小分類"
        items={questionOptionsRef.current}
        value={selectedQuestionId}
        defaultValue={"1"}
        onChange={(selected) => setSelectedQuestionId(selected)}
      />
    </>
  );
}
