import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useEffect, useState } from "react";

export interface ItemTypes {
  key: string;
  value: string;
}

type SelectFromListProps = {
  label: string;
  data: ItemTypes[];
  onChange: (selected: string) => void;
};

const SelectFromList = ({ label, data, onChange }: SelectFromListProps) => {
  // useStateの初期値をdefaultValueに設定
  const [selectedValue, setSelectedValue] = useState("");

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedValue(event.target.value);
  };

  function getKeyFromValue(targetValue: string): string | undefined {
    const foundOption = data.find((option) => option.value === targetValue);
    return foundOption ? foundOption.key : undefined;
  }

  useEffect(() => {
    const key = getKeyFromValue(selectedValue);
    if (key) onChange(key!);
  }, [selectedValue, setSelectedValue]);

  return (
    <FormControl fullWidth sx={{ height: "43px", mt: "12px" }}>
      <InputLabel sx={{ mt: "-5px" }}>{label}</InputLabel>
      <Select
        value={selectedValue}
        onChange={handleChange}
        sx={{ height: "100%" }}
      >
        {data.map((option) => (
          <MenuItem key={option.key} value={option.value}>
            {option.value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectFromList;
