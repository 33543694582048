import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Button, Typography } from "@mui/material";
import { sendEmailVerificationWrap } from "hooks/firebase/context/auth";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function ConfirmResetEmail() {
  const navigate = useNavigate();
  const [errMsg, setErrMsg] = useState("");
  const resendEmail = async () => {
    const errResendMsg: string = await sendEmailVerificationWrap(); //errMsgはデフォルトが"", エラーが発生するとそのエラーメッセージを含みます。
    setErrMsg(errResendMsg);
  };
  return (
    <Box
      sx={{
        background: "linear-gradient(45deg, #d37c89, #fbecb6)",
        height: "100vh",
        pt: 7,
      }}
    >
      <Container component="main">
        <CssBaseline />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "rgba(255, 255, 255, 0.2)",
            pt: 5,
            pb: 8,
          }}
        >
          <Typography component="h1" variant="h4" fontWeight={"bold"}>
            早稲田生活
          </Typography>
          <Typography fontWeight={"bold"}>メールアドレス再設定</Typography>
          <Box height={20} mt={4}>
            <Typography color="red">{errMsg}</Typography>
          </Box>
          <Box sx={{ mt: 5, mx: 3, textAlign: "center" }}>
            <Typography
              sx={{ fontSize: { xs: "24px", sm: "28px" }, fontWeight: "bold" }}
            >
              メールアドレス再設定用のリンクを送信いたしました
            </Typography>
            <Typography mt={3} sx={{ fontSize: { xs: "16px", sm: "18px" } }}>
              記載されているURLをクリックして、メールアドレス再設定の処理をしてください
            </Typography>
            <Typography mb={6} sx={{ fontSize: { xs: "14px", sm: "16px" } }}>
              変更を取り消す場合は以前のメールアドレスに記載されたurlを確認してください
            </Typography>
            <Button
              disableElevation
              variant="contained"
              onClick={() => {
                navigate("/login");
              }}
              fullWidth
              sx={{
                mt: 3,
                mb: 2,
                background: "linear-gradient(45deg, #66e29c, #66b2e2)",
              }}
            >
              ログイン画面へ
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
