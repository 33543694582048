import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { CompanyHeader } from "components/CompanyHeader";
import { Copyright } from "components/Copyright";
import Chart from "./Chart";
import { Avatar, Divider, Typography } from "@mui/material";
import profileIcon from "assets/profileIcon.jpg";
import styled from "styled-components";

const mdTheme = createTheme();

interface MainContainerProps {
  open: boolean;
}

const MainContainer = styled(Box)<MainContainerProps>`
  width: ${(props) =>
    props.open ? "calc(100vw - 240px)" : "calc(100vw - 72px)"};
  margin-left: ${(props) => (props.open ? "240px" : "72px")};
  transition: 0.3s;
  overflow: auto;
`;
const ChartPaper = styled(Paper)`
  padding: 2px;
  display: flex;
  flex-direction: column;
  height: 280px;
`;

const ChatBox = styled(Box)`
  width: 100%;
  height: 70px;
  border-radius: 1px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1px;
`;

const AvatarContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const MessageContainer = styled(Box)`
  flex: 3;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
`;

const StyledTypography = styled(Typography)`
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
`;

export default function SampleCompanyTop() {
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <CompanyHeader open={open} toggleDrawer={toggleDrawer} />
        <MainContainer open={open}>
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={8} lg={8}>
                <ChartPaper>
                  <Chart />
                </ChartPaper>
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <ChartPaper>
                  <Typography p={1}>新着チャット</Typography>
                  <ChatBox>
                    <AvatarContainer>
                      <Avatar
                        alt="iconImg"
                        src={profileIcon}
                        sx={{ width: 40, height: 40 }}
                      />
                      <Typography sx={{ fontSize: 12 }}>早稲田太郎</Typography>
                    </AvatarContainer>
                    <MessageContainer>
                      <StyledTypography>
                        ありがとうございます！
                      </StyledTypography>
                    </MessageContainer>
                  </ChatBox>
                  <Divider />
                  <ChatBox>
                    <AvatarContainer>
                      <Avatar
                        alt="iconImg"
                        src={profileIcon}
                        sx={{ width: 40, height: 40 }}
                      />
                      <Typography sx={{ fontSize: 12 }}>早稲田二郎</Typography>
                    </AvatarContainer>
                    <MessageContainer>
                      <StyledTypography>
                        ご連絡ありがとうございます！ぜひご連絡ありがとうございます！ぜひ...
                      </StyledTypography>
                    </MessageContainer>
                  </ChatBox>
                  <Divider />
                  <ChatBox>
                    <AvatarContainer>
                      <Avatar
                        alt="iconImg"
                        src={profileIcon}
                        sx={{ width: 40, height: 40 }}
                      />
                      <Typography sx={{ fontSize: 12 }}>早稲田三郎</Typography>
                    </AvatarContainer>
                    <MessageContainer>
                      <StyledTypography>
                        次回の面接日程についてですが、13日次回の面接日程についてですが、13日...
                      </StyledTypography>
                    </MessageContainer>
                  </ChatBox>
                  <Divider />
                </ChartPaper>
              </Grid>
              <Grid item xs={12}>
                <Paper
                  sx={{ p: 2, display: "flex", flexDirection: "column" }}
                ></Paper>
              </Grid>
            </Grid>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </MainContainer>
      </Box>
    </ThemeProvider>
  );
}
