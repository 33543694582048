import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { graduatedYearData } from "data/StudentProfileData";

type GraduateSelectProps = {
  setGraduateSelectProps: (selected: string) => void;
  defaultValue?: string;
};
export default function GraduateSelectInput({
  setGraduateSelectProps,
  defaultValue,
}: GraduateSelectProps) {
  const defaultYear = graduatedYearData.find(
    (year) => year.key === String(Number(defaultValue) - 1)
  );
  return (
    <Autocomplete
      id="country-select-demo"
      sx={{ width: 200, height: 55, my: 1 }}
      options={graduatedYearData}
      autoHighlight
      getOptionLabel={(option) => option.value}
      onChange={(_, value) =>
        setGraduateSelectProps(value ? String(Number(value.key) + 1) : "")
      }
      defaultValue={defaultYear}
      renderInput={(params) => (
        <TextField
          {...params}
          label="卒業年度"
          inputProps={{
            ...params.inputProps,
            style: { fontWeight: "bold" },
          }}
          InputLabelProps={{
            style: { fontWeight: "bold" },
          }}
        />
      )}
    />
  );
}
