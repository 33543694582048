import { Snackbar, SnackbarContent } from "@mui/material";

type previewSnackbarProps = {
  open: boolean;
  message: string; // messageをpropsとして追加
};

export default function PreviewSnackbarEditableMsg({
  open,
  message,
}: previewSnackbarProps) {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={true}
      sx={{
        width: "60%",
        marginLeft: open ? "140px" : "12px",
      }}
    >
      <SnackbarContent
        message={message} // messageを動的に表示
        sx={{
          backgroundColor: (theme) => theme.palette.error.main,
          width: "100%",
          justifyContent: "center",
        }}
      />
    </Snackbar>
  );
}
