import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

type BunriSelectInputProps = {
  setHumanitiesOrScienceProps: (selected: string) => void;
  defaultValue?: string;
};

export default function BunriSelectInput({
  setHumanitiesOrScienceProps,
  defaultValue,
}: BunriSelectInputProps) {
  const defaultHumanitiesOrScience = countries.find(
    (humanitiesOrScience) => humanitiesOrScience.key === defaultValue
  );
  return (
    <Autocomplete
      id="country-select-demo"
      sx={{ width: 200, height: 55, my: 1 }}
      options={countries}
      autoHighlight
      getOptionLabel={(option) => option.value}
      onChange={(_, value) =>
        setHumanitiesOrScienceProps(value ? value.key : "")
      }
      defaultValue={defaultHumanitiesOrScience}
      renderInput={(params) => (
        <TextField
          {...params}
          label="文理区分"
          inputProps={{
            ...params.inputProps,
            style: { fontWeight: "bold" },
          }}
          InputLabelProps={{
            style: { fontWeight: "bold" },
          }}
        />
      )}
    />
  );
}

const countries = [
  { key: "1", value: "文系" },
  { key: "2", value: "理系" },
];
