import { Box, Button, IconButton, Typography } from "@mui/material";
import { graduatedYearData, offerTypeData } from "data/StudentProfileData";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { offerType } from "types/page/offerType";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import PreviewSnackbar from "components/PreviewSnackbar";

type OfferTemplateCardProps = {
  offerTemplate: offerType;
  selectedTemplateId: string;
  setSelectedTemplateId: React.Dispatch<React.SetStateAction<string>>;
};

const StyledRecruitButton = styled(Button)`
  display: flex;
  textalign: left;
  width: 600px;
  box-shadow: 0px 5px 10px lightgray;
  padding: 50px 30px 50px 0 !important;
  background-color: white !important;
`;

const OfferTemplateCard = ({
  offerTemplate,
  selectedTemplateId,
  setSelectedTemplateId,
}: OfferTemplateCardProps) => {
  const [isSelected, setIsSelected] = useState(false);
  const selectedRecruit = () => {
    setIsSelected(!isSelected);
    setSelectedTemplateId(offerTemplate.id);
  };
  useEffect(() => {
    if (offerTemplate.id === selectedTemplateId) {
      setIsSelected(true);
    } else {
      setIsSelected(false);
    }
  }, [selectedTemplateId]);
  return (
    <Box
      sx={{
        borderRadius: 5,
        mt: 3,
        display: "flex",
        alignItems: "center",
        width: 600,
        boxShadow: "0px 5px 10px lightgray",
        padding: "50px 30px 50px 0",
        backgroundColor: "white",
        cursor: "pointer",
      }}
      onClick={selectedRecruit}
    >
      <IconButton aria-label="add to favorites" sx={{ mx: 1 }}>
        <FontAwesomeIcon
          icon={faCircleCheck}
          color={isSelected ? "green" : "lightgray"}
          size="lg"
        />
      </IconButton>
      <Box
        sx={{
          width: "3px",
          height: "50px",
          backgroundColor: offerTemplate.offerType === 0 ? "blue" : "red",
          mr: 2,
        }}
      />
      <Box
        sx={{
          width: "100%",
          textAlign: "left",
          color: "black",
        }}
      >
        <Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
          {graduatedYearData[offerTemplate.recruitGraduatedYear].value +
            "卒向け"}
        </Typography>
        <Typography sx={{ fontWeight: "bold" }}>
          ＜{offerTypeData[offerTemplate.offerType].value}＞
        </Typography>
      </Box>
    </Box>
  );
};
export default OfferTemplateCard;
