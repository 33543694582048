import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { FormControlLabel, Switch, TextField } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { useUser } from "hooks/firebase/context/auth";
import { registerCoupon } from "hooks/firebase/context/oemInfo";

interface FileInputModalProps {
  open: boolean;
  onClose: () => void;
  setCompleteMsg: (msg: string) => void;
  setIsLoading: (isLoading: boolean) => void;
  universityNumber: number;
}

const FileInputModal: React.FC<FileInputModalProps> = ({
  open,
  onClose,
  setCompleteMsg,
  setIsLoading,
  universityNumber,
}) => {
  const [preview, setPreview] = useState<string | null>(null);
  const [isCouponOpen, setIsCouponOpen] = useState<boolean>(true);
  const [googleUrl, setGoogleUrl] = useState<string>("");
  const [errMsg, setErrMsg] = useState<string>("");
  const [couponFile, setCouponFile] = useState<File | null>(null);
  const [isCouponOnlyOnce, setIsCouponOnlyOnce] = useState<boolean>(false);
  const user = useUser();

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      setCouponFile(file);

      const reader = new FileReader();
      reader.onload = () => {
        setPreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: { "image/*": [] },
  });

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (user) {
      const uid = user.auth.uid;
      if (couponFile) {
        setIsLoading(true);
        const errorMessage = await registerCoupon(
          uid,
          universityNumber,
          couponFile,
          isCouponOpen,
          googleUrl,
          false,
          "",
          "",
          isCouponOnlyOnce
        );
        setErrMsg(errorMessage);
        if (!errorMessage) {
          setCompleteMsg("complete");
          setPreview(null);
          setGoogleUrl("");
          setIsCouponOpen(true);
          setIsCouponOnlyOnce(false);
          onClose();
        } else {
          setCompleteMsg(errorMessage);
          onClose();
        }
        setIsLoading(false);
      }
    } else return;
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
        setPreview(null);
      }}
      aria-labelledby="file-input-modal-title"
      aria-describedby="file-input-modal-description"
      sx={{ zIndex: 1 }}
    >
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          position: "absolute",
          top: "55%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography
          id="file-input-modal-title"
          variant="h6"
          component="h2"
          textAlign={"center"}
        >
          クーポン作成
        </Typography>
        <div
          {...getRootProps()}
          style={{
            marginTop: 20,
            marginBottom: 20,
            padding: 20,
            backgroundColor: "#EDEDED",
            cursor: "pointer",
          }}
        >
          <input {...getInputProps()} />
          {preview ? (
            <Box sx={{ mt: 2, textAlign: "center" }}>
              <img
                src={preview}
                alt="Preview"
                style={{ width: "210px", height: "300px", objectFit: "cover" }}
              />
            </Box>
          ) : (
            <Box textAlign={"center"}>
              <FontAwesomeIcon icon={faUpload} size="2x" color="black" />
              <Typography mt={1}>画像ファイルをドロップ</Typography>
              <Typography>または</Typography>
              <Box
                sx={{
                  mx: "auto",
                  width: 100,
                  height: 25,
                  mt: 1,
                  backgroundColor: "white",
                  border: "1px solid black",
                  borderRadius: 20,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography>選択</Typography>
              </Box>
            </Box>
          )}
        </div>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <FormControlLabel
            control={
              <Switch
                checked={isCouponOpen}
                onChange={(e) => setIsCouponOpen(e.target.checked)}
              />
            }
            label="公開"
          />
          <FormControlLabel
            control={
              <Switch
                checked={isCouponOnlyOnce}
                onChange={(e) => setIsCouponOnlyOnce(e.target.checked)}
              />
            }
            label="使用を一度きりにする"
          />
        </Box>
        <TextField
          margin="normal"
          fullWidth
          label="GoogleマップURL"
          type="text"
          value={googleUrl}
          onChange={(e) => setGoogleUrl(e.target.value)}
          variant="outlined"
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{
            mt: 3,
            mb: 2,
            backgroundColor: "black",
            "&:hover": { backgroundColor: "dimgray" },
          }}
        >
          アップロード
        </Button>
        <Button
          onClick={() => {
            onClose();
            setPreview(null);
          }}
          style={{ marginTop: 20 }}
        >
          閉じる
        </Button>
      </Box>
    </Modal>
  );
};

export default FileInputModal;
