import {
  writeBatch,
  doc,
  getDoc,
  DocumentReference,
  serverTimestamp,
  addDoc,
  collection,
  setDoc,
  deleteDoc,
  query,
  orderBy,
  getDocs,
} from "firebase/firestore";
import {
  UserWrap,
  user_company_private,
  user_company_public,
} from "../../../types/firebase/user";
import { db, storage } from "../main";
import { StorageReference, ref, uploadBytes } from "firebase/storage";

export async function submitCompanyInfo(
  name: string, //会社名
  businessContent: string, //事業内容
  listingDivision: number, //上場区分
  pic: string, //担当者
  phone: string, //電話番号
  user: UserWrap,
  iconImage: Blob,
  coverImage: Blob,
  prefecture: number,
  addressDetail: string,
  recruitCatchcopy: string,
  jobCategory: number[],
  picRole: number, //担当者の役割(任意)
  companyCulture: string,
  companyURL?: string, //会社URL
  PRImage1?: Blob, //PR画像
  PRImage2?: Blob
): Promise<string> {
  let errMsg = "";
  const batch = writeBatch(db);
  let iconPhtUrl = "";
  let coverPhtUrl = "";
  let PRPhtUrl1 = "";
  let PRPhtUrl2 = "";

  const uploadPromises = [];
  const metadata = { contentType: "image/jpeg" };

  if (iconImage) {
    var iconImageRef = ref(storage, `compUsers/${user.auth.uid}/iconImage`);
    iconPhtUrl = iconImageRef.fullPath;
    uploadPromises.push(uploadBytes(iconImageRef, iconImage, metadata));
  }
  if (coverImage) {
    var coverImageRef = ref(storage, `compUsers/${user.auth.uid}/coverImage`);
    coverPhtUrl = coverImageRef.fullPath;
    uploadPromises.push(uploadBytes(coverImageRef, coverImage, metadata));
  }
  if (PRImage1) {
    var PRImageRef1 = ref(storage, `compUsers/${user.auth.uid}/PRImage1`);
    PRPhtUrl1 = PRImageRef1.fullPath;
    uploadPromises.push(uploadBytes(PRImageRef1, PRImage1, metadata));
  }
  if (PRImage2) {
    var PRImageRef2 = ref(storage, `compUsers/${user.auth.uid}/PRImage2`);
    PRPhtUrl2 = PRImageRef2.fullPath;
    uploadPromises.push(uploadBytes(PRImageRef2, PRImage2, metadata));
  }
  const publicdata = {
    name: name,
    prefecture: prefecture,
    addressDetail: addressDetail,
    recruitCatchcopy: recruitCatchcopy,
    jobCategory: jobCategory,
    updatedAt: serverTimestamp(),
    businessContent: businessContent,
    listingDivision: listingDivision,
    companyURL: companyURL,
    iconPhtUrl: iconPhtUrl,
    coverPhtUrl: coverPhtUrl,
    companyCulture: companyCulture,
    PRPhtUrl1: PRPhtUrl1,
    PRPhtUrl2: PRPhtUrl2,
  };
  const privatedata = {
    name: name, //会社名
    updatedAt: serverTimestamp(), //更新日
    prefecture: prefecture,
    addressDetail: addressDetail,
    recruitCatchcopy: recruitCatchcopy,
    jobCategory: jobCategory,
    businessContent: businessContent,
    listingDivision: listingDivision,
    companyURL: companyURL,
    pic: pic, //担当者
    phone: phone,
    picRole: picRole, //担当者の役割(任意)
    iconPhtUrl: iconPhtUrl,
    coverPhtUrl: coverPhtUrl,
    companyCulture: companyCulture,
    PRPhtUrl1: PRPhtUrl1,
    PRPhtUrl2: PRPhtUrl2,
  };
  const refToPrivate = doc(db, "compUsersPrivate", user.auth.uid);
  const refToPublic = doc(db, "compUsersPublic", user.auth.uid);
  // Firestoreのデータを更新
  batch.set(refToPrivate, privatedata, { merge: true });
  batch.set(refToPublic, publicdata, { merge: true });

  // 画像のアップロードとFirestoreのバッチ処理の完了を待つ
  try {
    await Promise.all([...uploadPromises, batch.commit()]);
  } catch (err) {
    if (err instanceof Error) {
      console.log(err.message); // 'Error' 型の場合、メッセージにアクセスできる
      errMsg = "予期せぬエラーが発生しました。もう一度お確かめください。";
    } else {
      console.log("発生したエラーは標準のErrorオブジェクトではありません。");
      errMsg = "エラーの詳細を確認できませんでした。";
    }
  }
  return errMsg;
}

//会社の情報を取得
export async function getCompanyInfo(
  user: UserWrap
): Promise<user_company_private> {
  const ref: DocumentReference = doc(db, "compUsersPrivate", user.auth.uid);
  const privateData: user_company_private = (await (
    await getDoc(ref)
  ).data()) as user_company_private;
  return privateData;
}

// 学生に対してメモを作成する関数
export async function createStudentMemo(studentId:string,content:string,uid:string){
  let errMsg = ""
  try{
    const memoDoc=doc(db,"compUsersPrivate",uid,"memo",studentId)
    const data = {content:content,updateAt:serverTimestamp(),studentId:studentId}
    await setDoc(memoDoc,data);
  }catch(error){
    errMsg = "選考メモの作成に失敗しました。"
  }
  return errMsg;
}

// お気に入りの企業を設定する関数
export async function setFavoriteCompany(studentId:string,companyId:string){
  let errMsg = ""
  try{
    const studentDoc = doc(db,"jobHuntingStudentUsersPublic",studentId);
    const favCompDoc = doc(db,"compUsersPrivate",companyId,"favoriteStudents",studentId);
    // 存在を確認
    const studentSnapshot = await getDoc(studentDoc);
    if (!studentSnapshot.exists()){
      throw new Error("指定した学生が存在しません。")
    }
    //すでにお気に入りしている場合はエラー
    if ((await getDoc(favCompDoc)).exists()){
      throw new Error("すでにお気に入りに登録しています。")
    }
    await setDoc(favCompDoc,{
      setAt:serverTimestamp(),
      name:studentSnapshot.data().lastName+studentSnapshot.data().firstName,
      iconPhtUrl:studentSnapshot.data().iconPhtUrl})
  }catch(error){
    errMsg = error instanceof Error ? error.message : "オファーの承認に失敗しました。";
  }
  return errMsg
}

// お気に入りの学生を削除する関数
export async function deleteFavoriteCompany(studentId:string,companyId:string){
  let errMsg = "";
  try{
    const favCompDoc = doc(db,"compUsersPrivate",companyId,"favoriteStudents",studentId);
    if(!(await getDoc(favCompDoc)).exists()){
      throw new Error("指定した学生はお気に入りに存在しません。");
    }
    await deleteDoc(favCompDoc);
  }catch(error){
    errMsg = error instanceof Error ? error.message : "エラーが発生しました。"
  }
  return errMsg
}

// お気に入り登録した学生を取得する関数
export async function getFavoriteStudents(companyId:string){
  let errMsg = "";
  try{
    const q = query(collection(db,"compUsersPrivate",companyId,"favoriteStudents"),orderBy("addedAt","desc"))
    const stdSnapshot = await getDocs(q);
    const stdLists = stdSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data() // すべてのフィールドを取得
    }));
    return {errMsg,stdLists};
  }catch(error){
    errMsg = "データの取得に失敗しました。"
    return {errMsg,stdLists:null};
  }
}