import React, { useState } from "react";
import Cropper from "react-easy-crop";
import { Modal, Box, Button } from "@mui/material";
import getCroppedImg from "./cropImage"; // 画像クロップ関数のインポート（後述）
const style = {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  bgcolor: "background.paper",
  p: 4,
  overflow: "auto",
};

interface CropModalProps {
  open: boolean;
  onClose: () => void;
  setImage: (url: string) => void; // クロップした画像のURLを設定する関数
  imageSrc: string; // クロップする画像のURL
  aspect?: number; // アスペクト比を設定する新しいprops
}

const CropModal: React.FC<CropModalProps> = ({
  open,
  onClose,
  setImage,
  imageSrc,
  aspect = 2 / 1,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<{
    x: number;
    y: number;
    width: number;
    height: number;
  } | null>(null);
  const onCropComplete = (_croppedArea: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const handleCropImage = async () => {
    try {
      if (croppedAreaPixels) {
        // Check if croppedAreaPixels is not null
        const croppedImage = (await getCroppedImg(
          imageSrc,
          croppedAreaPixels
        )) as string;
        setImage(croppedImage);
        onClose();
      } else {
        console.error("Cropped area is null");
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="crop-modal"
      aria-describedby="crop-modal-description"
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          handleCropImage();
        }
      }}
    >
      <Box sx={style}>
        <Cropper
          image={imageSrc}
          crop={crop}
          zoom={zoom}
          aspect={aspect}
          onCropChange={setCrop}
          onZoomChange={setZoom}
          onCropComplete={onCropComplete}
          style={{
            containerStyle: {
              width: "100%",
              height: "100%",
            },
          }}
        />
        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            left: "50%",
            transform: "translateX(-50%)",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            p: 2,
          }}
        >
          <Button
            onClick={handleCropImage}
            style={{
              width: "200px",
              height: "50px",
              fontWeight: "bold",
              fontSize: "1.2rem",
              backgroundColor: "black",
              color: "white",
            }}
          >
            トリミング
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default CropModal;
