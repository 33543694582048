import * as React from "react";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import { getDownLoadUrlFromFullPath } from "hooks/firebase/context/storage";
import { IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpRightFromSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import {
  closeCoupon,
  deleteCoupon,
  openCoupon,
} from "hooks/firebase/context/oemInfo";
import { useUser } from "hooks/firebase/context/auth";

type OemCloseCouponCardProps = {
  imagePath: string;
  couponid: string;
  setDeleteMsg: (msg: string) => void;
  setOpenCouponMsg: (msg: string) => void;
  setIsLoading: (isLoading: boolean) => void;
};

export default function OemCloseCouponCard({
  imagePath,
  couponid,
  setDeleteMsg,
  setOpenCouponMsg,
  setIsLoading,
}: OemCloseCouponCardProps) {
  const [couponValue, setCouponValue] = useState("");
  const user = useUser();

  useEffect(() => {
    if (imagePath === undefined) return;
    Promise.all([getDownLoadUrlFromFullPath(imagePath)]).then(
      async (values) => {
        if (values[0] === null) return;
        setCouponValue(values[0]);
      }
    );
  }, []);

  const deleteCouponFile = async () => {
    if (user) {
      setIsLoading(true);
      const errMsg = await deleteCoupon(user.auth.uid, 1, couponid);
      if (errMsg === "") {
        setDeleteMsg("complete");
        setIsLoading(false);
      } else {
        setDeleteMsg(errMsg);
        setIsLoading(false);
      }
    }
  };
  const openCouponFile = async () => {
    if (user) {
      setIsLoading(true);
      const errMsg = await openCoupon(user.auth.uid, 1, couponid);
      if (errMsg === "") {
        setOpenCouponMsg("complete");
        setIsLoading(false);
      } else {
        setOpenCouponMsg(errMsg);
        setIsLoading(false);
      }
    }
  };

  return (
    <Box sx={{ mx: 4 }}>
      <img
        src={couponValue}
        style={{ width: "210px", height: "300px", objectFit: "cover" }}
      />
      <Box
        sx={{
          width: "210px",
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: 6,
        }}
      >
        <IconButton onClick={openCouponFile}>
          <FontAwesomeIcon
            icon={faArrowUpRightFromSquare}
            size="sm"
            color="gray"
            style={{ marginRight: 3 }}
          />
        </IconButton>
        <IconButton onClick={deleteCouponFile}>
          <FontAwesomeIcon icon={faTrash} size="sm" color="gray" />
        </IconButton>
      </Box>
    </Box>
  );
}
