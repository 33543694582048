import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { CompanyHeader } from "components/CompanyHeader";
import styled from "styled-components";
import {
  CardMedia,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { studentInfoTypes } from "types/page/studentInfoTypes";
import { useUser } from "hooks/firebase/context/auth";

import {
  graduatedMonthData,
  graduatedYearData,
  prefectureData,
  studentDepartmentData,
  studentFacultyData,
} from "data/StudentProfileData";
import { oemUniversityData } from "context/OEMinfo";
import {
  registerDocumentAccepted,
  registerDocumentRejected,
} from "hooks/firebase/context/recruit";
import { jobStudentInfoType } from "types/page/jobStudentInfoType";
const mdTheme = createTheme();

const StyledOfferButton = styled.button`
  width: 150px;
  height: 40px;
  margin: 0 10px;
  font-size: 16px;
  border-radius: 20px;
  background-color: #35b500;
  color: white;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  border: none;
  &:hover {
    background-color: #29d630;
  }
`;
const TableLeftText = styled(Typography)`
  font-size: 18px !important;
  color: dimgray !important;
  text-align: right !important;
`;
const TableRightText = styled(Typography)`
  font-size: 20px !important;
  font-weight: bold !important;
`;

export default function ApplicateStudentProfileDetail() {
  // const [favorite, isFavorite] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const [studentInfos, setStudentInfos] = useState<jobStudentInfoType>();
  const [modalOpen, setModalOpen] = useState(false);
  const [checked, setChecked] = React.useState("passed");
  const [comment, setComment] = React.useState("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.name);
  };
  useEffect(() => {
    setStudentInfos(location.state);
    window.scrollTo(0, 0);
  }, [location]);
  const mainStyles = {
    width: open ? "calc(100vw - 240px)" : "calc(100vw - 72px)",
    marginLeft: open ? "240px" : "72px",
    transition: ".3s",
    overflow: "auto",
  };
  const handleModalClose = () => setModalOpen(false);
  const handleModalOpen = () => setModalOpen(true);

  const formRecruitResult = async () => {
    if (studentInfos?.recruitId) {
      if (checked === "passed") {
        const errMsg = await registerDocumentAccepted(studentInfos?.recruitId);
        console.log(errMsg);
        if (errMsg === "") {
          handleModalClose();
          navigate("/companychat");
        }
      } else if (checked === "rejected") {
        const errMsg = await registerDocumentRejected(
          studentInfos?.recruitId,
          "rejected"
        );
        console.log(errMsg);
        if (errMsg === "") {
          handleModalClose();
          navigate("/applicant-offer-student");
        }
      }
    }
  };
  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <CompanyHeader open={open} toggleDrawer={toggleDrawer} />
        <Box
          component="main"
          sx={{
            ...mainStyles,
          }}
        >
          <Box
            sx={{
              mt: 8,
              px: 5,
              backgroundColor: "#C6D6E2",
            }}
          >
            <Box
              sx={{
                display: "flex",
              }}
            >
              <CardMedia
                component="img"
                sx={{ width: 200, height: 200, borderRadius: 100, mt: 5 }}
                image={studentInfos?.iconPhtUrl}
              />
              <Box sx={{ ml: 10, mt: 5 }}>
                {studentInfos?.lastName ? (
                  <Box sx={{ my: 1 }}>
                    <Typography sx={{ fontSize: 38, fontWeight: "bold" }}>
                      {studentInfos?.lastName + " " + studentInfos?.firstName}
                    </Typography>
                  </Box>
                ) : (
                  <Box />
                )}

                <Box sx={{ my: 1 }}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {studentInfos?.university ? (
                      <Typography
                        sx={{ ml: 3, fontSize: 26, fontWeight: "bold" }}
                      >
                        {oemUniversityData[studentInfos?.university - 1].value}
                      </Typography>
                    ) : (
                      <Box />
                    )}

                    <Typography
                      sx={{ fontSize: 20, ml: 5, fontWeight: "bold" }}
                    >
                      {/* {
                        graduatedYearData[studentInfos?.graduatedYear]
                          .value
                      } */}
                      {studentInfos?.graduatedYear &&
                        studentInfos?.graduatedMonth &&
                        graduatedYearData[studentInfos?.graduatedYear - 1]
                          .value +
                          "年" +
                          graduatedMonthData[studentInfos?.graduatedMonth - 1]
                            .value +
                          "月"}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {studentInfos?.humanitiesOrScience &&
                    studentInfos.humanitiesOrScience === 1 ? (
                      <Chip label={"文系"} color="error" />
                    ) : (
                      <Chip label={"理系"} color="primary" />
                    )}
                    <Typography
                      sx={{ ml: 2, fontSize: 16, fontWeight: "bold" }}
                    >
                      {studentInfos?.faculty &&
                        studentFacultyData[studentInfos?.faculty - 1].value +
                          " "}
                      {studentInfos?.department
                        ? studentDepartmentData[studentInfos?.department - 1]
                            .value
                        : ""}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box sx={{ textAlign: "right", pr: 5 }}>
              {/* <StyledSaveButton>保存する</StyledSaveButton> */}
              <StyledOfferButton onClick={handleModalOpen}>
                選考結果の入力
              </StyledOfferButton>
            </Box>

            <Box sx={{ display: "flex" }}>
              <Button
                sx={{
                  fontSize: 22,
                  mx: 1,
                  fontWeight: "bold",
                  color: "black",
                }}
              >
                プロフィール
              </Button>
              <Button
                sx={{
                  fontSize: 22,
                  mx: 1,
                  fontWeight: "bold",
                  color: "black",
                }}
                onClick={() => {
                  navigate("/applicatestudentprofiledesiredoccupation", {
                    state: {
                      iconPhtUrl: studentInfos?.iconPhtUrl,
                      senderId: studentInfos?.senderId,
                      grade: studentInfos?.grade,
                      university: studentInfos?.university,
                      faculty: studentInfos?.faculty,
                      department: studentInfos?.department,
                      firstName: studentInfos?.firstName,
                      lastName: studentInfos?.lastName,
                      firstNameKana: studentInfos?.firstNameKana,
                      lastNameKana: studentInfos?.lastNameKana,
                      sex: studentInfos?.sex,
                      livingArea: studentInfos?.livingArea,
                      birthPlace: studentInfos?.birthPlace,
                      graduatedHighSchool: studentInfos?.graduatedHighSchool,
                      certification: studentInfos?.certification,
                      programmingExperience:
                        studentInfos?.programmingExperience,
                      programmingExperienceDetail:
                        studentInfos?.programmingExperienceDetail,
                      circle: studentInfos?.circle,
                      researchGroup: studentInfos?.researchGroup,
                      desiredFirstBusiness: studentInfos?.desiredFirstBusiness,
                      desiredSecondBusiness:
                        studentInfos?.desiredSecondBusiness,
                      desiredThirdBusiness: studentInfos?.desiredThirdBusiness,
                      desiredFirstJob: studentInfos?.desiredFirstJob,
                      desiredSecondJob: studentInfos?.desiredSecondJob,
                      desiredThirdJob: studentInfos?.desiredThirdJob,
                      choiceFirstStandard: studentInfos?.choiceFirstStandard,
                      choiceSecondStandard: studentInfos?.choiceSecondStandard,
                      choiceThirdStandard: studentInfos?.choiceThirdStandard,
                      graduatedYear: studentInfos?.graduatedYear,
                      graduatedMonth: studentInfos?.graduatedMonth,
                      humanitiesOrScience: studentInfos?.humanitiesOrScience,
                      internCompName: studentInfos?.internCompName,
                      internJobType: studentInfos?.internJobType,
                      internPeriod: studentInfos?.internPeriod,
                      selfPRMain: studentInfos?.selfPRMain,
                      updatedAt: studentInfos?.updatedAt,
                      recruitId: studentInfos?.recruitId,
                    },
                  });
                }}
              >
                就活における価値観
              </Button>
            </Box>

            <Box sx={{ display: "flex" }}>
              <Box
                sx={{ width: "160px", height: "5px", backgroundColor: "black" }}
              />
            </Box>
          </Box>
          <Box sx={{ mx: 5, my: 5 }}>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <TableLeftText>性別</TableLeftText>
              </Grid>
              <Grid item xs={9}>
                <TableRightText>
                  {studentInfos?.sex === 0 ? "男性" : "女性"}
                </TableRightText>
              </Grid>
              <Grid item xs={3}>
                <TableLeftText>住所</TableLeftText>
              </Grid>
              <Grid item xs={9}>
                <TableRightText>
                  {studentInfos?.livingArea &&
                    prefectureData[studentInfos.livingArea - 1].value}
                </TableRightText>
              </Grid>
              {studentInfos?.graduatedHighSchool && (
                <>
                  <Grid item xs={3}>
                    <TableLeftText>卒業高校</TableLeftText>
                  </Grid>
                  <Grid item xs={9}>
                    <TableRightText>
                      {studentInfos.graduatedHighSchool}
                    </TableRightText>
                  </Grid>
                </>
              )}
              {studentInfos?.certification && (
                <>
                  <Grid item xs={3}>
                    <TableLeftText>資格</TableLeftText>
                  </Grid>
                  <Grid item xs={9}>
                    <TableRightText>
                      {studentInfos.certification}
                    </TableRightText>
                  </Grid>
                </>
              )}

              <Grid item xs={3}>
                <TableLeftText>プログラミング経験</TableLeftText>
              </Grid>
              <Grid item xs={9}>
                <TableRightText>
                  {studentInfos?.programmingExperience === 1 ? "有り" : "無し"}
                </TableRightText>
              </Grid>
              {studentInfos?.programmingExperienceDetail && (
                <>
                  <Grid item xs={3}>
                    <TableLeftText>プログラミング経験詳細</TableLeftText>
                  </Grid>
                  <Grid item xs={9}>
                    <TableRightText>
                      {studentInfos?.programmingExperienceDetail}
                    </TableRightText>
                  </Grid>
                </>
              )}
              {studentInfos?.circle && (
                <>
                  <Grid item xs={3}>
                    <TableLeftText>サークル</TableLeftText>
                  </Grid>
                  <Grid item xs={9}>
                    <TableRightText>{studentInfos?.circle}</TableRightText>
                  </Grid>
                </>
              )}
              {studentInfos?.researchGroup && (
                <>
                  <Grid item xs={3}>
                    <TableLeftText>ゼミ・研究室</TableLeftText>
                  </Grid>
                  <Grid item xs={9}>
                    <TableRightText>
                      {studentInfos?.researchGroup}
                    </TableRightText>
                  </Grid>
                </>
              )}
              {studentInfos?.selfPRMain && (
                <>
                  <Grid item xs={3}>
                    <TableLeftText>自己PR</TableLeftText>
                  </Grid>
                  <Grid item xs={9}>
                    <TableRightText>
                      {studentInfos?.selfPRMain
                        .split("\n")
                        .map((line, index) => (
                          <React.Fragment key={index}>
                            {line}
                            <br />
                          </React.Fragment>
                        ))}
                    </TableRightText>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        </Box>
      </Box>
      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 450,
            bgcolor: "background.paper",
            borderRadius: 5,
            boxShadow: 24,
            p: 4,
            textAlign: "center",
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            書類選考の結果を入力してください。
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              my: 3,
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked === "passed"}
                  onChange={handleChange}
                  name="passed"
                  sx={{
                    color: "black",
                    "&.Mui-checked": { color: "#00A62E" },
                  }}
                />
              }
              label={<Typography variant="h6">選考通過</Typography>}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked === "rejected"}
                  onChange={handleChange}
                  name="rejected"
                  sx={{ color: "black", "&.Mui-checked": { color: "#00A62E" } }}
                />
              }
              label={<Typography variant="h6">不採用</Typography>}
            />
          </Box>

          {checked === "rejected" && (
            <TextField
              id="outlined-multiline-static"
              label="学生へのメッセージ"
              multiline
              rows={4}
              value={comment}
              onChange={(e) => setComment(e.target.value)} // テキストエリアの値が変更されたときに状態を更新
              variant="outlined"
              sx={{ width: "100%", mt: 2 }}
            />
          )}
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            ※ 選考通過後はキャンセルできません。
          </Typography>
          <Button
            onClick={handleModalClose}
            variant="outlined"
            sx={{
              mt: 2,
              mr: 2,
              width: 120,
              px: 1,
              fontWeight: "bold",
              borderColor: "gray", // 枠線の色を黒に設定
              color: "gray", // ボタンのテキスト色も黒に設定する場合
              "&:hover": {
                borderColor: "gray", // ホバー時の枠線の色も黒に保持
                backgroundColor: "transparent", // ホバー時の背景色を透明に保持
              },
            }}
            size="large"
          >
            キャンセル
          </Button>
          <Button
            variant="contained"
            onClick={formRecruitResult}
            sx={{
              mt: 2,
              width: 120,
              px: 1,
              backgroundColor: "#00A62E",
              "&:hover": { backgroundColor: "#00A62E" },
              fontWeight: "bold",
            }}
            size="large"
          >
            確定する
          </Button>
        </Box>
      </Modal>
    </ThemeProvider>
  );
}
