import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import { Button, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import serviceLogo from "assets/serviceLogo.png";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";

const LogoImg = styled.img`
  width: 250px;
`;

const StyledButton = styled(Button)`
  &:hover {
    background-color: gray !important;
  }
`;
export default function CompleteCompanyRegister() {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        height: "100vh",
        pt: 7,
        backgroundColor: "whitesmoke",
      }}
    >
      <Container component="main">
        <CssBaseline />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "white",
            pt: 5,
            height: 400,
            px: 4,
          }}
        >
          <Link to="/">
            <LogoImg src={serviceLogo} />
          </Link>
          <Box sx={{ mt: 5, textAlign: "center" }}>
            <Typography sx={{ fontSize: "28px", fontWeight: "bold" }}>
              企業の仮アカウント登録が完了いたしました。
            </Typography>
            <Typography sx={{ fontSize: "20px", fontWeight: "bold", mt: 3 }}>
              ログイン画面にて先ほどのメールアドレスとパスワードを入力してください。
            </Typography>
            <StyledButton
              disableElevation
              variant="contained"
              sx={{
                mt: 7,
                background: "dimgray",
                width: "200px",
                fontWeight: "bold",
                fontSize: "1.1rem",
                "&:hover": {
                  backgroundColor: "dimgray",
                },
              }}
              onClick={() => {
                navigate("/companylogin");
              }}
            >
              ログインへ
            </StyledButton>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
