import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { CompanyHeader } from "components/CompanyHeader";
import { useState } from "react";
import { studentInfoTypes } from "types/page/studentInfoTypes";
import NonAcceptedOfferedStudentProfileCard from "components/NonAcceptedOfferedStudentProfileCard";
import { Typography } from "@mui/material";
import { styled } from "styled-components";

const mdTheme = createTheme();
const StyledRecruitButton = styled(Button)`
  display: flex;
  textalign: left;
  width: 300px;
  box-shadow: 0px 5px 10px lightgray;
  padding: 15px !important;
  background-color: white !important;
  margin: 20px !important;
`;
export default function NonPublicCreatedRecruitSample() {
  const navigate = useNavigate();

  const [open, setOpen] = useState(true);

  const toggleDrawer = () => {
    setOpen(!open);
  };
  const mainStyles = {
    width: open ? "calc(100vw - 240px)" : "calc(100vw - 72px)",
    marginLeft: open ? "240px" : "72px",
    transition: ".3s",
    overflow: "auto",
  };
  return (
    <ThemeProvider theme={mdTheme}>
      <CssBaseline />
      <CompanyHeader
        open={open}
        toggleDrawer={toggleDrawer}
        showrecruitModalProps={true}
      />
      <Box
        component="main"
        sx={{
          ...mainStyles,
        }}
      >
        <Box mt={14}>
          <Typography
            fontSize={"1.8rem"}
            fontWeight={"bold"}
            textAlign={"center"}
          >
            作成した求人
          </Typography>
        </Box>
        <Box
          sx={{
            width: { xs: 340, sm: 500 },
            height: 40,
            borderRadius: 20,
            marginTop: { xs: 3, sm: 5 },
            backgroundColor: "#D9D9D9",
          }}
          mx="auto"
        >
          <Button
            onClick={() => navigate("/sample/public-created-recruit")}
            variant="contained"
            disableElevation
            sx={{
              width: { xs: 169, sm: 249 },
              borderRadius: 20,
              height: 40,
              color: "#4b4b4b",
              fontSize: { xs: "0.95rem", sm: "1.2rem" },
              fontWeight: "bold",
              backgroundColor: "#D9D9D9",
              ":hover": { backgroundColor: "#D9D9D9" },
            }}
          >
            掲載中の求人
          </Button>
          <Button
            variant="contained"
            disableElevation
            sx={{
              width: { xs: 169, sm: 249 },
              borderRadius: 20,
              fontWeight: "bold",
              fontSize: { xs: "0.95rem", sm: "1.2rem" },
              height: 40,
              backgroundColor: "#4b4b4b",
              ":hover": { backgroundColor: "#4b4b4b" },
            }}
          >
            未掲載の求人
          </Button>
        </Box>

        <Box
          sx={{
            width: "80%",
            marginLeft: "10%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap",
            marginTop: 3,
          }}
        >
          <StyledRecruitButton
            sx={{
              borderRadius: 5,
              mt: 3,
            }}
            onClick={() => navigate("/sample/edit-recruit-detail-intern")}
          >
            <Box
              sx={{
                width: "3px",
                height: "50px",
                backgroundColor: "#00A2C6",
                mr: 2,
              }}
            />
            <Box
              sx={{
                width: "100%",
                textAlign: "left",
                color: "black",
              }}
            >
              <Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                2025年卒向け
              </Typography>
              <Typography sx={{ fontWeight: "bold" }}>
                ＜長期インターン＞
              </Typography>
              <Box sx={{ textAlign: "right" }}>
                <Typography sx={{ fontWeight: "bold" }}>開く＞</Typography>
              </Box>
            </Box>
          </StyledRecruitButton>
          <StyledRecruitButton
            sx={{
              borderRadius: 5,
              mt: 3,
            }}
            onClick={() => navigate("/sample/edit-recruit-detail")}
          >
            <Box
              sx={{
                width: "3px",
                height: "50px",
                backgroundColor: "red",
                mr: 2,
              }}
            />
            <Box
              sx={{
                width: "100%",
                textAlign: "left",
                color: "black",
              }}
            >
              <Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                2023年卒向け
              </Typography>
              <Typography sx={{ fontWeight: "bold" }}>＜新卒採用＞</Typography>
              <Box sx={{ textAlign: "right" }}>
                <Typography sx={{ fontWeight: "bold" }}>開く＞</Typography>
              </Box>
            </Box>
          </StyledRecruitButton>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
