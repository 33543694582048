import * as React from "react";
import TextField from "@mui/material/TextField";
import { useState } from "react";

type DefaultTextFieldProps = {
  name: string;
  placeholder: string;
  required?: boolean;
};

export default function DefaultTextField({
  name,
  placeholder,
  required = false,
}: DefaultTextFieldProps) {
  const [value, setValue] = useState("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    setValue(inputValue);
  };
  return (
    <TextField
      inputProps={{ style: { padding: "10px" } }}
      margin="normal"
      fullWidth
      onChange={handleChange}
      value={value}
      name={name}
      placeholder={placeholder}
      required={required}
    />
  );
}
