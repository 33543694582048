import * as React from "react";
import { useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  IconButton,
  Typography,
} from "@mui/material";
import {
  AddCircleOutline,
  CloseOutlined,
  CloudUploadOutlined,
} from "@mui/icons-material";
import ReactDatePicker from "react-datepicker";
import {
  getDownloadURL,
  ref,
  StorageReference,
  uploadBytes,
} from "firebase/storage";
import { db, storage } from "../../hooks/firebase/main";
import { addDoc, collection, doc, setDoc } from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";

const mdTheme = createTheme();

// TODO: アクセス制限をかける

type Coupon = {
  file: File;
  startedAt: Date;
  endedAt: Date;
};

export default function UploadCoupon() {
  const [coupons, setCoupons] = useState<Coupon[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const defaultStartedAt = new Date();
  defaultStartedAt.setHours(0);
  defaultStartedAt.setMinutes(0);
  const defaultEndedAt = new Date();
  defaultEndedAt.setHours(23);
  defaultEndedAt.setMinutes(59);
  const UploadCouponImages = async () => {
    setIsLoading(true);
    let index = 0;
    for (const coupon of coupons) {
      const m_uuid = uuidv4();
      const phtBlob = new Blob([coupon.file], { type: coupon.file.type });
      const chatPhtRef: StorageReference = ref(storage, `coupons/${m_uuid}`);
      const metadata = {
        contentType: coupon.file.type,
      };
      await uploadBytes(chatPhtRef, phtBlob, metadata);
      await setDoc(doc(db, "coupons", m_uuid), {
        imgRef: chatPhtRef.fullPath,
        startsAt: coupon.startedAt,
        endsAt: coupon.endedAt,
      });
      // リストから消す
      let tmpCoupons: Coupon[] = [...coupons];
      tmpCoupons.splice(index, 1);
      setCoupons(tmpCoupons);
      index++;
    }
    setIsLoading(false);
    alert("アップロード完了");
  };
  return (
    <ThemeProvider theme={mdTheme}>
      <Box>
        <Typography variant="h4" sx={{ margin: "8px" }}>
          クーポンアップロード
        </Typography>
        <Box style={{ display: "flex" }}>
          {coupons.map((coupon: Coupon, index: number) => {
            return (
              <Card sx={{ maxWidth: 345, margin: "8px" }} key={index}>
                <CardHeader
                  sx={{ margin: "4px", padding: "4px" }}
                  action={
                    <IconButton
                      sx={{ size: "small" }}
                      aria-label="delete"
                      onClick={() => {
                        let tmpCoupons: Coupon[] = [...coupons];
                        tmpCoupons.splice(index, 1);
                        setCoupons(tmpCoupons);
                      }}
                    >
                      <CloseOutlined />
                    </IconButton>
                  }
                />
                <CardMedia
                  sx={{ height: 140 }}
                  image={URL.createObjectURL(coupon.file)}
                  title="green iguana"
                />
                <CardContent>
                  <Typography gutterBottom variant="body2">
                    {coupon.file.name}
                  </Typography>
                  <Typography variant="subtitle2">使用開始日</Typography>
                  <ReactDatePicker
                    selected={coupon.startedAt}
                    dateFormat="yyyy/MM/dd"
                    onChange={(e: Date) => {
                      if (!e) return;
                      let tmpCoupons: Coupon[] = [...coupons];
                      e.setHours(0);
                      e.setMinutes(0);
                      tmpCoupons[index].startedAt = e;
                      if (e <= tmpCoupons[index].endedAt)
                        setCoupons(tmpCoupons);
                    }}
                  />
                  <Typography variant="subtitle2">使用終了日</Typography>
                  <ReactDatePicker
                    selected={coupon.endedAt}
                    dateFormat="yyyy/MM/dd"
                    onChange={(e: Date) => {
                      if (!e) return;
                      let tmpCoupons: Coupon[] = [...coupons];
                      e.setHours(23);
                      e.setMinutes(59);
                      tmpCoupons[index].endedAt = e;
                      if (tmpCoupons[index].startedAt <= e)
                        setCoupons(tmpCoupons);
                    }}
                  />
                </CardContent>
              </Card>
            );
          })}
          <Card
            sx={{ maxWidth: 345, height: 365, margin: "8px" }}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IconButton
              aria-label="add image"
              color="primary"
              onClick={() => {
                const selectImageInputElm = document.querySelector("input");
                if (selectImageInputElm) {
                  selectImageInputElm.click();
                }
              }}
            >
              <AddCircleOutline />
            </IconButton>
          </Card>
        </Box>
        <input
          type="file"
          accept="image/*"
          multiple
          style={{ display: "none" }}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const files = event.currentTarget.files;
            // ファイルがなければ終了
            if (!files || files?.length === 0) return;
            const files2coupons: Coupon[] = Array.from(files).map((e: File) => {
              return {
                file: e,
                startedAt: defaultStartedAt,
                endedAt: defaultEndedAt,
              };
            });
            setCoupons(coupons.concat(files2coupons));
          }}
        />
        <Button
          variant="outlined"
          color="primary"
          startIcon={<CloudUploadOutlined />}
          sx={{ margin: "8px" }}
          onClick={() => UploadCouponImages()}
          disabled={isLoading}
        >
          アップロード
        </Button>
      </Box>
    </ThemeProvider>
  );
}
