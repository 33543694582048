import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { CompanyHeader } from "components/CompanyHeader";
import { useState } from "react";
import { clubInfoArticleType } from "types/page/clubInfoArticleType";
import ClubProfileCard from "components/ClubProfileCard";
import UseCombo from "components/UseCombo";
import PreviewSnackbar from "components/PreviewSnackbar";

const mdTheme = createTheme();

export default function CompanySearchStudent() {
  const navigate = useNavigate();

  const [open, setOpen] = useState(true);
  const [genreStateProp, setGenreStateProp] = React.useState<string>("");
  const [studentInfos, setStudentInfos] = useState<clubInfoArticleType[]>([
    {
      name: "Run",
      activity:
        "Runは【すべての大学生がやりたいことを実現する】をコンセプトに、週に１回活動しているインカレビジネスサークルです！ 「個性を生かしたい！」「世界はもっと良くできる！」と思っているあなたへ。 より実践的な活動を通して、本気で努力しませんか。 Runで周りより刺激的で成長できる大学生活を。",
      place: "新宿近辺",
      date: "毎週土曜日の夜",
      people: "10人",
      twitter: "https://twitter.com/Run20020000",
      insta: "https://www.instagram.com/run20020000/",
      lastStatement:
        "新入生の皆さんご入学おめでとうございます！大学生活を少しでも将来のために努力したいというあなたにぜひ来てほしいです。まずは気軽に新歓に足を運んでみてください！",
      feature: [""],
      updatedAt: "2023-12-05",
      largeGenre: 2,
      smallGenre: 1,
      iconImage: "/images/circle1.png",
      coverImage: "",
    },
  ]);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const mainStyles = {
    width: open ? "calc(100vw - 240px)" : "calc(100vw - 72px)",
    marginLeft: open ? "240px" : "72px",
    transition: ".3s",
    overflow: "auto",
  };
  return (
    <ThemeProvider theme={mdTheme}>
      <CssBaseline />
      <CompanyHeader open={open} toggleDrawer={toggleDrawer} />
      <Box
        component="main"
        sx={{
          ...mainStyles,
        }}
      >
        <PreviewSnackbar open={open} />
        <Box
          sx={{
            width: { xs: 242, sm: 302 },
            height: 42,
            borderRadius: 20,
            marginTop: { xs: 10, sm: 12 },
            border: "1px solid #4b4b4b",
          }}
          mx="auto"
        >
          <Button
            variant="contained"
            disableElevation
            onClick={() => navigate("/sample/companysearchstudent")}
            sx={{
              width: { xs: 120, sm: 150 },
              borderRadius: 20,
              color: "#4b4b4b",
              height: 40,
              fontSize: "1.2rem",
              fontWeight: "bold",
              backgroundColor: "rgba(0,0,0,0)",
              ":hover": { backgroundColor: "rgba(0,0,0,0)" },
            }}
          >
            学生
          </Button>
          <Button
            variant="contained"
            disableElevation
            sx={{
              width: { xs: 120, sm: 150 },
              borderRadius: 20,
              fontWeight: "bold",
              fontSize: "1.2rem",
              height: 40,
              backgroundColor: "#4b4b4b",
              ":hover": { backgroundColor: "#4b4b4b" },
            }}
          >
            サークル
          </Button>
        </Box>

        <Paper
          component="form"
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: 500,
            mx: "auto",
            mt: 5,
            borderRadius: 20,
            boxShadow: 0,
            border: "1px solid black",
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="キーワード検索  例)TOEIC、早稲田大学"
            inputProps={{ "aria-label": "search google maps" }}
          />
          <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
            <SearchIcon />
          </IconButton>
        </Paper>
        <Box
          sx={{
            display: "flex",
            width: "80%",
            flexWrap: "wrap",
            mx: "auto",
            justifyContent: "space-around",
            mt: 5,
          }}
        >
          <Box
            sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" } }}
          >
            <UseCombo
              largeId={"0"}
              smallId={"0"}
              updateState={setGenreStateProp}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            width: "90%",
            flexWrap: "wrap",
            mx: "auto",
          }}
        >
          {studentInfos.map((studentInfo, i) => {
            return (
              <ClubProfileCard
                key={i}
                imagePath={studentInfo.iconImage}
                clubProfileInfos={studentInfo}
              />
            );
          })}
        </Box>
      </Box>
    </ThemeProvider>
  );
}
