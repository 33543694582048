interface ClubGenre {
  largeGenreId: string;
  genreTitle: string;
  smallGenres: {
    smallGenreId: string;
    smallGenreText: string;
  }[];
}

const ClubGenreList: ClubGenre[] = [
  {
    largeGenreId: "0",
    genreTitle: "全て",
    smallGenres: [
      {
        smallGenreId: "0",
        smallGenreText: "全て",
      },
    ],
  },
  {
    largeGenreId: "1",
    genreTitle: "スポーツ（球技）",
    smallGenres: [
      {
        smallGenreId: "0",
        smallGenreText: "全て",
      },
      {
        smallGenreId: "1",
        smallGenreText: "野球",
      },
      {
        smallGenreId: "2",
        smallGenreText: "サッカー",
      },
      {
        smallGenreId: "3",
        smallGenreText: "フットサル",
      },
      {
        smallGenreId: "4",
        smallGenreText: "ラグビー",
      },
      {
        smallGenreId: "5",
        smallGenreText: "アメリカンフットボール",
      },
      {
        smallGenreId: "6",
        smallGenreText: "バスケットボール",
      },
      {
        smallGenreId: "7",
        smallGenreText: "テニス",
      },
      {
        smallGenreId: "8",
        smallGenreText: "バレーボール",
      },
      {
        smallGenreId: "9",
        smallGenreText: "ゴルフ",
      },
      {
        smallGenreId: "10",
        smallGenreText: "その他",
      },
    ],
  },
  {
    largeGenreId: "2",
    genreTitle: "スポーツ（球技以外）",
    smallGenres: [
      {
        smallGenreId: "0",
        smallGenreText: "全て",
      },
      {
        smallGenreId: "1",
        smallGenreText: "バドミントン",
      },
      {
        smallGenreId: "2",
        smallGenreText: "ダンス",
      },
      {
        smallGenreId: "3",
        smallGenreText: "武道",
      },
      {
        smallGenreId: "4",
        smallGenreText: "乗馬",
      },
      {
        smallGenreId: "5",
        smallGenreText: "ヨット",
      },
      {
        smallGenreId: "6",
        smallGenreText: "スキー",
      },
      {
        smallGenreId: "7",
        smallGenreText: "水泳",
      },
      {
        smallGenreId: "8",
        smallGenreText: "サイクリング",
      },
      {
        smallGenreId: "9",
        smallGenreText: "アウトドア",
      },
      {
        smallGenreId: "10",
        smallGenreText: "その他",
      },
    ],
  },
  {
    largeGenreId: "3",
    genreTitle: "学問",
    smallGenres: [
      {
        smallGenreId: "0",
        smallGenreText: "全て",
      },
      {
        smallGenreId: "1",
        smallGenreText: "政治",
      },
      {
        smallGenreId: "2",
        smallGenreText: "経済",
      },
      {
        smallGenreId: "3",
        smallGenreText: "歴史",
      },
      {
        smallGenreId: "4",
        smallGenreText: "宗教",
      },
      {
        smallGenreId: "5",
        smallGenreText: "哲学",
      },
      {
        smallGenreId: "6",
        smallGenreText: "法律",
      },
      {
        smallGenreId: "7",
        smallGenreText: "自然科学",
      },
      {
        smallGenreId: "8",
        smallGenreText: "言語",
      },
      {
        smallGenreId: "9",
        smallGenreText: "日本文学",
      },
      {
        smallGenreId: "10",
        smallGenreText: "日本文化",
      },
      {
        smallGenreId: "11",
        smallGenreText: "学問",
      },
      {
        smallGenreId: "12",
        smallGenreText: "趣味",
      },
      {
        smallGenreId: "13",
        smallGenreText: "技術",
      },
    ],
  },
  {
    largeGenreId: "4",
    genreTitle: "メディア・出版",
    smallGenres: [
      {
        smallGenreId: "0",
        smallGenreText: "全て",
      },
      {
        smallGenreId: "1",
        smallGenreText: "出版",
      },
      {
        smallGenreId: "2",
        smallGenreText: "コミュニケーション",
      },
      {
        smallGenreId: "3",
        smallGenreText: "マスメディア",
      },
      {
        smallGenreId: "4",
        smallGenreText: "企画",
      },
      {
        smallGenreId: "5",
        smallGenreText: "哲学",
      },
      {
        smallGenreId: "6",
        smallGenreText: "法律",
      },
      {
        smallGenreId: "7",
        smallGenreText: "自然科学",
      },
      {
        smallGenreId: "8",
        smallGenreText: "言語",
      },
      {
        smallGenreId: "9",
        smallGenreText: "日本文学",
      },
      {
        smallGenreId: "10",
        smallGenreText: "日本文化",
      },
      {
        smallGenreId: "11",
        smallGenreText: "学問",
      },
      {
        smallGenreId: "12",
        smallGenreText: "趣味",
      },
      {
        smallGenreId: "13",
        smallGenreText: "技術",
      },
    ],
  },
  {
    largeGenreId: "5",
    genreTitle: "文化・芸術",
    smallGenres: [
      {
        smallGenreId: "0",
        smallGenreText: "全て",
      },
      {
        smallGenreId: "1",
        smallGenreText: "舞台芸術",
      },
      {
        smallGenreId: "2",
        smallGenreText: "演劇",
      },
      {
        smallGenreId: "3",
        smallGenreText: "映画",
      },
      {
        smallGenreId: "4",
        smallGenreText: "音楽",
      },
      {
        smallGenreId: "5",
        smallGenreText: "声楽",
      },
      {
        smallGenreId: "6",
        smallGenreText: "美術",
      },
      {
        smallGenreId: "7",
        smallGenreText: "その他",
      },
    ],
  },
  {
    largeGenreId: "6",
    genreTitle: "国際交流・ボランティア",
    smallGenres: [
      {
        smallGenreId: "0",
        smallGenreText: "全て",
      },
      {
        smallGenreId: "1",
        smallGenreText: "国際交流",
      },
      {
        smallGenreId: "2",
        smallGenreText: "ボランティア",
      },
    ],
  },
  {
    largeGenreId: "7",
    genreTitle: "その他",
    smallGenres: [
      {
        smallGenreId: "0",
        smallGenreText: "全て",
      },
      {
        smallGenreId: "1",
        smallGenreText: "地方学生の会",
      },
      {
        smallGenreId: "2",
        smallGenreText: "その他",
      },
    ],
  },
];
export default ClubGenreList;
