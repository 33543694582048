import * as React from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  Container,
  Grid,
  CssBaseline,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useState, useEffect } from "react";
import {
  companyLogin,
  login,
  oemLogin,
  sendEmailVerificationWrap,
  useUser,
} from "hooks/firebase/context/auth";
import { CircularProgress } from "@mui/material";
import { signOut } from "firebase/auth";
import { auth } from "hooks/firebase/main";
import serviceLogo from "assets/serviceLogo.png";

const BottomLink = styled(Link)`
  text-decoration: none;
  font-size: 0.95rem;
  color: #2a4cce;
`;
const LogoImg = styled.img`
  width: 300px;
`;
const StyledButton = styled(Button)`
  &:hover {
    background-color: gray !important;
  }
`;

export default function OEMLogin() {
  const user = useUser();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [isVerified, setIsVerified] = useState(true);
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    setIsLoading(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    function formRef(key: string): string {
      if (data.get(key) === null) return "";
      else return data.get(key) as string;
    }
    const loginMsg: string = await oemLogin(
      formRef("email"),
      formRef("password")
    );
    setIsLoading(false);
    if (loginMsg === "" || loginMsg === "基本情報未登録です") {
      navigate("/sample/oemtop");
    }
    if (loginMsg === "認証メールが送信されました。") {
      navigate("/companyconfirmemail");
    } else {
      setIsVerified(true);
    }
    setErrMsg(loginMsg);
  };
  const resendEmail = async () => {
    const errResendMsg: string = await sendEmailVerificationWrap();
    setErrMsg(errResendMsg);
  };
  useEffect(() => {
    signOut(auth);
  }, []);

  return (
    <Box
      sx={{
        height: "100vh",
        pt: { xs: 2, sm: 7 },
        backgroundColor: "whitesmoke",
      }}
    >
      <Container component="main">
        <CssBaseline />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: 1,
            pt: { xs: 2, sm: 3 },
          }}
        >
          <Link to="/">
            <LogoImg src={serviceLogo} />
          </Link>
          <Box sx={{ position: "absolute", top: { xs: 130, sm: 180 } }}>
            {isLoading ? <CircularProgress size={30} /> : <Box />}
          </Box>
          <Box mx={3} mt={5}>
            <Typography color="red">{errMsg}</Typography>
          </Box>
          {isVerified ? (
            <Box></Box>
          ) : (
            <Box mt={1}>
              <Button onClick={resendEmail}>認証メールを再送</Button>
            </Box>
          )}
          <Box
            sx={{
              border: "2px solid gray",
              width: { xs: "100%", sm: "80%" },
              height: "50%",
              backgroundColor: "white",
            }}
          >
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{
                my: 4,
                mx: "auto",
                width: { xs: "90%", sm: "400px" },
              }}
            >
              <Typography fontWeight={"bold"}>メールアドレス</Typography>
              <TextField
                sx={{ mt: { xs: 1 } }}
                inputProps={{ style: { padding: "10px" } }}
                margin="normal"
                required
                fullWidth
                name="email"
                type="email"
                id="email"
              />
              <Typography fontWeight={"bold"}>パスワード</Typography>
              <TextField
                sx={{ mt: { xs: 1 } }}
                inputProps={{ style: { padding: "10px" } }}
                margin="normal"
                required
                fullWidth
                name="password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <Grid container sx={{ mb: 1 }}>
                <Grid item>
                  <BottomLink to="/resetpassword">
                    パスワードをお忘れの方
                  </BottomLink>
                </Grid>
              </Grid>

              <Box textAlign={"center"}>
                <StyledButton
                  type="submit"
                  disableElevation
                  variant="contained"
                  sx={{
                    margin: "10px auto",
                    background: "#2a2a2a",
                    width: "200px",
                    fontWeight: "bold",
                    fontSize: "1.1rem",
                    "&:hover": {
                      backgroundColor: "dimgray",
                    },
                  }}
                >
                  ログイン
                </StyledButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
