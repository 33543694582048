import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import Container from "@mui/material/Container";
import { Button } from "@mui/material";
import { sendEmailVerificationWrap } from "hooks/firebase/context/auth";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import serviceLogo from "assets/serviceLogo.png";
import styled from "styled-components";

const LogoImg = styled.img`
  width: 250px;
`;

export default function ConfirmEmail({ navigatePage }: any) {
  const navigate = useNavigate();
  const [errMsg, setErrMsg] = useState("");
  const resendEmail = async () => {
    const errResendMsg: string = await sendEmailVerificationWrap(); //errMsgはデフォルトが"", エラーが発生するとそのエラーメッセージを含みます。
    setErrMsg(errResendMsg);
  };
  return (
    <Box
      sx={{
        height: "100vh",
        backgroundColor: "whitesmoke",
        pt: 7,
      }}
    >
      <Container component="main">
        <CssBaseline />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "white",
            pt: 5,
            pb: 4,
            px: 4,
          }}
        >
          <LogoImg src={serviceLogo} />

          <Box height={20} mt={4}>
            <Typography color="red">{errMsg}</Typography>
          </Box>
          <Box sx={{ mt: 5, textAlign: "center" }}>
            <Typography sx={{ fontSize: "28px", fontWeight: "bold" }}>
              認証メールを送信いたしました
            </Typography>
            <Typography mt={3} mb={6} sx={{ fontSize: "18px" }}>
              記載されているURLをクリックして、認証を完了してください
            </Typography>
            <Button
              disableElevation
              variant="contained"
              onClick={() => {
                navigate(navigatePage);
              }}
              fullWidth
              sx={{
                mt: 3,
                mb: 2,
                margin: "10px auto",
                background: "dimgray",
                width: "200px",
                fontWeight: "bold",
                fontSize: "1.1rem",
                "&:hover": {
                  backgroundColor: "gray",
                },
              }}
            >
              ログイン画面へ
            </Button>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                margin: "0 auto",
                alignItems: "center",
                justifyContent: "space-between",
                mt: 2,
              }}
            >
              <Typography color="gray" pb={1}>
                ※認証メールが届かない場合は
              </Typography>
              <Button variant="outlined" size="small" onClick={resendEmail}>
                認証メールを再送信
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
