export const prefectureData = [
  { key: "0", value: "北海道" },
  { key: "1", value: "青森県" },
  { key: "2", value: "岩手県" },
  { key: "3", value: "宮城県" },
  { key: "4", value: "秋田県" },
  { key: "5", value: "山形県" },
  { key: "6", value: "福島県" },
  { key: "7", value: "茨城県" },
  { key: "8", value: "栃木県" },
  { key: "9", value: "群馬県" },
  { key: "10", value: "埼玉県" },
  { key: "11", value: "千葉県" },
  { key: "12", value: "東京都" },
  { key: "13", value: "神奈川県" },
  { key: "14", value: "新潟県" },
  { key: "15", value: "富山県" },
  { key: "16", value: "石川県" },
  { key: "17", value: "福井県" },
  { key: "18", value: "山梨県" },
  { key: "19", value: "長野県" },
  { key: "20", value: "岐阜県" },
  { key: "21", value: "静岡県" },
  { key: "22", value: "愛知県" },
  { key: "23", value: "三重県" },
  { key: "24", value: "滋賀県" },
  { key: "25", value: "京都府" },
  { key: "26", value: "大阪府" },
  { key: "27", value: "兵庫県" },
  { key: "28", value: "奈良県" },
  { key: "29", value: "和歌山県" },
  { key: "30", value: "鳥取県" },
  { key: "31", value: "島根県" },
  { key: "32", value: "岡山県" },
  { key: "33", value: "広島県" },
  { key: "34", value: "山口県" },
  { key: "35", value: "徳島県" },
  { key: "36", value: "香川県" },
  { key: "37", value: "愛媛県" },
  { key: "38", value: "高知県" },
  { key: "39", value: "福岡県" },
  { key: "40", value: "佐賀県" },
  { key: "41", value: "長崎県" },
  { key: "42", value: "熊本県" },
  { key: "43", value: "大分県" },
  { key: "44", value: "宮崎県" },
  { key: "45", value: "鹿児島県" },
  { key: "46", value: "沖縄県" },
];
export const gradeData = [
  { key: "0", value: "1年" },
  { key: "1", value: "2年" },
  { key: "2", value: "3年" },
  { key: "3", value: "4年" },
  { key: "4", value: "修士1年" },
  { key: "5", value: "修士2年" },
  { key: "6", value: "その他" },
];
export const studentFacultyData = [
  { key: "0", value: "政治経済学部" },
  { key: "1", value: "法学部" },
  { key: "2", value: "教育学部" },
  { key: "3", value: "商学部" },
  { key: "4", value: "社会科学部" },
  { key: "5", value: "人間科学部" },
  { key: "6", value: "スポーツ科学部" },
  { key: "7", value: "国際教養学部" },
  { key: "8", value: "文化構想学部" },
  { key: "9", value: "文学部" },
  { key: "10", value: "人間科学部(通信教育課程）" },
  { key: "11", value: "基幹理工学部" },
  { key: "12", value: "創造理工学部" },
  { key: "13", value: "先進理工学部" },
  { key: "14", value: "大学院政治学研究科" },
  { key: "15", value: "大学院経済学研究科" },
  { key: "16", value: "大学院法学研究科" },
  { key: "17", value: "大学院文学研究科" },
  { key: "18", value: "大学院商学研究科" },
  { key: "19", value: "大学院教育学研究科" },
  { key: "20", value: "大学院人間科学研究科" },
  { key: "21", value: "大学院社会科学研究科" },
  { key: "22", value: "大学院アジア太平洋研究科" },
  { key: "23", value: "大学院日本語教育研究科" },
  { key: "24", value: "大学院情報生産システム研究科" },
  { key: "25", value: "大学院法務研究科" },
  { key: "26", value: "大学院会計研究科" },
  { key: "27", value: "大学院スポーツ科学研究科" },
  { key: "28", value: "大学院基幹理工学研究科" },
  { key: "29", value: "大学院創造理工学研究科" },
  { key: "30", value: "大学院先進理工学研究科" },
  { key: "31", value: "大学院環境・エネルギー研究科" },
  { key: "32", value: "大学院国際コミュニケーション研究科" },
  { key: "33", value: "大学院経営管理研究科" },
  { key: "34", value: "芸術学校" },
  { key: "35", value: "日本語教育研究センター" },
  { key: "36", value: "留学センター" },
];
export const studentDepartmentData = [
  { key: "0", value: "政治学科" },
  { key: "1", value: "経済学科" },
  { key: "2", value: "国際政治経済学科" },
  { key: "3", value: "教育学科" },
  { key: "4", value: "国語国文学科" },
  { key: "5", value: "英語英文学科" },
  { key: "6", value: "社会学科" },
  { key: "7", value: "理学科" },
  { key: "8", value: "数学科" },
  { key: "9", value: "複合文化学科" },
  { key: "10", value: "社会科学科" },
  { key: "11", value: "国際教養学科" },
  { key: "12", value: "文化構想学科" },
  { key: "13", value: "文学科" },
  { key: "14", value: "数学科" },
  { key: "15", value: "応用数理学科" },
  { key: "16", value: "機械科学・航空宇宙学科" },
  { key: "17", value: "電子物理システム学科" },
  { key: "18", value: "情報理工学科" },
  { key: "19", value: "情報通信学科" },
  { key: "20", value: "表現工学科" },
  { key: "21", value: "建築学科" },
  { key: "22", value: "総合機械工学科" },
  { key: "23", value: "経営システム工学科" },
  { key: "24", value: "社会環境工学科" },
  { key: "25", value: "環境資源工学科" },
  { key: "26", value: "物理学科" },
  { key: "27", value: "応用物理学科" },
  { key: "28", value: "化学・生命化学科" },
  { key: "29", value: "応用化学科" },
  { key: "30", value: "生命医科学科" },
  { key: "31", value: "電気・情報生命工学科" },
  { key: "32", value: "人間環境科学科" },
  { key: "33", value: "健康福祉科学科" },
  { key: "34", value: "人間情報科学科" },
  { key: "35", value: "スポーツ科学科" },
];
export const universityData = [{ key: "0", value: "早稲田大学" }];

export const sexData = [
  { key: "0", value: "男性" },
  { key: "1", value: "女性" },
];

export const experienceData = [
  { key: "0", value: "あり" },
  { key: "1", value: "なし" },
];

export const businessData = [
  { key: "0", value: "IT" },
  { key: "1", value: "金融" },
];

export const jobData = [
  { key: "0", value: "エンジニア" },
  { key: "1", value: "経営専任職員" },
];

export const standardData = [
  { key: "0", value: "成長できる" },
  { key: "1", value: "収入が高い" },
];

export const graduatedYearData = [
  { key: "0", value: "2024" },
  { key: "1", value: "2025" },
  { key: "2", value: "2026" },
  { key: "3", value: "2027" },
];
export const recruitGraduatedYearData = [
  { key: "0", value: "2024年卒" },
  { key: "1", value: "2025年卒" },
  { key: "2", value: "2026年卒" },
  { key: "3", value: "2027年卒" },
];

export const graduatedMonthData = [
  { key: "0", value: "1" },
  { key: "1", value: "2" },
  { key: "2", value: "3" },
  { key: "3", value: "4" },
  { key: "4", value: "5" },
  { key: "5", value: "6" },
  { key: "6", value: "7" },
  { key: "7", value: "8" },
  { key: "8", value: "9" },
  { key: "9", value: "10" },
  { key: "10", value: "11" },
  { key: "11", value: "12" },
];

export const humanitiesOrScienceData = [
  { key: "0", value: "文系" },
  { key: "1", value: "理系" },
];

export const offerTypeData = [
  { key: "0", value: "長期インターン" },
  { key: "1", value: "新卒採用" },
];
export const SalaryFormData = [
  { key: "0", value: "月給" },
  { key: "1", value: "年俸" },
  { key: "2", value: "時給" },
  { key: "3", value: "週給" },
  { key: "4", value: "日給" },
  { key: "5", value: "業務単位" },
  { key: "6", value: "その他" },
];
export const EmploymentStatusData = [
  { key: "0", value: "正社員" },
  { key: "1", value: "派遣社員" },
  { key: "2", value: "契約社員" },
  { key: "3", value: "パート社員" },
  { key: "4", value: "短時間正社員" },
  { key: "5", value: "業務委託" },
  { key: "6", value: "その他" },
];

export const recruitedNumberData = [
  { key: "0", value: "1人" },
  { key: "1", value: "2人" },
  { key: "2", value: "3人" },
  { key: "3", value: "4人" },
  { key: "4", value: "5人" },
  { key: "5", value: "6人" },
  { key: "6", value: "7人" },
  { key: "7", value: "8人" },
  { key: "8", value: "9人" },
  { key: "9", value: "10人" },
  { key: "10", value: "11人以上" },
  { key: "11", value: "常時募集" },
];
export const daysToRecruitData = [
  { key: "0", value: "1〜3日" },
  { key: "1", value: "3〜7日" },
  { key: "2", value: "1~2週間" },
  { key: "3", value: "2~4週間" },
  { key: "4", value: "4週間以上" },
];

export const jobCategoryData = [
  { key: "0", value: "営業" },
  { key: "1", value: "事務" },
  { key: "2", value: "企画" },
  { key: "3", value: "法務" },
  { key: "4", value: "記者" },
  { key: "5", value: "編集・制作" },
  { key: "6", value: "接客" },
  { key: "7", value: "マーケティング" },
  { key: "8", value: "広報" },
  { key: "9", value: "専任職員" },
  { key: "10", value: "ディーラー" },
  { key: "11", value: "証券アナリスト" },
  { key: "12", value: "ファンドマネジメント" },
  { key: "13", value: "トレーダー" },
  { key: "14", value: "プログラマー" },
  { key: "15", value: "webエンジニア" },
  { key: "16", value: "ネットワークエンジニア" },
  { key: "17", value: "セキュリティエンジニア" },
  { key: "18", value: "データサイエンティスト" },
  { key: "19", value: "セールスエンジニア" },
  { key: "20", value: "設計・開発" },
  { key: "21", value: "生産・製造" },
  { key: "22", value: "物流" },
  { key: "23", value: "講師" },
  { key: "24", value: "ゲームクリエイター" },
  { key: "25", value: "ゲームプログラマー" },
  { key: "26", value: "農業" },
  { key: "27", value: "林業" },
  { key: "28", value: "漁業" },
  { key: "29", value: "その他" },
];
