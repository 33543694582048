import { getDownloadURL, StorageReference, ref } from "firebase/storage";
import { storage } from "../main";

export async function getDownLoadUrlFromFullPath(
  fullPath: string
): Promise<string | null> {
  if (fullPath === "") {
    return null;
  } else {
    const imgRef: StorageReference = ref(storage, fullPath);
    return await getDownloadURL(imgRef);
  }
}
