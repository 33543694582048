import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useEffect, useState } from "react";

export interface ItemTypes {
  key: string;
  value: string;
}

type MultipleSelectFromListProps = {
  label: string;
  data: ItemTypes[];
  onChange: (selected: string[]) => void; // この関数は文字列の配列を返すように変更されます
  defaultValue?: string[];
};

const MultipleSelectFromList = ({
  label,
  data,
  onChange,
  defaultValue = [],
}: MultipleSelectFromListProps) => {
  const [selectedValue, setSelectedValue] = useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof selectedValue>) => {
    setSelectedValue(event.target.value as string[]);
  };

  function getKeysFromValues(targetValues: string[]): string[] {
    return data
      .filter((option) => targetValues.includes(option.value))
      .map((option) => option.key);
  }

  useEffect(() => {
    setSelectedValue(defaultValue);
  }, []);

  useEffect(() => {
    const keys = getKeysFromValues(selectedValue);
    onChange(keys);
  }, [selectedValue, setSelectedValue]);

  return (
    <FormControl fullWidth sx={{ height: "43px", mt: "7px" }}>
      <InputLabel sx={{ mt: "-5px" }}>{label}</InputLabel>
      <Select
        multiple
        value={selectedValue}
        onChange={handleChange}
        sx={{ height: "100%" }}
      >
        {data.map((option) => (
          <MenuItem key={option.key} value={option.value}>
            {option.value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MultipleSelectFromList;
