import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import {
  Alert,
  Avatar,
  Backdrop,
  Button,
  CircularProgress,
  Snackbar,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import serviceLogo from "assets/serviceLogo.png";
import styled from "styled-components";
import { useUser } from "hooks/firebase/context/auth";
import { submitCompanyInfo } from "hooks/firebase/context/companyInfo";
import SelectFromList from "components/SelectFromList";
import {
  listingDivisionData,
  picRoleData,
  prefectureData,
} from "data/CompanyUserData";
import MultipleSelectFromList from "components/MultipleSelectFromList";
import { jobCategoryData } from "data/StudentProfileData";
import CropModal from "components/CropModal";

const LogoImg = styled.img`
  width: 250px;
`;
const StyledButton = styled(Button)`
  color: white !important;
  background-color: #4d4d4d !important;
  font-size: 18px !important;
  font-weight: bold !important;
  padding: 10px 20px !important;
  &:hover {
    background-color: gray !important;
  }
`;
const TextareaStyle = styled(TextareaAutosize)`
  width: 100%;
  font-size: 16px;
  margin: 0 !important;
  padding: 10px;
  border-radius: 5px;
  margin-left: 10px;
  outline: none;
  border: 1px solid #c4c4c4;
  resize: none;
`;
const ImageWrapper = styled.img`
  object-fit: cover;
  max-width: 100%;
  max-height: 500px;
`;
const StyledHeadline = styled(Typography)`
  font-size: 1.8rem !important;
  font-weight: bold !important;
  @media (max-width: 600px) {
    font-size: 1.4rem !important;
  }
`;
const StyledSubHeadline = styled(Typography)`
  font-size: 1.1rem !important;
  @media (max-width: 600px) {
    font-size: 0.9rem !important;
  }
`;
export default function CompanyEditDetail() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [prefectureState, setPrefectureState] = useState("");
  const [listingDivisionState, setListingDivisionState] = useState("");
  const [picRoleState, setPicRoleState] = useState("");
  const [companyNameState, setCompanyNameState] = useState("");
  const [showAlert, setShowAlert] = React.useState(false); // アラートの表示状態
  const [picNameState, setPicNameState] = useState("");
  const user = useUser();

  const inputProfileRef = useRef<HTMLInputElement>(null!);
  const inputIconRef = useRef<HTMLInputElement>(null!);
  const inputPrImage1Ref = useRef<HTMLInputElement>(null!);
  const inputPrImage2Ref = useRef<HTMLInputElement>(null!);
  const [iconImage, setIconImage] = useState("");
  const [profileImage, setProfileImage] = useState<string | null>(null);
  const [prImage1, setPrImage1] = useState<string | null>(null);
  const [prImage2, setPrImage2] = useState<string | null>(null);
  const [jobCategory, setJobCategory] = useState<string[]>([""]);
  const [profileCropOpen, setProfileCropOpen] = useState(false);
  const [croppedProfileImage, setProfileCroppedImage] = useState("");
  const [croppedPrImage1, setPrImage1CroppedImage] = useState("");
  const [prImage1CropOpen, setPrImage1CropOpen] = useState(false);
  const [prImage2CropOpen, setPrImage2CropOpen] = useState(false);
  const [croppedPrImage2, setPrImage2CroppedImage] = useState("");
  const [iconCropOpen, setIconCropOpen] = useState(false);
  const [croppedIconImage, setIconCroppedImage] = useState("");

  React.useEffect(() => {
    if (user) {
      setCompanyNameState(user?.compPrivateData.name || "");
      setPicNameState(user?.compPrivateData.pic || "");
    }
  }, [user]);

  const onIconInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const fileObject = e.target.files[0];
    setIconImage(window.URL.createObjectURL(fileObject));
    setIconCropOpen(true);
  };
  const onIconButtonClick = () => {
    inputIconRef.current.click();
  };
  const onProfileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    setProfileImage(window.URL.createObjectURL(e.target.files[0]));
    setProfileCropOpen(true);
  };
  const onPrImage1InputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    setPrImage1(window.URL.createObjectURL(e.target.files[0]));
    setPrImage1CropOpen(true);
  };
  const onPrImage2InputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    setPrImage2(window.URL.createObjectURL(e.target.files[0]));
    setPrImage2CropOpen(true);
  };
  const onProfileButtonClick = () => {
    inputProfileRef.current.click();
  };
  const onPrImage1ButtonClick = () => {
    inputPrImage1Ref.current.click();
  };
  const onPrImage2ButtonClick = () => {
    inputPrImage2Ref.current.click();
  };
  const onChangeJobCategory = (values: string[]) => {
    setJobCategory(values);
  };
  async function convertUrlToBlob(imageUrl: string): Promise<Blob> {
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    return blob;
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    function formRef(key: string): string {
      if (data.get(key) === null) return "";
      else return data.get(key) as string;
    }
    if (
      formRef("companyName") === "" ||
      formRef("pic") === "" ||
      formRef("phone") === "" ||
      formRef("businessContent") === "" ||
      formRef("recruitCatchcopy") === "" ||
      formRef("address") === "" ||
      formRef("companyCulture") === "" ||
      iconImage === "" ||
      croppedProfileImage === "" ||
      prefectureState === "" ||
      listingDivisionState === "" ||
      picRoleState === "" ||
      jobCategory.length === 0
    ) {
      setShowAlert(true);
      return;
    }
    setIsLoading(true);
    // let coverBlob = new Blob([formRef("coverImg") as BlobPart], {
    //   type: "image/jpeg",
    // });
    let iconBlob = new Blob([formRef("iconImg") as BlobPart], {
      type: "image/jpeg",
    });
    let coverBlob = await convertUrlToBlob(croppedProfileImage);
    let prImg1 = await convertUrlToBlob(croppedPrImage1);
    let prImg2 = await convertUrlToBlob(croppedPrImage2);

    // let prImg1 = new Blob([formRef("prImg1") as BlobPart], {
    //   type: "image/jpeg",
    // });
    // let prImg2 = new Blob([formRef("prImg2") as BlobPart], {
    //   type: "image/jpeg",
    // });
    if (user) {
      let submitErrMsg: string = "";
      submitErrMsg = await submitCompanyInfo(
        formRef("companyName"),
        formRef("businessContent"),
        Number(listingDivisionState),
        formRef("pic"),
        formRef("phone"),
        user,
        iconBlob,
        coverBlob,
        Number(prefectureState),
        formRef("address"),
        formRef("recruitCatchcopy"),
        jobCategory.map((str: string) => parseFloat(str)),
        Number(picRoleState),
        formRef("companyCulture"),
        "",
        croppedPrImage1 ? prImg1 : undefined,
        croppedPrImage2 ? prImg2 : undefined
      );
      setErrMsg(submitErrMsg);
      const sleep = (second: number) =>
        new Promise((resolve) => setTimeout(resolve, second * 1000));
      if (submitErrMsg === "") {
        await sleep(1);
        navigate("/completecompanymainregister");
        setIsLoading(false);
        return;
      }
      setIsLoading(false);
    }
  };
  const handleClose = (event: any) => {
    setShowAlert(false);
  };
  return (
    <Box
      sx={{
        pt: 7,
        backgroundColor: "whitesmoke",
      }}
    >
      <Snackbar
        open={showAlert}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{
          width: "80%",
        }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          未入力の項目があります
        </Alert>
      </Snackbar>
      <Container component="main">
        <CssBaseline />
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Box sx={{ textAlign: "center" }}>
          <LogoImg src={serviceLogo} />
          <Typography
            mt={1}
            mb={2}
            sx={{ fontSize: "26px", fontWeight: "bold" }}
          >
            プロフィール情報編集
          </Typography>
        </Box>
        <Box mt={1} textAlign={"center"}>
          <Typography color="red">{errMsg}</Typography>
        </Box>
        <Box component="form" onSubmit={handleSubmit} sx={{ pb: 5 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "white",
              mt: 4,
              px: 4,
              py: 5,
              border: "1px solid black",
            }}
          >
            <Box sx={{ textAlign: "left", width: "100%" }}>
              <Typography mb={3} sx={{ fontSize: "16px", fontWeight: "bold" }}>
                <span style={{ color: "red" }}>＊</span>は必須項目です
              </Typography>
              <Box sx={{ mb: 3 }}>
                <StyledHeadline>
                  会社名<span style={{ color: "red" }}>*</span>
                </StyledHeadline>
                <StyledSubHeadline>
                  正式名称で入力をお願いします。
                </StyledSubHeadline>
                <TextField
                  inputProps={{ style: { padding: "10px" } }}
                  margin="normal"
                  required
                  fullWidth
                  name="companyName"
                  value={companyNameState}
                  onChange={(e) => setCompanyNameState(e.target.value)}
                />
              </Box>
              <Box sx={{ mb: 3 }}>
                <StyledHeadline>
                  申込者名（フルネーム）<span style={{ color: "red" }}>*</span>
                </StyledHeadline>
                <TextField
                  inputProps={{
                    style: { padding: "10px" },
                  }}
                  margin="normal"
                  required
                  fullWidth
                  name="pic"
                  value={picNameState}
                  onChange={(e) => setPicNameState(e.target.value)}
                />
              </Box>
              <Box sx={{ mb: 3 }}>
                <StyledHeadline>
                  申込者の電話番号<span style={{ color: "red" }}>*</span>
                </StyledHeadline>
                <StyledSubHeadline>
                  半角、ハイフンあり(例:070-5313-2216)
                </StyledSubHeadline>
                <TextField
                  inputProps={{ style: { padding: "10px" } }}
                  margin="normal"
                  required
                  fullWidth
                  name="phone"
                />
              </Box>
              <Box sx={{ mb: 3 }}>
                <StyledHeadline>
                  採用における申込者の役割
                  <span style={{ color: "red" }}>*</span>
                </StyledHeadline>
                <SelectFromList
                  label={"役割"}
                  data={picRoleData}
                  onChange={setPicRoleState}
                />
              </Box>
              <Box
                mt={5}
                mb={2}
                sx={{
                  width: "100%",
                }}
              >
                <StyledHeadline>PR画像(1)</StyledHeadline>
                <StyledSubHeadline>
                  オフィスの風景など、企業の雰囲気が伝わるイメージをアップロードしてください。
                </StyledSubHeadline>
                <Button
                  variant="outlined"
                  component="span"
                  onClick={onPrImage1ButtonClick}
                  sx={{
                    width: 110,
                    borderRadius: 1,
                    height: 35,
                    backgroundColor: "white",
                    border: "1px solid black",
                    color: "black",
                    mt: 2,
                  }}
                >
                  画像を選択
                </Button>
              </Box>

              <Box sx={{ width: "100%", marginBottom: 5, textAlign: "center" }}>
                {prImage1 && (
                  <CropModal
                    open={prImage1CropOpen}
                    onClose={() => setPrImage1CropOpen(false)}
                    setImage={(croppedUrl: string) => {
                      setPrImage1CroppedImage(croppedUrl);
                      setPrImage1CropOpen(false);
                    }}
                    imageSrc={prImage1}
                  />
                )}
                {croppedPrImage1 && (
                  <img
                    src={croppedPrImage1}
                    alt="Cropped Profile"
                    width="80%"
                    style={{ marginTop: "20px" }}
                  />
                )}
                <input
                  style={{ display: "none" }}
                  ref={inputPrImage1Ref}
                  name="prImg1"
                  type="file"
                  accept="image/*"
                  onChange={onPrImage1InputChange}
                />
              </Box>
              {/* <Box sx={{ width: "100%", marginBottom: 5, textAlign: "center" }}>
                {prImage1 && <ImageWrapper alt="prImg1" src={prImage1} />}
                <input
                  style={{ display: "none" }}
                  ref={inputPrImage1Ref}
                  name="prImg1"
                  type="file"
                  accept="image/*"
                  onChange={onPrImage1InputChange}
                />
              </Box> */}
              <Box
                mt={5}
                mb={2}
                sx={{
                  width: "100%",
                }}
              >
                <StyledHeadline>PR画像(2)</StyledHeadline>
                <StyledSubHeadline>
                  オフィスの風景など、企業の雰囲気が伝わるイメージをアップロードしてください。
                </StyledSubHeadline>
                <Button
                  variant="outlined"
                  component="span"
                  onClick={onPrImage2ButtonClick}
                  sx={{
                    width: 110,
                    borderRadius: 1,
                    height: 35,
                    backgroundColor: "white",
                    border: "1px solid black",
                    color: "black",
                    mt: 2,
                  }}
                >
                  画像を選択
                </Button>
              </Box>
              <Box sx={{ width: "100%", marginBottom: 5, textAlign: "center" }}>
                {prImage2 && (
                  <CropModal
                    open={prImage2CropOpen}
                    onClose={() => setPrImage2CropOpen(false)}
                    setImage={(croppedUrl: string) => {
                      setPrImage2CroppedImage(croppedUrl);
                      setPrImage2CropOpen(false);
                    }}
                    imageSrc={prImage2}
                  />
                )}
                {croppedPrImage2 && (
                  <img
                    src={croppedPrImage2}
                    alt="Cropped Profile"
                    width="80%"
                    style={{ marginTop: "20px" }}
                  />
                )}
                <input
                  style={{ display: "none" }}
                  ref={inputPrImage2Ref}
                  name="prImg2"
                  type="file"
                  accept="image/*"
                  onChange={onPrImage2InputChange}
                />
              </Box>
              <Box sx={{ mb: 3 }}>
                <StyledHeadline>
                  企業アピールポイント<span style={{ color: "red" }}>*</span>
                </StyledHeadline>
                <StyledSubHeadline>
                  事業概要を簡潔に伝えるための短い文章を入力してください。(学生に見出しとして表示されます)
                </StyledSubHeadline>
                <TextareaStyle
                  minRows={5}
                  name="recruitCatchcopy"
                  placeholder="例：&#13;&#10;コンサル業界に興味ある方におすすめ！&#13;&#10;製造業や印刷業回のコンサル経験ができます！"
                  required
                />
              </Box>
              <Box sx={{ mb: 3 }}>
                <StyledHeadline>
                  事業内容<span style={{ color: "red" }}>*</span>
                </StyledHeadline>
                <StyledSubHeadline>
                  事業の概要がわかるような説明を記載してください。
                </StyledSubHeadline>
                <TextareaStyle
                  minRows={5}
                  name="businessContent"
                  placeholder="例：&#13;&#10;当社は、製造業や印刷業界に特化したコンサルティングサービスを提供しています。当社の事業内容は、以下の通りです：&#13;&#10;プロセス最適化: 製造業のクライアントに対して、生産プロセスの効率化と品質向上のためのコンサルティングを行います。現行プロセスの分析と改善策の提案を通じて、生産ライ"
                  required
                />
              </Box>
              <Box sx={{ mb: 3 }}>
                <StyledHeadline>
                  本社所在地<span style={{ color: "red" }}>*</span>
                </StyledHeadline>
                <StyledSubHeadline>
                  都道府県を選択してください。
                </StyledSubHeadline>
                <SelectFromList
                  label={"都道府県"}
                  data={prefectureData}
                  onChange={setPrefectureState}
                />
                <StyledSubHeadline my={1}>
                  建物名まで記載してください。
                </StyledSubHeadline>
                <TextField
                  inputProps={{
                    style: { padding: "10px" },
                  }}
                  placeholder="例： 新宿区 ○ー○ー○ 学生生活ビル5階"
                  required
                  fullWidth
                  name="address"
                />
              </Box>
              <Box sx={{ mb: 3 }}>
                <StyledHeadline>
                  上場区分<span style={{ color: "red" }}>*</span>
                </StyledHeadline>
                <SelectFromList
                  label={"上場区分"}
                  data={listingDivisionData}
                  onChange={setListingDivisionState}
                />
              </Box>
              <Box sx={{ mb: 3 }}>
                <StyledHeadline>
                  職種カテゴリー<span style={{ color: "red" }}>*</span>
                </StyledHeadline>
                <StyledSubHeadline>複数選択可能</StyledSubHeadline>
                <MultipleSelectFromList
                  label={"職種カテゴリー"}
                  data={jobCategoryData}
                  onChange={onChangeJobCategory}
                />
              </Box>
              {/* <Box sx={{ mb: 3 }}>
                <StyledHeadline>企業ページURL</StyledHeadline>
                <TextField
                  inputProps={{
                    style: { padding: "10px" },
                  }}
                  margin="normal"
                  fullWidth
                  name="companyURL"
                />
              </Box> */}
              <Box sx={{ mb: 3 }}>
                <StyledHeadline>
                  社風・風土<span style={{ color: "red" }}>*</span>
                </StyledHeadline>
                <StyledSubHeadline>
                  会社の雰囲気が伝わるような説明を記載してください。
                </StyledSubHeadline>
                <TextareaStyle
                  minRows={5}
                  name="companyCulture"
                  placeholder="例：&#13;&#10;1. 革新と技術への情熱: 「私たちの会社は、製造と印刷業界の最前線で革新を追求しています。最新技術の採用や独自のソリューション開発に熱心で、業界の進化をリードすることに情熱を注いでいます。あなたがもし、最新技術への好奇心と、常に学び、成長し続ける意欲を持っているなら、当社はその才能を存分に発揮できる場所です。」&#13;&#10;
                  2. 協力とチームワーク: 「当社では、社員一人ひとりが重要な役割を担い、チームワークを大切にしています。互いに支え合い、知識を共有する文化が根付いており、新卒のあなたもすぐにこの協力的な環境の一員となれます。チームで成果を出す喜びを、私たちと共に感じてください。」"
                  required
                />
              </Box>
              <Box
                mt={1}
                mb={2}
                sx={{
                  width: "100%",
                }}
              >
                <StyledHeadline>
                  アイコン画像<span style={{ color: "red" }}>*</span>
                </StyledHeadline>
                <StyledSubHeadline>
                  会社ロゴなど、会社を象徴するイメージをアップロードしてください。
                </StyledSubHeadline>
                <Button
                  variant="outlined"
                  component="span"
                  onClick={onIconButtonClick}
                  sx={{
                    width: 110,
                    borderRadius: 1,
                    height: 35,
                    backgroundColor: "white",
                    border: "1px solid black",
                    color: "black",
                    mt: 2,
                  }}
                >
                  画像を選択
                </Button>
              </Box>
              <Box sx={{ width: "100%", marginBottom: 5, textAlign: "center" }}>
                {iconImage && (
                  <CropModal
                    open={iconCropOpen}
                    onClose={() => setIconCropOpen(false)}
                    setImage={(croppedUrl: string) => {
                      setIconCroppedImage(croppedUrl);
                      setIconCropOpen(false);
                    }}
                    imageSrc={iconImage}
                    aspect={1 / 1}
                  />
                )}
                {croppedIconImage && (
                  <Avatar
                    alt="iconImg"
                    src={croppedIconImage}
                    sx={{ width: 240, height: 240, margin: "0 auto" }}
                  />
                )}
                <input
                  style={{ display: "none" }}
                  ref={inputIconRef}
                  name="iconImg"
                  type="file"
                  accept="image/*"
                  onChange={onIconInputChange}
                />
              </Box>
              <Box
                mt={5}
                mb={2}
                sx={{
                  width: "100%",
                }}
              >
                <StyledHeadline>
                  カバー画像<span style={{ color: "red" }}>*</span>
                </StyledHeadline>
                <StyledSubHeadline>
                  オフィスの風景など、企業の雰囲気が伝わるイメージをアップロードしてください。
                </StyledSubHeadline>
                <Button
                  variant="outlined"
                  component="span"
                  onClick={onProfileButtonClick}
                  sx={{
                    width: 110,
                    borderRadius: 1,
                    height: 35,
                    backgroundColor: "white",
                    border: "1px solid black",
                    color: "black",
                    mt: 2,
                  }}
                >
                  画像を選択
                </Button>
              </Box>
              <Box sx={{ width: "100%", marginBottom: 5, textAlign: "center" }}>
                {profileImage && (
                  <CropModal
                    open={profileCropOpen}
                    onClose={() => setProfileCropOpen(false)}
                    setImage={(croppedUrl: string) => {
                      setProfileCroppedImage(croppedUrl);
                      setProfileCropOpen(false);
                    }}
                    imageSrc={profileImage}
                  />
                )}
                {croppedProfileImage && (
                  <img
                    src={croppedProfileImage}
                    alt="Cropped Profile"
                    width="80%"
                    style={{ marginTop: "20px" }}
                  />
                )}
                <input
                  style={{ display: "none" }}
                  ref={inputProfileRef}
                  name="coverImg"
                  type="file"
                  accept="image/*"
                  onChange={onProfileInputChange}
                />
              </Box>
            </Box>
          </Box>
          <Box sx={{ textAlign: "right", mt: 3 }}>
            <StyledButton type="submit">保存</StyledButton>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
