import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { CompanyHeader } from "components/CompanyHeader";
import styled from "styled-components";
import { CardMedia, Chip, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { studentInfoTypes } from "types/page/studentInfoTypes";
import {
  graduatedYearData,
  prefectureData,
  sexData,
} from "data/StudentProfileData";
import PreviewSnackbar from "components/PreviewSnackbar";
const mdTheme = createTheme();

const StyledSaveButton = styled.button`
  width: 150px;
  height: 40px;
  margin: 0 10px;
  font-size: 16px;
  border-radius: 20px;
  background-color: whitesmoke;
  color: #4f4f4f;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  border: 2px solid #4f4f4f;
  &:hover {
    background-color: white;
  }
`;
const StyledOfferButton = styled.button`
  width: 150px;
  height: 40px;
  margin: 0 10px;
  font-size: 16px;
  border-radius: 20px;
  background-color: #35b500;
  color: white;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  border: none;
  &:hover {
    background-color: #29d630;
  }
`;
const TableLeftText = styled(Typography)`
  font-size: 20px !important;
  color: dimgray !important;
  text-align: right !important;
  font-weight: bold !important;
`;
const TableRightText = styled(Typography)`
  font-size: 20px !important;
  font-weight: bold !important;
`;

export default function SampleCompanyStudentProfileDetail() {
  const navigate = useNavigate();
  const location = useLocation();

  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const [studentInfos, setStudentInfos] = useState<studentInfoTypes>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    setStudentInfos(location.state);
    window.scrollTo(0, 0);
  }, [location]);
  const mainStyles = {
    width: open ? "calc(100vw - 240px)" : "calc(100vw - 72px)",
    marginLeft: open ? "240px" : "72px",
    transition: ".3s",
    overflow: "auto",
  };
  const sendOffer = () => {
    navigate("/sample/company-offer-select-detail");
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <CompanyHeader
          open={open}
          toggleDrawer={toggleDrawer}
          showofferModalProps={true}
        />
        <PreviewSnackbar open={open} />
        <Box
          component="main"
          sx={{
            ...mainStyles,
          }}
        >
          <Box
            sx={{
              mt: 8,
              px: 5,
              backgroundColor: "#C6D6E2",
            }}
          >
            <Box
              sx={{
                display: "flex",
              }}
            >
              <CardMedia
                component="img"
                sx={{ width: 200, height: 200, borderRadius: 100, mt: 5 }}
                image={studentInfos?.iconPhtUrl}
                alt="Live from space album cover"
              />
              <Box sx={{ ml: 10, mt: 5 }}>
                {studentInfos?.lastName ? (
                  <Box sx={{ my: 1 }}>
                    <Typography sx={{ fontSize: 38, fontWeight: "bold" }}>
                      {studentInfos?.lastName + " " + studentInfos?.firstName}
                    </Typography>
                  </Box>
                ) : (
                  <Box />
                )}

                <Box sx={{ my: 1 }}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography sx={{ fontSize: 26, fontWeight: "bold" }}>
                      早稲田大学
                    </Typography>

                    <Typography
                      sx={{ fontSize: 20, ml: 3, fontWeight: "bold" }}
                    >
                      {studentInfos?.graduatedYear
                        ? graduatedYearData[studentInfos?.graduatedYear - 1]
                            .value
                        : ""}
                      年 3月 (卒業見込み)
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                    <Chip
                      label={
                        studentInfos?.humanitiesOrScience === 0
                          ? "理系"
                          : "文系"
                      }
                      color={
                        studentInfos?.humanitiesOrScience === 0
                          ? "primary"
                          : "error"
                      }
                      size="medium"
                    />
                    <Typography
                      sx={{ ml: 2, fontSize: 16, fontWeight: "bold" }}
                    >
                      商学部 商学科
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box sx={{ textAlign: "right", pr: 5 }}>
              <StyledSaveButton>保存する</StyledSaveButton>
              <StyledOfferButton onClick={sendOffer}>
                オファーする
              </StyledOfferButton>
            </Box>

            <Box sx={{ display: "flex" }}>
              <Button>
                <Typography
                  sx={{
                    fontSize: 22,
                    mx: 1,
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  プロフィール
                </Typography>
              </Button>
              <Button
                onClick={() => {
                  navigate("/sample/studentprofiledesiredoccupation", {
                    state: studentInfos,
                  });
                }}
              >
                <Typography
                  sx={{
                    fontSize: 22,
                    mx: 1,
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  就活における価値観
                </Typography>
              </Button>
            </Box>

            <Box sx={{ display: "flex" }}>
              <Box
                sx={{ width: "160px", height: "5px", backgroundColor: "black" }}
              />
            </Box>
          </Box>

          {/* <Modal open={isModalOpen} onClose={handleClose}>
            <ModalBox sx={{ width: { xs: "80%", sm: "65%" } }}>
              <Typography fontSize={26} fontWeight={"bold"}>
                オファーする求人内容を選択してください
              </Typography>
              {offerTemplateState?.map(
                (offerTemplate: offerType, i: number) => {
                  return (
                    <OfferTemplateCard
                      key={i}
                      offerTemplate={offerTemplate}
                      selectedTemplateId={selectedTemplateId}
                      setSelectedTemplateId={setSelectedTemplateId}
                    />
                  );
                }
              )}
              <StyledOfferButton
                onClick={completeOffer}
                style={{ marginTop: "50px" }}
              >
                決定
              </StyledOfferButton>
            </ModalBox>
          </Modal> */}
          <Box sx={{ mx: 5, my: 5 }}>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <TableLeftText>性別</TableLeftText>
              </Grid>
              <Grid item xs={9}>
                <TableRightText>
                  {studentInfos?.sex !== undefined
                    ? sexData[studentInfos.sex].value
                    : ""}
                </TableRightText>
              </Grid>
              <Grid item xs={3}>
                <TableLeftText>住所</TableLeftText>
              </Grid>
              <Grid item xs={9}>
                <TableRightText>
                  {studentInfos?.livingArea !== undefined
                    ? prefectureData[studentInfos.livingArea].value
                    : ""}
                </TableRightText>
              </Grid>
              <Grid item xs={3}>
                <TableLeftText>卒業高校</TableLeftText>
              </Grid>
              <Grid item xs={9}>
                <TableRightText>
                  {studentInfos?.graduatedHighSchool}
                </TableRightText>
              </Grid>
              <Grid item xs={3}>
                <TableLeftText>資格</TableLeftText>
              </Grid>
              <Grid item xs={9}>
                <TableRightText>{studentInfos?.certification}</TableRightText>
              </Grid>
              <Grid item xs={3}>
                <TableLeftText>プログラミング経験</TableLeftText>
              </Grid>
              <Grid item xs={9}>
                <TableRightText>
                  {studentInfos?.programmingExperience === 1 ? "あり" : "なし"}
                </TableRightText>
              </Grid>
              {studentInfos?.programmingExperience === 1 ? (
                <Grid item xs={3}>
                  <TableLeftText>プログラミング経験内容</TableLeftText>
                </Grid>
              ) : (
                <Box></Box>
              )}
              {studentInfos?.programmingExperience === 1 && (
                <Grid item xs={9}>
                  {studentInfos?.programmingExperienceDetail
                    .split("\n")
                    .map((line, index) => (
                      <TableRightText key={index}>
                        {line}
                        <br />
                      </TableRightText>
                    ))}
                </Grid>
              )}
              {studentInfos?.internCompName && (
                <Grid item xs={3}>
                  <TableLeftText>インターン経験</TableLeftText>
                </Grid>
              )}
              {studentInfos?.internCompName && (
                <Grid item xs={9}>
                  <TableRightText>
                    会社：{studentInfos?.internCompName}
                  </TableRightText>
                  <TableRightText>
                    期間：{studentInfos?.internPeriod}
                  </TableRightText>
                  <TableRightText>職種：コンサルタント</TableRightText>
                </Grid>
              )}
              <Grid item xs={3}>
                <TableLeftText>サークル</TableLeftText>
              </Grid>
              <Grid item xs={9}>
                <TableRightText>{studentInfos?.circle}</TableRightText>
              </Grid>
              <Grid item xs={3}>
                <TableLeftText>ゼミ・研究室</TableLeftText>
              </Grid>
              {studentInfos?.researchGroup !== "" && (
                <Grid item xs={9}>
                  <TableRightText>{studentInfos?.researchGroup}</TableRightText>
                </Grid>
              )}
              <Grid item xs={3}>
                <TableLeftText>自己PR</TableLeftText>
              </Grid>
              <Grid item xs={9}>
                <TableRightText>{studentInfos?.selfPRMain}</TableRightText>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
