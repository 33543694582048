import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import Container from "@mui/material/Container";
import { Button } from "@mui/material";
import { sendEmailVerificationWrap } from "hooks/firebase/context/auth";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function ConfirmEmail() {
  const navigate = useNavigate();
  const [errMsg, setErrMsg] = useState("");
  const resendEmail = async () => {
    const errResendMsg: string = await sendEmailVerificationWrap(); //errMsgはデフォルトが"", エラーが発生するとそのエラーメッセージを含みます。
    setErrMsg(errResendMsg);
  };
  return (
    <Box
      sx={{
        height: "100vh",
        pt: 7,
      }}
    >
      <Container component="main">
        <CssBaseline />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "rgba(255, 255, 255, 0.2)",
            pt: 5,
            pb: 8,
          }}
        >
          <Typography component="h1" variant="h4" fontWeight={"bold"}>
            CAMPUS REACH
          </Typography>
          <Typography fontWeight={"bold"}>パスワード再設定</Typography>
          <Box height={20} mt={4}>
            <Typography color="red">{errMsg}</Typography>
          </Box>
          <Box sx={{ mt: 5, mx: 3, textAlign: "center" }}>
            <Typography
              sx={{ fontSize: { xs: "24px", sm: "28px" }, fontWeight: "bold" }}
            >
              パスワード再設定用のリンクを送信いたしました
            </Typography>
            <Typography
              mt={3}
              mb={6}
              sx={{ fontSize: { xs: "16px", sm: "18px" } }}
            >
              記載されているURLをクリックして、パスワードを再設定してください
            </Typography>
            <Button
              disableElevation
              variant="contained"
              onClick={() => {
                navigate("/companylogin");
              }}
              sx={{
                margin: "10px auto",
                background: "#2a2a2a",
                width: "200px",
                fontWeight: "bold",
                fontSize: "1.1rem",
                "&:hover": {
                  backgroundColor: "dimgray",
                },
              }}
            >
              ログイン画面へ
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
