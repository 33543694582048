import React, { useEffect, useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import styled from "styled-components";
import { MyCompanyOfferType } from "types/page/myCompanyOfferType";
import { graduatedYearData } from "data/StudentProfileData";
import { getDownLoadUrlFromFullPath } from "hooks/firebase/context/storage";
import { oemUniversityData } from "context/OEMinfo";
import { searchStudentByIconPhtUrl } from "hooks/firebase/context/offer";
import { useNavigate } from "react-router-dom";
import { studentInfoTypes } from "types/page/studentInfoTypes";

const StyledRecruitButton = styled(Box)`
  display: flex;
  text-align: left;
  width: 80%;
  padding: 15px !important;
  border: 1px solid gray !important;
  margin: 10px !important;
`;

const StudentImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50px;
`;

interface RecruitButtonProps {
  myOffer: MyCompanyOfferType;
}

const ApplicateRecruitBox: React.FC<RecruitButtonProps> = ({ myOffer }) => {
  const navigate = useNavigate();
  const [iconValue, setIconValue] = useState("");

  useEffect(() => {
    const fetchIconUrl = async () => {
      const url = await getDownLoadUrlFromFullPath(myOffer.student.iconPhtUrl);
      if (url) setIconValue(url);
    };

    fetchIconUrl();
  }, [myOffer.student.iconPhtUrl]);

  const fetchStudentInfo = async () => {
    const student = await searchStudentByIconPhtUrl(myOffer.student.iconPhtUrl);
    const StudentProfileInfos = student[0] as studentInfoTypes;
    if (student) {
      navigate("/companyapplicatestudentprofiledetail", {
        state: {
          iconPhtUrl: iconValue,
          senderId: StudentProfileInfos.senderId,
          grade: StudentProfileInfos.grade,
          university: StudentProfileInfos.university,
          faculty: StudentProfileInfos.faculty,
          department: StudentProfileInfos.department,
          firstName: StudentProfileInfos.firstName,
          lastName: StudentProfileInfos.lastName,
          firstNameKana: StudentProfileInfos.firstNameKana,
          lastNameKana: StudentProfileInfos.lastNameKana,
          sex: StudentProfileInfos.sex,
          livingArea: StudentProfileInfos.livingArea,
          birthPlace: StudentProfileInfos.birthPlace,
          graduatedHighSchool: StudentProfileInfos.graduatedHighSchool,
          certification: StudentProfileInfos.certification,
          programmingExperience: StudentProfileInfos.programmingExperience,
          programmingExperienceDetail:
            StudentProfileInfos.programmingExperienceDetail,
          circle: StudentProfileInfos.circle,
          researchGroup: StudentProfileInfos.researchGroup,
          desiredFirstBusiness: StudentProfileInfos.desiredFirstBusiness,
          desiredSecondBusiness: StudentProfileInfos.desiredSecondBusiness,
          desiredThirdBusiness: StudentProfileInfos.desiredThirdBusiness,
          desiredFirstJob: StudentProfileInfos.desiredFirstJob,
          desiredSecondJob: StudentProfileInfos.desiredSecondJob,
          desiredThirdJob: StudentProfileInfos.desiredThirdJob,
          choiceFirstStandard: StudentProfileInfos.choiceFirstStandard,
          choiceSecondStandard: StudentProfileInfos.choiceSecondStandard,
          choiceThirdStandard: StudentProfileInfos.choiceThirdStandard,
          graduatedYear: StudentProfileInfos.graduatedYear,
          graduatedMonth: StudentProfileInfos.graduatedMonth,
          humanitiesOrScience: StudentProfileInfos.humanitiesOrScience,
          internCompName: StudentProfileInfos.internCompName,
          internJobType: StudentProfileInfos.internJobType,
          internPeriod: StudentProfileInfos.internPeriod,
          selfPRMain: StudentProfileInfos.selfPRMain,
          updatedAt: StudentProfileInfos.updatedAt,
          recruitId: myOffer.id,
        },
      });
    }
  };
  return (
    <StyledRecruitButton
      sx={{
        borderRadius: 5,
        mt: 3,
      }}
    >
      <Box
        sx={{
          width: "50%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box sx={{ width: "100px", textAlign: "center" }}>
          <StudentImg src={iconValue} />
        </Box>
        <Box pl={2}>
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: 20,
              maxWidth: 100,
              overflow: "hidden",
            }}
          >
            {myOffer.student.lastName + " " + myOffer.student.firstName}
          </Typography>
          <Typography>
            {myOffer.student.university &&
              oemUniversityData[myOffer.student.university - 1].value}
          </Typography>
          <Button
            onClick={fetchStudentInfo}
            variant="contained"
            color="primary"
            size="small"
            sx={{
              backgroundColor: "black",
              height: 30,
              width: 100,
              mt: 1,
              "&:hover": { backgroundColor: "black" },
            }}
          >
            学生詳細
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          width: "3px",
          height: "50px",
          backgroundColor: myOffer.offerType === 1 ? "red" : "blue",
          mr: 2,
        }}
      />
      <Box
        sx={{
          width: "50%",
          textAlign: "left",
          color: "black",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box>
            <Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
              {graduatedYearData[myOffer.recruitGraduatedYear].value}
              年卒向け
            </Typography>
            {myOffer.offerType === 1 ? (
              <Typography sx={{ fontWeight: "bold" }}>＜新卒採用＞</Typography>
            ) : (
              <Typography sx={{ fontWeight: "bold" }}>
                ＜長期インターンシップ＞
              </Typography>
            )}
          </Box>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() =>
              navigate("/recruit-detail-content", {
                state: {
                  trialPeriod: myOffer.trialPeriod,
                  access: myOffer.access,
                  appealPoint: myOffer.appealPoint,
                  daysToRecruit: myOffer.daysToRecruit,
                  employmentStatus: myOffer.employmentStatus,
                  jobDetail: myOffer.jobDetail,
                  jobName: myOffer.jobName,
                  maxSalary: myOffer.maxSalary,
                  minSalary: myOffer.minSalary,
                  neededPersonal: myOffer.neededPersonal,
                  offerType: myOffer.offerType,
                  others: myOffer.others,
                  recruitCatchCopy: "",
                  recruitGraduatedYear: myOffer.recruitGraduatedYear,
                  recruitedNumber: myOffer.recruitedNumber,
                  salaryDetail: myOffer.salaryDetail,
                  salaryForm: myOffer.salaryForm,
                  treatment: myOffer.treatment,
                  workDay: myOffer.workDay,
                  workPlace: myOffer.workPlace,
                  id: myOffer.id,
                },
              })
            }
            sx={{
              backgroundColor: "black",
              mt: 1,
              height: 30,
              width: 100,
              "&:hover": { backgroundColor: "black" },
            }}
          >
            求人詳細
          </Button>
        </Box>
        <Typography sx={{ fontSize: 14, marginTop: 1 }}>
          {myOffer.appealPoint}
        </Typography>
      </Box>
    </StyledRecruitButton>
  );
};

export default ApplicateRecruitBox;
