import {
  createContext,
  ReactNode,
  useEffect,
  useState,
  useContext,
} from "react";
import {
  user_public,
  user_private,
  UserWrap,
  user_company_private,
  user_company_public,
  club_user_public,
  club_user_private,
  student_user_private,
  student_user_public,
} from "types/firebase/user";
import {
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  deleteUser,
  EmailAuthProvider,
  reauthenticateWithCredential,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  updateEmail,
  User,
  signOut,
} from "@firebase/auth";
import {
  query,
  getDoc,
  getDocs,
  writeBatch,
  serverTimestamp,
  doc,
  collection,
  where,
} from "@firebase/firestore";
import { auth, db, functions } from "../main";
import { httpsCallable } from "firebase/functions";

// コンテクスト用の型を定義
type UserContextType = UserWrap | null | undefined;

// コンテクストを作成
const AuthContext = createContext<UserContextType>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  // 配布したいデータの定義
  const [userWrap, setUserWrap] = useState<UserContextType>();

  useEffect(() => {
    // ログイン状態を監視し、変化があったら発動
    onAuthStateChanged(auth, async (firebaseUser) => {
      // console.log("hello");
      if (firebaseUser && firebaseUser.emailVerified) {
        // ユーザがログインしていてかつ認証ずみの場合はUserWrapクラスにデータを代入
        const ref2Private = doc(db, `clubUsersPrivate/${firebaseUser.uid}`);
        const ref2Public = doc(db, `clubUsersPublic/${firebaseUser.uid}`);
        const ref2CompPrivate = doc(db, `compUsersPrivate/${firebaseUser.uid}`);
        const ref2CompPublic = doc(db, `compUsersPublic/${firebaseUser.uid}`);
        const auth: User = firebaseUser;
        const privateData: user_private = (
          await getDoc(ref2Private).catch((err) => {
            console.log(`context error: ${err.code}`);
          })
        )?.data() as user_private;
        const publicData: user_public = (
          await getDoc(ref2Public).catch((err) => {
            console.log(`context error: ${err.code}`);
          })
        )?.data() as user_public;
        const compPublicData: user_company_public = (
          await getDoc(ref2CompPublic).catch((err) => {
            console.log(`context error: ${err.code}`);
          })
        )?.data() as user_company_public;
        const compPrivateData: user_company_private = (
          await getDoc(ref2CompPrivate).catch((err) => {
            console.log(`context error: ${err.code}`);
          })
        )?.data() as user_company_private;
        setUserWrap(
          new UserWrap(
            auth,
            privateData,
            publicData,
            compPrivateData,
            compPublicData
          )
        );
      } else {
        // ユーザがログインしていないか認証ずみでない場合nullを返してloginへ遷移
        setUserWrap(null);
      }
    });
  }, []);
  // プロバイダーを作成し、配布物を格納する
  return (
    <AuthContext.Provider value={userWrap}>{children}</AuthContext.Provider>
  );
};

export const useUser = () => useContext(AuthContext);
// 学生アカウントが存在するか
export async function hasStudentUser(uid: string) {
  const studentRef = doc(db, "studentUsersPublic", uid);
  const studentSnapshot = await getDoc(studentRef);
  if (studentSnapshot.exists()) {
    return true;
  } else return false;
}

export async function createUser(
  name: string,
  owner: string,
  address: string,
  password: string
) {
  let errMsg: string = "";

  try {
    // 既存ユーザーの確認
    await signInWithEmailAndPassword(auth, address, password);
    const user = auth.currentUser;

    if (user && user.emailVerified) {
      // 既存のユーザーが見つかり、メールが認証されている場合
      const studentRef = doc(db, "studentUsersPublic", user.uid);
      const studentSnapshot = await getDoc(studentRef);

      const clubRef = doc(db, "clubUsersPublic", user.uid);
      const clubSnapshot = await getDoc(clubRef);
      if (clubSnapshot.exists()) {
        errMsg =
          "既にアカウントが作成されています。アプリからログインしてください。";
        return errMsg;
      }

      if (studentSnapshot.exists()) {
        // 学生ユーザーが存在する場合、クラブユーザーを作成
        errMsg = await createClubUserFromStudentUser(
          name,
          owner,
          address,
          password
        );
      } else {
        // 学生ユーザーが存在しない場合、エラーメッセージを設定
        errMsg = "学生ユーザーが見つかりません。";
      }
    } else {
      // メールが未認証、またはユーザーが見つからない場合
      errMsg =
        "指定されたメールアドレスに対応する学生ユーザーが見つかりません。";
      return errMsg;
    }
  } catch (error) {
    // signInWithEmailAndPassword でエラーが発生した場合、新規ユーザー作成を試みる
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        address,
        password
      );
      const newUser = userCredential.user;

      // 新規ユーザーのデータセットアップ
      // ここに studentUsersPublic にデータを登録する処理を追加
      const private_data: user_private = {
        name: name,
        owner: owner,
        desc: "",
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        address: address,
        coverPhtUrl: "",
        iconPhtUrl: "",
        activity: "",
        place: "",
        date: "",
        people: "",
        twitter: "",
        insta: "",
        lastStatement: "",
        largeGenre: 100,
        smallGenre: 100,
        feature: [],
      };
      const public_data: user_public = {
        name: name,
        desc: "",
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        coverPhtUrl: "",
        iconPhtUrl: "",
        activity: "",
        place: "",
        date: "",
        people: "",
        twitter: "",
        insta: "",
        lastStatement: "",
        largeGenre: 100,
        smallGenre: 100,
        feature: [],
      };
      const batch = writeBatch(db);
      const ref_to_public = doc(db, `clubUsersPublic`, newUser.uid);
      const ref_to_private = doc(db, `clubUsersPrivate`, newUser.uid);
      batch.set(ref_to_public, public_data);
      batch.set(ref_to_private, private_data);

      await batch.commit().catch((err) => {
        console.log(err.code);
        errMsg = "ユーザーデータの保存に失敗しました。";
        deleteUser(newUser);
      });
      if (errMsg === "ユーザーデータの保存に失敗しました。") {
        return errMsg;
      }

      // 認証メールの送信
      await sendEmailVerification(newUser);
    } catch (signupError) {
      // createUserWithEmailAndPassword でエラーが発生した場合
      errMsg = "新規ユーザー作成中にエラーが発生しました: " + signupError;
    }
  }

  return errMsg;
}

// 学生アカウントが存在する場合にクラブユーザーを作成する関数(最初にログインして、成功したら生徒ユーザーのみ存在するか確認)
export async function createClubUserFromStudentUser(
  name: string,
  owner: string,
  address: string,
  password: string
) {
  let errMsg = "";
  try {
    await signInWithEmailAndPassword(auth, address, password);
    if (!auth.currentUser) {
      throw new Error("ログインに失敗しました。");
    }
    if (!auth.currentUser.emailVerified) {
      throw new Error(
        "お使いのアカウントは認証されていません。認証メールをご確認ください"
      );
    }

    const user: User = auth.currentUser!;
    const uid = user.uid;
    // 学生アカウントとサークルアカウントの存在確認
    //学生アカウントが存在するか確認
    const studentRef = doc(db, "studentUsersPublic", uid);
    const studentSnapshot = await getDoc(studentRef);
    //サークルアカウントが存在するか確認
    const clubRef = doc(db, "clubUsersPublic", uid);
    const clubSnapshot = await getDoc(clubRef);
    if (studentSnapshot.exists()) {
      if (clubSnapshot.exists()) {
        errMsg = "すでにサークルユーザーが存在します。";
      } else {
        const private_data: club_user_private = {
          name: name,
          owner: owner,
          desc: "",
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
          address: user.email!,
          coverPhtUrl: "",
          iconPhtUrl: "",
          activity: "",
          place: "",
          date: "",
          people: "",
          twitter: "",
          insta: "",
          lastStatement: "",
          largeGenre: 100,
          smallGenre: 100,
          feature: [],
        };
        const public_data: club_user_public = {
          name: name,
          desc: "",
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
          coverPhtUrl: "",
          iconPhtUrl: "",
          activity: "",
          place: "",
          date: "",
          people: "",
          twitter: "",
          insta: "",
          lastStatement: "",
          largeGenre: 100,
          smallGenre: 100,
          feature: [],
        };
        const batch = writeBatch(db);
        const ref_to_public = doc(db, `clubUsersPublic`, uid);
        const ref_to_private = doc(db, `clubUsersPrivate`, uid);
        batch.set(ref_to_public, public_data);
        batch.set(ref_to_private, private_data);
        await batch.commit().catch((err) => {
          console.log(err.code);
          errMsg = "ユーザーデータの保存に失敗しました。";
        });
      }
    } else {
      errMsg = "学生ユーザーが存在しません";
    }
  } catch (error) {
    errMsg = error as string;
  }
  return errMsg;
}
export async function login(
  address: string,
  password: string
): Promise<string> {
  let errMsg: string = "";
  await signInWithEmailAndPassword(auth, address, password)
    .then(() => {
      if (auth.currentUser) {
        if (auth.currentUser.emailVerified) {
        } else {
          errMsg =
            "お使いのアカウントは認証されていません。認証メールをご確認ください";
        }
      }
    })
    .catch(() => {
      errMsg = "メールアドレスもしくはパスワードが間違っています";
    });
  return errMsg;
}

// 新規ユーザーの作成
export async function createStudentUser(
  name: string,
  grade: number,
  faculty: number,
  address: string,
  password: string,
  nickname: string,
  iconPhtBlob?: Blob
) {
  let errMsg = "";
  // wasedaメールか確認
  const reg = new RegExp(
    /^[a-zA-Z0-9_.+-]+@(lightroads.net|((akane|asagi|fuji|moegi|ruri|suou|toki)\.)+waseda\.jp)$/
  );
  if (!reg.test(address)) {
    errMsg = "Wasedaメールを入力してください";
    return errMsg;
  }
  await signInWithEmailAndPassword(auth, address, password)
    .then(async () => {
      if (auth.currentUser) {
        if (auth.currentUser.emailVerified) {
          // ユーザーアカウントを作成する
          // 成功時にユーザーのカスタムクレームにstudentロールを割り当てる
          functions.region = "asia-northeast1";
          const setClaim = httpsCallable(functions, "setTypeClaim");
          await setClaim({ type: "student" });

          // 現在のユーザ（今作成したユーザ）を取得
          const user: User = auth.currentUser!;
          let iconPhtUrl = "";

          // if (iconPhtBlob) {
          //   // storageのiconへのpathを作成してそれをstringに変換（firestoreで格納できるようにするため）
          //   var iconPhtRef: StorageReference = ref(
          //     storage,
          //     `studentUsers/${user.uid}/icon`
          //   );
          //   iconPhtUrl = iconPhtRef.fullPath;
          // }

          // clubUsersPrivateに格納するデータ
          const privateData: student_user_private = {
            name: name,
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp(),
            address: address,
            grade: grade,
            faculty: faculty,
            nickname: nickname,
            iconPhtUrl: iconPhtUrl,
          };

          // studentUsersPublicに格納するデータ
          const publicData: student_user_public = {
            name: name,
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp(),
            grade: grade,
            faculty: faculty,
            nickname: nickname,
            iconPhtUrl: iconPhtUrl,
          };

          // batchの設定（同時に書き込みを行う機能です。いずれかの書き込みに失敗した場合に別の書き込みもしないようにできます））
          const batch = writeBatch(db);
          // studentUsersPublicへのreference
          const ref_to_public = doc(db, `studentUsersPublic`, user.uid);
          // studentUsersPrivateへのreference
          const ref_to_private = doc(db, `studentUsersPrivate`, user.uid);
          // ユーザーの時間割データへのreference
          const ref2TimeTable = doc(db, "userTimeTables", user.uid);
          // ユーザーの時間割データへのreference（時間割データのドキュメントの中に具体的な時間割を格納するドキュメントがあります。）
          const ref2TimeTableSpecific = doc(
            db,
            "userTimeTables",
            user.uid,
            "timeTables",
            user.uid
          );
          // 各referenceに対してbatchへset
          batch.set(ref_to_public, publicData);
          batch.set(ref_to_private, privateData);
          batch.set(ref2TimeTable, {
            tableOrder: [user.uid],
            tableImgRef: null,
          });
          batch.set(ref2TimeTableSpecific, {
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp(),
            isFull: {},
          });

          // batchの実行
          await batch
            .commit()
            .then(async () => {
              // const metadata = {
              //   contentType: "image/jpeg",
              // };
              // if (iconPhtBlob !== undefined) {
              //   await uploadBytes(iconPhtRef, iconPhtBlob, metadata);
              // }
            })
            .catch((err) => {
              errMsg = "ユーザーデータの保存に失敗しました。";
              deleteUser(user);
            });
          if (errMsg === "ユーザーデータの保存に失敗しました。") {
            return errMsg;
          }
          //
        } else {
          errMsg =
            "お使いのアカウントは認証されていません。認証メールをご確認ください";
        }
      }
    })
    .catch(() => {
      errMsg = "メールアドレスもしくはパスワードが間違っています";
    });
  return errMsg;
}

export async function sendEmailVerificationWrap(): Promise<string> {
  let errMsg = "";
  await sendEmailVerification(auth.currentUser!).catch((err) => {
    if (err.code === "auth/too-many-requests")
      errMsg =
        "前回の送信から時間がたっていません。しばらく待ってからお試しください。";
    else errMsg = "予期せぬエラーが発生しました。もう一度お試しください。";
  });
  return errMsg;
}

export async function resetPassword(mail: string) {
  return sendPasswordResetEmail(auth, mail);
}

export async function reauthenticate(
  user: User,
  email: string,
  password: string
) {
  const credential = EmailAuthProvider.credential(email, password);
  return reauthenticateWithCredential(user, credential);
}

export async function updateEmailAdress(user: User, email: string) {
  return updateEmail(user, email);
}

//会社ユーザーの作成（ライトローズがサイトから行う→企業が直接登録するように変更）
export async function createCompUser(
  name: string,
  pic: string,
  address: string,
  password: string,
  type: string,
  monthLimitOffer: number
) {
  let errMsg: string = "";
  await createUserWithEmailAndPassword(auth, address, password)
    .then(async () => {
      const user: User = await auth.currentUser!;
      const private_data: user_company_private = {
        email: address,
        name: name,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        businessContent: "",
        addressDetail: "",
        listingDivision: 0,
        companyURL: "",
        pic: pic, //担当者
        phone: "",
        picRole: 0, //担当者の役割(任意)
        iconPhtUrl: "",
        coverPhtUrl: "",
        prefecture: 0,
        recruitCatchcopy: "",
        jobCategory: [],
        PRPhtUrl1: "",
        PRPhtUrl2: "",
        companyCulture: "",
        monthLimitOffer: monthLimitOffer,
        monthlyOffer: 0,
      };
      const public_data: user_company_public = {
        name: name,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        businessContent: "",
        prefecture: 0,
        addressDetail: "",
        listingDivision: 0,
        companyURL: "",
        iconPhtUrl: "",
        coverPhtUrl: "",
        recruitCatchcopy: "",
        jobCategory: [],
        PRPhtUrl1: "",
        PRPhtUrl2: "",
        companyCulture: "",
      };
      const batch = writeBatch(db);
      const ref_to_public = doc(db, `${type}UsersPublic`, user.uid);
      const ref_to_private = doc(db, `${type}UsersPrivate`, user.uid);
      batch.set(ref_to_public, public_data);
      batch.set(ref_to_private, private_data);
      await batch.commit().catch((err) => {
        console.log(err.code);
        errMsg = "ユーザーデータの保存に失敗しました。";
        deleteUser(user);
      });
      if (errMsg === "ユーザーデータの保存に失敗しました。") {
        return errMsg;
      }

      // 認証メールの送信
      await sendEmailVerification(auth.currentUser!)
        .then(() => {
          return "";
        })
        .catch(() => {
          errMsg = "認証メールが送信できませんでした。";
        });
      await signOut(auth);
    })
    .catch((err) => {
      if (err.code === "auth/weak-password")
        errMsg = "パスワードは6文字以上である必要があります。";
      else errMsg = "このアカウントは既に登録されています。";
    });
  return errMsg;
}

export async function companyLogin(
  address: string,
  password: string
): Promise<string> {
  let errMsg: string = "";
  try {
    await signInWithEmailAndPassword(auth, address, password);

    if (auth.currentUser) {
      if (!auth.currentUser.emailVerified) {
        await sendEmailVerification(auth.currentUser);
        errMsg = "認証メールが送信されました。";
      } else {
        const docRef = doc(db, "compUsersPrivate", auth.currentUser.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          if (docSnap.data().businessContent == "") {
            errMsg = "基本情報未登録です";
          }
        }else{
          errMsg = "企業として登録されていません。"
          await signOut(auth)
          return errMsg;
        }
      }
    }
  } catch (error) {
    errMsg = "メールアドレスもしくはパスワードが間違っています";
  }
  return errMsg;
}

export async function checkExistStudentUser(address: string) {
  const studentUserSnapshots = await getDocs(
    query(
      collection(db, "studentUsersPrivate"),
      where("address", "==", address)
    )
  );
  return !studentUserSnapshots.empty;
}

// OEM団体のログイン
export async function oemLogin(
  address: string,
  password: string
): Promise<string> {
  let errMsg: string = "";
  try {
    await signInWithEmailAndPassword(auth, address, password);
    if (auth.currentUser) {
      //メールアドレス認証をする
      if (!auth.currentUser.emailVerified) {
        await sendEmailVerification(auth.currentUser);
        errMsg = "認証メールが送信されました。";
      }
      const superuserDoc = doc(db, "OEMsuperuser", auth.currentUser.uid);
      const superuserSnapshot = await getDoc(superuserDoc);
      if (!superuserSnapshot.exists()) {
        await signOut(auth);
        errMsg = "管理団体として登録されていません。";
      }
    }
  } catch (error) {
    console.log(error);
    errMsg = "メールアドレスもしくはパスワードが間違っています";
  }
  return errMsg;
}
