import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import Container from "@mui/material/Container";
import { Link } from "react-router-dom";
import styled from "styled-components";
import serviceLogo from "assets/serviceLogo.png";

const LogoImg = styled.img`
  width: 400px;
  @media screen and (max-width: 480px) {
    width: 300px;
  }
`;
const TopSelectLink = styled(Link)`
  text-decoration: none;
  color: white;
  font-size: 0.95rem;
  display: block;
  width: 100%;
  height: 45px;
  margin: 10px 0;
  text-align: center;
  line-height: 45px;
  border-radius: 5px;
  background-color: #2a2a2a;
  &:hover {
    opacity: 0.9;
  }
`;
export default function Home() {
  return (
    <Box
      sx={{
        pt: 7,
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 1,
        backgroundColor: "rgba(255, 255, 255, 0.2)",
        mt: 20,
      }}
    >
      <LogoImg src={serviceLogo} />

      {/* <Typography
        fontWeight={"bold"}
        sx={{ mt: { xs: 20, sm: 30 } }}
        fontSize={{ xs: "2.5rem", sm: "4rem" }}
      >
        早稲田生活
      </Typography>
      <Typography
        sx={{ mt: 2, letterSpacing: { xs: 2, sm: 10 } }}
        fontSize={{ xs: 14, sm: 20 }}
      >
        早稲田で最高の学生生活を
      </Typography> */}
      <Container component="main">
        <CssBaseline />
        <Box sx={{ width: "100%", mx: "auto" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              alignItems: "center",
              justifyContent: "center",
              padding: 1,
              mt: 8,
            }}
          >
            <Box sx={{ width: 350 }}>
              <TopSelectLink to="/companylogin">企業ログイン</TopSelectLink>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
