import * as React from "react";
import TextField from "@mui/material/TextField";

type DefaultMultilineTextFieldProps = {
  name: string;
  placeholder: string;
  rows: number;
  required?: boolean;
};

export default function DefaultMultilineTextField({
  name,
  placeholder,
  rows,
  required = false,
}: DefaultMultilineTextFieldProps) {
  return (
    <TextField
      inputProps={{ style: { padding: "0px" } }}
      margin="normal"
      fullWidth
      multiline
      name={name}
      placeholder={placeholder}
      rows={rows}
      required={required}
    />
  );
}
