import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import { useLocation, useNavigate } from "react-router-dom";
import { CompanyHeader } from "components/CompanyHeader";
import { useEffect, useState } from "react";
import { Modal, Typography } from "@mui/material";
import { styled } from "styled-components";
import { useUser } from "hooks/firebase/context/auth";
import { offerType } from "types/page/offerType";
import {
  daysToRecruitData,
  EmploymentStatusData,
  graduatedYearData,
  offerTypeData,
  prefectureData,
  recruitedNumberData,
} from "data/StudentProfileData";
import { deleteOfferTemplate } from "hooks/firebase/context/offer";

const mdTheme = createTheme();

const StyledHeadline = styled(Typography)`
  font-size: 22px !important;
  font-weight: bold !important;
  margin-top: 24px !important;
  color: dimgray;
`;
const StyledSubHeadline = styled(Typography)`
  font-size: 20px !important;
  margin-left: 16px !important;
  margin-top: 8px !important;
`;
const StyledButton = styled.button`
  width: 200px;
  height: 40px;
  font-size: 16px;
  border-radius: 5px;
  background-color: black;
  color: white;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  border: none;
  &:hover {
    background-color: #4d4d4d;
  }
`;

const StyledOfferButton = styled.button`
  width: 150px;
  height: 40px;
  margin: 0 10px;
  font-size: 16px;
  border-radius: 8px;
  background-color: #35b500;
  color: white;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  border: none;
  &:hover {
    background-color: #29d630;
  }
`;
const ModalBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  height: 30%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 20px;
  box-shadow: 24px;
  padding: 40px 60px 60px 60px;
  text-align: center;
`;

export default function RecruitDetailContent() {
  const navigate = useNavigate();
  const user = useUser();

  const [open, setOpen] = useState(true);
  const [publicRecruit, setPublic] = useState(true);
  const [deleteModal, showDeleteModal] = useState(false);
  const [offerTemplate, setOfferTemplate] = useState<offerType>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleClose = () => {
    setIsModalOpen(false);
  };
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const location = useLocation();
  const deleteOffer = async () => {
    if (offerTemplate?.id && user) {
      const errMsg = await deleteOfferTemplate(offerTemplate?.id, user);
      if (errMsg === "") {
        navigate("/public-created-recruit");
      } else {
        alert(errMsg);
      }
    }
  };
  useEffect(() => {
    if (user) {
      setOfferTemplate(location.state);
      window.scrollTo(0, 0);
    }
  }, [location, user]);
  const mainStyles = {
    width: open ? "calc(100vw - 240px)" : "calc(100vw - 72px)",
    marginLeft: open ? "240px" : "72px",
    transition: ".3s",
    overflow: "auto",
  };
  return (
    <ThemeProvider theme={mdTheme}>
      <CssBaseline />
      <CompanyHeader
        open={open}
        toggleDrawer={toggleDrawer}
        showrecruitModalProps={true}
      />
      <Box
        component="main"
        sx={{
          ...mainStyles,
        }}
      >
        <Box sx={{ mx: 5, my: 5, mt: 15 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mt: 5,
              mb: 3,
            }}
          >
            <Box
              sx={{
                borderRadius: 5,
                width: 250,
                height: 60,
                alignItems: "center",
                display: "flex",
              }}
            >
              <Box
                sx={{
                  width: "3px",
                  height: "60px",
                  backgroundColor:
                    offerTemplate?.offerType === 0 ? "blue" : "red",
                  mr: 2,
                }}
              />
              <Box
                sx={{
                  width: "100%",
                  textAlign: "left",
                  color: "black",
                }}
              >
                <Typography sx={{ fontSize: 23, fontWeight: "bold" }}>
                  {offerTemplate?.recruitGraduatedYear !== undefined &&
                    graduatedYearData[offerTemplate?.recruitGraduatedYear]
                      .value}
                  卒向け
                </Typography>
                <Typography sx={{ fontSize: 23, fontWeight: "bold" }}>
                  ＜
                  {offerTemplate?.offerType !== undefined &&
                    offerTypeData[offerTemplate?.offerType].value}
                  ＞
                </Typography>
              </Box>
            </Box>
            {/* TODO */}
            <Box sx={{ display: "flex" }}>
              <Box sx={{ textAlign: "right", mr: 5 }}>
                <StyledButton
                  onClick={() =>
                    navigate("/company-edit-recruit", {
                      state: {
                        trialPeriod: offerTemplate?.trialPeriod,
                        access: offerTemplate?.access,
                        appealPoint: offerTemplate?.appealPoint,
                        daysToRecruit: offerTemplate?.daysToRecruit,
                        employmentStatus: offerTemplate?.employmentStatus,
                        jobDetail: offerTemplate?.jobDetail,
                        jobName: offerTemplate?.jobName,
                        maxSalary: offerTemplate?.maxSalary,
                        minSalary: offerTemplate?.minSalary,
                        neededPersonal: offerTemplate?.neededPersonal,
                        offerType: offerTemplate?.offerType,
                        others: offerTemplate?.others,
                        recruitCatchCopy: offerTemplate?.recruitCatchCopy,
                        recruitGraduatedYear:
                          offerTemplate?.recruitGraduatedYear,
                        recruitedNumber: offerTemplate?.recruitedNumber,
                        salaryDetail: offerTemplate?.salaryDetail,
                        salaryForm: offerTemplate?.salaryForm,
                        treatment: offerTemplate?.treatment,
                        workDay: offerTemplate?.workDay,
                        workPlace: offerTemplate?.workPlace,
                        id: offerTemplate?.id,
                      },
                    })
                  }
                >
                  求人内容を編集する
                </StyledButton>
              </Box>
              <Box sx={{ textAlign: "right" }}>
                <StyledButton onClick={() => setIsModalOpen(true)}>
                  この求人を削除する
                </StyledButton>
              </Box>
            </Box>
          </Box>
          <Modal open={isModalOpen} onClose={handleClose}>
            <ModalBox>
              <Typography sx={{ fontSize: 26, fontWeight: "bold" }}>
                本当に削除しますか？
              </Typography>
              <Typography sx={{ fontSize: 16 }}>
                この操作は取り消せません
              </Typography>
              <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
                <StyledOfferButton onClick={handleClose}>
                  キャンセル
                </StyledOfferButton>
                <StyledOfferButton onClick={deleteOffer}>
                  削除する
                </StyledOfferButton>
              </Box>
            </ModalBox>
          </Modal>
          {/* TODO */}
          {/* <Box
            sx={{
              width: { xs: 340, sm: 322 },
              height: 40,
              borderRadius: 20,
              marginTop: 5,
              marginBottom: 5,
              backgroundColor: "#D9D9D9",
            }}
          >
            <Button
              onClick={() => setPublic(true)}
              variant="contained"
              disableElevation
              sx={{
                width: 160,
                borderRadius: 20,
                height: 40,
                fontSize: { xs: "0.95rem", sm: "1.2rem" },
                fontWeight: "bold",
                color: publicRecruit ? "white" : "#4b4b4b",
                backgroundColor: publicRecruit ? "#4b4b4b" : "#D9D9D9",
                ":hover": {
                  backgroundColor: publicRecruit ? "#4b4b4b" : "#D9D9D9",
                },
              }}
            >
              掲載中
            </Button>
            <Button
              onClick={() => setPublic(false)}
              variant="contained"
              disableElevation
              sx={{
                width: 160,
                borderRadius: 20,
                fontWeight: "bold",
                color: publicRecruit ? "#4b4b4b" : "white",
                fontSize: { xs: "0.95rem", sm: "1.2rem" },
                height: 40,
                backgroundColor: publicRecruit ? "#D9D9D9" : "#4b4b4b",
                ":hover": {
                  backgroundColor: publicRecruit ? "#D9D9D9" : "#4b4b4b",
                },
              }}
            >
              未掲載
            </Button>
          </Box> */}
          <StyledHeadline>職種名</StyledHeadline>
          <StyledSubHeadline>{offerTemplate?.jobName}</StyledSubHeadline>
          <StyledHeadline>求人カテゴリー</StyledHeadline>
          <StyledSubHeadline>
            {offerTemplate?.offerType !== undefined &&
              offerTypeData[offerTemplate?.offerType].value}
          </StyledSubHeadline>
          <StyledHeadline>採用卒業年</StyledHeadline>
          <StyledSubHeadline>
            {offerTemplate?.recruitGraduatedYear !== undefined &&
              graduatedYearData[offerTemplate?.recruitGraduatedYear].value}
            年卒
          </StyledSubHeadline>
          <StyledHeadline>雇用形態</StyledHeadline>
          <StyledSubHeadline>
            {offerTemplate?.employmentStatus !== undefined &&
              EmploymentStatusData[offerTemplate?.employmentStatus].value}
          </StyledSubHeadline>
          <StyledHeadline>給与</StyledHeadline>
          <StyledSubHeadline>
            {offerTemplate?.minSalary}円 ~ {offerTemplate?.maxSalary}円
          </StyledSubHeadline>
          {offerTemplate?.salaryDetail !== "" && (
            <Box>
              <StyledHeadline>給与詳細</StyledHeadline>
              <StyledSubHeadline>
                {offerTemplate?.salaryDetail.split("\n").map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
              </StyledSubHeadline>
            </Box>
          )}

          <StyledHeadline>試用期間</StyledHeadline>
          <StyledSubHeadline>
            {offerTemplate?.trialPeriod.split("\n").map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </StyledSubHeadline>
          <StyledHeadline>採用予定人数</StyledHeadline>
          <StyledSubHeadline>
            {offerTemplate?.recruitedNumber !== undefined &&
              recruitedNumberData[offerTemplate?.recruitedNumber].value}
          </StyledSubHeadline>
          <StyledHeadline>採用までの希望日数</StyledHeadline>
          <StyledSubHeadline>
            {offerTemplate?.daysToRecruit !== undefined &&
              daysToRecruitData[offerTemplate?.daysToRecruit].value}
          </StyledSubHeadline>
          <StyledHeadline>仕事内容</StyledHeadline>
          <StyledSubHeadline>
            {offerTemplate?.jobDetail.split("\n").map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </StyledSubHeadline>
          <StyledHeadline>アピールポイント</StyledHeadline>
          <StyledSubHeadline>
            {offerTemplate?.appealPoint.split("\n").map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </StyledSubHeadline>
          <StyledHeadline>求める人材</StyledHeadline>
          <StyledSubHeadline>
            {offerTemplate?.neededPersonal.split("\n").map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </StyledSubHeadline>
          <StyledHeadline>勤務時間・曜日</StyledHeadline>
          <StyledSubHeadline>
            {offerTemplate?.workDay.split("\n").map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </StyledSubHeadline>
          <StyledHeadline>勤務地</StyledHeadline>
          <StyledSubHeadline>
            {offerTemplate?.workPlace !== undefined &&
              prefectureData[offerTemplate?.workPlace].value}
          </StyledSubHeadline>
          <StyledHeadline>アクセス</StyledHeadline>
          <StyledSubHeadline>
            {offerTemplate?.access.split("\n").map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </StyledSubHeadline>
          <StyledHeadline>待遇・福利厚生</StyledHeadline>
          <StyledSubHeadline>
            {offerTemplate?.treatment.split("\n").map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </StyledSubHeadline>
          {offerTemplate?.others !== "" && (
            <Box>
              <StyledHeadline>その他</StyledHeadline>
              <StyledSubHeadline>
                {offerTemplate?.others.split("\n").map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
              </StyledSubHeadline>
            </Box>
          )}
        </Box>
      </Box>
    </ThemeProvider>
  );
}
