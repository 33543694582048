import { Box, Button, Typography, styled } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CompanyHeader } from "components/CompanyHeader";
import PreviewSnackbar from "components/PreviewSnackbar";

const mdTheme = createTheme();

const Title = styled(Typography)`
  font-size: 22px !important;
  font-weight: bold !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
`;
export default function ReverseOfferProfileConfirm() {
  const navigate = useNavigate();

  const [open, setOpen] = useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const mainStyles = {
    width: open ? "calc(100vw - 240px)" : "calc(100vw - 72px)",
    marginLeft: open ? "240px" : "72px",
    transition: ".3s",
    overflow: "auto",
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <PreviewSnackbar open={open} />
        <CssBaseline />
        <CompanyHeader
          open={open}
          toggleDrawer={toggleDrawer}
          showofferModalProps={true}
        />
        <Box component="main" sx={{ ...mainStyles }}>
          <Box sx={{ display: "flex", mt: 10, px: 20 }}>
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Title sx={{ textAlign: "center" }}>
                逆求人用プロフィール確認
              </Title>
              <Title>企業紹介動画</Title>
              <video src="images/topmov.mp4" width={"50%"} controls={true} />
              <Title>選考フロー</Title>
              <Box sx={{ display: "flex" }}>
                <Typography
                  sx={{
                    border: "1px solid black",
                    display: "inline-block",
                    borderRadius: 1,
                    padding: "10px",
                  }}
                >
                  オファー承認
                </Typography>
                <img
                  src="images/recruitArrowIcon.png"
                  alt=""
                  width="40px"
                  style={{
                    marginLeft: 5,
                    marginRight: 5,
                  }}
                />
                <Typography
                  sx={{
                    border: "1px solid black",
                    display: "inline-block",
                    borderRadius: 1,
                    padding: "10px",
                  }}
                >
                  チャット面談
                </Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  type="submit"
                  variant="contained"
                  onClick={() =>
                    navigate("/sample/complete-reverse-offer-profile")
                  }
                  disableElevation
                  style={{
                    width: 120,
                    borderRadius: 5,
                    marginTop: 20,
                    marginBottom: 8,
                    height: 45,
                    backgroundColor: "black",
                  }}
                >
                  設定完了
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
