import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { CompanyHeader } from "components/CompanyHeader";
import { useState } from "react";
import OfferedStudentProfileCard from "components/OfferedStudentProfileCard";
import { Typography } from "@mui/material";
import {
  getMyApplication,
  getMyCompanyOffer,
} from "hooks/firebase/context/offer";
import { useUser } from "hooks/firebase/context/auth";
import { MyCompanyOfferType } from "types/page/myCompanyOfferType";
import {
  registerDocumentAccepted,
  registerDocumentRejected,
} from "hooks/firebase/context/recruit";

const mdTheme = createTheme();

export default function NonAcceptedStudentOffer() {
  const navigate = useNavigate();
  const user = useUser();

  const [open, setOpen] = useState(true);
  const [myCompanyOffers, setMyCompanyOffers] = useState<MyCompanyOfferType[]>(
    []
  );

  const toggleDrawer = () => {
    setOpen(!open);
  };
  const mainStyles = {
    width: open ? "calc(100vw - 240px)" : "calc(100vw - 72px)",
    marginLeft: open ? "240px" : "72px",
    transition: ".3s",
    overflow: "auto",
  };

  React.useEffect(() => {
    if (user) {
      const fetchOffer = async () => {
        const offers = (await getMyCompanyOffer(
          user.auth.uid
        )) as MyCompanyOfferType[];
        const unreachedOffers = offers.filter(
          (offer) => offer.status === "unread"
        );
        setMyCompanyOffers(unreachedOffers);
      };
      fetchOffer();
    }
  }, [user]);

  // const handleOffer = async () => {
  //   const errMsg = await registerDocumentRejected(
  //     "EzNTUIGR0RQY45qs6eG1",
  //     "残念でした"
  //   );

  //   console.log(errMsg);
  // };

  return (
    <ThemeProvider theme={mdTheme}>
      <CssBaseline />
      <CompanyHeader
        open={open}
        toggleDrawer={toggleDrawer}
        showofferModalProps={true}
      />
      <Box
        component="main"
        sx={{
          ...mainStyles,
        }}
      >
        <Box mt={14}>
          <Typography
            fontSize={"1.8rem"}
            fontWeight={"bold"}
            textAlign={"center"}
          >
            オファーを送信した学生
          </Typography>
        </Box>
        <Box
          sx={{
            width: { xs: 340, sm: 500 },
            height: 40,
            borderRadius: 20,
            marginTop: { xs: 3, sm: 5 },
            backgroundColor: "#D9D9D9",
          }}
          mx="auto"
        >
          <Button
            onClick={() => navigate("/accepted-student-offer")}
            variant="contained"
            disableElevation
            sx={{
              width: { xs: 169, sm: 249 },
              borderRadius: 20,
              height: 40,
              color: "#4b4b4b",
              fontSize: { xs: "0.95rem", sm: "1.2rem" },
              fontWeight: "bold",
              backgroundColor: "#D9D9D9",
              ":hover": { backgroundColor: "#D9D9D9" },
            }}
          >
            オファー承認済
          </Button>
          <Button
            variant="contained"
            disableElevation
            sx={{
              width: { xs: 169, sm: 249 },
              borderRadius: 20,
              fontWeight: "bold",
              fontSize: { xs: "0.95rem", sm: "1.2rem" },
              height: 40,
              backgroundColor: "#4b4b4b",
              ":hover": { backgroundColor: "#4b4b4b" },
            }}
          >
            オファー承認前
          </Button>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            width: "90%",
            flexWrap: "wrap",
            mx: "auto",
            mt: 5,
          }}
        >
          {myCompanyOffers.map((myCompanyOffer, i) => {
            return (
              <OfferedStudentProfileCard
                key={i}
                offer={myCompanyOffer}
                step={0}
              />
            );
          })}
        </Box>
      </Box>
    </ThemeProvider>
  );
}
