import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import Container from "@mui/material/Container";
import { createCompUser } from "hooks/firebase/context/auth";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { CircularProgress } from "@mui/material";
import serviceLogo from "assets/serviceLogo.png";

const NoDecorationLink = styled(Link)`
  color: #4169e1;
  text-decoration: none;
`;

const LogoImg = styled.img`
  width: 250px;
`;
export default function SignupCompany() {
  const navigate = useNavigate();
  const [errMsg, setErrMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    setIsLoading(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    function formRef(key: string): string {
      if (data.get(key) === null) return "";
      else return data.get(key) as string;
    }
    const createMsg: string = await createCompUser(
      formRef("companyName"),
      formRef("presidentName"),
      formRef("email"),
      formRef("password"),
      "comp",
      50
    );
    setIsLoading(false);
    if (createMsg === "") navigate("/companyconfirmemail");
    else console.log(createMsg);
  };

  return (
    <Box
      sx={{
        height: "100vh",
        pt: 7,
      }}
    >
      <Container component="main">
        <CssBaseline />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: 1,
            backgroundColor: "rgba(255, 255, 255, 0.2)",
            pt: 3,
          }}
        >
          <LogoImg src={serviceLogo} />
          <Box my={1}>
            <Typography color="red">{errMsg}</Typography>
          </Box>
          <Box sx={{ position: "absolute", top: { xs: 120, sm: 170 } }}>
            {isLoading ? <CircularProgress /> : <Box />}
          </Box>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 3, width: "400px" }}
          >
            <Typography>御社名</Typography>
            <TextField
              margin="normal"
              required
              fullWidth
              id="companyName"
              name="companyName"
              autoFocus
            />
            <Typography>担当者名</Typography>
            <TextField
              margin="normal"
              required
              fullWidth
              name="presidentName"
              type="text"
              id="presidentName"
            />
            <Typography>法人メールアドレス</Typography>
            <TextField
              margin="normal"
              required
              fullWidth
              name="email"
              type="email"
              id="email"
            />
            <Typography>パスワード</Typography>
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              disableElevation
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                backgroundColor: "black",
                "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.6)" },
              }}
            >
              登録
            </Button>
            <Grid container sx={{ pb: 3 }}>
              {/* <Grid item xs>
                <NoDecorationLink to="/resetpassword">
                  パスワードをお忘れの場合
                </NoDecorationLink>
              </Grid>
              <Grid item>
                <NoDecorationLink to="/login">{"ログイン"}</NoDecorationLink>
              </Grid> */}
            </Grid>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
