import { Box, Button, Typography } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CompanyHeader } from "components/CompanyHeader";
import { styled } from "styled-components";
import OfferTemplateCard from "components/OfferTemplateCard";
import { offerType } from "types/page/offerType";
import { useUser } from "hooks/firebase/context/auth";
import { getOfferTemplatesInfos } from "hooks/firebase/context/offer";

const mdTheme = createTheme();

const Title = styled(Typography)`
  font-size: 22px !important;
  font-weight: bold !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  text-align: center;
`;
export default function CompanyOfferSelectDetail() {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useUser();
  const [open, setOpen] = useState(true);

  const [offerTemplateState, setOfferTemplateState] = useState<offerType[]>();
  const [selectedTemplateId, setSelectedTemplateId] = useState("");
  const [studentSenderId, setStudentSenderId] = useState("");

  useEffect(() => {
    setStudentSenderId(location.state.studentSenderId);
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {
    if (user != null) {
      Promise.all([getOfferTemplatesInfos(user)]).then(async (values) => {
        setOfferTemplateState(values[0][0]);
      });
    }
  }, [user]);

  const mainStyles = {
    width: open ? "calc(100vw - 240px)" : "calc(100vw - 72px)",
    marginLeft: open ? "240px" : "72px",
    transition: ".3s",
    overflow: "visible",
  };

  const renderOfferTemplates = () => (
    <>
      {offerTemplateState?.map((offerTemplate: offerType, i: number) => (
        <OfferTemplateCard
          key={i}
          offerTemplate={offerTemplate}
          selectedTemplateId={selectedTemplateId}
          setSelectedTemplateId={setSelectedTemplateId}
        />
      ))}
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          width: "600px",
        }}
      >
        <Button
          variant="contained"
          onClick={() =>
            navigate("/company-offer-chat-detail", {
              state: { studentSenderId, selectedTemplateId },
            })
          }
          disableElevation
          style={{
            width: 100,
            borderRadius: 5,
            marginTop: 40,
            marginBottom: 8,
            height: 40,
            backgroundColor: "black",
          }}
        >
          次へ
        </Button>
      </Box>
    </>
  );

  const renderNoOffers = () => (
    <>
      <Title>現在募集している求人がございません</Title>
      <Button
        variant="contained"
        onClick={() => navigate("/companycreaterecruit")}
        disableElevation
        style={{
          width: 220,
          borderRadius: 5,
          marginTop: 40,
          marginBottom: 8,
          height: 45,
          backgroundColor: "black",
          fontWeight: "bold",
        }}
      >
        オファーを作成する
      </Button>
    </>
  );

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <CompanyHeader open={open} toggleDrawer={() => setOpen(!open)} />
        <Box component="main" sx={{ ...mainStyles }}>
          <Box sx={{ display: "flex", mt: 10, px: 5 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              {offerTemplateState?.length !== 0
                ? renderOfferTemplates()
                : renderNoOffers()}
            </Box>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
