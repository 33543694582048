import * as React from "react";
import TextField from "@mui/material/TextField";
import { useState } from "react";

type DefaultValueTextFieldProps = {
  name: string;
  placeholder: string;
  required?: boolean;
  value: string; // Add this line
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void; // Add this line
};

export default function DefaultValueTextField({
  name,
  placeholder,
  required = false,
  value, // Add this
  onChange, // Add this
}: DefaultValueTextFieldProps) {
  return (
    <TextField
      inputProps={{ style: { padding: "10px" } }}
      margin="normal"
      fullWidth
      onChange={onChange}
      value={value}
      name={name}
      placeholder={placeholder}
      required={required}
    />
  );
}
