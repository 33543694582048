import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import UniversitySelectInput from "components/UniversitySelectInput";
import BunriSelectInput from "components/BunriSelectInput";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import GraduateSelectInput from "components/GraduateSelectInput";
import CompanyGenresSelectInput from "components/CompanyGenresSelectInput";
import TypeSelectInput from "components/TypeSelectInput";
import { CompanyHeader } from "components/CompanyHeader";

const mdTheme = createTheme();

export default function CompanySearchClub() {
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const mainStyles = {
    width: open ? "calc(100vw - 240px)" : "calc(100vw - 72px)",
    marginLeft: open ? "240px" : "72px",
    transition: ".3s",
    overflow: "auto",
  };
  return (
    <ThemeProvider theme={mdTheme}>
      <CssBaseline />
      <CompanyHeader open={open} toggleDrawer={toggleDrawer} />
      <Box
        component="main"
        sx={{
          ...mainStyles,
        }}
      >
        <Box
          sx={{
            width: { xs: 242, sm: 302 },
            height: 42,
            borderRadius: 20,
            marginTop: { xs: 10, sm: 12 },
            border: "1px solid #4b4b4b",
          }}
          mx="auto"
        >
          <Button
            onClick={() => navigate("/companysearchstudent")}
            variant="contained"
            disableElevation
            sx={{
              width: { xs: 120, sm: 150 },
              borderRadius: 20,
              height: 40,
              color: "#4b4b4b",
              fontSize: "1.2rem",
              fontWeight: "bold",
              backgroundColor: "rgba(0,0,0,0)",
              ":hover": { backgroundColor: "rgba(0,0,0,0)" },
            }}
          >
            学生
          </Button>
          <Button
            variant="contained"
            disableElevation
            sx={{
              width: { xs: 120, sm: 150 },
              borderRadius: 20,
              fontWeight: "bold",
              fontSize: "1.2rem",
              height: 40,
              backgroundColor: "#4b4b4b",
              ":hover": { backgroundColor: "#4b4b4b" },
            }}
          >
            サークル
          </Button>
        </Box>

        <Paper
          component="form"
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: 500,
            mx: "auto",
            mt: 5,
            borderRadius: 20,
            boxShadow: 0,
            border: "1px solid black",
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="キーワード検索  例)TOEIC、早稲田大学"
            inputProps={{ "aria-label": "search google maps" }}
          />
          <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
            <SearchIcon />
          </IconButton>
        </Paper>
        <Box
          sx={{
            display: "flex",
            width: "80%",
            height: "160px",
            flexWrap: "wrap",
            mx: "auto",
            justifyContent: "space-around",
            mt: 5,
          }}
        >
          {/* <UniversitySelectInput />
          <BunriSelectInput />
          <GraduateSelectInput />
          <CompanyGenresSelectInput />
          <TypeSelectInput /> */}
        </Box>
        {/* <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "90%",
            flexWrap: "wrap",
            mx: "auto",
            mt: 5,
          }}
        >
          <Box
            sx={{ cursor: "pointer" }}
            onClick={() => navigate("/companystudentprofiledetail")}
          >
            <StudentProfileCard />
          </Box>
          <StudentProfileCard />
          <StudentProfileCard />
          <StudentProfileCard />
        </Box> */}
      </Box>
    </ThemeProvider>
  );
}
