import * as React from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { studentInfoTypes } from "types/page/studentInfoTypes";
import { studentFacultyData } from "data/StudentProfileData";

type StudentProfileProps = {
  imagePath?: string;
  StudentProfileInfos: studentInfoTypes;
};

export default function NonAcceptedOfferedStudentProfileCard({
  imagePath,
  StudentProfileInfos,
}: StudentProfileProps) {
  const [isFavorite, setIsFavorite] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = ["オファー承認", "チャット面談", "面接", "採用"];

  const navigate = useNavigate();
  const toggleFavorite = () => {
    setIsFavorite(!isFavorite);
  };
  const handleClick = () => {
    navigate("/sample/companystudentprofiledetail", {
      state: {
        iconImage: imagePath,
        senderId: StudentProfileInfos.senderId,
        grade: StudentProfileInfos.grade,
        university: "早稲田大学",
        faculty: StudentProfileInfos.faculty,
        department: StudentProfileInfos.department,
        firstName: StudentProfileInfos.firstName,
        lastName: StudentProfileInfos.lastName,
        firstNameKana: StudentProfileInfos.firstNameKana,
        lastNameKana: StudentProfileInfos.lastNameKana,
        sex: StudentProfileInfos.sex,
        livingArea: StudentProfileInfos.livingArea,
        birthPlace: StudentProfileInfos.birthPlace,
        graduatedHighSchool: StudentProfileInfos.graduatedHighSchool,
        certification: StudentProfileInfos.certification,
        programmingExperience: StudentProfileInfos.programmingExperience,
        programmingExperienceDetail:
          StudentProfileInfos.programmingExperienceDetail,
        circle: StudentProfileInfos.circle,
        researchGroup: StudentProfileInfos.researchGroup,
        desiredFirstBusiness: StudentProfileInfos.desiredFirstBusiness,
        desiredSecondBusiness: StudentProfileInfos.desiredSecondBusiness,
        desiredThirdBusiness: StudentProfileInfos.desiredThirdBusiness,
        desiredFirstJob: StudentProfileInfos.desiredFirstJob,
        desiredSecondJob: StudentProfileInfos.desiredSecondJob,
        desiredThirdJob: StudentProfileInfos.desiredThirdJob,
        choiceFirstStandard: StudentProfileInfos.choiceFirstStandard,
        choiceSecondStandard: StudentProfileInfos.choiceSecondStandard,
        choiceThirdStandard: StudentProfileInfos.choiceThirdStandard,
        graduatedYear: StudentProfileInfos.graduatedYear,
        graduatedMonth: StudentProfileInfos.graduatedMonth,
        humanitiesOrScience: StudentProfileInfos.humanitiesOrScience,
        internCompName: StudentProfileInfos.internCompName,
        internJobType: StudentProfileInfos.internJobType,
        internPeriod: StudentProfileInfos.internPeriod,
        selfPRMain: StudentProfileInfos.selfPRMain,
        updatedAt: StudentProfileInfos.updatedAt,
      },
    });
  };
  return (
    <Card
      sx={{
        display: "flex",
        marginTop: 4,
        width: "80%",
        py: 4,
        px: 3,
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ display: "flex" }}>
        <CardMedia
          component="img"
          sx={{ width: 140, height: 140, borderRadius: 70 }}
          image={imagePath}
          alt="Live from space album cover"
        />
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <CardContent sx={{ flex: "1 0 auto" }}>
            <Typography component="div" variant="h5">
              {StudentProfileInfos.lastName} {StudentProfileInfos.firstName}
            </Typography>
            <Typography variant="subtitle1" component="div" fontSize={14}>
              早稲田大学
            </Typography>
            <Typography variant="subtitle2" component="div" fontSize={12}>
              {
                studentFacultyData[Number(StudentProfileInfos.faculty) - 1]
                  .value
              }
            </Typography>
            <Box mt={1}>
              <Chip label={"文系"} color={"error"} sx={{ mr: 1 }} />
              <Chip label={"24卒"} color={"primary"} variant="outlined" />
            </Box>
          </CardContent>
        </Box>
      </Box>
      <Box
        sx={{
          borderRadius: 5,
          width: 200,
          height: 40,
          alignItems: "center",
          display: "flex",
        }}
      >
        <Box
          sx={{
            width: "3px",
            height: "50px",
            backgroundColor: "#04A1D2",
            mr: 2,
          }}
        />
        <Box
          sx={{
            width: "100%",
            textAlign: "left",
            color: "black",
          }}
        >
          <Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
            2024年卒向け
          </Typography>
          <Typography sx={{ fontWeight: "bold" }}>＜新卒採用＞</Typography>
        </Box>
      </Box>
      <Button
        variant="outlined"
        onClick={() => navigate("/companychat")}
        disableElevation
        color="inherit"
        style={{
          width: 150,
          borderRadius: 20,
          marginTop: 10,
          height: 40,
        }}
      >
        チャットを開く
      </Button>
    </Card>
  );
}
