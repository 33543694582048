import {
  addDoc,
  collection,
  doc,
  DocumentData,
  DocumentReference,
  getDoc,
  getDocs,
  increment,
  onSnapshot,
  orderBy,
  Query,
  query,
  QuerySnapshot,
  serverTimestamp,
  where,
  writeBatch,
} from "firebase/firestore";
import {
  stdCompChatRoom,
  stdCompMessage,
  roomInfo,
  roomInfoToStdPrivateDoc,
} from "types/firebase/chat";
import { db, functions, storage } from "../main";
import { httpsCallable } from "firebase/functions";
import { reverse } from "dns";
import { ref, StorageReference, uploadBytes } from "firebase/storage";

// chatroomの詳細情報を返す関数
export async function getChatInfo(roomID: string) {
  // 降順で（送信日時の）ソートするクエリを作成して、返す。
  const q: Query = query(
    collection(db, "chatBetCompStd", roomID, "messages"),
    orderBy("sendedAt", "desc")
  );
  const querySnapshot: QuerySnapshot = await getDocs(q);
  if (!querySnapshot.empty) {
    console.log(querySnapshot.docs);
  } else {
    return null;
  }
}

// チャットの一覧を取得する関数
export async function getChatList(uid: string) {
  let errMsg = "";
  try {
    const chatCol = collection(db, "compUsersPrivate", uid, "chatWithStd");
    const q = query(chatCol, orderBy("updatedAt", "desc"));
    const chatSnapshot = await getDocs(q);
    const chatLists = chatSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(), // すべてのフィールドを取得
    }));
    return { errMsg, chatLists };
  } catch (error) {
    errMsg =
      error instanceof Error ? error.message : "チャットの取得に失敗しました。";
    return { errMsg, chatLists: null };
  }
}

// 企業から学生にメッセージを送信する関数
export async function sendMessage(
  messageType: number,
  companyId: string,
  message: string,
  roomID: string,
  sendedFile?: Blob
) {
  let errMsg = "";
  // ユーザー情報を取得
  const user = (await getDoc(doc(db, "compUsersPrivate", companyId))).data();
  const batch = writeBatch(db);
  const doc_to_room = doc(db, `chatBetCompStd`, roomID);
  const roomSnapshot = await getDoc(doc_to_room);
  if (!roomSnapshot.exists()) {
    errMsg = "チャットルームが存在しません。";
    return errMsg;
  }
  const studentId = roomSnapshot.data().author.student.id;
  const ref_to_msg = collection(db, `chatBetCompStd`, roomID, "messages");
  const msgId = doc(ref_to_msg).id;
  const msgDoc = doc(db, `chatBetCompStd`, roomID, "messages", msgId);
  if (messageType == 1) {
    //テキストメッセージの時の挙動
    var sendData: stdCompMessage = {
      messageType: messageType,
      sendedFileUrl: "",
      senderID: companyId,
      readerID: studentId,
      message: message,
      sendedAt: serverTimestamp(),
      isRead: false,
      selectionRef: null,
      isSenderStudent: false,
      registerdStatus: "",
    };
  } else {
    //ファイルの時の挙動
    let sendedFileUrl = "";
    if (sendedFile) {
      // storageのiconへのpathを作成してそれをstringに変換（firestoreで格納できるようにするため）
      var sendedFileRef: StorageReference = ref(
        storage,
        `chatBetCompStd/${roomID}/${msgId}`
      );
      sendedFileUrl = sendedFileRef.fullPath;
    }
    var sendData: stdCompMessage = {
      messageType: messageType,
      sendedFileUrl: sendedFileUrl,
      senderID: companyId,
      readerID: studentId,
      message: "ファイルを送信しました。",
      sendedAt: serverTimestamp(),
      isRead: false,
      selectionRef: null,
      isSenderStudent: false,
      registerdStatus: "",
    };
  }
  const data2std = {
    lastMessage: sendData.message,
    unread: increment(1),
    updatedAt: sendData.sendedAt,
  };
  const data2comp = {
    lastMessage: sendData.message,
    updatedAt: sendData.sendedAt,
  };
  batch.set(msgDoc, sendData);
  batch.update(doc_to_room, {
    lastMessage: sendData.message,
    updatedAt: sendData.sendedAt,
  });
  batch.update(
    doc(
      db,
      "jobHuntingStudentUsersPrivate",
      studentId,
      "chatWithComp",
      companyId
    ),
    data2std
  );
  batch.update(
    doc(db, "compUsersPrivate", companyId, "chatWithStd", studentId),
    data2comp
  );
  await batch
    .commit()
    .then(async () => {
      const metadata = {
        contentType: "image/jpeg",
      };
      if (sendedFile !== undefined) {
        await uploadBytes(sendedFileRef, sendedFile, metadata);
      }
    })
    .catch((err: string) => {
      console.log(err);
      errMsg = "メッセージの送信に失敗しました。";
    });
  return errMsg;
}

// チャットメッセージを取得する関数
export function getChatMessages(
  roomId: string,
  onMessageAdded: (message: DocumentData) => void
): () => void {
  const messagesRef = collection(db, `/chatBetCompStd/${roomId}/messages`);
  const q = query(messagesRef, orderBy("createdAt", "desc")); // timestamp でメッセージを並べ替える場合
  const unsubscribe = onSnapshot(q, (snapshot) => {
    snapshot.docChanges().forEach((change) => {
      if (change.type === "added") {
        onMessageAdded(change.doc.data());
      }
      // 必要に応じて 'modified' や 'removed' の処理もここに追加
    });
  });
  return unsubscribe;
}

export async function makeReadStamp(roomID: string) {
  console.log("makeReadStamp");

  functions.region = "asia-northeast1";
  const readMessage = httpsCallable(functions, "readChatBetCompStdMesssage");
  await readMessage({ roomID: roomID, isCompany: true });
}

//学生とのチャットに関連したデータを取得
export async function getChatRelatedData(selectionDocRef:DocumentReference,studentId:string){
  let errMsg = "";
  try{
    const selectionDocSnap = await getDoc(selectionDocRef);
    const selectionData = selectionDocSnap.data();
    const studentDocRef = doc(db,"jobHuntingStudentUsersPublic",studentId);
    const studnetDocSnap = await getDoc(studentDocRef);
    const studentData = studnetDocSnap.data();
    return {errMsg,selectionData,studentData}
  }catch(error){
    errMsg = error instanceof Error ? error.message:"予期せぬエラーが発生しました。"
    return {errMsg,selectionData:null,StudentData:null}
  }
}