import React, { useEffect, useState } from "react";
import {
  fetchUniversitiesForUser,
  getClosedCouponList,
  getExtraClosedCouponList,
  getExtraOpenCouponList,
  getOpenCouponList,
} from "hooks/firebase/context/oemInfo";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  Alert,
  Backdrop,
  CircularProgress,
  MenuItem,
  Select,
  SelectChangeEvent,
  Snackbar,
} from "@mui/material";
import { useUser } from "hooks/firebase/context/auth";
import { couponType } from "types/page/couponType";
import { DocumentData, DocumentSnapshot } from "firebase/firestore";
import FileInputModal from "components/FileInputModal";
import OemOpenCouponCard from "components/OemOpenCouponCard";
import OemCloseCouponCard from "components/OemCloseCouponCard";
import { OemHeader } from "components/OemHeader";
import { oemUniversityData } from "data/OEMData";

const ManageCoupon = () => {
  const [openCouponFiles, setOpenCouponFiles] = useState<couponType[]>([]);
  const [closeCouponFiles, setCloseCouponFiles] = useState<couponType[]>([]);
  const [showOpenCoupon, setShowOpenCoupon] = useState<boolean>(true);
  const user = useUser();
  const [modalOpen, setModalOpen] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [completeMsg, setCompleteMsg] = useState<string>("");
  const [deleteMsg, setDeleteMsg] = useState("");
  const [closeCouponMsg, setCloseCouponMsg] = useState("");
  const [openCouponMsg, setOpenCouponMsg] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [closeAlertOpen, setCloseAlertOpen] = useState(false);
  const [openCouponAlertOpen, setOpenCouponAlertOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedUniversityNumber, setSelectedUniversityNumber] =
    useState<string>("");
  const [universityNumber, setUniversityNumber] = useState<number[]>([]);
  const [openCouponsLastVisible, setOpenCouponsLastVisible] =
    useState<DocumentSnapshot | null>(null);
  const [closeCouponsLastVisible, setCloseCouponsLastVisible] =
    useState<DocumentSnapshot | null>(null);
  const [isLastOpenCoupons, setIsLastOpenCoupons] = useState<boolean>(false);
  const [isLastCloseCoupons, setIsLastCloseCoupons] = useState<boolean>(false);
  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const showAlert = () => {
    setAlertOpen(true);
    setTimeout(() => {
      setAlertOpen(false);
    }, 1500);
  };
  const showDeleteAlert = () => {
    setDeleteAlertOpen(true);
    setTimeout(() => {
      setDeleteAlertOpen(false);
    }, 1500);
  };
  const showCloseAlert = () => {
    setCloseAlertOpen(true);
    setTimeout(() => {
      setCloseAlertOpen(false);
    }, 1500);
  };
  const showOpenCouponAlert = () => {
    setOpenCouponAlertOpen(true);
    setTimeout(() => {
      setOpenCouponAlertOpen(false);
    }, 1500);
  };

  useEffect(() => {
    if (completeMsg == "complete") {
      showAlert();
      setCompleteMsg("");
    }
    if (deleteMsg == "complete") {
      showDeleteAlert();
      setDeleteMsg("");
    }
    if (closeCouponMsg == "complete") {
      showCloseAlert();
      setCloseCouponMsg("");
    }
    if (openCouponMsg == "complete") {
      showOpenCouponAlert();
      setOpenCouponMsg("");
    }
    const fetchData = async () => {
      if (user) {
        setIsLoading(true);
        const universityData = await fetchUniversitiesForUser(user.auth.uid);
        setUniversityNumber(universityData.map(Number));
        const openCoupons = await getOpenCouponList(Number(universityData[0]));
        const closeCoupons = await getClosedCouponList(
          Number(universityData[0])
        );
        setOpenCouponFiles(
          openCoupons.couponLists.map((coupon: DocumentData) => ({
            affiliateCode: coupon.affiliateCode,
            createdAt: coupon.createdAt,
            id: coupon.id,
            imgRef: coupon.imgRef,
            isAffiliate: coupon.isAffiliate,
            isOpen: coupon.isOpen,
            mapurl: coupon.mapurl,
            onlyOnce: coupon.onlyOnce,
            serviceUrl: coupon.serviceUrl,
            university: coupon.university,
            usedBy: coupon.usedBy,
          }))
        );
        setCloseCouponFiles(
          closeCoupons.couponLists.map((coupon: DocumentData) => ({
            affiliateCode: coupon.affiliateCode,
            createdAt: coupon.createdAt,
            id: coupon.id,
            imgRef: coupon.imgRef,
            isAffiliate: coupon.isAffiliate,
            isOpen: coupon.isOpen,
            mapurl: coupon.mapurl,
            onlyOnce: coupon.onlyOnce,
            serviceUrl: coupon.serviceUrl,
            university: coupon.university,
            usedBy: coupon.usedBy,
          }))
        );
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);
  useEffect(() => {
    if (universityNumber.length > 0 && selectedUniversityNumber === "") {
      setSelectedUniversityNumber(String(universityNumber[0]));
    }
  }, [universityNumber, selectedUniversityNumber]);
  useEffect(() => {
    if (completeMsg == "complete") {
      showAlert();
      setCompleteMsg("");
    }
    if (deleteMsg == "complete") {
      showDeleteAlert();
      setDeleteMsg("");
    }
    if (closeCouponMsg == "complete") {
      showCloseAlert();
      setCloseCouponMsg("");
    }
    if (openCouponMsg == "complete") {
      showOpenCouponAlert();
      setOpenCouponMsg("");
    }
    const fetchData = async () => {
      if (user) {
        setIsLoading(true);
        const openCoupons = await getOpenCouponList(
          Number(selectedUniversityNumber)
        );
        const closeCoupons = await getClosedCouponList(
          Number(selectedUniversityNumber)
        );
        if (openCoupons.couponLists.length === 10) {
          setIsLastOpenCoupons(false);
        } else {
          setIsLastOpenCoupons(true);
        }
        if (closeCoupons.couponLists.length === 10) {
          setIsLastCloseCoupons(false);
        } else {
          setIsLastCloseCoupons(true);
        }
        setOpenCouponsLastVisible(openCoupons.lastVisible);
        setCloseCouponsLastVisible(closeCoupons.lastVisible);
        setOpenCouponFiles(
          openCoupons.couponLists.map((coupon: DocumentData) => ({
            affiliateCode: coupon.affiliateCode,
            createdAt: coupon.createdAt,
            id: coupon.id,
            imgRef: coupon.imgRef,
            isAffiliate: coupon.isAffiliate,
            isOpen: coupon.isOpen,
            mapurl: coupon.mapurl,
            onlyOnce: coupon.onlyOnce,
            serviceUrl: coupon.serviceUrl,
            university: coupon.university,
            usedBy: coupon.usedBy,
          }))
        );
        setCloseCouponFiles(
          closeCoupons.couponLists.map((coupon: DocumentData) => ({
            affiliateCode: coupon.affiliateCode,
            createdAt: coupon.createdAt,
            id: coupon.id,
            imgRef: coupon.imgRef,
            isAffiliate: coupon.isAffiliate,
            isOpen: coupon.isOpen,
            mapurl: coupon.mapurl,
            onlyOnce: coupon.onlyOnce,
            serviceUrl: coupon.serviceUrl,
            university: coupon.university,
            usedBy: coupon.usedBy,
          }))
        );
        setIsLoading(false);
      }
    };
    fetchData();
  }, [
    completeMsg,
    deleteMsg,
    closeCouponMsg,
    openCouponMsg,
    selectedUniversityNumber,
  ]);
  const getNextOpenCouponList = async () => {
    if (user && openCouponsLastVisible) {
      setIsLoading(true);
      const openCoupons = await getExtraOpenCouponList(
        Number(selectedUniversityNumber),
        openCouponsLastVisible
      );
      if (openCoupons.couponLists.length === 10) {
        setIsLastOpenCoupons(false);
      } else {
        setIsLastOpenCoupons(true);
      }
      setOpenCouponsLastVisible(openCoupons.lastVisible);
      setOpenCouponFiles([
        ...openCouponFiles,
        ...openCoupons.couponLists.map((coupon: DocumentData) => ({
          affiliateCode: coupon.affiliateCode,
          createdAt: coupon.createdAt,
          id: coupon.id,
          imgRef: coupon.imgRef,
          isAffiliate: coupon.isAffiliate,
          isOpen: coupon.isOpen,
          mapurl: coupon.mapurl,
          onlyOnce: coupon.onlyOnce,
          serviceUrl: coupon.serviceUrl,
          university: coupon.university,
          usedBy: coupon.usedBy,
        })),
      ]);
      setIsLoading(false);
    }
  };
  const getNextCloseCouponList = async () => {
    if (user && closeCouponsLastVisible) {
      setIsLoading(true);
      const closeCoupons = await getExtraClosedCouponList(
        Number(selectedUniversityNumber),
        closeCouponsLastVisible
      );
      if (closeCoupons.couponLists.length === 10) {
        setIsLastCloseCoupons(false);
      } else {
        setIsLastCloseCoupons(true);
      }
      setCloseCouponsLastVisible(closeCoupons.lastVisible);
      setCloseCouponFiles([
        ...closeCouponFiles,
        ...closeCoupons.couponLists.map((coupon: DocumentData) => ({
          affiliateCode: coupon.affiliateCode,
          createdAt: coupon.createdAt,
          id: coupon.id,
          imgRef: coupon.imgRef,
          isAffiliate: coupon.isAffiliate,
          isOpen: coupon.isOpen,
          mapurl: coupon.mapurl,
          onlyOnce: coupon.onlyOnce,
          serviceUrl: coupon.serviceUrl,
          university: coupon.university,
          usedBy: coupon.usedBy,
        })),
      ]);
      setIsLoading(false);
    }
  };
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const mainStyles = {
    width: open ? "calc(100vw - 240px)" : "calc(100vw - 72px)",
    marginLeft: open ? "240px" : "72px",
    transition: ".3s",
    overflow: "auto",
  };
  return (
    <Box>
      <OemHeader open={open} toggleDrawer={toggleDrawer} />

      <Box
        component="main"
        sx={{
          ...mainStyles,
        }}
      >
        <Box mt={12} mx={5}>
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <Snackbar
            open={alertOpen}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert severity="success" sx={{ width: "100%" }}>
              クーポンをアップロードしました
            </Alert>
          </Snackbar>
          <Snackbar
            open={deleteAlertOpen}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert severity="error" sx={{ width: "100%" }}>
              クーポンを削除しました
            </Alert>
          </Snackbar>
          <Snackbar
            open={closeAlertOpen}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert severity="error" sx={{ width: "100%" }}>
              クーポンを非公開にしました
            </Alert>
          </Snackbar>
          <Snackbar
            open={openCouponAlertOpen}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert severity="success" sx={{ width: "100%" }}>
              クーポンを公開しました
            </Alert>
          </Snackbar>
          <Typography sx={{ fontSize: "30px", fontWeight: "bold" }}>
            クーポン管理
          </Typography>

          <Box
            sx={{
              width: "90%",
              height: 100,
              justifyContent: "space-between",
              display: "flex",
              alignItems: "center",
              mb: 3,
            }}
          >
            <Button
              onClick={handleOpenModal}
              sx={{
                color: "white",
                backgroundColor: "black",
                fontWeight: "bold",
                px: 3,
                mt: 1,
                "&:hover": { backgroundColor: "dimgray" },
              }}
            >
              新しいクーポンの作成
            </Button>
            <Select
              value={selectedUniversityNumber}
              onChange={(event: SelectChangeEvent<string>) => {
                setSelectedUniversityNumber(String(event.target.value));
              }}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              {oemUniversityData
                .filter((university) =>
                  universityNumber.includes(Number(university.key))
                )
                .map((filteredUniversity) => (
                  <MenuItem
                    key={filteredUniversity.key}
                    value={filteredUniversity.key}
                  >
                    {filteredUniversity.value}
                  </MenuItem>
                ))}
            </Select>
          </Box>

          <Box
            sx={{
              width: 400,
              display: "flex",
              justifyContent: "space-between",
              mb: 5,
            }}
          >
            <Button
              onClick={() => {
                setShowOpenCoupon(true);
              }}
              style={{
                color: "black",
              }}
            >
              <Typography
                sx={{
                  fontSize: "20px",
                  color: showOpenCoupon ? "black" : "gray",
                }}
              >
                掲載中のクーポン
              </Typography>
            </Button>
            <Button
              onClick={() => {
                setShowOpenCoupon(false);
              }}
              style={{
                color: "black",
              }}
            >
              <Typography
                sx={{
                  fontSize: "20px",
                  color: showOpenCoupon ? "gray" : "black",
                }}
              >
                掲載停止のクーポン
              </Typography>
            </Button>
          </Box>
          <Box sx={{ display: "flex", width: "100%", flexWrap: "wrap" }}>
            {showOpenCoupon
              ? openCouponFiles.map((coupon) => (
                  <OemOpenCouponCard
                    key={coupon.id}
                    imagePath={coupon.imgRef}
                    couponid={coupon.id}
                    setDeleteMsg={setDeleteMsg}
                    setCloseCouponMsg={setCloseCouponMsg}
                    setIsLoading={setIsLoading}
                  />
                ))
              : closeCouponFiles.map((coupon) => (
                  <OemCloseCouponCard
                    key={coupon.id}
                    imagePath={coupon.imgRef}
                    couponid={coupon.id}
                    setDeleteMsg={setDeleteMsg}
                    setOpenCouponMsg={setOpenCouponMsg}
                    setIsLoading={setIsLoading}
                  />
                ))}
            {!isLastOpenCoupons && showOpenCoupon && (
              <Button
                sx={{
                  mx: 4,
                  backgroundColor: "white",
                  color: "royalblue",
                  width: "210px",
                  height: "300px",
                  border: "2px solid royalblue",
                }}
                onClick={getNextOpenCouponList}
              >
                もっと見る
              </Button>
            )}
            {!isLastCloseCoupons && !showOpenCoupon && (
              <Button
                sx={{
                  mx: 4,
                  backgroundColor: "white",
                  color: "royalblue",
                  width: "210px",
                  height: "300px",
                  border: "2px solid royalblue",
                }}
                onClick={getNextCloseCouponList}
              >
                もっと見る
              </Button>
            )}
          </Box>

          <FileInputModal
            open={modalOpen}
            onClose={handleCloseModal}
            setCompleteMsg={setCompleteMsg}
            setIsLoading={setIsLoading}
            universityNumber={Number(selectedUniversityNumber)}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ManageCoupon;
