import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { faBuilding, faUser } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CompanyHeader } from "components/CompanyHeader";
import { getOfferTemplatesInfos } from "hooks/firebase/context/offer";
import { useEffect, useLayoutEffect } from "react";
import { useUser } from "hooks/firebase/context/auth";
import { offerType } from "types/page/offerType";
import {
  graduatedYearData,
  jobCategoryData,
  offerTypeData,
  prefectureData,
} from "data/StudentProfileData";

const mdTheme = createTheme();
const StyledButton = styled.button`
  width: 200px;
  height: 40px;
  font-size: 16px;
  border-radius: 5px;
  background-color: black;
  color: white;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  border: none;
  &:hover {
    background-color: #4d4d4d;
  }
`;
const StyledProfileButton = styled(Button)`
  display: flex;
  align-items: center;
  &:hover {
    background-color: rgba(0, 0, 0, 0) !important;
  }
`;
const StyledRecruitButton = styled(Button)`
  display: flex;
  textalign: left;
  width: 300px;
  box-shadow: 0px 5px 10px lightgray;
  padding: 15px !important;
  background-color: white !important;
  margin: 20px !important;
`;
const StyledHeadline = styled(Typography)`
  font-size: 26px !important;
  font-weight: bold !important;
  margin-top: 24px !important;
  margin-bottom: 12px !important;
  padding: 0 !important;
  color: black !important;
`;

export default function CompanyAppUiRecruitTop() {
  // const [favorite, isFavorite] = React.useState(false);
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(true);
  const [offerTemplates, setOfferTemplates] = React.useState<offerType[]>([]);
  const [jobCategory, setJobCategory] = React.useState<number[]>();
  const [defaultIconImage, setDefaultIconImage] = React.useState<string>();
  const [defaultProfileImage, setDefaultProfileImage] =
    React.useState<string>();
  const [companyName, setCompanyName] = React.useState<string>();
  const [prefecture, setPrefecture] = React.useState<number>();
  const [recruitCatchcopy, setRecruitCatchcopy] = React.useState<string>();

  const location = useLocation();

  useEffect(() => {
    const jobData = location.state.jobCategory;
    const iconImage = location.state.iconImage;
    const profileImage = location.state.profileImage;
    const companyName = location.state.name;
    const prefecture = location.state.prefecture;
    const recruitCatchcopy = location.state.recruitCatchCopy;

    setDefaultIconImage(iconImage);
    setDefaultProfileImage(profileImage);
    setJobCategory(jobData);
    setCompanyName(companyName);
    setPrefecture(prefecture);
    setRecruitCatchcopy(recruitCatchcopy);
    window.scrollTo(0, 0);
  }, [location]);

  const toggleDrawer = () => {
    setOpen(!open);
  };
  const mainStyles = {
    width: open ? "calc(100vw - 240px)" : "calc(100vw - 72px)",
    marginLeft: open ? "240px" : "72px",
    transition: ".3s",
    overflow: "auto",
  };
  const user = useUser();
  useLayoutEffect(() => {
    if (user != null) {
      Promise.all([getOfferTemplatesInfos(user)]).then(async (values) => {
        const offerTemplates = values[0][0];
        setOfferTemplates(offerTemplates);
      });
    }
  }, [user]);
  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <CompanyHeader open={open} toggleDrawer={toggleDrawer} />
        <Box
          component="main"
          sx={{
            ...mainStyles,
          }}
        >
          <Box mt={8} sx={{ display: "flex", justifyContent: "center" }}>
            {defaultProfileImage ? (
              <img
                src={defaultProfileImage}
                alt={"companyProfile"}
                loading="lazy"
                style={{
                  width: open
                    ? "calc((100vw - 240px)*0.6)"
                    : "calc((100vw - 72px)*0.6)",
                  height: open
                    ? "calc((100vw - 240px)*0.3)"
                    : "calc((100vw - 72px)*0.3)",
                  objectFit: "cover",
                }}
              />
            ) : (
              <Box
                sx={{
                  width: "100%",
                  height: "300px",
                  backgroundColor: "lightgray",
                }}
              />
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              mt: -2,
              ml: "20%",
              px: 3,
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex" }}>
              {defaultIconImage ? (
                <Box
                  sx={{
                    width: { xs: "80px", lg: "100px" },
                    height: { xs: "80px", lg: "100px" },
                  }}
                >
                  <img
                    src={defaultIconImage}
                    alt={"companyProfile"}
                    loading="lazy"
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "10px",
                      objectFit: "cover",
                    }}
                  />
                </Box>
              ) : (
                <Box
                  sx={{
                    width: "100px",
                    height: "100px",
                    borderRadius: "10px",
                    border: "1px solid white",
                    backgroundColor: "lightgray",
                  }}
                />
              )}

              <Box sx={{ maxWidth: "60%", ml: 4 }}>
                <Box sx={{ mt: 3 }}>
                  <Typography sx={{ fontSize: 26, fontWeight: "bold" }}>
                    {companyName}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Typography
                    sx={{ fontSize: 20, color: "gray", mr: 3, minWidth: 42 }}
                  >
                    職種
                  </Typography>
                  <Typography sx={{ fontSize: 20 }}>
                    {jobCategory &&
                      jobCategory
                        .map((category) => jobCategoryData[category].value)
                        .join(", ")}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Typography sx={{ fontSize: 20, color: "gray", mr: 3 }}>
                    所在地
                  </Typography>
                  <Typography sx={{ fontSize: 20 }}>
                    {prefecture !== undefined &&
                      prefectureData[prefecture].value}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{ width: "80%", display: "flex", justifyContent: "flex-end" }}
          >
            <StyledButton
              style={{ marginTop: 20 }}
              onClick={() => navigate("/companyprofiledetail")}
            >
              プロフィール画面へ戻る
            </StyledButton>
          </Box>
          <Box sx={{ width: "60%", mx: "auto" }}>
            <StyledHeadline>
              {recruitCatchcopy &&
                recruitCatchcopy.split("\n").map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
            </StyledHeadline>

            <Box sx={{ display: "flex" }}>
              <StyledProfileButton
                startIcon={
                  <FontAwesomeIcon icon={faBuilding} size="lg" color="black" />
                }
                onClick={() => {
                  navigate("/companyappuiprofiledetail");
                }}
              >
                <Typography
                  sx={{
                    fontSize: 22,
                    mx: 1,
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  企業概要
                </Typography>
              </StyledProfileButton>
              <StyledProfileButton
                startIcon={
                  <FontAwesomeIcon icon={faUser} size="lg" color="black" />
                }
              >
                <Typography
                  sx={{
                    fontSize: 22,
                    mx: 1,
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  求人情報
                </Typography>
              </StyledProfileButton>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Box
                sx={{
                  width: "140px",
                  height: "5px",
                  backgroundColor: "lightgray",
                }}
              />
              <Box
                sx={{
                  width: "140px",
                  height: "5px",
                  backgroundColor: "black",
                }}
              />
            </Box>

            <Box
              sx={{
                width: "80%",
                marginLeft: "10%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexWrap: "wrap",
                marginTop: 3,
              }}
            >
              {offerTemplates.length === 0 ? (
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: "bold",
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                >
                  現在募集している求人はございません
                </Typography>
              ) : (
                offerTemplates.map((offerTemplate) => {
                  return (
                    <StyledRecruitButton
                      key={offerTemplate.id}
                      sx={{
                        borderRadius: 5,
                        mt: 3,
                      }}
                      onClick={() =>
                        navigate("/companyappuirecruitdetail", {
                          state: {
                            trialPeriod: offerTemplate.trialPeriod,
                            access: offerTemplate.access,
                            appealPoint: offerTemplate.appealPoint,
                            daysToRecruit: offerTemplate.daysToRecruit,
                            employmentStatus: offerTemplate.employmentStatus,
                            jobCategory: jobCategory,
                            jobDetail: offerTemplate.jobDetail,
                            jobName: offerTemplate.jobName,
                            maxSalary: offerTemplate.maxSalary,
                            minSalary: offerTemplate.minSalary,
                            neededPersonal: offerTemplate.neededPersonal,
                            offerType: offerTemplate.offerType,
                            others: offerTemplate.others,
                            recruitCatchCopy: offerTemplate.recruitCatchCopy,
                            recruitGraduatedYear:
                              offerTemplate.recruitGraduatedYear,
                            recruitedNumber: offerTemplate.recruitedNumber,
                            salaryDetail: offerTemplate.salaryDetail,
                            salaryForm: offerTemplate.salaryForm,
                            treatment: offerTemplate.treatment,
                            workDay: offerTemplate.workDay,
                            workPlace: offerTemplate.workPlace,
                            id: offerTemplate.id,
                            iconImage: defaultIconImage,
                            profileImage: defaultProfileImage,
                            name: companyName,
                            prefecture: prefecture,
                            companyInfoRecruitCatchCopy: recruitCatchcopy,
                          },
                        })
                      }
                    >
                      <Box
                        sx={{
                          width: "3px",
                          height: "50px",
                          backgroundColor:
                            offerTemplate.offerType === 0 ? "blue" : "red",
                          mr: 2,
                        }}
                      />
                      <Box
                        sx={{
                          width: "100%",
                          textAlign: "left",
                          color: "black",
                        }}
                      >
                        <Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                          {offerTemplate.recruitGraduatedYear !== undefined &&
                            graduatedYearData[
                              offerTemplate.recruitGraduatedYear
                            ].value}
                          年卒向け
                        </Typography>
                        <Typography sx={{ fontWeight: "bold" }}>
                          ＜
                          {offerTemplate.offerType !== undefined &&
                            offerTypeData[offerTemplate.offerType].value}
                          ＞
                        </Typography>
                        <Box sx={{ textAlign: "right" }}>
                          <Typography sx={{ fontWeight: "bold" }}>
                            開く＞
                          </Typography>
                        </Box>
                      </Box>
                    </StyledRecruitButton>
                  );
                })
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
