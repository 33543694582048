import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import { Typography } from "@mui/material";
import Container from "@mui/material/Container";
import { createStudentUser, useUser } from "hooks/firebase/context/auth";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useState } from "react";
import { CircularProgress } from "@mui/material";
import serviceLogo from "assets/serviceLogo.png";
import SelectFromList from "components/SelectFromList";
import { gradeData, studentFacultyData } from "data/StudentProfileData";

const LogoImg = styled.img`
  width: 300px;
`;
export default function StudentsSignup() {
  const user = useUser();
  const navigate = useNavigate();
  const [errMsg, setErrMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [grade, setGrade] = useState("");
  const [faculty, setFaculty] = useState("");
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    setIsLoading(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    function formRef(key: string): string {
      if (data.get(key) === null) return "";
      else return data.get(key) as string;
    }
    if (
      !formRef("name") ||
      !formRef("password") ||
      grade === "" ||
      faculty === ""
    ) {
      setErrMsg("未入力の項目があります");
      setIsLoading(false);
      return;
    }
    if (!user?.auth.email) {
      // 未ログイン状態でメアドが取得できなかったとき
      setErrMsg(
        "メールアドレスを取得できませんでした。リロードしてみてください"
      );
      setIsLoading(false);
      return;
    }
    // studentUserアカウント作成
    const createMsg: string = await createStudentUser(
      formRef("name"),
      Number(grade),
      Number(faculty),
      user?.auth.email,
      formRef("password"),
      "",
      undefined
    );
    setIsLoading(false);
    if (createMsg === "") navigate("/clublogin");
    setErrMsg(createMsg);
  };

  return (
    <Box
      sx={{
        height: "100vh",
        pt: { xs: 2, sm: 7 },
      }}
    >
      <Container component="main">
        <CssBaseline />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: 1,
            backgroundColor: "rgba(255, 255, 255, 0.2)",
            pt: { xs: 2, sm: 3 },
          }}
        >
          <Link to="/">
            <LogoImg src={serviceLogo} />
          </Link>
          {/* <Typography component="h1" variant="h4" fontWeight={"bold"}>
            早稲田生活
          </Typography> */}
          <Typography fontWeight={"bold"}>代表者情報追加登録</Typography>
          <Box my={1}>
            <Typography color="red">{errMsg}</Typography>
          </Box>
          <Box sx={{ position: "absolute", top: { xs: 120, sm: 170 } }}>
            {isLoading ? <CircularProgress /> : <Box />}
          </Box>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ width: { xs: "300px", sm: "400px" } }}
          >
            <Alert sx={{ mb: { xs: 1 } }} severity="info">
              サークルログインのために代表者の情報を追加登録してください
            </Alert>
            <Typography>名前</Typography>
            <TextField
              sx={{ mt: { xs: 1 } }}
              margin="normal"
              required
              fullWidth
              id="name"
              name="name"
              autoFocus
            />
            <Typography>学年</Typography>
            <SelectFromList
              label=""
              data={gradeData}
              onChange={(val) => {
                setGrade(val + 1); // +1してアプリの定義データ(src/context/context.tsx)に合わせる
              }}
            />
            <Typography>学部</Typography>
            <SelectFromList
              label=""
              data={studentFacultyData}
              onChange={(val) => {
                setFaculty(val + 1); // +1してアプリの定義データ(src/context/context.tsx)に合わせる
              }}
            />
            <Typography>代表者メールアドレス</Typography>
            <TextField
              sx={{ mt: { xs: 1 } }}
              margin="normal"
              required
              fullWidth
              name="studentEmail"
              type="email"
              id="studentEmail"
              value={user?.auth.email}
              disabled
            />
            <Typography>サークルアカウントのパスワード</Typography>
            <TextField
              sx={{ mt: { xs: 1 } }}
              margin="normal"
              required
              fullWidth
              name="password"
              type="password"
              id="password"
              autoComplete="new-password"
            />
            <Button
              type="submit"
              fullWidth
              disableElevation
              variant="contained"
              sx={{
                my: 2,
                backgroundColor: "#2a2a2a",
                "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.6)" },
              }}
            >
              登録
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
