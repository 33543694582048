import React, { useEffect, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {
  Backdrop,
  Box,
  CircularProgress,
  Divider,
  ListSubheader,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { styled } from "styled-components";
import ListItemIcon from "@mui/material/ListItemIcon";
import { ArrowForwardIos } from "@mui/icons-material";
import ChatIcon from "@mui/icons-material/Chat";
import noimage from "../../assets/noimage.jpg";
import {
  fetchUniversitiesForUser,
  getAdditionalCircleList,
  getAdditionalCompanyList,
  getAdditionalJobStudentList,
  getAdditionalStudentList,
  getCircleList,
  getCompanyList,
  getJobStudentList,
  getStudentList,
} from "hooks/firebase/context/oemInfo";
import { useUser } from "hooks/firebase/context/auth";
import { studentSimpleInfoTypes } from "types/page/studentSimpleInfoTypes";
import {
  graduatedMonthData,
  graduatedYearData,
  studentDepartmentData,
  studentFacultyData,
} from "data/StudentProfileData";
import { clubSimpleInfoTypes } from "types/page/clubSimpleInfoTypes";
import { companyPrivateType } from "types/page/companyAccountType";
import { getDownLoadUrlFromFullPath } from "hooks/firebase/context/storage";
import { OemHeader } from "components/OemHeader";
import { oemUniversityData } from "data/OEMData";
import { SelectChangeEvent } from "@mui/material/Select";
import { jobStudentInfoType } from "types/page/jobStudentInfoType";
import {
  jobData,
  businessData,
  prefectureData,
  companySizeData,
} from "data/CompanyUserData";

type CompanyChat = {
  id: number;
  name: string;
};
type StudentChat = {
  id: number;
  name: string;
};

type Category = {
  id: number;
  name: string;
};

const categories: Category[] = [
  { id: 1, name: "学生" },
  { id: 2, name: "企業" },
  { id: 3, name: "サークル" },
  { id: 4, name: "ES登録学生" },
];
const InfoValue = styled(Typography)``;
const companyChatList: CompanyChat[] = [
  {
    id: 1,
    name: "田中圭一",
  },
  {
    id: 2,
    name: "公認サークル",
  },
  {
    id: 3,
    name: "株式会社Lightroads",
  },
];
const studentChatList: StudentChat[] = [
  {
    id: 1,
    name: "田中圭一",
  },
  {
    id: 2,
    name: "公認サークル",
  },
  {
    id: 3,
    name: "鈴木忠吉",
  },
];

const StudentListApp = () => {
  const user = useUser();
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(
    null
  );
  const [selectedStudent, setSelectedStudent] =
    useState<studentSimpleInfoTypes | null>(null);
  const [selectedClub, setSelectedClub] = useState<clubSimpleInfoTypes | null>(
    null
  );
  const [selectedJobStudent, setSelectedJobStudent] =
    useState<jobStudentInfoType | null>(null);
  const [oemStudent, setOemStudent] = useState<studentSimpleInfoTypes[] | null>(
    null
  );
  const [oemClub, setOemClub] = useState<clubSimpleInfoTypes[] | null>(null);
  const [oemCompany, setOemCompany] = useState<companyPrivateType[] | null>(
    null
  );
  const [companyIconPhtImage, setCompanyIconPhtImage] = useState("");
  const [companyCoverPhtImage, setCompanyCoverPhtImage] = useState("");
  const [companyPr1PhtImage, setCompanyPr1PhtImage] = useState("");
  const [companyPr2PhtImage, setCompanyPr2PhtImage] = useState("");
  const [clubIconPhtLink, setClubIconPhtLink] = useState("");
  const [clubCoverPhtLink, setClubCoverPhtLink] = useState("");
  const [studentIconPhtLink, setStudentIconPhtLink] = useState("");
  const [universityNumber, setUniversityNumber] = useState<number[]>([]);
  const [open, setOpen] = useState(false);
  const [selectedUniversityNumber, setSelectedUniversityNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [oemCompanyLastVisible, setOemCompanyLastVisible] = useState<any>(null);
  const [oemStudentLastVisible, setOemStudentLastVisible] = useState<any>(null);
  const [oemClubLastVisible, setOemClubLastVisible] = useState<any>(null);
  const [oemJobStudentLastVisible, setOemJobStudentLastVisible] =
    useState<any>(null);
  const [oemJobStudent, setOemJobStudent] = useState<jobStudentInfoType[]>([]);
  const [isEndCompany, setIsEndCompany] = useState(true);
  const [isEndStudent, setIsEndStudent] = useState(true);
  const [isEndClub, setIsEndClub] = useState(true);
  const [isEndJobStudent, setIsEndJobStudent] = useState(true);
  const [jobStudentIconPhtLink, setJobStudentIconPhtLink] = useState("");

  const handleCategoryClick = (category: Category) => {
    setSelectedCategory(category);
  };

  const handleStudentClick = (student: studentSimpleInfoTypes) => {
    setSelectedCompany(null);
    setSelectedClub(null);
    setSelectedStudent(student);
    setSelectedJobStudent(null);
  };

  const [selectedCompany, setSelectedCompany] =
    useState<companyPrivateType | null>(null);

  const handleCompanyClick = (company: companyPrivateType) => {
    setSelectedStudent(null);
    setSelectedClub(null);
    setSelectedCompany(company);
    setSelectedJobStudent(null);
  };

  const handleClubClick = (club: clubSimpleInfoTypes) => {
    setSelectedStudent(null);
    setSelectedCompany(null);
    setSelectedClub(club);
    setSelectedJobStudent(null);
  };
  const handleJobStudentClick = (jobStudent: jobStudentInfoType) => {
    setSelectedStudent(null);
    setSelectedCompany(null);
    setSelectedClub(null);
    setSelectedJobStudent(jobStudent);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (user) {
        setIsLoading(true);
        const universityData = await fetchUniversitiesForUser(user.auth.uid);
        setUniversityNumber(universityData.map(Number));
        const results = await Promise.all([
          getCircleList(user?.auth.uid, Number(universityData[0])),
          getStudentList(user?.auth.uid, Number(universityData[0])),
          getCompanyList(user?.auth.uid, Number(universityData[0])),
          getJobStudentList(user?.auth.uid, Number(universityData[0])),
        ]);
        const circles = results[0];
        const students = results[1];
        const companies = results[2];
        const jobStudent = results[3];
        setOemStudent(students.studentList);
        setOemClub(circles.circleList);
        setOemCompany(companies.companyList);
        setOemJobStudent(jobStudent.circleList);

        if (students.studentList.length === 10) {
          setIsEndStudent(false);
        } else {
          setIsEndStudent(true);
        }
        if (circles.circleList.length === 10) {
          setIsEndClub(false);
        } else {
          setIsEndClub(true);
        }
        if (companies.companyList.length === 10) {
          setIsEndCompany(false);
        } else {
          setIsEndCompany(true);
        }
        if (jobStudent.circleList.length === 10) {
          setIsEndJobStudent(false);
        } else {
          setIsEndJobStudent(true);
        }

        setIsLoading(false);
      }
    };
    fetchData();
  }, [user]);
  const getNextCompanyList = async () => {
    if (user) {
      setIsLoading(true);
      const companies = await getAdditionalCompanyList(
        user?.auth.uid,
        Number(selectedUniversityNumber),
        oemCompanyLastVisible
      );
      if (companies.companyList.length === 10) {
        setIsEndCompany(false);
      } else {
        setIsEndCompany(true);
      }
      setOemCompany([...(oemCompany || []), ...companies.companyList]);
      setOemCompanyLastVisible(companies.lastVisible);
      setIsLoading(false);
    }
  };
  const getNextStudentList = async () => {
    if (user) {
      setIsLoading(true);
      const students = await getAdditionalStudentList(
        user?.auth.uid,
        Number(selectedUniversityNumber),
        oemStudentLastVisible
      );
      if (students.companyList.length === 10) {
        setIsEndStudent(false);
      } else {
        setIsEndStudent(true);
      }
      setOemStudent([...(oemStudent || []), ...students.companyList]);
      setOemStudentLastVisible(students.lastVisible);
      setIsLoading(false);
    }
  };
  const getNextClubList = async () => {
    if (user) {
      setIsLoading(true);
      const clubs = await getAdditionalCircleList(
        user?.auth.uid,
        Number(selectedUniversityNumber),
        oemClubLastVisible
      );
      if (clubs.circleList.length === 10) {
        setIsEndClub(false);
      } else {
        setIsEndClub(true);
      }
      setOemClub([...(oemClub || []), ...clubs.circleList]);
      setOemClubLastVisible(clubs.lastVisible);
      setIsLoading(false);
    }
  };
  const getNextJobStudentList = async () => {
    if (user) {
      setIsLoading(true);
      const students = await getAdditionalJobStudentList(
        user?.auth.uid,
        Number(selectedUniversityNumber),
        oemJobStudentLastVisible
      );
      if (students.companyList.length === 10) {
        setIsEndJobStudent(false);
      } else {
        setIsEndJobStudent(true);
      }
      setOemJobStudent([...(oemJobStudent || []), ...students.companyList]);
      setOemJobStudentLastVisible(students.lastVisible);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (universityNumber.length > 0 && selectedUniversityNumber === "") {
      setSelectedUniversityNumber(String(universityNumber[0]));
    }
    const fetchData = async () => {
      if (user) {
        setIsLoading(true);
        const results = await Promise.all([
          getCircleList(user?.auth.uid, Number(selectedUniversityNumber)),
          getStudentList(user?.auth.uid, Number(selectedUniversityNumber)),
          getCompanyList(user?.auth.uid, Number(selectedUniversityNumber)),
          getJobStudentList(user?.auth.uid, Number(selectedUniversityNumber)),
        ]);
        const circles = results[0];
        const students = results[1];
        const companies = results[2];
        const jobStudent = results[3];

        setOemStudent(students.studentList);
        setOemClub(circles.circleList);
        setOemCompany(companies.companyList);
        setOemJobStudent(jobStudent.circleList);
        setOemStudentLastVisible(students.lastVisible);
        setOemClubLastVisible(circles.lastVisible);
        setOemCompanyLastVisible(companies.lastVisible);
        setOemJobStudentLastVisible(jobStudent.lastVisible);

        if (students.studentList.length === 10) {
          setIsEndStudent(false);
        } else {
          setIsEndStudent(true);
        }
        if (circles.circleList.length === 10) {
          setIsEndClub(false);
        } else {
          setIsEndClub(true);
        }
        if (companies.companyList.length === 10) {
          setIsEndCompany(false);
        } else {
          setIsEndCompany(true);
        }
        if (jobStudent.circleList.length === 10) {
          setIsEndJobStudent(false);
        } else {
          setIsEndJobStudent(true);
        }
        setIsLoading(false);
      }
    };
    fetchData();
  }, [universityNumber, selectedUniversityNumber, user]);
  const getCompanyIconPhtLink = async (storagePath: string) => {
    try {
      const downloadUrl = await getDownLoadUrlFromFullPath(storagePath);
      if (downloadUrl) setCompanyIconPhtImage(downloadUrl);
      return downloadUrl;
    } catch (error) {
      console.error("Error fetching download URL: ", error);
      return null;
    }
  };
  const getCompanyCoverPhtLink = async (storagePath: string) => {
    try {
      const downloadUrl = await getDownLoadUrlFromFullPath(storagePath);
      if (downloadUrl) setCompanyCoverPhtImage(downloadUrl);
      return downloadUrl;
    } catch (error) {
      console.error("Error fetching download URL: ", error);
      return null;
    }
  };
  const getCompanyPr1PhtLink = async (storagePath: string) => {
    try {
      const downloadUrl = await getDownLoadUrlFromFullPath(storagePath);
      if (downloadUrl) setCompanyPr1PhtImage(downloadUrl);
      return downloadUrl;
    } catch (error) {
      console.error("Error fetching download URL: ", error);
      return null;
    }
  };
  const getCompanyPr2PhtLink = async (storagePath: string) => {
    try {
      const downloadUrl = await getDownLoadUrlFromFullPath(storagePath);
      if (downloadUrl) setCompanyPr2PhtImage(downloadUrl);
      return downloadUrl;
    } catch (error) {
      console.error("Error fetching download URL: ", error);
      return null;
    }
  };
  const getClubIconPhtLink = async (storagePath: string) => {
    try {
      const downloadUrl = await getDownLoadUrlFromFullPath(storagePath);
      if (downloadUrl) setClubIconPhtLink(downloadUrl);
      return downloadUrl;
    } catch (error) {
      console.error("Error fetching download URL: ", error);
      return null;
    }
  };
  const getClubCoverPhtLink = async (storagePath: string) => {
    try {
      const downloadUrl = await getDownLoadUrlFromFullPath(storagePath);
      if (downloadUrl) setClubCoverPhtLink(downloadUrl);
      return downloadUrl;
    } catch (error) {
      console.error("Error fetching download URL: ", error);
      return null;
    }
  };
  const getStudentIconPhtLink = async (storagePath: string) => {
    try {
      const downloadUrl = await getDownLoadUrlFromFullPath(storagePath);
      if (downloadUrl) setStudentIconPhtLink(downloadUrl);
      return downloadUrl;
    } catch (error) {
      console.error("Error fetching download URL: ", error);
      return null;
    }
  };
  const getJobStudentIconPhtLink = async (storagePath: string) => {
    try {
      const downloadUrl = await getDownLoadUrlFromFullPath(storagePath);
      if (downloadUrl) setJobStudentIconPhtLink(downloadUrl);
      return downloadUrl;
    } catch (error) {
      console.error("Error fetching download URL: ", error);
      return null;
    }
  };

  useEffect(() => {
    if (selectedCompany?.iconPhtUrl) {
      getCompanyIconPhtLink(selectedCompany.iconPhtUrl).then((url) => {
        if (url) {
          setCompanyIconPhtImage(url);
        }
      });
    } else {
      setCompanyIconPhtImage("");
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (selectedCompany?.coverPhtUrl) {
      getCompanyCoverPhtLink(selectedCompany.coverPhtUrl).then((url) => {
        if (url) {
          setCompanyCoverPhtImage(url);
        }
      });
    } else {
      setCompanyCoverPhtImage("");
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (selectedCompany?.PRPhtUrl1) {
      getCompanyPr1PhtLink(selectedCompany.PRPhtUrl1).then((url) => {
        if (url) {
          setCompanyPr1PhtImage(url);
        }
      });
    } else {
      setCompanyPr1PhtImage("");
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (selectedCompany?.PRPhtUrl2) {
      getCompanyPr2PhtLink(selectedCompany.PRPhtUrl2).then((url) => {
        if (url) {
          setCompanyPr2PhtImage(url);
        }
      });
    } else {
      setCompanyPr2PhtImage("");
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (selectedClub?.iconPhtUrl) {
      getClubIconPhtLink(selectedClub.iconPhtUrl).then((url) => {
        if (url) {
          setClubIconPhtLink(url);
        }
      });
    } else {
      setClubIconPhtLink("");
    }
  }, [selectedClub]);

  useEffect(() => {
    if (selectedClub?.coverPhtUrl) {
      getClubCoverPhtLink(selectedClub.coverPhtUrl).then((url) => {
        if (url) {
          setClubCoverPhtLink(url);
        }
      });
    } else {
      setClubCoverPhtLink("");
    }
  }, [selectedClub]);

  useEffect(() => {
    if (selectedStudent?.iconPhtUrl) {
      getStudentIconPhtLink(selectedStudent?.iconPhtUrl).then((url) => {
        if (url) {
          setStudentIconPhtLink(url);
        }
      });
    } else {
      setStudentIconPhtLink("");
    }
  }, [selectedStudent]);

  useEffect(() => {
    if (selectedJobStudent?.iconPhtUrl) {
      getJobStudentIconPhtLink(selectedJobStudent?.iconPhtUrl).then((url) => {
        if (url) {
          setJobStudentIconPhtLink(url);
        }
      });
    } else {
      setJobStudentIconPhtLink("");
    }
  }, [selectedJobStudent]);

  const toggleDrawer = () => {
    setOpen(!open);
  };
  const mainStyles = {
    width: open ? "calc(100vw - 240px)" : "calc(100vw - 72px)",
    marginLeft: open ? "240px" : "72px",
    transition: ".3s",
    overflow: "auto",
  };
  return (
    <Box>
      <OemHeader open={open} toggleDrawer={toggleDrawer} />
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        component="main"
        sx={{
          ...mainStyles,
        }}
      >
        <Typography fontSize={"1.8rem"} fontWeight={"bold"} mt={12} ml={"10%"}>
          ユーザーデータベース
        </Typography>
        <Box
          sx={{
            width: "95%",
            justifyContent: "flex-end",
            display: "flex",
          }}
        >
          <Select
            value={selectedUniversityNumber}
            onChange={(event: SelectChangeEvent<string>) => {
              setSelectedUniversityNumber(String(event.target.value));
            }}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
          >
            {oemUniversityData
              .filter((university) =>
                universityNumber.includes(Number(university.key))
              )
              .map((filteredUniversity) => (
                <MenuItem
                  key={filteredUniversity.key}
                  value={filteredUniversity.key}
                >
                  {filteredUniversity.value}
                </MenuItem>
              ))}
          </Select>
        </Box>
        <StyledBox>
          <StyledInnerBox>
            <StyledList
              subheader={
                <StyledListSubheader
                  sx={{
                    borderRadius: "30px 0 0 0",
                  }}
                >
                  カテゴリー
                </StyledListSubheader>
              }
            >
              {categories.map((category) => (
                <ListItem
                  button
                  key={category.id}
                  onClick={() => handleCategoryClick(category)}
                  sx={{ pr: 0 }}
                >
                  <ListItemText primary={category.name} />
                  <ListItemIcon sx={{ marginRight: -3 }}>
                    <ArrowForwardIos fontSize={"small"} />
                  </ListItemIcon>
                </ListItem>
              ))}
            </StyledList>
            <Divider orientation="vertical" flexItem />
            <StyledList
              subheader={<StyledListSubheader>ユーザー名</StyledListSubheader>}
            >
              {selectedCategory &&
                selectedCategory.name === "学生" &&
                oemStudent &&
                oemStudent.map((student, index) => (
                  <ListItem
                    button
                    key={index}
                    onClick={() => handleStudentClick(student)}
                    sx={{ pr: 0 }}
                  >
                    <ListItemText primary={student.name} />
                    <ListItemIcon sx={{ marginRight: -3 }}>
                      <ArrowForwardIos fontSize={"small"} />
                    </ListItemIcon>
                  </ListItem>
                ))}
              {selectedCategory &&
                selectedCategory.name === "学生" &&
                !isEndStudent && (
                  <ListItem
                    button
                    onClick={() => getNextStudentList()}
                    sx={{ pr: 0 }}
                  >
                    <ListItemText
                      sx={{ color: "blue" }}
                      primary={"さらに表示"}
                    />
                    <ListItemIcon sx={{ marginRight: -3 }}>
                      <ArrowForwardIos
                        fontSize={"small"}
                        sx={{ color: "blue" }}
                      />
                    </ListItemIcon>
                  </ListItem>
                )}
              {selectedCategory &&
                selectedCategory.name === "企業" &&
                oemCompany &&
                oemCompany.map((company, index) => (
                  <ListItem
                    button
                    key={index}
                    onClick={() => handleCompanyClick(company)}
                    sx={{ pr: 0 }}
                  >
                    <ListItemText primary={company.name} />
                    <ListItemIcon sx={{ marginRight: -3 }}>
                      <ArrowForwardIos fontSize={"small"} />
                    </ListItemIcon>
                  </ListItem>
                ))}
              {selectedCategory &&
                selectedCategory.name === "企業" &&
                !isEndCompany && (
                  <ListItem
                    button
                    onClick={() => getNextCompanyList()}
                    sx={{ pr: 0 }}
                  >
                    <ListItemText
                      sx={{ color: "blue" }}
                      primary={"さらに表示"}
                    />

                    <ListItemIcon sx={{ marginRight: -3 }}>
                      <ArrowForwardIos
                        fontSize={"small"}
                        sx={{ color: "blue" }}
                      />
                    </ListItemIcon>
                  </ListItem>
                )}
              {selectedCategory &&
                selectedCategory.name === "サークル" &&
                oemClub &&
                oemClub.map((club, index) => (
                  <ListItem
                    button
                    key={index}
                    onClick={() => handleClubClick(club)}
                    sx={{ pr: 0 }}
                  >
                    <ListItemText primary={club.name} />
                    <ListItemIcon sx={{ marginRight: -3 }}>
                      <ArrowForwardIos fontSize={"small"} />
                    </ListItemIcon>
                  </ListItem>
                ))}
              {selectedCategory &&
                selectedCategory.name === "サークル" &&
                !isEndClub && (
                  <ListItem
                    button
                    onClick={() => getNextClubList()}
                    sx={{ pr: 0 }}
                  >
                    <ListItemText
                      sx={{ color: "blue" }}
                      primary={"さらに表示"}
                    />

                    <ListItemIcon sx={{ marginRight: -3 }}>
                      <ArrowForwardIos
                        fontSize={"small"}
                        sx={{ color: "blue" }}
                      />
                    </ListItemIcon>
                  </ListItem>
                )}
              {selectedCategory &&
                selectedCategory.name === "ES登録学生" &&
                oemJobStudent &&
                oemJobStudent.map((student, index) => (
                  <ListItem
                    button
                    key={index}
                    onClick={() => handleJobStudentClick(student)}
                    sx={{ pr: 0 }}
                  >
                    <ListItemText
                      primary={student.lastName + student.firstName}
                    />
                    <ListItemIcon sx={{ marginRight: -3 }}>
                      <ArrowForwardIos fontSize={"small"} />
                    </ListItemIcon>
                  </ListItem>
                ))}
              {selectedCategory &&
                selectedCategory.name === "ES登録学生" &&
                !isEndJobStudent && (
                  <ListItem
                    button
                    onClick={() => getNextJobStudentList()}
                    sx={{ pr: 0 }}
                  >
                    <ListItemText
                      sx={{ color: "blue" }}
                      primary={"さらに表示"}
                    />

                    <ListItemIcon sx={{ marginRight: -3 }}>
                      <ArrowForwardIos
                        fontSize={"small"}
                        sx={{ color: "blue" }}
                      />
                    </ListItemIcon>
                  </ListItem>
                )}
            </StyledList>

            <Divider orientation="vertical" flexItem />
            <StyledDetailsBox>
              <StyledHeaderBox>
                <StyledHeaderText>ユーザー情報</StyledHeaderText>
              </StyledHeaderBox>
              {selectedStudent &&
              !selectedClub &&
              !selectedJobStudent &&
              !selectedCompany ? (
                <Box p={3}>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      maxHeight: 427,
                      overflow: "scroll",
                    }}
                  >
                    <Box sx={{ flex: 2 }}>
                      <Typography mb={2} fontWeight={"bold"}>
                        アイコン画像
                      </Typography>
                      <img
                        src={studentIconPhtLink ? studentIconPhtLink : noimage}
                        alt={"studentIcon"}
                        loading="lazy"
                        style={{
                          width: "100px",
                          height: "100px",
                          borderRadius: "50px",
                          objectFit: "cover",
                          marginBottom: "20px",
                        }}
                      />
                      <InfoBox>
                        <InfoLabel>名前</InfoLabel>
                        <InfoValue>{selectedStudent.name}</InfoValue>
                      </InfoBox>
                      <InfoBox>
                        <InfoLabel sx={{ minWidth: 120 }}>
                          メールアドレス
                        </InfoLabel>
                        <InfoValue>{selectedStudent.address}</InfoValue>
                      </InfoBox>
                      <InfoBox>
                        <InfoLabel>学部</InfoLabel>
                        <InfoValue>
                          {studentDepartmentData[selectedStudent.faculty].value}
                        </InfoValue>
                      </InfoBox>
                      <InfoBox>
                        <InfoLabel>学年</InfoLabel>
                        <InfoValue>{selectedStudent.grade}</InfoValue>
                      </InfoBox>
                      <InfoBox>
                        <InfoLabel>選考中の企業</InfoLabel>
                        <InfoValue>
                          0{/* {selectedStudent.recrutingCompanyNum} */}
                        </InfoValue>
                      </InfoBox>
                      <InfoBox>
                        <InfoLabel>受信オファー数</InfoLabel>
                        <InfoValue>0</InfoValue>
                      </InfoBox>
                    </Box>
                    <Box
                      sx={{ flex: 1, textAlign: { md: "start", lg: "center" } }}
                    >
                      <Box my={3}>
                        <Typography fontWeight={"bold"}>
                          最終ログイン時刻
                        </Typography>
                        <Typography>
                          {selectedStudent.updatedAt.toDate().toLocaleString()}
                        </Typography>
                      </Box>
                      <Typography fontWeight={"bold"}>チャット</Typography>
                      <Box
                        sx={{
                          backgroundColor: "lightgray",
                          borderRadius: 5,
                          mt: 1,
                          width: 220,
                          p: 0,
                        }}
                      >
                        <List
                          sx={{
                            overflowY: "scroll",
                            height: 240,
                            p: 0,
                            m: 0,
                          }}
                        >
                          {companyChatList.map((student) => (
                            <ListItem
                              button
                              key={student.id}
                              sx={{
                                pr: 0,
                              }}
                            >
                              <ListItemText primary={student.name} />
                              <ListItemIcon sx={{ marginRight: 0 }}>
                                <ChatIcon fontSize={"small"} />
                              </ListItemIcon>
                            </ListItem>
                          ))}
                        </List>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              ) : !selectedStudent &&
                !selectedClub &&
                !selectedJobStudent &&
                selectedCompany ? (
                <Box p={3}>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      maxHeight: "428px",
                      overflow: "scroll",
                    }}
                  >
                    <Box sx={{ flex: 2 }}>
                      <Typography mb={2} fontWeight={"bold"}>
                        アイコン画像
                      </Typography>
                      <img
                        src={
                          companyIconPhtImage ? companyIconPhtImage : noimage
                        }
                        alt={"companyProfile"}
                        loading="lazy"
                        style={{
                          width: "100px",
                          height: "100px",
                          borderRadius: "10px",
                          objectFit: "cover",
                        }}
                      />
                      <Typography my={2} fontWeight={"bold"}>
                        カバー画像
                      </Typography>
                      <img
                        src={
                          companyCoverPhtImage ? companyCoverPhtImage : noimage
                        }
                        alt={"companyProfile"}
                        loading="lazy"
                        style={{
                          width: "240px",
                          height: "120px",
                          borderRadius: "10px",
                          objectFit: "cover",
                        }}
                      />
                      <Box mb={2}>
                        <Typography my={2} fontWeight={"bold"}>
                          PR画像1
                        </Typography>
                        <img
                          src={
                            companyPr1PhtImage ? companyPr1PhtImage : noimage
                          }
                          alt={"companyPr1"}
                          loading="lazy"
                          style={{
                            width: "240px",
                            height: "120px",
                            borderRadius: "10px",
                            objectFit: "cover",
                          }}
                        />
                        <Typography my={2} fontWeight={"bold"}>
                          PR画像2
                        </Typography>
                        <img
                          src={
                            companyPr2PhtImage ? companyPr2PhtImage : noimage
                          }
                          alt={"companyPr2"}
                          loading="lazy"
                          style={{
                            width: "240px",
                            height: "120px",
                            borderRadius: "10px",
                            objectFit: "cover",
                          }}
                        />
                      </Box>
                      <InfoBox>
                        <InfoLabel>名前</InfoLabel>
                        <InfoValue>{selectedCompany.name}</InfoValue>
                      </InfoBox>
                      <InfoBox>
                        <InfoLabel>メールアドレス</InfoLabel>
                        <InfoValue>{selectedCompany.email}</InfoValue>
                      </InfoBox>
                      <InfoBox>
                        <InfoLabel>電話番号</InfoLabel>
                        <InfoValue>{selectedCompany.phone}</InfoValue>
                      </InfoBox>
                      <InfoBox>
                        <InfoLabel>担当者名</InfoLabel>
                        <InfoValue>{selectedCompany.pic}</InfoValue>
                      </InfoBox>
                      <InfoBox>
                        <InfoLabel>登録日</InfoLabel>
                        <InfoValue>
                          {/* {selectedCompany.createdAt.toDate().toLocaleString()} */}
                        </InfoValue>
                      </InfoBox>
                      <InfoBox>
                        <InfoLabel>選考中の学生</InfoLabel>
                        <InfoValue>
                          0{/* {selectedCompany.recrutingStudentNum} */}
                        </InfoValue>
                      </InfoBox>
                      <InfoBox>
                        <InfoLabel>協賛中の団体</InfoLabel>
                        <InfoValue>
                          0{/* {selectedCompany.SponsorshipClubNum} */}
                        </InfoValue>
                      </InfoBox>
                      <InfoBox>
                        <InfoLabel>作成した求人</InfoLabel>
                        <InfoValue>0</InfoValue>
                      </InfoBox>
                      <InfoBox>
                        <InfoLabel>掲載した求人</InfoLabel>
                        <InfoValue>0</InfoValue>
                      </InfoBox>
                    </Box>
                    <Box
                      sx={{ flex: 1, textAlign: { sm: "start", lg: "center" } }}
                    >
                      <Box my={3}>
                        <Typography fontWeight={"bold"}>
                          最終ログイン時刻
                        </Typography>
                        <Typography>
                          {/* {selectedCompany.updatedAt
                          ? selectedCompany.updatedAt.toDate().toLocaleString()
                            : ""} */}
                        </Typography>
                      </Box>
                      <Typography fontWeight={"bold"}>チャット</Typography>
                      <Box
                        sx={{
                          backgroundColor: "lightgray",
                          borderRadius: 5,
                          mt: 1,
                          width: 220,
                          p: 0,
                        }}
                      >
                        <List
                          sx={{
                            overflowY: "scroll",
                            height: 240,
                            p: 0,
                            m: 0,
                          }}
                        >
                          {studentChatList.map((student) => (
                            <ListItem
                              button
                              key={student.id}
                              sx={{
                                pr: 0,
                              }}
                            >
                              <ListItemText primary={student.name} />
                              <ListItemIcon sx={{ marginRight: 0 }}>
                                <ChatIcon fontSize={"small"} />
                              </ListItemIcon>
                            </ListItem>
                          ))}
                        </List>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              ) : !selectedStudent &&
                !selectedCompany &&
                !selectedJobStudent &&
                selectedClub ? (
                <Box p={3}>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      maxHeight: "428px",
                      overflow: "scroll",
                    }}
                  >
                    <Box sx={{ flex: 2 }}>
                      {/* <img
                      src={
                        companyIconPhtImage
                          ? companyIconPhtImage
                          : defaultProfileImage
                      }
                      alt={"companyProfile"}
                      loading="lazy"
                      style={{
                        width: "100px",
                        height: "100px",
                        borderRadius: "10px",
                        objectFit: "cover",
                      }}
                    />
                    <img
                      src={
                        companyCoverPhtImage
                          ? companyCoverPhtImage
                          : defaultProfileImage
                      }
                      alt={"companyProfile"}
                      loading="lazy"
                      style={{
                        width: "100px",
                        height: "100px",
                        borderRadius: "10px",
                        objectFit: "cover",
                        marginLeft: "20px",
                      }}
                    /> */}

                      {selectedClub.name && (
                        <InfoBox>
                          <InfoLabel>名前</InfoLabel>
                          <InfoValue>{selectedClub.name}</InfoValue>
                        </InfoBox>
                      )}
                      <Typography mb={2} fontWeight={"bold"}>
                        アイコン画像
                      </Typography>
                      <img
                        src={clubIconPhtLink ? clubIconPhtLink : noimage}
                        alt={"clubIcon"}
                        loading="lazy"
                        style={{
                          width: "100px",
                          height: "100px",
                          borderRadius: "50px",
                          objectFit: "cover",
                        }}
                      />
                      <Typography my={2} fontWeight={"bold"}>
                        カバー画像
                      </Typography>
                      <img
                        src={clubCoverPhtLink ? clubCoverPhtLink : noimage}
                        alt={"clubProfile"}
                        loading="lazy"
                        style={{
                          width: "300px",
                          height: "100px",
                          borderRadius: "10px",
                          objectFit: "cover",
                        }}
                      />

                      <InfoBox>
                        <InfoLabel>メールアドレス</InfoLabel>
                        <InfoValue>{selectedClub.address}</InfoValue>
                      </InfoBox>
                      <InfoBox>
                        <InfoLabel>登録日</InfoLabel>
                        <InfoValue>
                          {selectedClub.createdAt.toDate().toLocaleString()}
                        </InfoValue>
                      </InfoBox>
                      {selectedClub.owner && (
                        <InfoBox>
                          <InfoLabel>所有者</InfoLabel>
                          <InfoValue>{selectedClub.owner}</InfoValue>
                        </InfoBox>
                      )}

                      {selectedClub.activity && (
                        <InfoBox>
                          <InfoLabel>活動内容</InfoLabel>
                          <InfoValue>{selectedClub.activity}</InfoValue>
                        </InfoBox>
                      )}
                      {selectedClub.people && (
                        <InfoBox>
                          <InfoLabel>人数</InfoLabel>
                          <InfoValue>{selectedClub.people}</InfoValue>
                        </InfoBox>
                      )}
                      {selectedClub.place && (
                        <InfoBox>
                          <InfoLabel>場所</InfoLabel>
                          <InfoValue>{selectedClub.place}</InfoValue>
                        </InfoBox>
                      )}

                      {selectedClub.twitter && (
                        <InfoBox>
                          <InfoLabel>X</InfoLabel>
                          <InfoValue>{selectedClub.twitter}</InfoValue>
                        </InfoBox>
                      )}
                      {selectedClub.insta && (
                        <InfoBox>
                          <InfoLabel>Instagram</InfoLabel>
                          <InfoValue>{selectedClub.insta}</InfoValue>
                        </InfoBox>
                      )}
                      {selectedClub.lastStatement && (
                        <InfoBox>
                          <InfoLabel>最後に一言</InfoLabel>
                          <InfoValue>{selectedClub.lastStatement}</InfoValue>
                        </InfoBox>
                      )}

                      <InfoBox>
                        <InfoLabel>選考中の学生</InfoLabel>
                        <InfoValue>
                          0{/* {selectedCompany.recrutingStudentNum} */}
                        </InfoValue>
                      </InfoBox>
                      <InfoBox>
                        <InfoLabel>協賛中の団体</InfoLabel>
                        <InfoValue>
                          0{/* {selectedCompany.SponsorshipClubNum} */}
                        </InfoValue>
                      </InfoBox>
                      <InfoBox>
                        <InfoLabel>作成した求人</InfoLabel>
                        <InfoValue>0</InfoValue>
                      </InfoBox>
                      <InfoBox>
                        <InfoLabel>掲載した求人</InfoLabel>
                        <InfoValue>0</InfoValue>
                      </InfoBox>
                    </Box>
                    <Box
                      sx={{ flex: 1, textAlign: { sm: "start", lg: "center" } }}
                    >
                      <Box my={3}>
                        <Typography fontWeight={"bold"}>
                          最終ログイン時刻
                        </Typography>
                        <Typography></Typography>
                      </Box>
                      <Typography fontWeight={"bold"}>チャット</Typography>
                      <Box
                        sx={{
                          backgroundColor: "lightgray",
                          borderRadius: 5,
                          mt: 1,
                          width: 220,
                          p: 0,
                        }}
                      >
                        <List
                          sx={{
                            overflowY: "scroll",
                            height: 240,
                            p: 0,
                            m: 0,
                          }}
                        >
                          {studentChatList.map((student) => (
                            <ListItem
                              button
                              key={student.id}
                              sx={{
                                pr: 0,
                              }}
                            >
                              <ListItemText primary={student.name} />
                              <ListItemIcon sx={{ marginRight: 0 }}>
                                <ChatIcon fontSize={"small"} />
                              </ListItemIcon>
                            </ListItem>
                          ))}
                        </List>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              ) : !selectedStudent &&
                !selectedCompany &&
                !selectedClub &&
                selectedJobStudent ? (
                <Box px={3}>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      maxHeight: 427,
                      overflow: "scroll",
                    }}
                  >
                    <Box sx={{ width: "100%" }}>
                      <Box textAlign={"center"}>
                        <img
                          src={
                            jobStudentIconPhtLink
                              ? jobStudentIconPhtLink
                              : noimage
                          }
                          alt={"jobStudentIcon"}
                          loading="lazy"
                          style={{
                            width: "100px",
                            height: "100px",
                            borderRadius: "50px",
                            objectFit: "cover",
                            marginTop: "20px",
                            marginBottom: "10px",
                          }}
                        />
                        <Typography mb={2} fontSize={20} fontWeight={"bold"}>
                          {selectedJobStudent.lastName +
                            " " +
                            selectedJobStudent.firstName +
                            " (" +
                            selectedJobStudent.lastNameKana +
                            " " +
                            selectedJobStudent.firstNameKana +
                            ")"}
                        </Typography>
                      </Box>
                      <InfoBox>
                        <InfoLabel>メールアドレス</InfoLabel>
                        <InfoValue>{selectedJobStudent.mail}</InfoValue>
                      </InfoBox>

                      <InfoBox>
                        <InfoLabel>性別</InfoLabel>
                        <InfoValue>
                          {selectedJobStudent.sex === 0 ? "男性" : "女性"}
                        </InfoValue>
                      </InfoBox>
                      <InfoBox>
                        <InfoLabel>居住地域</InfoLabel>
                        <InfoValue>
                          {
                            prefectureData[selectedJobStudent.livingArea - 1]
                              .value
                          }
                        </InfoValue>
                      </InfoBox>
                      <InfoBox>
                        <InfoLabel>出身地</InfoLabel>
                        <InfoValue>
                          {
                            prefectureData[selectedJobStudent.birthPlace - 1]
                              .value
                          }
                        </InfoValue>
                      </InfoBox>
                      {selectedJobStudent.phoneNumber && (
                        <InfoBox>
                          <InfoLabel>電話番号</InfoLabel>
                          <InfoValue>
                            {selectedJobStudent.phoneNumber}
                          </InfoValue>
                        </InfoBox>
                      )}
                      {selectedJobStudent.graduatedHighSchool && (
                        <InfoBox>
                          <InfoLabel>出身高校</InfoLabel>
                          <InfoValue>
                            {selectedJobStudent.graduatedHighSchool}
                          </InfoValue>
                        </InfoBox>
                      )}
                      <InfoBox>
                        <InfoLabel>文理選択</InfoLabel>
                        <InfoValue>
                          {selectedJobStudent.humanitiesOrScience === 1
                            ? "文系"
                            : "理系"}
                        </InfoValue>
                      </InfoBox>
                      <InfoBox>
                        <InfoLabel>学年</InfoLabel>
                        <InfoValue>{selectedJobStudent.grade}年</InfoValue>
                      </InfoBox>
                      <InfoBox>
                        <InfoLabel>学部</InfoLabel>
                        <InfoValue>
                          {
                            studentFacultyData[selectedJobStudent.faculty - 1]
                              .value
                          }
                        </InfoValue>
                      </InfoBox>
                      <InfoBox>
                        <InfoLabel>学科</InfoLabel>
                        <InfoValue>
                          {selectedJobStudent.department !== 0
                            ? studentDepartmentData[
                                selectedJobStudent.department - 1
                              ].value
                            : "未選択"}
                        </InfoValue>
                      </InfoBox>
                      <InfoBox>
                        <InfoLabel>卒業予定年</InfoLabel>
                        <InfoValue>
                          {graduatedYearData[
                            selectedJobStudent.graduatedYear - 1
                          ].value +
                            "年" +
                            graduatedMonthData[
                              selectedJobStudent.graduatedMonth - 1
                            ].value +
                            "月"}
                        </InfoValue>
                      </InfoBox>
                      {selectedJobStudent.certification && (
                        <InfoBox>
                          <InfoLabel>資格</InfoLabel>
                          <InfoValue>
                            {selectedJobStudent.certification}
                          </InfoValue>
                        </InfoBox>
                      )}

                      <InfoBox>
                        <InfoLabel>プログラミング経験の有無</InfoLabel>
                        <InfoValue>
                          {selectedJobStudent.programmingExperience === 1
                            ? "有り"
                            : "無し"}
                        </InfoValue>
                      </InfoBox>
                      {selectedJobStudent.programmingExperience === 1 && (
                        <InfoBox>
                          <InfoLabel>プログラミング経験詳細</InfoLabel>
                          <InfoValue>
                            {selectedJobStudent.programmingExperienceDetail}
                          </InfoValue>
                        </InfoBox>
                      )}
                      {selectedJobStudent.circle && (
                        <InfoBox>
                          <InfoLabel>部活・サークル</InfoLabel>
                          <InfoValue>{selectedJobStudent.circle}</InfoValue>
                        </InfoBox>
                      )}
                      {selectedJobStudent.researchGroup && (
                        <InfoBox>
                          <InfoLabel>ゼミ・研究室</InfoLabel>
                          <InfoValue>
                            {selectedJobStudent.researchGroup}
                          </InfoValue>
                        </InfoBox>
                      )}
                      {selectedJobStudent.desiredFirstBusiness !== 0 && (
                        <InfoBox>
                          <InfoLabel>志望業界(第一志望)</InfoLabel>
                          <InfoValue>
                            {
                              businessData[
                                selectedJobStudent.desiredFirstBusiness - 1
                              ].value
                            }
                          </InfoValue>
                        </InfoBox>
                      )}
                      {selectedJobStudent.desiredSecondBusiness !== 0 && (
                        <InfoBox>
                          <InfoLabel>志望業界(第二志望)</InfoLabel>
                          <InfoValue>
                            {
                              businessData[
                                selectedJobStudent.desiredSecondBusiness - 1
                              ].value
                            }
                          </InfoValue>
                        </InfoBox>
                      )}
                      {selectedJobStudent.desiredThirdBusiness !== 0 && (
                        <InfoBox>
                          <InfoLabel>志望業界(第三志望)</InfoLabel>
                          <InfoValue>
                            {
                              businessData[
                                selectedJobStudent.desiredThirdBusiness - 1
                              ].value
                            }
                          </InfoValue>
                        </InfoBox>
                      )}
                      {selectedJobStudent.desiredFirstJob !== 0 && (
                        <InfoBox>
                          <InfoLabel>志望職種(第一志望)</InfoLabel>
                          <InfoValue>
                            {
                              jobData[selectedJobStudent.desiredFirstJob - 1]
                                .value
                            }
                          </InfoValue>
                        </InfoBox>
                      )}
                      {selectedJobStudent.desiredSecondJob !== 0 && (
                        <InfoBox>
                          <InfoLabel>志望職種(第二志望)</InfoLabel>
                          <InfoValue>
                            {
                              jobData[selectedJobStudent.desiredSecondJob - 1]
                                .value
                            }
                          </InfoValue>
                        </InfoBox>
                      )}
                      {selectedJobStudent.desiredThirdJob !== 0 && (
                        <InfoBox>
                          <InfoLabel>志望職種(第三志望)</InfoLabel>
                          <InfoValue>
                            {
                              jobData[selectedJobStudent.desiredThirdJob - 1]
                                .value
                            }
                          </InfoValue>
                        </InfoBox>
                      )}
                      {selectedJobStudent.internCompName && (
                        <InfoBox>
                          <InfoLabel>インターン先企業名</InfoLabel>
                          <InfoValue>
                            {selectedJobStudent.internCompName}
                          </InfoValue>
                        </InfoBox>
                      )}
                      {selectedJobStudent.internCompName && (
                        <InfoBox>
                          <InfoLabel>インターン先企業職種</InfoLabel>
                          <InfoValue>
                            {
                              jobData[selectedJobStudent.internJobType - 1]
                                .value
                            }
                          </InfoValue>
                        </InfoBox>
                      )}
                      {selectedJobStudent.internPeriod && (
                        <InfoBox>
                          <InfoLabel>インターン期間</InfoLabel>
                          <InfoValue>
                            {selectedJobStudent.internPeriod}
                          </InfoValue>
                        </InfoBox>
                      )}

                      {selectedJobStudent.companySize.length !== 0 && (
                        <InfoBox>
                          <InfoLabel>企業規模</InfoLabel>
                          <InfoValue>
                            {selectedJobStudent.companySize
                              .map((size) => companySizeData[size - 1].value)
                              .join(", ")}
                          </InfoValue>
                        </InfoBox>
                      )}
                      {selectedJobStudent.careerGoal && (
                        <InfoBox>
                          <InfoLabel>キャリアに求めること</InfoLabel>
                          <InfoValue>{selectedJobStudent.careerGoal}</InfoValue>
                        </InfoBox>
                      )}
                      {selectedJobStudent.selfPRGakuchika && (
                        <InfoBox>
                          <InfoLabel>学生時代に頑張ったこと</InfoLabel>
                          <InfoValue>
                            {selectedJobStudent.selfPRGakuchika}
                          </InfoValue>
                        </InfoBox>
                      )}
                      {selectedJobStudent.selfPRStrength && (
                        <InfoBox>
                          <InfoLabel>強み</InfoLabel>
                          <InfoValue>
                            {selectedJobStudent.selfPRStrength}
                          </InfoValue>
                        </InfoBox>
                      )}
                      {selectedJobStudent.selfPRMain && (
                        <InfoBox>
                          <InfoLabel>自己PR</InfoLabel>
                          <InfoValue>{selectedJobStudent.selfPRMain}</InfoValue>
                        </InfoBox>
                      )}
                      <InfoBox>
                        <InfoLabel>選考中の企業</InfoLabel>
                        <InfoValue>0</InfoValue>
                      </InfoBox>
                      <InfoBox>
                        <InfoLabel>受信オファー数</InfoLabel>
                        <InfoValue>0</InfoValue>
                      </InfoBox>
                      <Box my={3}>
                        <Typography fontWeight={"bold"}>
                          最終ログイン時刻
                        </Typography>
                        <Typography>
                          {selectedJobStudent.updatedAt
                            .toDate()
                            .toLocaleString()}
                        </Typography>
                      </Box>
                      <Typography fontWeight={"bold"}>チャット</Typography>
                      <Box
                        sx={{
                          backgroundColor: "lightgray",
                          borderRadius: 5,
                          mt: 1,
                          width: "90%",
                          marginLeft: "5%",
                          p: 0,
                        }}
                      >
                        <List
                          sx={{
                            overflowY: "scroll",
                            height: 240,
                            p: 0,
                            m: 0,
                          }}
                        >
                          {companyChatList.map((student) => (
                            <ListItem
                              button
                              key={student.id}
                              sx={{
                                pr: 0,
                              }}
                            >
                              <ListItemText primary={student.name} />
                              <ListItemIcon sx={{ marginRight: 0 }}>
                                <ChatIcon fontSize={"small"} />
                              </ListItemIcon>
                            </ListItem>
                          ))}
                        </List>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              ) : (
                <Box></Box>
              )}
            </StyledDetailsBox>
          </StyledInnerBox>
        </StyledBox>
      </Box>
    </Box>
  );
};

const StyledBox = styled(Box)`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  justify-content: center;
`;

const StyledInnerBox = styled(Box)`
  display: flex;
  height: 500px;
  width: 90%;
  margin-top: 2rem;
  border: 1px solid black;
  border-radius: 30px;
  background-color: #e9e9e9;
`;

const StyledList = styled(List)`
  width: 20%;
  max-height: 100%;
  overflow-y: scroll;
`;

const StyledDetailsBox = styled(Box)`
  width: 60%;
`;
const StyledListSubheader = styled(ListSubheader)`
  background-color: #525252 !important;
  color: white !important;
  text-align: center !important;
  font-size: 1.1rem !important;
`;
const StyledHeaderBox = styled.div`
  height: 48px;
  background-color: #525252;
  color: white;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 30px 0 0 !important;
`;

const StyledHeaderText = styled(Typography)`
  font-size: 1.1rem !important;
  color: white !important;
`;

const InfoBox = styled.div`
  display: flex;
  margin-bottom: 16px;
  flex-wrap: wrap;
`;

const InfoLabel = styled(Typography)`
  margin-right: 16px !important;
  font-weight: bold !important;
`;

export default StudentListApp;
