import { FieldValue } from "@firebase/firestore";
import { User } from "firebase/auth";
export type user = {
  id: string;
  name: string;
  photoURL: string;
  email: string;
};

export type user_private = {
  name: string;
  desc: string;
  owner: string;
  createdAt: FieldValue;
  updatedAt: FieldValue;
  address: string;
  coverPhtUrl: string;
  iconPhtUrl: string;
  activity: string;
  place: string;
  date: string;
  people: string;
  twitter: string;
  insta: string;
  lastStatement: string;
  largeGenre: number;
  smallGenre: number;
  feature: Array<any>;
};

export type user_public = {
  name: string;
  desc: string;
  createdAt: FieldValue;
  updatedAt: FieldValue;
  coverPhtUrl: string;
  iconPhtUrl: string;
  activity: string;
  place: string;
  date: string;
  people: string;
  twitter: string;
  insta: string;
  lastStatement: string;
  largeGenre: number;
  smallGenre: number;
  feature: Array<any>;
};

export type user_company_public = {
  name: string;
  createdAt: FieldValue;
  updatedAt: FieldValue;
  businessContent: string;
  prefecture: number; //都道府県
  addressDetail: string;
  listingDivision: number;
  companyURL: string;
  iconPhtUrl: string;
  coverPhtUrl: string;
  recruitCatchcopy: string;
  jobCategory: number[]; //職種カテゴリー
  PRPhtUrl1: string;
  PRPhtUrl2: string;
  companyCulture: string;
};

export type user_company_private = {
  email: string;
  name: string;
  createdAt: FieldValue;
  updatedAt: FieldValue;
  businessContent: string;
  prefecture: number;
  addressDetail: string; //市区町村以下の住所
  listingDivision: number;
  companyURL: string;
  pic: string; //担当者名
  phone: string;
  picRole: number; //担当者の役割(任意)
  iconPhtUrl: string;
  coverPhtUrl: string;
  recruitCatchcopy: string;
  jobCategory: number[]; //職種カテゴリー
  PRPhtUrl1: string;
  PRPhtUrl2: string;
  companyCulture: string;
  monthlyOffer: number; // 1ヶ月に作成したオファー数
  monthLimitOffer: number; //1ヶ月のオファー上限
};

export type student_user_private = {
  iconPhtUrl: string;
  grade: number;
  faculty: number;
  address: string;
  nickname: string;
  name: string;
  createdAt: FieldValue;
  updatedAt: FieldValue;
};

export type student_user_public = {
  iconPhtUrl: string;
  grade: number;
  faculty: number;
  nickname: string;
  name: string;
  createdAt: FieldValue;
  updatedAt: FieldValue;
};

export class UserWrap {
  auth: User;
  privateData: user_private;
  publicData: user_public;
  compPrivateData: user_company_private;
  compPublicData: user_company_public;

  constructor(
    auth: User,
    privateData: user_private,
    publicData: user_public,
    compPrivateData: user_company_private,
    compPublicData: user_company_public
  ) {
    this.auth = auth;
    this.privateData = privateData;
    this.publicData = publicData;
    this.compPrivateData = compPrivateData;
    this.compPublicData = compPublicData;
  }
}

export type requestSetToken = {
  uid: string;
  type: string;
};

export type club_user_private = {
  name: string;
  desc: string;
  owner: string;
  createdAt: FieldValue;
  updatedAt: FieldValue;
  address: string;
  coverPhtUrl: string;
  iconPhtUrl: string;
  activity: string;
  place: string;
  date: string;
  people: string;
  twitter: string;
  insta: string;
  lastStatement: string;
  largeGenre: number;
  smallGenre: number;
  feature: Array<any>;
};

export type club_user_public = {
  name: string;
  desc: string;
  createdAt: FieldValue;
  updatedAt: FieldValue;
  coverPhtUrl: string;
  iconPhtUrl: string;
  activity: string;
  place: string;
  date: string;
  people: string;
  twitter: string;
  insta: string;
  lastStatement: string;
  largeGenre: number;
  smallGenre: number;
  feature: Array<any>;
};

export type jobHuntingUserPublic = {
  iconPhtUrl: string;
  grade: number;
  university: number;
  faculty: number; //学部
  department: number; //学科
  firstName: string; //名前
  lastName: string; //苗字
  firstNameKana: string;
  lastNameKana: string;
  createdAt: FieldValue;
  updatedAt: FieldValue;
  sex: number;
  livingArea: number;
  birthPlace: number;
  graduatedHighSchool: string;
  certification: string;
  programmingExperience: number;
  programmingExperienceDetail: string;
  circle: string;
  researchGroup: string;
  desiredFirstBusiness: number;
  desiredSecondBusiness: number;
  desiredThirdBusiness: number;
  desiredFirstJob: number;
  desiredSecondJob: number;
  desiredThirdJob: number;
  choiceFirstStandard: number;
  choiceSecondStandard: number;
  choiceThirdStandard: number;
  graduatedYear: number;
  graduatedMonth: number;
  humanitiesOrScience: number;
  internCompName: string;
  internJobType: number;
  internPeriod: string;
  selfPRGakuchika: string;
  selfPRStrength: string;
  selfPRMain: string;
};

export type jobHuntingUserPrivate = {
  iconPhtUrl: string;
  grade: number;
  university: number;
  faculty: number;
  department: number;
  firstName: string;
  lastName: string;
  mail: string;
  firstNameKana: string;
  lastNameKana: string;
  createdAt: FieldValue;
  updatedAt: FieldValue;
  sex: number;
  livingArea: number;
  birthPlace: number;
  phoneNumber: string;
  graduatedHighSchool: string;
  certification: string;
  programmingExperience: number;
  programmingExperienceDetail: string;
  circle: string;
  researchGroup: string;
  desiredFirstBusiness: number;
  desiredSecondBusiness: number;
  desiredThirdBusiness: number;
  desiredFirstJob: number;
  desiredSecondJob: number;
  desiredThirdJob: number;
  choiceFirstStandard: number;
  choiceSecondStandard: number;
  choiceThirdStandard: number;
  graduatedYear: number;
  graduatedMonth: number;
  humanitiesOrScience: number;
  internCompName: string;
  internJobType: number;
  internPeriod: string;
  selfPRGakuchika: string;
  selfPRStrength: string;
  selfPRMain: string;
};
