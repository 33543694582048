import React, { useState, useEffect } from "react";
import { Button, Paper } from "@mui/material";
import { IconButton } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
// TypeScript interface for the image array
interface Image {
  src: string;
  alt: string;
}

type SlideshowImageProps = {
  pr1: string;
  pr2: string;
};

export default function Slideshow({ pr1, pr2 }: SlideshowImageProps) {
  // Image array with paths and alt text
  const images: Image[] = [
    { src: pr1, alt: "First Image" },
    { src: pr2, alt: "Second Image" },
  ];
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [currentIndex, setCurrentIndex] = useState(0);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    startSlideshow();
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, []);

  const startSlideshow = () => {
    const id = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);
    setIntervalId(id);
  };

  const goToIndex = (index: number) => {
    if (intervalId) clearInterval(intervalId);
    setCurrentIndex(index);
    startSlideshow();
  };

  return (
    <Paper
      elevation={0}
      style={{
        position: "relative",
        textAlign: "center",
        width: isSmallScreen ? "100%" : "60%",
        paddingBottom: -10,
      }}
    >
      <IconButton
        style={{
          position: "absolute",
          left: 10,
          top: "50%",
          transform: "translateY(-50%)",
          backgroundColor: "white",
          width: 35,
          height: 35,
        }}
        onClick={() =>
          goToIndex((currentIndex - 1 + images.length) % images.length)
        }
      >
        <ArrowBackIosIcon
          sx={{ position: "absolute", left: 10, fontSize: 24 }}
        />
      </IconButton>
      <img
        src={images[currentIndex].src}
        alt={images[currentIndex].alt}
        style={{ width: "100%", height: "100%", objectFit: "cover" }}
      />
      <IconButton
        style={{
          position: "absolute",
          right: 10,
          top: "50%",
          transform: "translateY(-50%)",
          backgroundColor: "white",
          width: 35,
          height: 35,
        }}
        onClick={() => goToIndex((currentIndex + 1) % images.length)}
      >
        <ArrowForwardIosIcon sx={{ fontSize: 24 }} />
      </IconButton>
    </Paper>
  );
}
