import * as React from "react";
import Box from "@mui/material/Box";
import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { studentInfoTypes } from "types/page/studentInfoTypes";
import { graduatedYearData } from "data/StudentProfileData";
import { clubInfoArticleType } from "types/page/clubInfoArticleType";

type StudentProfileProps = {
  imagePath?: string;
  clubProfileInfos: clubInfoArticleType;
};

export default function ClubProfileCard({
  imagePath,
  clubProfileInfos,
}: StudentProfileProps) {
  const [isFavorite, setIsFavorite] = React.useState(false);

  const navigate = useNavigate();
  const toggleFavorite = () => {
    setIsFavorite(!isFavorite);
  };
  const handleClick = () => {
    navigate("/clubinfoarticledetail", {
      state: {
        name: clubProfileInfos.name,
        activity: clubProfileInfos.activity,
        place: clubProfileInfos.place,
        date: clubProfileInfos.date,
        people: clubProfileInfos.people,
        twitter: clubProfileInfos.twitter,
        insta: clubProfileInfos.insta,
        lastStatement: clubProfileInfos.lastStatement,
        feature: clubProfileInfos.feature,
        updatedAt: clubProfileInfos.updatedAt,
        largeGenre: clubProfileInfos.largeGenre,
        smallGenre: clubProfileInfos.smallGenre,
      },
    });
  };
  return (
    <Card sx={{ width: 340, borderRadius: 5, my: 5 }}>
      <CardActions sx={{ pb: 0 }}>
        <IconButton
          aria-label="add to favorites"
          sx={{ ml: "auto" }}
          onClick={toggleFavorite}
        >
          <FontAwesomeIcon
            icon={faCircleCheck}
            color={isFavorite ? "green" : "lightgray"}
            size="lg"
          />
        </IconButton>
      </CardActions>
      <CardActionArea onClick={handleClick}>
        <Box sx={{ px: 3, pb: 3, pt: 1 }}>
          <Box sx={{ display: "flex" }}>
            <CardMedia
              component="img"
              sx={{
                height: 140,
                width: 140,
                objectFit: "cover",
                borderRadius: 70,
              }}
              image={clubProfileInfos.iconImage}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              {/* <IconButton sx={{ ml: "auto" }} onClick={toggleFavorite}>
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  color={isFavorite ? "green" : "lightgray"}
                  size="lg"
                />
              </IconButton> */}
              <CardContent sx={{ flex: "1 0 auto", width: "100%", pt: 0 }}>
                <Typography component="div" variant="h5" fontWeight={"bold"}>
                  {clubProfileInfos.name}
                </Typography>
              </CardContent>
            </Box>
          </Box>
          {/* <Stack
            direction="row"
            spacing={1}
            mt={1}
            px={2}
            maxWidth={400}
            overflow={"auto"}
          >
            {clubProfileInfos.programmingExperience === 0 ? (
              <Box />
            ) : (
              <Chip label={"プログラミング経験あり"} />
            )}
            {clubProfileInfos.internCompName === "" ? (
              <Box />
            ) : (
              <Chip label={"インターン経験あり"} />
            )}
          </Stack> */}
          <Box my={1}>
            <Divider />
          </Box>
          <Box sx={{ maxHeight: 195, overflow: "hidden" }}>
            <Typography>{clubProfileInfos.activity}</Typography>
          </Box>
        </Box>
      </CardActionArea>
    </Card>
  );
}
