import { Box, Button, IconButton, TextField, Typography } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CompanyHeader } from "components/CompanyHeader";
import { styled } from "styled-components";
import { Add, Remove } from "@mui/icons-material";
import serviceLogo from "assets/serviceWhiteLogo.png";
import PreviewSnackbar from "components/PreviewSnackbar";

const mdTheme = createTheme();

const Title = styled(Typography)`
  font-size: 22px !important;
  font-weight: bold !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
`;
const Subtitle = styled(Typography)`
  font-size: 16px !important;
`;

export default function ReverseOfferProfileSetup() {
  const navigate = useNavigate();
  const inputMovieRef = useRef<HTMLInputElement>(null!);

  const [open, setOpen] = useState(true);
  const [movieContent, setMovieContent] = useState<string | undefined>();
  const [textBoxes, setTextBoxes] = useState([""]);

  const addTextBox = () => {
    setTextBoxes([...textBoxes, ""]);
  };
  const removeTextBox = () => {
    if (textBoxes.length > 1) {
      setTextBoxes(textBoxes.slice(0, -1));
    }
  };
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const onMovieImage1ButtonClick = () => {
    inputMovieRef.current.click();
  };
  const onMovieInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    setMovieContent(window.URL.createObjectURL(e.target.files[0]));
  };
  const handleInputChange = (index: number, newValue: string) => {
    setTextBoxes(textBoxes.map((value, i) => (i === index ? newValue : value)));
  };
  const mainStyles = {
    width: open ? "calc(100vw - 240px)" : "calc(100vw - 72px)",
    marginLeft: open ? "240px" : "72px",
    transition: ".3s",
    overflow: "auto",
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <CompanyHeader
          open={open}
          toggleDrawer={toggleDrawer}
          showofferModalProps={true}
        />
        <Box component="main" sx={{ ...mainStyles }}>
          <PreviewSnackbar open={open} />
          <Box sx={{ display: "flex", mt: 10, px: 5, pb: 3 }}>
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Title sx={{ textAlign: "center" }}>
                逆求人用プロフィール設定
              </Title>
              <Box
                sx={{
                  width: "80%",
                  marginLeft: "10%",
                  border: "2px solid black",
                  borderRadius: 5,
                  px: 5,
                  pb: 3,
                }}
              >
                <Title>企業紹介動画</Title>
                <Subtitle>逆求人サービス専用の企業紹介動画です。</Subtitle>
                <Subtitle>
                  企業の魅力が伝わる動画を１〜2分程度で作成して掲載すると学生がイメージを持ちやすくなります。
                </Subtitle>
                <Box
                  mt={5}
                  mb={1}
                  sx={{
                    width: "100%",
                  }}
                >
                  <Button
                    variant="outlined"
                    component="span"
                    onClick={onMovieImage1ButtonClick}
                    sx={{
                      width: 110,
                      borderRadius: 1,
                      height: 35,
                      backgroundColor: "white",
                      border: "1px solid black",
                      color: "black",
                    }}
                  >
                    動画を選択
                  </Button>
                </Box>
                <Box
                  sx={{ width: "100%", marginBottom: 5, textAlign: "center" }}
                >
                  {movieContent && (
                    <video width="320" height="240" controls>
                      <source src={movieContent} type="video/mp4" />
                      お使いのブラウザはvideoタグをサポートしていません。
                    </video>
                  )}
                  <input
                    style={{ display: "none" }}
                    ref={inputMovieRef}
                    name="movieContent"
                    type="file"
                    accept="video/*"
                    onChange={onMovieInputChange}
                  />
                </Box>

                <Title>選考フロー</Title>
                <Subtitle>
                  選考フローを図式化して学生が選考イメージを持ちやすくします。
                </Subtitle>

                <Subtitle>
                  選考フローの図式化を用いることで、学生の応募への心理的ハードルを下げることができます。
                </Subtitle>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  {textBoxes.map((textBox, index) => (
                    <Box
                      sx={{
                        width: "40%",
                        mx: 3,
                        display: "flex",
                        alignItems: "center",
                        position: "relative",
                      }}
                    >
                      <TextField
                        key={index}
                        inputProps={{
                          style: { padding: "10px" },
                        }}
                        margin="normal"
                        fullWidth
                        name="companyURL"
                        value={textBox}
                        onChange={(e) =>
                          handleInputChange(index, e.target.value)
                        }
                      />
                      <img
                        src="images/recruitArrowIcon.png"
                        alt=""
                        width="24px"
                        style={{
                          marginTop: "8px",
                          position: "absolute",
                          right: -30,
                        }}
                      />
                    </Box>
                  ))}
                  <IconButton
                    aria-label="add"
                    onClick={addTextBox}
                    size="small"
                    sx={{
                      backgroundColor: "rgb(75, 75, 75)",
                      "&:hover": { backgroundColor: "rgb(75, 75, 75)" },
                      ml: 3,
                      mr: 2,
                      my: "auto",
                    }}
                  >
                    <Add sx={{ color: "white" }} />
                  </IconButton>
                  <IconButton
                    aria-label="remove"
                    onClick={removeTextBox}
                    size="small"
                    sx={{
                      my: "auto",
                      backgroundColor: "rgb(75, 75, 75)",
                      "&:hover": { backgroundColor: "rgb(75, 75, 75)" },
                    }}
                  >
                    <Remove sx={{ color: "white" }} />
                  </IconButton>
                </Box>
              </Box>
              <Box
                sx={{
                  width: "80%",
                  marginLeft: "10%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  onClick={() =>
                    navigate("/sample/reverse-offer-profile-confirm")
                  }
                  disableElevation
                  style={{
                    width: 150,
                    borderRadius: 5,
                    marginLeft: "75%",
                    marginTop: 12,
                    marginBottom: 8,
                    height: 40,
                    backgroundColor: "black",
                  }}
                >
                  確認画面へ
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
