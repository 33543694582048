import algoliasearch from 'algoliasearch';

// const algoliasearch = require("algoliasearch");
import { ENV } from "../../ENV";

export const client = algoliasearch(
  ENV.algoliaInitOption.APP_ID,
  ENV.algoliaInitOption.API_KEY
);
export const index = client.initIndex(ENV.algoliaInitOption.INDEX);
index.setSettings({
  searchableAttributes: ["name", "department", "semester_day_period"],
});

export const compIndex = client.initIndex(
  ENV.algoliaInitOption.COMP_USER_INDEX
);
compIndex.setSettings({
  searchableAttributes: [
    "companyName",
    "jobDetail",
    "recruitCatchCopy",
    "appealPoint",
  ],
  attributesForFaceting: ["filterOnly(workPlace)", "filterOnly(jobCategory)"],
});

export const jobStudentIndex = client.initIndex(ENV.algoliaInitOption.JOB_STUDENT_INDEX);
jobStudentIndex.setSettings({
    searchableAttributes: [
      "lastName",
      "firstName",
    ],
    attributesForFaceting: ["filterOnly(university)", "filterOnly(humanitiesOrScience)","filterOnly(graduatedYear)"],
  });