import * as React from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Typography,
} from "@mui/material";
import { Stepper, Step, StepLabel } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { studentInfoTypes } from "types/page/studentInfoTypes";
import { studentFacultyData } from "data/StudentProfileData";
import { ApplicatedJobType } from "types/page/applicatedJobType";
import { useLayoutEffect } from "react";
import { getDownLoadUrlFromFullPath } from "hooks/firebase/context/storage";
import { MyCompanyOfferType } from "types/page/myCompanyOfferType";
import { oemUniversityData } from "context/OEMinfo";

type OfferedStudentProfileCardProps = {
  offer: MyCompanyOfferType;
  step?: number;
};

export default function OfferedStudentProfileCard({
  offer,
  step = 0,
}: OfferedStudentProfileCardProps) {
  const [isFavorite, setIsFavorite] = React.useState(false);
  const [iconValue, setIconValue] = React.useState("");
  const steps = ["オファー送信", "オファー承認", "採用"];

  const navigate = useNavigate();
  const toggleFavorite = () => {
    setIsFavorite(!isFavorite);
  };

  useLayoutEffect(() => {
    if (offer.student.iconPhtUrl === undefined) return;
    Promise.all([getDownLoadUrlFromFullPath(offer.student.iconPhtUrl)]).then(
      async (values) => {
        if (values[0] === null) return;
        setIconValue(values[0]);
      }
    );
  }, []);
  // const handleClick = () => {
  //   navigate("/sample/companystudentprofiledetail", {
  //     state: {
  //       iconPhtUrl: imagePath,
  //       senderId: StudentProfileInfos.senderId,
  //       grade: StudentProfileInfos.grade,
  //       university: "早稲田大学",
  //       faculty: StudentProfileInfos.faculty,
  //       department: StudentProfileInfos.department,
  //       firstName: StudentProfileInfos.firstName,
  //       lastName: StudentProfileInfos.lastName,
  //       firstNameKana: StudentProfileInfos.firstNameKana,
  //       lastNameKana: StudentProfileInfos.lastNameKana,
  //       sex: StudentProfileInfos.sex,
  //       livingArea: StudentProfileInfos.livingArea,
  //       birthPlace: StudentProfileInfos.birthPlace,
  //       graduatedHighSchool: StudentProfileInfos.graduatedHighSchool,
  //       certification: StudentProfileInfos.certification,
  //       programmingExperience: StudentProfileInfos.programmingExperience,
  //       programmingExperienceDetail:
  //         StudentProfileInfos.programmingExperienceDetail,
  //       circle: StudentProfileInfos.circle,
  //       researchGroup: StudentProfileInfos.researchGroup,
  //       desiredFirstBusiness: StudentProfileInfos.desiredFirstBusiness,
  //       desiredSecondBusiness: StudentProfileInfos.desiredSecondBusiness,
  //       desiredThirdBusiness: StudentProfileInfos.desiredThirdBusiness,
  //       desiredFirstJob: StudentProfileInfos.desiredFirstJob,
  //       desiredSecondJob: StudentProfileInfos.desiredSecondJob,
  //       desiredThirdJob: StudentProfileInfos.desiredThirdJob,
  //       choiceFirstStandard: StudentProfileInfos.choiceFirstStandard,
  //       choiceSecondStandard: StudentProfileInfos.choiceSecondStandard,
  //       choiceThirdStandard: StudentProfileInfos.choiceThirdStandard,
  //       graduatedYear: StudentProfileInfos.graduatedYear,
  //       graduatedMonth: StudentProfileInfos.graduatedMonth,
  //       humanitiesOrScience: StudentProfileInfos.humanitiesOrScience,
  //       internCompName: StudentProfileInfos.internCompName,
  //       internJobType: StudentProfileInfos.internJobType,
  //       internPeriod: StudentProfileInfos.internPeriod,
  //       selfPRMain: StudentProfileInfos.selfPRMain,
  //       updatedAt: StudentProfileInfos.updatedAt,
  //     },
  //   });
  // };
  return (
    <Card
      sx={{
        display: "flex",
        marginTop: 4,
        width: "80%",
        py: 4,
        px: 3,
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ display: "flex" }}>
        <CardMedia
          component="img"
          sx={{ width: 140, height: 140, borderRadius: 70 }}
          // image={iconValue}
          image={iconValue}
          alt="Live from space album cover"
        />
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <CardContent sx={{ flex: "1 0 auto" }}>
            <Typography component="div" variant="h5">
              {offer.student.lastName} {offer.student.firstName}
            </Typography>
            <Typography variant="subtitle1" component="div" fontSize={14}>
              {offer.student.university &&
                oemUniversityData[offer.student.university - 1].value}
            </Typography>
            <Typography variant="subtitle2" component="div" fontSize={12}>
              {/* {StudentProfileInfos. !== undefined &&
                studentFacultyData[Number(StudentProfileInfos.faculty) - 1]
                  .value} */}
            </Typography>
            {/* <Box mt={1}>
              <Chip label={"文系"} color={"error"} sx={{ mr: 1 }} />
              <Chip label={"24卒"} color={"primary"} variant="outlined" />
            </Box> */}
          </CardContent>
        </Box>
      </Box>
      <Box
        sx={{
          width: "60%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            width: "100%",
            border: "1px solid black",
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              width: 100,
              height: 32,
              backgroundColor: "#434343",
              borderRadius: 1,
              alignItems: "center",
              justifyContent: "center",
              marginTop: -2,
              marginLeft: -2,
            }}
          >
            <Typography
              sx={{ color: "white", textAlign: "center", lineHeight: 2 }}
            >
              フロー
            </Typography>
          </Box>
          <Stepper activeStep={step} alternativeLabel sx={{ my: 3 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        <Button
          variant="outlined"
          onClick={() => navigate("/companychat")}
          disableElevation
          color="inherit"
          style={{
            width: 150,
            borderRadius: 20,
            marginTop: 10,
            height: 40,
          }}
        >
          チャットを開く
        </Button>
      </Box>
    </Card>
  );
}
