import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import Container from "@mui/material/Container";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { CircularProgress } from "@mui/material";
import { resetPassword } from "hooks/firebase/context/auth";
import serviceLogo from "assets/serviceLogo.png";

const BottomLink = styled(Button)`
  text-decoration: none;
  font-size: 0.95rem;
  color: #2a4cce;
  padding: 0;
`;
const LogoImg = styled.img`
  width: 300px;
`;

export default function ResetPassword() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    setIsLoading(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    function formRef(key: string): string {
      if (data.get(key) === null) return "";
      else return data.get(key) as string;
    }
    if (formRef("email") === "") {
      alert("メールアドレスを入力してください");
      setIsLoading(false);
      return;
    }
    resetPassword(formRef("email"));
    setIsLoading(false);
    navigate("/confirmResetPassword");
  };
  return (
    <Box
      sx={{
        height: "100vh",
        pt: 7,
      }}
    >
      <Container component="main">
        <CssBaseline />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: 1,
            backgroundColor: "rgba(255, 255, 255, 0.2)",
            pt: 3,
          }}
        >
          <LogoImg src={serviceLogo} />

          {/* <Typography component="h1" variant="h4" fontWeight={"bold"}>
            早稲田生活
          </Typography> */}
          <Typography fontWeight={"bold"}>パスワード再設定</Typography>
          <Typography pt={2} pb={2} textAlign={"center"}>
            入力いただいたメールアドレス宛に
            <br />
            パスワード再設定用のリンクをお送りします
          </Typography>
          <Box sx={{ position: "absolute", top: { xs: 100, sm: 150 } }}>
            {isLoading ? <CircularProgress /> : <Box />}
          </Box>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 3, width: { xs: "300px", sm: "400px" } }}
          >
            <Typography>メールアドレス</Typography>
            <TextField
              margin="normal"
              required
              fullWidth
              name="email"
              type="email"
              id="clubEmail"
            />
            <Button
              type="submit"
              fullWidth
              disableElevation
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                backgroundColor: "#2a2a2a",
                "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.6)" },
              }}
            >
              決定
            </Button>
            <Grid container sx={{ pb: 3 }}>
              <Grid item xs>
                <BottomLink
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  戻る
                </BottomLink>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
