import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { oemUniversityData } from "data/OEMData";

type UniversitySelectInputProps = {
  setUniversitySelecProps: (selected: string) => void;
  defaultValue?: string;
};

export default function UniversitySelectInput({
  setUniversitySelecProps,
  defaultValue,
}: UniversitySelectInputProps) {
  const defaultUniversity = oemUniversityData.find(
    (university) => university.key === defaultValue
  );

  return (
    <Autocomplete
      id="country-select-demo"
      sx={{ width: 200, height: 55, my: 1 }}
      options={oemUniversityData}
      autoHighlight
      getOptionLabel={(option) => option.value}
      onChange={(_, value) => setUniversitySelecProps(value ? value.key : "")}
      defaultValue={defaultUniversity}
      renderInput={(params) => (
        <TextField
          {...params}
          label="大学"
          inputProps={{
            ...params.inputProps,
            style: { fontWeight: "bold" },
          }}
          InputLabelProps={{
            style: { fontWeight: "bold" },
          }}
        />
      )}
    />
  );
}
