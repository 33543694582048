import React, { useEffect } from "react";
import { Header } from "./Header";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";

import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  AccountBox,
  Group,
  LocalActivity,
  Settings,
} from "@mui/icons-material";

type CompanyHeaderProps = {
  open: boolean;
  toggleDrawer: () => void;
};

const ListItemLink = styled(Link)`
  color: black;
  text-decoration: none;
`;
export function OemHeader({ open, toggleDrawer }: CompanyHeaderProps) {
  const generateCompanyListItems = () => {
    return (
      <React.Fragment>
        <ListItemLink to="/sample/oemtop">
          <ListItemButton>
            <ListItemIcon>
              <Group />
            </ListItemIcon>
            <ListItemText primary="ユーザーデータベース" />
          </ListItemButton>
        </ListItemLink>
        <ListItemLink to="/managecoupon">
          <ListItemButton>
            <ListItemIcon>
              <LocalActivity />
            </ListItemIcon>
            <ListItemText primary="クーポン管理" />
          </ListItemButton>
        </ListItemLink>
      </React.Fragment>
    );
  };

  return (
    <Header
      open={open}
      toggleDrawer={toggleDrawer}
      listItems={generateCompanyListItems()}
    />
  );
}
