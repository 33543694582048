import {
  writeBatch,
  doc,
  getDoc,
  serverTimestamp,
  collection,
  orderBy,
  limit,
  query,
  getDocs,
  QuerySnapshot,
  Query,
  addDoc,
  updateDoc,
  where,
  increment,
  runTransaction,
  DocumentReference,
  setDoc,
  DocumentSnapshot,
  startAfter,
  QueryDocumentSnapshot,
  DocumentData,
} from "firebase/firestore";
import { offerTemplate, offer } from "types/firebase/offer";

import { UserWrap } from "types/firebase/user";
import { db, storage } from "../main";
import { StorageReference, ref, uploadBytes } from "firebase/storage";
import { jobStudentIndex } from "hooks/algolia/main";
import {
  roomInfoToCompPrivateDoc,
  roomInfoToStdPrivateDoc,
  stdCompChatRoom,
  stdCompMessage,
} from "types/firebase/chat";

// 求人を作成する関数
export async function setOfferTemplateInfo(
  offerType: number,
  jobName: string, //職種名
  recruitGraduatedYear: number,
  employmentStatus: number,
  minSalary: string,
  maxSalary: string,
  salaryForm: number,
  salaryDetail: string,
  trialPeriod: string,
  recruitedNumber: number,
  daysToRecruit: number, //採用までの希望日数
  jobDetail: string,
  appealPoint: string,
  neededPersonal: string,
  workDay: string,
  workPlace: number,
  access: string,
  treatment: string, //待遇や福利厚生
  others: string,
  user: UserWrap,
  isUseForApplications: boolean
) {
  let errMsg = "";
  const batch = writeBatch(db);
  const dataToUser: offerTemplate = {
    offerType: offerType,
    jobName: jobName, //職種名
    recruitGraduatedYear: recruitGraduatedYear,
    employmentStatus: employmentStatus,
    minSalary: minSalary,
    maxSalary: maxSalary,
    salaryForm: salaryForm,
    salaryDetail: salaryDetail,
    trialPeriod: trialPeriod,
    recruitedNumber: recruitedNumber,
    daysToRecruit: daysToRecruit, //採用までの希望日数
    jobDetail: jobDetail,
    appealPoint: appealPoint,
    neededPersonal: neededPersonal,
    workDay: workDay,
    workPlace: workPlace,
    access: access,
    treatment: treatment, //待遇や福利厚生
    others: others,
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
    isUseForApplications: isUseForApplications,
  };
  const offerTmpId = doc(
    collection(db, "compUsersPrivate", user.auth.uid, "offerTemplates")
  ).id;
  const offerTmpDoc = doc(
    db,
    "compUsersPrivate",
    user.auth.uid,
    "offerTemplates",
    offerTmpId
  );
  const companyDoc = doc(db, "compUsersPublic", user.auth.uid);
  const companySnapshot = await getDoc(companyDoc);
  if (companySnapshot.exists()) {
    const companyName = companySnapshot.data().name;
    const companyIconPhtUrl = companySnapshot.data().iconPhtUrl;
    const companyCoverPhtUrl = companySnapshot.data().coverPhtUrl;
    //テンプレートコレクション内にも保存する
    const allTmpDoc = doc(db, "allOfferTemplates", offerTmpId);
    const dataToAllTmp = {
      ...dataToUser, // dataToUserのプロパティをすべてコピー
      companyName: companyName, // companyNameを追加
      companyIconPhtUrl: companyIconPhtUrl, //アイコンとカバー画像も保存
      companyCoverPhtUrl: companyCoverPhtUrl,
    };
    batch.set(offerTmpDoc, dataToUser);
    batch.set(allTmpDoc, dataToAllTmp);
    await batch
      .commit()
      .then()
      .catch((err) => {
        errMsg = "ユーザーデータの保存に失敗しました。";
      });
    return errMsg;
  }
  errMsg = "ユーザーが見つかりませんでした。";
  return errMsg;
}

// 求人を更新する関数
export async function updateOfferTemplateInfo(
  offerType: number,
  jobName: string, //職種名
  recruitGraduatedYear: number,
  employmentStatus: number,
  minSalary: string,
  maxSalary: string,
  salaryForm: number,
  salaryDetail: string,
  trialPeriod: string,
  recruitedNumber: number,
  daysToRecruit: number, //採用までの希望日数
  jobDetail: string,
  appealPoint: string,
  neededPersonal: string,
  workDay: string,
  workPlace: number,
  access: string,
  treatment: string, //待遇や福利厚生
  others: string,
  user: UserWrap,
  templateId: string
) {
  let errMsg = "";
  const batch = writeBatch(db);
  const dataToUser = {
    offerType: offerType,
    jobName: jobName, //職種名
    recruitGraduatedYear: recruitGraduatedYear,
    employmentStatus: employmentStatus,
    minSalary: minSalary,
    maxSalary: maxSalary,
    salaryForm: salaryForm,
    salaryDetail: salaryDetail,
    trialPeriod: trialPeriod,
    recruitedNumber: recruitedNumber,
    daysToRecruit: daysToRecruit, //採用までの希望日数
    jobDetail: jobDetail,
    appealPoint: appealPoint,
    neededPersonal: neededPersonal,
    workDay: workDay,
    workPlace: workPlace,
    access: access,
    treatment: treatment, //待遇や福利厚生
    others: others,
    updatedAt: serverTimestamp(),
  };
  const companyDoc = doc(db, "compUsersPublic", user.auth.uid);
  const companySnapshot = await getDoc(companyDoc);
  if (companySnapshot.exists()) {
    const companyName = companySnapshot.data().name;
    const companyIconPhtUrl = companySnapshot.data().iconPhtUrl;
    const companyCoverPhtUrl = companySnapshot.data().coverPhtUrl;
    const offerTmpDoc = doc(
      db,
      "compUsersPrivate",
      user.auth.uid,
      "offerTemplates",
      templateId
    );
    //テンプレートコレクション内にも保存する
    const allTmpDoc = doc(db, "allOfferTemplates", templateId);
    const dataToAllTmp = {
      ...dataToUser, // dataToUserのプロパティをすべてコピー
      companyName: companyName, // companyNameを追加
      companyIconPhtUrl: companyIconPhtUrl, //アイコンとカバー画像も保存
      companyCoverPhtUrl: companyCoverPhtUrl,
    };
    batch.update(offerTmpDoc, dataToUser);
    batch.update(allTmpDoc, dataToAllTmp);
    await batch
      .commit()
      .then()
      .catch((err) => {
        console.log(err);

        errMsg = "ユーザーデータの保存に失敗しました。";
      });
    return errMsg;
  }
  errMsg = "ユーザーが見つかりませんでした。";
  return errMsg;
}

//自社で作成したオファーのテンプレートを閲覧
export async function getOfferTemplatesInfos(user: UserWrap) {
  const q: Query = query(
    collection(db, "compUsersPrivate", user.auth.uid, "offerTemplates"),
    orderBy("updatedAt", "desc"),
    limit(20)
  );
  const querySnapshot = await getDocs(q);
  let response: any = [];
  const addData = async (id: any, data: any) => {
    const docData = {
      id: id,
      offerType: data.offerType,
      jobName: data.jobName, //職種名
      recruitGraduatedYear: data.recruitGraduatedYear,
      employmentStatus: data.employmentStatus,
      minSalary: data.minSalary,
      maxSalary: data.maxSalary,
      salaryForm: data.salaryForm,
      salaryDetail: data.salaryDetail,
      trialPeriod: data.trialPeriod,
      recruitedNumber: data.recruitedNumber,
      daysToRecruit: data.daysToRecruit, //採用までの希望日数
      jobDetail: data.jobDetail,
      appealPoint: data.appealPoint,
      neededPersonal: data.neededPersonal,
      workDay: data.workDay,
      workPlace: data.workPlace,
      access: data.access,
      treatment: data.treatment, //待遇や福利厚生
      others: data.others,
      createdAt: data.createdAt,
      updatedAt: data.updatedAt,
    };
    response.push(docData);
  };

  const dataDivisor: any = async (snap: QuerySnapshot) => {
    for (const doc of snap.docs) {
      await addData(doc.id, doc.data());
    }
    return [response, snap.docs[19]];
  };
  return await dataDivisor(querySnapshot);
}

//学生にオファーを作成する関数 ok
export async function createOffer(
  user: UserWrap,
  studentId: string,
  templateId: string,
  offerMessage: string
) {
  let errMsg = "";
  try {
    await runTransaction(db, async (transaction) => {
      const companyRef = doc(db, "compUsersPublic", user.auth.uid);
      const companyDocSnap = await transaction.get(companyRef);
      if (!companyDocSnap.exists()) {
        throw new Error("ユーザー情報の取得に失敗しました。");
      }
      const templateRef = doc(
        db,
        "compUsersPrivate",
        user.auth.uid,
        "offerTemplates",
        templateId
      );
      const tempDocSnap = await transaction.get(templateRef);
      // オファー数を超えていないか確認
      const monthlyOfferSize = companyDocSnap.data().monthlyOffer;
      if (monthlyOfferSize >= companyDocSnap.data().monthLimitOffer) {
        errMsg = "すでに1ヶ月のオファー上限(50件)に達しています。";
        return errMsg;
      }
      // 学生の情報を取得
      const studentRef = doc(db, "jobHuntingStudentUsersPublic", studentId);
      const studentDocSnap = await transaction.get(studentRef);
      if (tempDocSnap.exists() && studentDocSnap.exists()) {
        const tempData = tempDocSnap.data();
        const data: offer = {
          company: {
            ref: companyRef,
            name: companyDocSnap.data().name,
            iconPhtUrl: companyDocSnap.data().iconPhtUrl,
          },
          student: {
            ref: studentRef,
            lastName: studentDocSnap.data().lastName,
            firstName: studentDocSnap.data().firstName,
            iconPhtUrl: studentDocSnap.data().iconPhtUrl,
            university: studentDocSnap.data().university,
          },
          templateRef: templateRef,
          offerType: tempData.offerType,
          jobName: tempData.jobName, //職種名
          recruitGraduatedYear: tempData.recruitGraduatedYear,
          employmentStatus: tempData.employmentStatus,
          minSalary: tempData.minSalary,
          maxSalary: tempData.maxSalary,
          salaryForm: tempData.salaryForm,
          salaryDetail: tempData.salaryDetail,
          trialPeriod: tempData.trialPeriod,
          recruitedNumber: tempData.recruitedNumber,
          daysToRecruit: tempData.daysToRecruit,
          jobDetail: tempData.jobDetail,
          appealPoint: tempData.appealPoint,
          neededPersonal: tempData.neededPersonal,
          workDay: tempData.workDay,
          workPlace: tempData.workPlace,
          access: tempData.access,
          treatment: tempData.treatment,
          others: tempData.others,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
          status: "unread",
          offerMessage: offerMessage,
          resultMessage: "",
        };
        const offerColRef = collection(db, "offers");
        const offerId = doc(offerColRef).id;
        const offerDoc = doc(db, "offers", offerId);
        // チャットが存在していればそこに記録してオファーを作成
        const privateChatDoc = doc(
          db,
          "compUsersPrivate",
          user.auth.uid,
          "chatWithStd",
          studentId
        );
        const privateChatSnapshot = await transaction.get(privateChatDoc);
        if (privateChatSnapshot.exists()) {
          // チャットが存在している場合
          const chatDoc: DocumentReference = privateChatSnapshot.data().roomDoc;
          const chatSnapshot = await transaction.get(chatDoc);
          if (!chatSnapshot.exists()) {
            throw new Error("エラーが発生しました。");
          }
          const selectionStatusData = chatSnapshot.data().selectionStatus;
          // 進行中のオファーがないか確認
          const validStatus = [
            "expired",
            "rejected",
            "documentRejected",
            "notHired",
            "hired",
            "withdrawAfterHired",
            "withdrawWhileProgress",
          ];
          if (!validStatus.includes(selectionStatusData)) {
            errMsg =
              "現在この学生と進行中の選考が存在します。新しく選考を作成することはできません。";
            return errMsg;
          }
          const ChatMsgColRef = collection(
            db,
            "chatBetCompStd",
            chatSnapshot.id,
            "messages"
          );
          const chatMsgId = doc(ChatMsgColRef).id;
          const chatMsgDoc = doc(
            db,
            "chatBetCompStd",
            chatSnapshot.id,
            "messages",
            chatMsgId
          );
          const chatMessageData: stdCompMessage = {
            messageType: 2, //2は就活機能のログ
            sendedFileUrl: "",
            senderID: user.auth.uid,
            message: offerMessage,
            sendedAt: serverTimestamp(),
            isRead: false,
            readerID: studentId,
            selectionRef: offerDoc,
            isSenderStudent: false,
            registerdStatus: "created",
          };
          const data2std = {
            updatedAt: serverTimestamp(),
            selectionStatus: "unread",
            progressSelection: offerDoc,
          };
          const data2comp = {
            updatedAt: serverTimestamp(),
            selectionStatus: "created",
            progressSelection: offerDoc,
          };
          data["chatRoomId"] = chatSnapshot.id;
          transaction.set(chatMsgDoc, chatMessageData);
          transaction.update(
            doc(
              db,
              "jobHuntingStudentUsersPrivate",
              studentId,
              "chatWithComp",
              user.auth.uid
            ),
            data2std
          );
          transaction.update(privateChatDoc, data2comp);
          transaction.update(chatSnapshot.ref, {
            updatedAt: serverTimestamp(),
            selectionStatus: "created",
            progressSelection: offerDoc,
            lastMessage: "オファーが作成されました。",
          });
        } else {
          // チャットが存在していない場合にはチャットルームを作成してそこに記録してオファーを作成
          const ChatColRef = collection(db, "chatBetCompStd");
          const chatId = doc(ChatColRef).id;
          const chatDoc = doc(db, "chatBetCompStd", chatId);
          const chatRoomData: stdCompChatRoom = {
            author: {
              company: {
                id: user.auth.uid,
                iconPhtRef: companyDocSnap.data().iconPhtUrl,
                name: companyDocSnap.data().name,
              },
              student: {
                id: studentId,
                iconPhtRef: studentDocSnap.data().iconPhtUrl,
                name:
                  studentDocSnap.data().lastName +
                  studentDocSnap.data().firstName,
                university: studentDocSnap.data().university,
              },
            },
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp(),
            lastMessage: "オファーが作成されました。",
            selectionStatus: "created", //選考の状況。オファー送信済みやオファー採用など
            progressSelection: offerDoc, //現在進行中のオファー
          };
          const ChatMsgColRef = collection(
            db,
            "chatBetCompStd",
            chatId,
            "messages"
          );
          const chatMsgId = doc(ChatMsgColRef).id;
          const chatMsgDoc = doc(
            db,
            "chatBetCompStd",
            chatId,
            "messages",
            chatMsgId
          );
          const chatMsgData: stdCompMessage = {
            messageType: 2,
            sendedFileUrl: "",
            senderID: user.auth.uid,
            message: offerMessage,
            sendedAt: serverTimestamp(),
            isRead: false,
            readerID: studentId,
            selectionRef: offerDoc,
            isSenderStudent: false,
            registerdStatus: "created",
          };
          const data2std: roomInfoToStdPrivateDoc = {
            company: {
              name: data.company.name,
              id: user.auth.uid,
              iconPhtUrl: data.company.iconPhtUrl,
            },
            lastMessage: "",
            unread: 0,
            updatedAt: serverTimestamp(),
            roomDoc: chatDoc,
            selectionStatus: "unread",
            progressSelection: offerDoc,
          };
          const data2comp: roomInfoToCompPrivateDoc = {
            student: {
              name: data.student.lastName + data.student.firstName,
              id: studentId,
              iconPhtUrl: data.student.iconPhtUrl,
              university: data.student.university,
            },
            lastMessage: "オファーを作成しました。",
            unread: 0,
            updatedAt: serverTimestamp(),
            roomDoc: chatDoc,
            selectionStatus: "created",
            progressSelection: offerDoc,
          };
          transaction.set(
            doc(
              db,
              "jobHuntingStudentUsersPrivate",
              studentId,
              "chatWithComp",
              user.auth.uid
            ),
            data2std
          );
          transaction.set(
            doc(
              db,
              "compUsersPrivate",
              user.auth.uid,
              "chatWithStd",
              studentId
            ),
            data2comp
          );
          data["chatRoomId"] = chatId;
          transaction.set(chatDoc, chatRoomData);
          transaction.set(chatMsgDoc, chatMsgData);
        }
        transaction.set(offerDoc, data);
        transaction.update(doc(db, "compUsersPrivate", user.auth.uid), {
          monthlyOffer: increment(1),
          updatedAt: serverTimestamp(),
        });
      } else {
        errMsg = "テンプレートが存在しません。";
      }
    });
  } catch (error) {
    errMsg =
      error instanceof Error ? error.message : "オファーの作成に失敗しました。";
  }
  return errMsg;
}

//企業が学生を採用したことを登録する関数 ok
export async function registerOfferHired(
  offerId: string,
  resultMessage: string
) {
  let errMsg = "";
  try {
    await runTransaction(db, async (transaction) => {
      const offerDocRef = doc(db, "offers", offerId);
      const offerSnapshot = await transaction.get(offerDocRef);

      if (!offerSnapshot.exists()) {
        throw new Error("このオファーは無効です。");
      }

      const offerData = offerSnapshot.data();
      if (offerData.status != "progress") {
        throw new Error("このオファーは無効です。");
      }

      transaction.update(offerDocRef, {
        updatedAt: serverTimestamp(),
        status: "hired",
        resultMessage: resultMessage,
      });

      const msgColRef = collection(
        db,
        "chatBetCompStd",
        offerData.chatRoomId,
        "messages"
      );
      const msgDocRef = doc(msgColRef);
      const msgData: stdCompMessage = {
        messageType: 2,
        senderID: offerData.company.ref.id,
        readerID: offerData.student.ref.id,
        isRead: false,
        sendedFileUrl: "",
        sendedAt: serverTimestamp(),
        registerdStatus: "hired",
        isSenderStudent: false,
        message: "選考結果：採用 が送信されました。",
        selectionRef: offerDocRef,
      };
      transaction.set(msgDocRef, msgData);
      if (resultMessage != "") {
        const resultMsgDocRef = doc(msgColRef);
        const resultMsgData: stdCompMessage = {
          messageType: 1,
          senderID: offerData.company.ref.id,
          readerID: offerData.student.ref.id,
          isRead: false,
          sendedFileUrl: "",
          sendedAt: serverTimestamp(),
          registerdStatus: "",
          isSenderStudent: false,
          message: resultMessage,
          selectionRef: null,
        };
        transaction.set(resultMsgDocRef, resultMsgData);
        const chatDocRef = doc(db, "chatBetCompStd", offerData.chatRoomId);
        transaction.update(chatDocRef, {
          selectionStatus: "hired",
          updatedAt: serverTimestamp(),
          lastMessage: resultMessage,
        });

        const compPrivateDocRef = doc(
          db,
          "compUsersPrivate",
          offerData.company.ref.id,
          "chatWithStd",
          offerData.student.ref.id
        );
        transaction.update(compPrivateDocRef, {
          updatedAt: serverTimestamp(),
          selectionStatus: "hired",
          lastMessage: resultMessage,
        });

        const studentPrivateDocRef = doc(
          db,
          "jobHuntingStudentUsersPrivate",
          offerData.student.ref.id,
          "chatWithComp",
          offerData.company.ref.id
        );
        transaction.update(studentPrivateDocRef, {
          updatedAt: serverTimestamp(),
          selectionStatus: "hired",
          lastMessage: resultMessage,
          unread: increment(1),
        });
      } else {
        const chatDocRef = doc(db, "chatBetCompStd", offerData.chatRoomId);
        transaction.update(chatDocRef, {
          selectionStatus: "hired",
          updatedAt: serverTimestamp(),
          lastMessage: "選考結果：採用 が送信されました。",
        });

        const compPrivateDocRef = doc(
          db,
          "compUsersPrivate",
          offerData.company.ref.id,
          "chatWithStd",
          offerData.student.ref.id
        );
        transaction.update(compPrivateDocRef, {
          selectionStatus: "hired",
        });

        const studentPrivateDocRef = doc(
          db,
          "jobHuntingStudentUsersPrivate",
          offerData.student.ref.id,
          "chatWithComp",
          offerData.company.ref.id
        );
        transaction.update(studentPrivateDocRef, {
          selectionStatus: "hired",
        });
      }
    });
  } catch (error) {
    errMsg = error instanceof Error ? error.message : "エラーが発生しました。";
  }
  return errMsg;
}

// 企業が不採用を登録する関数  ok
export async function registerOfferNotHired(
  offerId: string,
  resultMessage: string
) {
  let errMsg = "";
  try {
    await runTransaction(db, async (transaction) => {
      const offerDocRef = doc(db, "offers", offerId);
      const offerSnapshot = await transaction.get(offerDocRef);

      if (!offerSnapshot.exists()) {
        throw new Error("このオファーは無効です。");
      }

      const offerData = offerSnapshot.data();
      if (offerData.status != "progress") {
        throw new Error("このオファーは無効です。");
      }

      transaction.update(offerDocRef, {
        updatedAt: serverTimestamp(),
        status: "notHired",
        resultMessage: resultMessage,
      });

      const msgColRef = collection(
        db,
        "chatBetCompStd",
        offerData.chatRoomId,
        "messages"
      );
      const msgDocRef = doc(msgColRef);
      const msgData: stdCompMessage = {
        messageType: 2,
        senderID: offerData.company.ref.id,
        readerID: offerData.student.ref.id,
        isRead: false,
        sendedFileUrl: "",
        sendedAt: serverTimestamp(),
        registerdStatus: "notHired",
        isSenderStudent: false,
        message: "選考結果：不採用 が送信されました。",
        selectionRef: offerDocRef,
      };
      transaction.set(msgDocRef, msgData);
      if (resultMessage != "") {
        const resultMsgDocRef = doc(msgColRef);
        const resultMsgData: stdCompMessage = {
          messageType: 1,
          senderID: offerData.company.ref.id,
          readerID: offerData.student.ref.id,
          isRead: false,
          sendedFileUrl: "",
          sendedAt: serverTimestamp(),
          registerdStatus: "",
          isSenderStudent: false,
          message: resultMessage,
          selectionRef: null,
        };
        transaction.set(resultMsgDocRef, resultMsgData);
        const chatDocRef = doc(db, "chatBetCompStd", offerData.chatRoomId);
        transaction.update(chatDocRef, {
          selectionStatus: "notHired",
          updatedAt: serverTimestamp(),
          lastMessage: resultMessage,
        });

        const compPrivateDocRef = doc(
          db,
          "compUsersPrivate",
          offerData.company.ref.id,
          "chatWithStd",
          offerData.student.ref.id
        );
        transaction.update(compPrivateDocRef, {
          updatedAt: serverTimestamp(),
          selectionStatus: "notHired",
          lastMessage: resultMessage,
        });

        const studentPrivateDocRef = doc(
          db,
          "jobHuntingStudentUsersPrivate",
          offerData.student.ref.id,
          "chatWithComp",
          offerData.company.ref.id
        );
        transaction.update(studentPrivateDocRef, {
          updatedAt: serverTimestamp(),
          selectionStatus: "notHired",
          lastMessage: resultMessage,
          unread: increment(1),
        });
      } else {
        const chatDocRef = doc(db, "chatBetCompStd", offerData.chatRoomId);
        transaction.update(chatDocRef, {
          selectionStatus: "notHired",
          updatedAt: serverTimestamp(),
          lastMessage: "選考結果：不採用 が送信されました。",
        });

        const compPrivateDocRef = doc(
          db,
          "compUsersPrivate",
          offerData.company.ref.id,
          "chatWithStd",
          offerData.student.ref.id
        );
        transaction.update(compPrivateDocRef, {
          selectionStatus: "notHired",
        });

        const studentPrivateDocRef = doc(
          db,
          "jobHuntingStudentUsersPrivate",
          offerData.student.ref.id,
          "chatWithComp",
          offerData.company.ref.id
        );
        transaction.update(studentPrivateDocRef, {
          selectionStatus: "notHired",
        });
      }
    });
  } catch (error) {
    errMsg = error instanceof Error ? error.message : "エラーが発生しました。";
  }
  return errMsg;
}
// 企業が採用を送信後に辞退したことを登録する ok
export async function registerWithdrawAfterHired(offerId: string) {
  let errMsg = "";
  try {
    await runTransaction(db, async (transaction) => {
      const offerDoc = doc(db, "offers", offerId);
      const offer = await transaction.get(offerDoc);
      if (offer.exists() && offer.data().status == "hired") {
        //オファーが存在し、選考完了のみ有効
        transaction.update(doc(db, "offers", offerId), {
          updatedAt: serverTimestamp(),
          status: "withdrawAfterHired",
        });
        transaction.update(doc(db, "chatBetCompStd", offer.data().chatRoomId), {
          selectionStatus: "withdrawAfterHired",
          lastMessage: "選考結果が採用から辞退に変更されました。",
          updatedAt: serverTimestamp(),
        });
        const msgColRef = collection(
          db,
          "chatBetCompStd",
          offer.data().chatRoomId,
          "messages"
        );
        const msgId = doc(msgColRef).id;
        const msgDoc = doc(
          db,
          "chatBetCompStd",
          offer.data().chatRoomId,
          "messages",
          msgId
        );
        const msgData: stdCompMessage = {
          messageType: 2,
          senderID: offer.data().company.ref.id,
          readerID: offer.data().student.ref.id,
          sendedFileUrl: "",
          sendedAt: serverTimestamp(),
          isRead: false,
          registerdStatus: "withdrawAfterHired", //statusが変更された場合は記載、もし結果が来た場合などはそれを送信する。
          isSenderStudent: false, //送信者が学生側かどうか
          message: "選考結果が採用から辞退に変更されました。",
          selectionRef: offerDoc, // 現在進行中の選考
        };
        transaction.set(msgDoc, msgData);
        //PrivateDocにも記載
        const stdChatPrivateDoc = doc(
          db,
          "jobHuntingStudentUsersPrivate",
          offer.data().student.ref.id,
          "chatWithComp",
          offer.data().company.ref.id
        );
        const compChatPrivateDoc = doc(
          db,
          "compUsersPrivate",
          offer.data().company.ref.id,
          "chatWithComp",
          offer.data().student.ref.id
        );
        transaction.update(stdChatPrivateDoc, {
          selectionStatus: "withdrawAfterHired",
        });
        transaction.update(compChatPrivateDoc, {
          selectionStatus: "withdrawAfterHired",
        });
      } else {
        errMsg = "このオファーは無効です。";
      }
    });
  } catch {
    errMsg = "エラーが発生しました。";
  }
  return errMsg;
}

// 企業が選考中に辞退したことを登録する(確認済み)
export async function registerWithdrawWhileSelection(offerId: string) {
  let errMsg = "";
  try {
    await runTransaction(db, async (transaction) => {
      const offerDoc = doc(db, "offers", offerId);
      const offer = await transaction.get(offerDoc);
      if (offer.exists() && offer.data().status == "progress") {
        //オファーが存在し、選考完了のみ有効
        transaction.update(offerDoc, {
          updatedAt: serverTimestamp(),
          status: "withdrawWhileSelection",
        });
        transaction.update(doc(db, "chatBetCompStd", offer.data().chatRoomId), {
          selectionStatus: "withdrawWhileSelection",
          lastMessage: "選考中に学生が辞退しました。",
          updatedAt: serverTimestamp(),
        });
        const msgColRef = collection(
          db,
          "chatBetCompStd",
          offer.data().chatRoomId,
          "messages"
        );
        const msgId = doc(msgColRef).id;
        const msgDoc = doc(
          db,
          "chatBetCompStd",
          offer.data().chatRoomId,
          "messages",
          msgId
        );
        const msgData: stdCompMessage = {
          messageType: 2,
          senderID: offer.data().company.ref.id,
          readerID: offer.data().student.ref.id,
          sendedFileUrl: "",
          sendedAt: serverTimestamp(),
          isRead: false,
          registerdStatus: "withdrawWhileSelection", //statusが変更された場合は記載、もし結果が来た場合などはそれを送信する。
          isSenderStudent: false, //送信者が学生側かどうか
          message: "選考を辞退しました。",
          selectionRef: offerDoc, // 現在進行中の選考
        };
        transaction.set(msgDoc, msgData);
        //PrivateDocにも記載
        const stdChatPrivateDoc = doc(
          db,
          "jobHuntingStudentUsersPrivate",
          offer.data().student.ref.id,
          "chatWithComp",
          offer.data().company.ref.id
        );
        const compChatPrivateDoc = doc(
          db,
          "compUsersPrivate",
          offer.data().company.ref.id,
          "chatWithStd",
          offer.data().student.ref.id
        );
        transaction.update(stdChatPrivateDoc, {
          selectionStatus: "withdrawWhileSelection",
        });
        transaction.update(compChatPrivateDoc, {
          selectionStatus: "withdrawWhileSelection",
        });
      } else {
        errMsg = "このオファーは無効です。";
      }
    });
  } catch {
    errMsg = "エラーが発生しました。";
  }
  return errMsg;
}

//送信したオファーを取得する関数
export async function getMyCompanyOffer(uid: string) {
  let errMsg = "";
  try {
    const companyDocRef = doc(db, "compUsersPublic", uid);
    const q = query(
      collection(db, "offers"),
      where("company.ref", "==", companyDocRef),
      orderBy("createdAt", "desc")
    );
    const offerSnapshot = await getDocs(q);
    const offerLists = offerSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(), // すべてのフィールドを取得
    }));
    return offerLists;
  } catch (error) {
    errMsg =
      error instanceof Error
        ? error.message
        : "オファーの取得中にエラーが発生しました。";
  }
  return errMsg;
}

// 学生からの応募を取得する関数
export async function getMyApplication(uid: string) {
  let errMsg = "";
  try {
    const companyDocRef = doc(db, "compUsersPublic", uid);
    const q = query(
      collection(db, "applications"),
      where("company.ref", "==", companyDocRef),
      orderBy("createdAt", "desc")
    );
    const offerSnapshot = await getDocs(q);
    const offerLists = offerSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(), // すべてのフィールドを取得
    }));
    return offerLists;
  } catch (error) {
    errMsg =
      error instanceof Error
        ? error.message
        : "オファーの取得中にエラーが発生しました。";
  }
  return errMsg;
}

// 選考完了を登録する(当面は使用しない)
// export async function registerSelectionCompleted(offerId:string){
//   let errMsg = "";
//   try{
//     const offerDoc = doc(db, "offers", offerId);
//     const offer = await getDoc(offerDoc);
//     if (offer.exists() && offer.data().status == "progress") {
//       const batch = writeBatch(db);
//       //オファーが存在し、選考完了のみ有効
//       batch.update(doc(db, "offers", offerId), {
//         updatedAt: serverTimestamp(),
//         status: "selectionCompleted",
//       });
//       // 不採用のため選考中の求人は無しにする
//       batch.update(
//         doc(db,"chatBetCompStd",offer.data().chatRoomId),
//       {selectionStatus:"offerSelectionCompleted",
//       lastMessage:"選考を辞退しました。",
//       updatedAt:serverTimestamp(),
//       progressSelection:null})
//       batch.commit()
//     } else {
//       errMsg = "このオファーは無効です。";
//     }
//   }catch{
//     errMsg = "エラーが発生しました。"
//   }
//   return errMsg;
// }

//学生を検索する関数(文理と大学と卒業年度)
//学生を検索する関数(文理と大学と卒業年度) - 次の10件を取得するためにlastVisibleも返すように修正
// export async function searchJobHuntingStudent(
//   university: number,
//   humanitiesOrScience: number,
//   graduatedYear: number
// ) {
//   const studentsCol = collection(db, "jobHuntingStudentUsersPublic");

//   // 条件に一致する学生を検索するクエリを作成
//   const q = query(
//     studentsCol,
//     where("university", "==", university),
//     where("humanitiesOrScience", "==", humanitiesOrScience),
//     where("graduatedYear", "==", graduatedYear),
//     orderBy("updatedAt"),
//     limit(10)
//   );

//   // クエリを実行し、結果を取得
//   const querySnapshot = await getDocs(q);
//   const students = querySnapshot.docs.map((doc) => ({
//     senderId: doc.id,
//     ...doc.data(),
//   }));
//   // 最後のドキュメントを取得（次のクエリの開始点として使用）
//   const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
//   return { students, lastVisible };
// }

export async function searchJobHuntingStudent(
  university: number,
  humanitiesOrScience: number,
  graduatedYear: number,
  lastVisibleDoc?: QueryDocumentSnapshot<DocumentData>
) {
  const studentsCol = collection(db, "jobHuntingStudentUsersPublic");

  let q;
  if (lastVisibleDoc) {
    q = query(
      studentsCol,
      where("university", "==", university),
      where("humanitiesOrScience", "==", humanitiesOrScience),
      where("graduatedYear", "==", graduatedYear),
      orderBy("updatedAt"),
      startAfter(lastVisibleDoc),
      limit(10)
    );
  } else {
    q = query(
      studentsCol,
      where("university", "==", university),
      where("humanitiesOrScience", "==", humanitiesOrScience),
      where("graduatedYear", "==", graduatedYear),
      orderBy("updatedAt"),
      limit(10)
    );
  }

  // クエリを実行し、結果を取得
  const querySnapshot = await getDocs(q);
  const students = querySnapshot.docs.map((doc) => ({
    senderId: doc.id,
    ...doc.data(),
  }));
  // 最後のドキュメントを取得（次のクエリの開始点として使用）
  const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
  return { students, lastVisible };
}

// 追加で10件のドキュメントを取得
export async function getNextJobHuntingStudents(
  university: number,
  humanitiesOrScience: number,
  graduatedYear: number,
  lastDoc: DocumentSnapshot
) {
  const studentsCol = collection(db, "jobHuntingStudentUsersPublic");

  // 条件に一致する学生を検索するクエリを作成
  const q = query(
    studentsCol,
    where("university", "==", university),
    where("humanitiesOrScience", "==", humanitiesOrScience),
    where("graduatedYear", "==", graduatedYear),
    startAfter(lastDoc), // Start after the last document from the previous batch
    orderBy("updatedAt"),
    limit(10) // Fetch only 10 documents at a time
  );

  // クエリを実行し、結果を取得
  const querySnapshot = await getDocs(q);
  const students = querySnapshot.docs.map((doc) => ({
    senderId: doc.id,
    ...doc.data(),
  }));
  return students;
}

// 学生をキーワード検索する関数(キーワード検索)
export async function searchJobHuntingStudentWithKeyword(
  keyword: string,
  university?: number,
  humanitiesOrScience?: number,
  graduatedYear?: number
) {
  let res: any = null;
  // キーワードで検索し、workPlaceとjobCategoryでフィルタリング
  let filterConditions: string[] = [];
  if (university) {
    filterConditions.push(`university:${university}`);
  }
  if (humanitiesOrScience) {
    filterConditions.push(`jobCategory:${humanitiesOrScience}`);
  }
  if (graduatedYear) {
    filterConditions.push(`graduatedYear:${graduatedYear}`);
  }
  const filters = filterConditions.join(" AND ");
  await jobStudentIndex
    .search(keyword, filters ? { filters } : {})
    .then((result: any) => {
      res = result.hits;
    })
    .catch(() => {});
  return res;
}

//　求人を公開する
export async function openOfferTemplate(templateId: string, user: UserWrap) {
  let errMsg = "";
  const batch = writeBatch(db);
  const companyDoc = doc(db, "compUsersPublic", user.auth.uid);
  const companySnapshot = await getDoc(companyDoc);
  if (companySnapshot.exists()) {
    const offerTmpDoc = doc(
      db,
      "compUsersPrivate",
      user.auth.uid,
      "offerTemplates",
      templateId
    );
    const offerTmpSnapshot = await getDoc(offerTmpDoc);
    if (!offerTmpSnapshot.exists()) {
      errMsg = "指定した求人が存在しない可能性があります。";
      return errMsg;
    }
    const allTmpDoc = doc(db, "allOfferTemplates", templateId);
    batch.update(offerTmpDoc, { isUseForApplications: true });
    batch.update(allTmpDoc, { isUseForApplications: true });
    await batch
      .commit()
      .then()
      .catch((err) => {
        errMsg = "ユーザーデータの保存に失敗しました。";
      });
    return errMsg;
  }
  errMsg = "ユーザーが見つかりませんでした。";
  return errMsg;
}

//　求人を非公開にする
export async function closeOfferTemplate(templateId: string, user: UserWrap) {
  let errMsg = "";
  const batch = writeBatch(db);
  const companyDoc = doc(db, "compUsersPublic", user.auth.uid);
  const companySnapshot = await getDoc(companyDoc);
  if (companySnapshot.exists()) {
    const offerTmpDoc = doc(
      db,
      "compUsersPrivate",
      user.auth.uid,
      "offerTemplates",
      templateId
    );
    const offerTmpSnapshot = await getDoc(offerTmpDoc);
    if (!offerTmpSnapshot.exists()) {
      errMsg = "指定した求人が存在しない可能性があります。";
      return errMsg;
    }
    const allTmpDoc = doc(db, "allOfferTemplates", templateId);
    batch.update(offerTmpDoc, { isUseForApplications: false });
    batch.update(allTmpDoc, { isUseForApplications: false });
    await batch
      .commit()
      .then()
      .catch((err) => {
        errMsg = "ユーザーデータの保存に失敗しました。";
      });
    return errMsg;
  }
  errMsg = "ユーザーが見つかりませんでした。";
  return errMsg;
}

// オファーを送信した学生一覧(承認前)を取得する
export async function getOffersBeforeProgress(companyId: string) {
  let errMsg = "";
  try {
    const q = query(
      collection(db, "offers"),
      where("company.ref", "==", doc(db, "compUsersPublic", companyId)),
      where("status", "in", ["unread", "read", "expired"]),
      limit(10)
    );
  } catch (error) {
    errMsg =
      error instanceof Error
        ? error.message
        : "オファーの情報の取得に失敗しました。";
  }
}

//企業と学生の間で進行中の選考が存在するか確認する
export async function checkExistProgressSelection(
  studentId: string,
  companyId: string
) {
  const privateChatDoc = doc(
    db,
    "compUsersPrivate",
    companyId,
    "chatWithStd",
    studentId
  );
  const chatSnapshot = await getDoc(privateChatDoc);
  if (!chatSnapshot.exists()) {
    return false;
  } else {
    const validStatus = [
      "expired",
      "rejected",
      "documentRejected",
      "notHired",
      "hired",
      "withdrawAfterHired",
      "withdrawWhileProgress",
    ];
    const selectionStatus = chatSnapshot.data().selectionStatus;
    return !validStatus.includes(selectionStatus);
  }
}

export async function deleteOfferTemplate(templateId: string, user: UserWrap) {
  console.log("deleteOfferTemplate");

  let errMsg = "";
  const batch = writeBatch(db);
  const companyDoc = doc(db, "compUsersPublic", user.auth.uid);
  const companySnapshot = await getDoc(companyDoc);
  if (companySnapshot.exists()) {
    const offerTmpDoc = doc(
      db,
      "compUsersPrivate",
      user.auth.uid,
      "offerTemplates",
      templateId
    );
    const offerTmpSnapshot = await getDoc(offerTmpDoc);
    if (!offerTmpSnapshot.exists()) {
      errMsg = "指定した求人が存在しない可能性があります。";
      return errMsg;
    }
    const allTmpDoc = doc(db, "allOfferTemplates", templateId);
    // 求人テンプレートを削除
    batch.delete(offerTmpDoc);
    batch.delete(allTmpDoc);
    await batch
      .commit()
      .then()
      .catch((err) => {
        errMsg = "求人データの削除に失敗しました。";
      });
    return errMsg;
  }
  errMsg = "ユーザーが見つかりませんでした。";
  return errMsg;
}

export async function searchStudentByIconPhtUrl(iconPhtUrl: string) {
  const studentsCol = collection(db, "jobHuntingStudentUsersPublic");

  const q = query(studentsCol, where("iconPhtUrl", "==", iconPhtUrl));

  // クエリを実行し、結果を取得
  const querySnapshot = await getDocs(q);
  const students = querySnapshot.docs.map((doc) => ({
    senderId: doc.id,
    ...doc.data(),
  }));

  return students;
}
