import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { CompanyHeader } from "components/CompanyHeader";
import StudentProfileCard from "components/StudentProfileCard";
import { useState } from "react";
import { studentInfoTypes } from "types/page/studentInfoTypes";
import OfferedStudentProfileCard from "components/OfferedStudentProfileCard";
import { Typography } from "@mui/material";
import PreviewSnackbar from "components/PreviewSnackbar";
import SampleOfferedStudentProfileCard from "components/SampleOfferedStudentProfileCard";
import { getMyCompanyOffer } from "hooks/firebase/context/offer";
import { useUser } from "hooks/firebase/context/auth";
import { MyCompanyOfferType } from "types/page/myCompanyOfferType";
import { registerDocumentAccepted } from "hooks/firebase/context/recruit";

const mdTheme = createTheme();

export default function AcceptedStudentOffer() {
  const navigate = useNavigate();
  const user = useUser();

  const [open, setOpen] = useState(true);
  const [myCompanyOffers, setMyCompanyOffers] = useState<MyCompanyOfferType[]>(
    []
  );
  // const [studentInfos, setStudentInfos] = useState<studentInfoTypes[]>([
  //   {
  //     iconPhtUrl: "images/student.png",
  //     senderId: "ID9333",
  //     grade: 3,
  //     university: 8,
  //     faculty: 8,
  //     department: 1,
  //     firstName: "晴翔",
  //     lastName: "渡辺",
  //     firstNameKana: "ハルト",
  //     lastNameKana: "ワタナベ",
  //     sex: 0,
  //     livingArea: 1,
  //     birthPlace: 19,
  //     graduatedHighSchool: "青森高校",
  //     certification: "実用英語技能検定準一級・TOEIC800点",
  //     programmingExperience: 5,
  //     programmingExperienceDetail: "経験内容2",
  //     circle: "〇〇サークル",
  //     researchGroup: "〇〇ゼミ",
  //     desiredFirstBusiness: 8,
  //     desiredSecondBusiness: 7,
  //     desiredThirdBusiness: 5,
  //     desiredFirstJob: 7,
  //     desiredSecondJob: 7,
  //     desiredThirdJob: 2,
  //     choiceFirstStandard: 2,
  //     choiceSecondStandard: 5,
  //     choiceThirdStandard: 3,
  //     graduatedYear: 1,
  //     graduatedMonth: 8,
  //     humanitiesOrScience: 0,
  //     internCompName: "インターン企業6",
  //     internJobType: 3,
  //     internPeriod: "期間3ヶ月",
  //     selfPRMain:
  //       "私は、チームプロジェクトにおけるリーダーシップと協働の能力を大切にしています。大学時代、私は学際的なプロジェクトチームのリーダーを務め、異なる専門分野のメンバーを率いて、厳しい締め切りまでに成果を出すために取り組みました。このプロジェクトでは、私たちは限られた時間とリソースの中で、革新的なマーケティング戦略を考案しました。この過程で、私はメンバーの意見を積極的に取り入れ、効果的な意思決定を行い、チーム全員が責任感を持って作業に取り組めるよう努めました。さらに、私はコミュニケーションを重視し、チーム内の透明性と信頼を構築することに注力しました。私は定期的なミーティングと個別のフィードバックセッションを通じて、メンバーの進捗を確認し、彼らの意見や懸念を聞きました。また、私自身も自分の考えや計画をオープンにし、チームの士気を高めるためにポジティブなフィードバックを提供しました。この経験から、私はリーダーシップと協働の重要性を深く理解し、さまざまな背景を持つ人々と効果的に協力する能力を身につけました。私は、これらのスキルを活かして、貴社のチームに貢献できると確信しています。",
  //     updatedAt: "2023-12-07 03:08:14",
  //   },
  //   {
  //     iconPhtUrl: "images/student2.jpeg",
  //     senderId: "ID4862",
  //     grade: 2,
  //     university: 1,
  //     faculty: 4,
  //     department: 7,
  //     firstName: "太郎",
  //     lastName: "佐藤",
  //     firstNameKana: "タロウ",
  //     lastNameKana: "サトウ",
  //     sex: 0,
  //     livingArea: 1,
  //     birthPlace: 13,
  //     graduatedHighSchool: "青森高校",
  //     certification: "実用英語技能検定準一級・TOEIC800点",
  //     programmingExperience: 3,
  //     programmingExperienceDetail: "経験内容1",
  //     circle: "〇〇サークル",
  //     researchGroup: "〇〇ゼミ",
  //     desiredFirstBusiness: 2,
  //     desiredSecondBusiness: 10,
  //     desiredThirdBusiness: 6,
  //     desiredFirstJob: 9,
  //     desiredSecondJob: 2,
  //     desiredThirdJob: 7,
  //     choiceFirstStandard: 2,
  //     choiceSecondStandard: 3,
  //     choiceThirdStandard: 5,
  //     graduatedYear: 1,
  //     graduatedMonth: 6,
  //     humanitiesOrScience: 1,
  //     internCompName: "インターン企業9",
  //     internJobType: 2,
  //     internPeriod: "期間1ヶ月",
  //     selfPRMain:
  //       "問題解決は、私の最も強みの一つです。実際に、インターンシップ中に発生した一つの難題を克服した経験があります。当時、私は顧客データベースの不具合に直面しました。この問題は、顧客情報の整合性を損ない、業務効率にも大きな影響を及ぼしていました。この課題に対処するため、私はまず、問題の原因を徹底的に分析しました。データベースのログを精査し、エラーパターンを特定することから始めました。その後、ITチームと密接に協力し、システムのバグを特定し、修正計画を立てました。私の提案した解決策は、データの整合性を確保し、業務の効率化に大きく貢献しました。この経験を通じて、私は複雑な問題に迅速かつ効果的に対応する能力を身につけました。私は、論理的思考とクリティカルなアプローチを用いて問題を分析し、実践的な解決策を提案することができます。このスキルセットを活かし、貴社においても困難な課題に取り組む際の重要な資産となることを自負しています。",
  //     updatedAt: "2023-12-07 03:08:14",
  //   },
  //   {
  //     iconPhtUrl: "images/student3.jpeg",
  //     senderId: "ID3584",
  //     grade: 1,
  //     university: 0,
  //     faculty: 8,
  //     department: 6,
  //     firstName: "太郎",
  //     lastName: "伊藤",
  //     firstNameKana: "タロウ",
  //     lastNameKana: "イトウ",
  //     sex: 1,
  //     livingArea: 3,
  //     birthPlace: 5,
  //     graduatedHighSchool: "宮城高校",
  //     certification: "実用英語技能検定準一級・TOEIC800点",
  //     programmingExperience: 4,
  //     programmingExperienceDetail: "経験内容3",
  //     circle: "〇〇サークル",
  //     researchGroup: "〇〇ゼミ",
  //     desiredFirstBusiness: 1,
  //     desiredSecondBusiness: 8,
  //     desiredThirdBusiness: 6,
  //     desiredFirstJob: 7,
  //     desiredSecondJob: 3,
  //     desiredThirdJob: 10,
  //     choiceFirstStandard: 2,
  //     choiceSecondStandard: 3,
  //     choiceThirdStandard: 2,
  //     graduatedYear: 1,
  //     graduatedMonth: 9,
  //     humanitiesOrScience: 0,
  //     internCompName: "インターン企業5",
  //     internJobType: 7,
  //     internPeriod: "期間5ヶ月",
  //     selfPRMain:
  //       "コミュニケーションは、どの職場でも必須のスキルです。私は、大学のプレゼンテーションやインターンシップ中のプロジェクト報告を通じて、この能力を磨いてきました。特に印象深いのは、インターンシップでの経験です。そこでは、私は週次のプロジェクト更新を上司やチームメンバーに報告する責任を負っていました。この役割を通じて、私は複雑な情報を簡潔かつ明瞭に伝える方法を学びました。プレゼンテーションの準備では、技術的な詳細を理解しやすい形で説明し、聴衆の関心を引くために視覚的な資料を効果的に使用しました。また、質疑応答のセッションでは、明確で説得力のある答えを提供し、チームメンバーの疑問や懸念を解消しました。これらの経験から、私は多様な聴衆に対して、明確かつ効果的にコミュニケーションを取る能力を身につけました。私はこのスキルを活かし、貴社のプロジェクトでのコミュニケーションの橋渡し役として貢献できると確信しています。",
  //     updatedAt: "2023-12-07 03:08:14",
  //   },

  //   {
  //     iconPhtUrl: "images/student4.jpeg",
  //     senderId: "ID7557",
  //     grade: 2,
  //     university: 0,
  //     faculty: 3,
  //     department: 7,
  //     firstName: "美咲",
  //     lastName: "伊藤",
  //     firstNameKana: "ミサキ",
  //     lastNameKana: "イトウ",
  //     sex: 1,
  //     livingArea: 2,
  //     birthPlace: 32,
  //     graduatedHighSchool: "岩手高校",
  //     certification: "実用英語技能検定準一級・TOEIC800点",
  //     programmingExperience: 5,
  //     programmingExperienceDetail: "経験内容1",
  //     circle: "〇〇サークル",
  //     researchGroup: "〇〇ゼミ",
  //     desiredFirstBusiness: 7,
  //     desiredSecondBusiness: 5,
  //     desiredThirdBusiness: 5,
  //     desiredFirstJob: 4,
  //     desiredSecondJob: 4,
  //     desiredThirdJob: 2,
  //     choiceFirstStandard: 5,
  //     choiceSecondStandard: 1,
  //     choiceThirdStandard: 5,
  //     graduatedYear: 1,
  //     graduatedMonth: 5,
  //     humanitiesOrScience: 0,
  //     internCompName: "インターン企業6",
  //     internJobType: 6,
  //     internPeriod: "期間2ヶ月",
  //     selfPRMain:
  //       "私は、困難な状況に直面した際にも冷静に対処し、効果的な解決策を見出す能力があります。大学時代、研究プロジェクトのリーダーとして、限られた予算と時間の中で目標を達成するために、チームワークと創造的なアプローチを駆使しました。具体的には、資源の最適化とプロセスの効率化により、期限内にプロジェクトを成功させることができました。この経験から、いかなる困難も乗り越える力を身につけました。",
  //     updatedAt: "2023-12-07 03:08:14",
  //   },
  //   {
  //     iconPhtUrl: "images/student5.jpeg",
  //     senderId: "ID6364",
  //     grade: 1,
  //     university: 2,
  //     faculty: 7,
  //     department: 7,
  //     firstName: "さくら",
  //     lastName: "高橋",
  //     firstNameKana: "サクラ",
  //     lastNameKana: "タカハシ",
  //     sex: 1,
  //     livingArea: 2,
  //     birthPlace: 31,
  //     graduatedHighSchool: "岩手高校",
  //     certification: "実用英語技能検定準一級・TOEIC800点",
  //     programmingExperience: 2,
  //     programmingExperienceDetail: "経験内容3",
  //     circle: "〇〇サークル",
  //     researchGroup: "〇〇ゼミ",
  //     desiredFirstBusiness: 5,
  //     desiredSecondBusiness: 10,
  //     desiredThirdBusiness: 10,
  //     desiredFirstJob: 4,
  //     desiredSecondJob: 7,
  //     desiredThirdJob: 6,
  //     choiceFirstStandard: 3,
  //     choiceSecondStandard: 2,
  //     choiceThirdStandard: 1,
  //     graduatedYear: 2,
  //     graduatedMonth: 3,
  //     humanitiesOrScience: 1,
  //     internCompName: "インターン企業1",
  //     internJobType: 5,
  //     internPeriod: "期間2ヶ月",
  //     selfPRMain:
  //       "私は、様々な背景を持つ人々と効果的にコミュニケーションをとることができます。留学経験を通じて、文化的な違いを乗り越え、多様な視点を受け入れることの重要性を学びました。学生団体の代表として、チームメンバー間の意思疎通を円滑にし、組織の目標達成に貢献しました。この経験は、職場での多様なチームワークにも活かせると自負しています。",
  //     updatedAt: "2023-12-07 03:08:14",
  //   },
  //   {
  //     iconPhtUrl: "images/student6.jpeg",
  //     senderId: "ID3933",
  //     grade: 3,
  //     university: 1,
  //     faculty: 8,
  //     department: 5,
  //     firstName: "結衣",
  //     lastName: "佐藤",
  //     firstNameKana: "ユイ",
  //     lastNameKana: "サトウ",
  //     sex: 1,
  //     livingArea: 1,
  //     birthPlace: 8,
  //     graduatedHighSchool: "青森高校",
  //     certification: "実用英語技能検定準一級・TOEIC800点",
  //     programmingExperience: 5,
  //     programmingExperienceDetail: "経験内容2",
  //     circle: "〇〇サークル",
  //     researchGroup: "〇〇ゼミ1",
  //     desiredFirstBusiness: 4,
  //     desiredSecondBusiness: 5,
  //     desiredThirdBusiness: 8,
  //     desiredFirstJob: 4,
  //     desiredSecondJob: 1,
  //     desiredThirdJob: 8,
  //     choiceFirstStandard: 5,
  //     choiceSecondStandard: 5,
  //     choiceThirdStandard: 2,
  //     graduatedYear: 1,
  //     graduatedMonth: 2,
  //     humanitiesOrScience: 1,
  //     internCompName: "インターン企業3",
  //     internJobType: 10,
  //     internPeriod: "期間3ヶ月",
  //     selfPRMain:
  //       "大学でのクラブ活動において、私はリーダーとしてメンバーを牽引し、共通の目標に向かってチームを導きました。プロジェクトの計画、実行、評価を統括し、メンバー一人ひとりの能力を最大限に引き出すことに注力しました。また、困難に直面した時は、柔軟な思考で解決策を提案し、チームを成功へと導いた経験があります。このリーダーシップ経験は、ビジネスの世界でも役立つと信じています。",
  //     updatedAt: "2023-12-07 03:08:14",
  //   },
  // ]);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const mainStyles = {
    width: open ? "calc(100vw - 240px)" : "calc(100vw - 72px)",
    marginLeft: open ? "240px" : "72px",
    transition: ".3s",
    overflow: "auto",
  };

  // React.useEffect(() => {
  //   if (user) {
  //     console.log("start");

  //     const fetchOffer = async () => {
  //       const offers = await getMyCompanyOffer(user.auth.uid);
  //       setMyCompanyOffers(offers as MyCompanyOfferType[]);
  //       console.log("offers", offers);
  //     };
  //     fetchOffer();
  //   }
  // }, []);

  const handleOffer = async () => {
    const errMsg = await registerDocumentAccepted("qR0gERLlBx0yapT6OwL8");
    console.log(errMsg);
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <CssBaseline />
      <CompanyHeader
        open={open}
        toggleDrawer={toggleDrawer}
        showofferModalProps={true}
      />
      <PreviewSnackbar open={open} />

      <Box
        component="main"
        sx={{
          ...mainStyles,
        }}
      >
        <Box mt={14}>
          <Typography
            fontSize={"1.8rem"}
            fontWeight={"bold"}
            textAlign={"center"}
          >
            オファーを送信した学生
          </Typography>
        </Box>
        <Box
          sx={{
            width: { xs: 340, sm: 500 },
            height: 40,
            borderRadius: 20,
            marginTop: { xs: 3, sm: 5 },
            backgroundColor: "#D9D9D9",
          }}
          mx="auto"
        >
          <Button
            variant="contained"
            disableElevation
            sx={{
              width: { xs: 169, sm: 249 },
              borderRadius: 20,
              height: 40,
              fontSize: { xs: "0.95rem", sm: "1.2rem" },
              fontWeight: "bold",
              backgroundColor: "#4b4b4b",
              ":hover": { backgroundColor: "#4b4b4b" },
            }}
          >
            オファー承認済
          </Button>
          <Button
            onClick={() => {
              navigate("/sample/non-accepted-student-offer");
            }}
            variant="contained"
            disableElevation
            sx={{
              width: { xs: 169, sm: 249 },
              borderRadius: 20,
              color: "#4b4b4b",
              fontWeight: "bold",
              fontSize: { xs: "0.95rem", sm: "1.2rem" },
              height: 40,
              backgroundColor: "#D9D9D9",
              ":hover": { backgroundColor: "#D9D9D9" },
            }}
          >
            オファー承認前
          </Button>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            width: "90%",
            flexWrap: "wrap",
            mx: "auto",
            mt: 5,
          }}
        >
          {myCompanyOffers.map((myCompanyOffer, i) => {
            return <OfferedStudentProfileCard key={i} offer={myCompanyOffer} />;
          })}
        </Box>
      </Box>
    </ThemeProvider>
  );
}
