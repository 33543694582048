import { Snackbar, SnackbarContent } from "@mui/material";

type previewSnackbarProps = {
  open: boolean;
};

export default function PreviewSnackbar({ open }: previewSnackbarProps) {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={true}
      sx={{
        width: "60%",
        marginLeft: open ? "140px" : "12px",
      }}
    >
      <SnackbarContent
        message={"こちらはプレビュー画面です。"}
        sx={{
          backgroundColor: (theme) => theme.palette.error.main,
          width: "100%",
          justifyContent: "center",
        }}
      />
    </Snackbar>
  );
}
