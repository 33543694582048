// 本番環境用firebase設定
const production = {
    FIREBASE_API_KEY: "AIzaSyB1aPdBT5OiywmD6tSn91OquTvOSHPjqi8",
    FIREBASE_AUTH_DOMAIN: "lightroads-prod.firebaseapp.com",
    FIREBASE_DATABASE_URL: "https://lightroads-prod-default-rtdb.firebaseio.com",
    FIREBASE_PROJECT_ID: "lightroads-prod",
    FIREBASE_STORAGE_BUCKET: "lightroads-prod.appspot.com",
    FIREBASE_MESSAGING_SENDER_ID: "146346494872",
    DOMAIN: "https://gakuseiseikatsu.jp",
    SERVICE_ACCOUNT_KEY: {
      type: "service_account",
      project_id: "lightroads-a4db7",
      private_key_id: "f9ab69e459cda8eb78002eafa3acace97dc9c185",
      private_key:
        "-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQC+qqVWRvRHM8d3\nonUyvTLu3QJlTVZrbWH2AgRxaVXixwQLtai0C3cc+w6qfHe/bASIN78IsnU+73Tt\nvcHGkyWHZmFvncAuXB+dyOFRgkNtQZcQDiBNZ0z/DfdIsgli8y4+qrEPw+FOFiGg\nBmcFyxvAbhjoR1wuUoHWzU1wYpk5FGYOddmp7O3kmPvRBH0uKy8Uh6TPXZ5wIrhE\nzS0L2IT5yJRU5cpgJ35MivfCn+eXtw1Py/6DxDQfKCLvqDCUxpufVeJ/duKTbFyq\nrGRo9mWnkAdmFc6UuDBq2C3Zx6p20GFWGUFcBiAwEiPfnymIxXMTL21zfACHcpwT\nZcT9eqRVAgMBAAECggEAH0Yy2gDH80DrRDSV2DV/+/2s9axWk0btFVLV4ztlrs25\nnGkW67dguZR1nqHNa4zefnB0aYsKH8Fb0t44AWgj9P5mV45O1lvehL1NNhcxk6N5\nUZUbl86sqr5h9B7d2sjsKjPJ2L3mwC3xpIPAVMswuWLlu33vARsf+HmS7O8DVoD4\nti9ys5h9G3c+G2XZKocIMGnt1PaV+D3408CBqkOPPVTh75+3k0MpC+pkbJB6g03B\nAWLFBOmkGxGbQ1OdYqZRnqzu5w8W74W0g4EcTbKlWM5Hc39o+cnKLTqJNWQkQaAu\n3yO75kHn0ber9evtwHyh2P788e2hMSAfjjuOrWp6iQKBgQD7zo1nfqUCZ1lG08sE\n2vPb9h/dq2CIghQ2NZHkZc8QbrN+8O9vGgGw9Lx50pa4M3HpHAHGiyjHYyICodWY\nItNNW67GchG4FErukGT1aj7XbLl0qUMU/Tbasp28KV4MyOBF6zu7r8sNSV2dcoZl\n8sT95q8w5pE1q6/Rv7IDdKOEjQKBgQDB13QplNcU+CEcDI5fh/p3rmitT3O5pZcs\nAaMwPoKKPzHMJ55hiaf7k7mxnDYMwLGb8DWvEa+Z5MiKOHnQ341EG4YpDJ2NjOXh\njOa4sxJJrMsl5IsIMuN4JKB6leeV0HVfhnOwhvWxw8ydfQBivwr7UWGwm0N8l4ln\nrD9uIwMA6QKBgB2pby7J1FY8JmAZgsjHogI/M/HAxlZOPGNGXDEzbPjakXpal1nX\nDmvx6GHRVL/OD5UOH4drzRnvq4nz1l1dfNaX/jaSKVQtz8XabIocQdrOi+oaJjTK\nvzNz5vDLTrDi2MhjtV2ej6S5B7YMT5iiyGHAUtLH1kMkPknO8wFFNB3hAoGBAKlG\nTEPPna/LK6bB9EbKiVigbvsCCi5Rw90DJQ4JWJf/ra353x6QRqi0jZHjt3OVhsSF\nlWvnxvBeUR4bSY202zlhy9vnD2NAarN4Bze98TdzdQgHN76WKfyOB7JlXBKBMy6T\nTE4Y3VA6i2HwejjBWoazv9qpC9suvihRzCaS3pmZAoGAWaXMzCxYw9oIglECvozy\n9vIZTODVwEuc1ARA3BKAfL4XLGbtBXMgP+1GP+JOFVZPM6QnH5dmOqwjBmrddE9+\n9x0jDNV73FELm2QOUR0HUN3qI/L+ZBV3NiG/jyUG4RU9/5vDl9sToOkP3A25pwZD\nFnYx5s+da++PMS6NJFnrM0E=\n-----END PRIVATE KEY-----\n",
      client_email:
        "firebase-adminsdk-ty526@lightroads-a4db7.iam.gserviceaccount.com",
      client_id: "108197641527104114270",
      auth_uri: "https://accounts.google.com/o/oauth2/auth",
      token_uri: "https://oauth2.googleapis.com/token",
      auth_provider_x509_cert_url: "https://www.googleapis.com/oauth2/v1/certs",
      client_x509_cert_url:
        "https://www.googleapis.com/robot/v1/metadata/x509/firebase-adminsdk-ty526%40lightroads-a4db7.iam.gserviceaccount.com",
    },
  };
  
  // 開発環境用firebase設定
  const develop = {
    FIREBASE_API_KEY: "AIzaSyDwMYcRzdToz3wuQVw-gGz-5DqTCLb1W-Y",
    FIREBASE_AUTH_DOMAIN: "lightroads-a4db7.firebaseapp.com",
    FIREBASE_DATABASE_URL: "https://lightroads-a4db7-default-rtdb.firebaseio.com",
    FIREBASE_PROJECT_ID: "lightroads-a4db7",
    FIREBASE_STORAGE_BUCKET: "lightroads-a4db7.appspot.com",
    FIREBASE_MESSAGING_SENDER_ID: "312333073302",
    DOMAIN: "http://localhost:3000",
    SERVICE_ACCOUNT_KEY: {
      type: "service_account",
      project_id: "lightroads-a4db7",
      private_key_id: "f9ab69e459cda8eb78002eafa3acace97dc9c185",
      private_key:
        "-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQC+qqVWRvRHM8d3\nonUyvTLu3QJlTVZrbWH2AgRxaVXixwQLtai0C3cc+w6qfHe/bASIN78IsnU+73Tt\nvcHGkyWHZmFvncAuXB+dyOFRgkNtQZcQDiBNZ0z/DfdIsgli8y4+qrEPw+FOFiGg\nBmcFyxvAbhjoR1wuUoHWzU1wYpk5FGYOddmp7O3kmPvRBH0uKy8Uh6TPXZ5wIrhE\nzS0L2IT5yJRU5cpgJ35MivfCn+eXtw1Py/6DxDQfKCLvqDCUxpufVeJ/duKTbFyq\nrGRo9mWnkAdmFc6UuDBq2C3Zx6p20GFWGUFcBiAwEiPfnymIxXMTL21zfACHcpwT\nZcT9eqRVAgMBAAECggEAH0Yy2gDH80DrRDSV2DV/+/2s9axWk0btFVLV4ztlrs25\nnGkW67dguZR1nqHNa4zefnB0aYsKH8Fb0t44AWgj9P5mV45O1lvehL1NNhcxk6N5\nUZUbl86sqr5h9B7d2sjsKjPJ2L3mwC3xpIPAVMswuWLlu33vARsf+HmS7O8DVoD4\nti9ys5h9G3c+G2XZKocIMGnt1PaV+D3408CBqkOPPVTh75+3k0MpC+pkbJB6g03B\nAWLFBOmkGxGbQ1OdYqZRnqzu5w8W74W0g4EcTbKlWM5Hc39o+cnKLTqJNWQkQaAu\n3yO75kHn0ber9evtwHyh2P788e2hMSAfjjuOrWp6iQKBgQD7zo1nfqUCZ1lG08sE\n2vPb9h/dq2CIghQ2NZHkZc8QbrN+8O9vGgGw9Lx50pa4M3HpHAHGiyjHYyICodWY\nItNNW67GchG4FErukGT1aj7XbLl0qUMU/Tbasp28KV4MyOBF6zu7r8sNSV2dcoZl\n8sT95q8w5pE1q6/Rv7IDdKOEjQKBgQDB13QplNcU+CEcDI5fh/p3rmitT3O5pZcs\nAaMwPoKKPzHMJ55hiaf7k7mxnDYMwLGb8DWvEa+Z5MiKOHnQ341EG4YpDJ2NjOXh\njOa4sxJJrMsl5IsIMuN4JKB6leeV0HVfhnOwhvWxw8ydfQBivwr7UWGwm0N8l4ln\nrD9uIwMA6QKBgB2pby7J1FY8JmAZgsjHogI/M/HAxlZOPGNGXDEzbPjakXpal1nX\nDmvx6GHRVL/OD5UOH4drzRnvq4nz1l1dfNaX/jaSKVQtz8XabIocQdrOi+oaJjTK\nvzNz5vDLTrDi2MhjtV2ej6S5B7YMT5iiyGHAUtLH1kMkPknO8wFFNB3hAoGBAKlG\nTEPPna/LK6bB9EbKiVigbvsCCi5Rw90DJQ4JWJf/ra353x6QRqi0jZHjt3OVhsSF\nlWvnxvBeUR4bSY202zlhy9vnD2NAarN4Bze98TdzdQgHN76WKfyOB7JlXBKBMy6T\nTE4Y3VA6i2HwejjBWoazv9qpC9suvihRzCaS3pmZAoGAWaXMzCxYw9oIglECvozy\n9vIZTODVwEuc1ARA3BKAfL4XLGbtBXMgP+1GP+JOFVZPM6QnH5dmOqwjBmrddE9+\n9x0jDNV73FELm2QOUR0HUN3qI/L+ZBV3NiG/jyUG4RU9/5vDl9sToOkP3A25pwZD\nFnYx5s+da++PMS6NJFnrM0E=\n-----END PRIVATE KEY-----\n",
      client_email:
        "firebase-adminsdk-ty526@lightroads-a4db7.iam.gserviceaccount.com",
      client_id: "108197641527104114270",
      auth_uri: "https://accounts.google.com/o/oauth2/auth",
      token_uri: "https://oauth2.googleapis.com/token",
      auth_provider_x509_cert_url: "https://www.googleapis.com/oauth2/v1/certs",
      client_x509_cert_url:
        "https://www.googleapis.com/robot/v1/metadata/x509/firebase-adminsdk-ty526%40lightroads-a4db7.iam.gserviceaccount.com",
    },
  };
  
  const algolia_dev = {
    APP_ID: "85KQIWVNPX",
    API_KEY: "c806b6625a1bfba9df04e54088e9bbf4",
    INDEX: "lightroads",
    COMP_USER_INDEX: "dev_offerTemplates",
    JOB_STUDENT_INDEX:"dev_jobStudents"
  };
  
  const algolia_prod = {
    APP_ID: "85KQIWVNPX",
    API_KEY: "c806b6625a1bfba9df04e54088e9bbf4",
    INDEX: "lightroads",
    COMP_USER_INDEX: "prod_offerTemplates",
    JOB_STUDENT_INDEX:"prod_jobStudents"
  };
  
  const ENVs = {
    development: {
      // 開発環境の変数
      firebaseInitOption: develop,
      algoliaInitOption: algolia_dev,
    },
    production: {
      // 本番環境の変数
      firebaseInitOption: production,
      algoliaInitOption: algolia_prod,
    },
  };
  
  function getEnvVars() {
    const appEnv = process.env.APP_ENV || "development";
  
    if (appEnv === "production") {
      return ENVs.production;
    } else {
      return ENVs.development;
    }
  }
  
  export const ENV = getEnvVars();